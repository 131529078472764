import {Component, HostListener, OnInit} from '@angular/core';
import {SharedService} from '../shared-service.service';
import {Event as NavigationEvent, NavigationEnd, Router} from '@angular/router';
import {NewIncidentComponent} from '../new-incident/new-incident.component';
import {ApiService} from '../api.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {NotificationMdlComponent} from '../notification-mdl/notification-mdl.component';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {setOffsetToUTC} from 'ngx-bootstrap/chronos/units/offset';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  // -Variables para typeahead ->
  searchItemSelected: string;
  typeaheadLoading: boolean;
  dataSource: Observable<any>;
  // -Variables para typeahead <-

  lclShowAdminAccess: boolean;
  lclShowUserAccess: boolean;
  lclShowDashboardMenu: boolean;

  user: any = {};
  incidentFilter: number;
  notificationList: any = [];
  bsModalRef: BsModalRef;
  notificationCount;
  insured: any = {};

  lclCustomerList: any = [];
  customerSelected: any = {};

  widthSize: number = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.widthSize = window.innerWidth;
  }
  constructor(private api: ApiService,
              public shared: SharedService,
              private router: Router,
              private modalService: BsModalService,
              private toastr: ToastrService) {
    this.shared.gCurrentRoleId = Number(localStorage.getItem('roleId'));

    this.getProfile();

    this.shared.fUpdateProfileObservable().subscribe(
      fProfile => {
        if (fProfile) {
          this.getProfile();
          this.shared.fUpdateProfile.next(false);
        }
      }
    );

    /**
     * Observable para monitorear los cambios en la barra de Búsqueda
     */
    this.dataSource = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.searchItemSelected);
    }).pipe(
      mergeMap((token: string) => this.getStatesAsObservable(token))
    );
  }

  ngOnInit() {
    this.user = {
      imageProfile: '../../assets/img/userprofile.png',
      name: '<i>Nombre de usuario</i>',
      position: '<i>Puesto de usuario</i>'
    };

    if (this.shared.gCurrentRoleId === 3) {
      this.invokeServiceForGetCustomerList();
    }

    this.lclShowAdminAccess = false;
    this.lclShowUserAccess = false;
    this.shared.gUserRoles.forEach((item, index) => {
      if (item.roleId === 3 && this.shared.gCurrentRoleId !== 3) {
        this.lclShowAdminAccess = true;
        this.lclShowUserAccess = false;
      } else if (item.roleId === 4 && this.shared.gCurrentRoleId === 3) {
        this.lclShowUserAccess = true;
        this.lclShowAdminAccess = false;
      }
      if (this.shared.gCurrentRoleId === 3) {
        this.lclShowDashboardMenu = true;
      }
    });

    this.incidentFilter = 0;
    /* rm-> this.shared.pgTitleObservable().subscribe(
      pgTitle => this.pgLclTitle = pgTitle
    );*/
    this.getNotificationUser();
    this.getcountNotification();
  }

  changeIncidentFilter(filterNumber) {
    this.incidentFilter = filterNumber;
    this.shared.fIncidentGeneral.next(filterNumber);
  }


  private getNotificationUser() {
    this.api.getNotificationUser().then((data) => {
        this.notificationList = data;
      }, error => {
        console.error(error);
      }
    );
  }

  private getcountNotification() {
    this.api.getcountNotification().then((data) => {
        if (data === null) {
          this.notificationCount = 0;
        } else {
          this.notificationCount = data;
        }
      }, error => {
        console.error(error);
        if (error.status === 204) {
          this.notificationCount = 0;
        }
      }
    );
  }

  modalnotification(notication) {
    const initialState = {
      list: [
        'Open a modal with component',
        'Pass your data',
        'Do something else',
        '...'
      ],
      title: 'Modal with component',
      notification: notication
    };
    this.bsModalRef = this.modalService.show(NotificationMdlComponent, {initialState});
    this.bsModalRef.setClass('modal-md');
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.getNotificationUser();
        this.getcountNotification();
      } else if (result === false) {
        console.info('Modal cancel');
      } else {
        console.info('Modal close');
      }
    });
  }

  deletenotification(notification) {
    this.api.deleteNotification(notification.id_notification).subscribe( response => {
      this.getNotificationUser();
    }, error => {
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
    });
  }

  /* Funciones para perfil */
  getProfile() {
    this.api.getUserProfile()
      .then(
        (data) => {
          this.user = data;
          this.shared.gProfileImg = this.user.imageProfile;
          this.shared.gProfileName = this.user.name;
          this.shared.gProfilePosition = this.user.position;
        }, error => {
          console.log(error);
        }
      );
  }

  closeSession() {
    localStorage.clear();
    this.shared.fStreamRoomMessages.next(false);
    // this.wsRocket.disconnectToHost();
    // this.router.navigate(['/login']).then();
    window.open('/', '_self');
  }

  goToAdminProfile() {
    this.lclShowAdminAccess = false;
    this.lclShowUserAccess = true;
    localStorage.setItem('roleId', '3');
    this.router.navigate(['/admin/home']);
  }
  goToUserProfile() {
    this.lclShowAdminAccess = true;
    this.lclShowUserAccess = false;
    localStorage.setItem('roleId', '4');
    this.router.navigate(['/user/home']);
  }
  /*----------------------------------------------------------------------------------------------------------------------------------------
   -----------------------------------B U S Q U E D A    G E N E R A L----------------------------------------------------------------------
   ---------------------------------------------------------------------------------------------------------------------------------------*/
  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }
  typeaheadOnSelect(e: TypeaheadMatch): void {
    const itemSelected: any = e.item;
    // this.searchItemSelected = `Póliza: ${itemSelected.title} - ${itemSelected.description}`;
    switch (itemSelected.type) {
      case 1:
        this.shared.gPolicyId.next(itemSelected.id);
        this.shared.fOpenSidebar(4);
        break;
      case 2:
        this.shared.gIncidentId.next(itemSelected.id);
        this.shared.fOpenSidebar(5);
        break;
      case 3:
        this.shared.gRequestId.next(itemSelected.id);
        this.shared.fOpenSidebar(6);
        break;
      case 4:
        this.insured = {
                userId: itemSelected.id,
                name: itemSelected.title,
                dependet: null,
                employeeNumber: null,
                created: null
                };
        this.shared.fInsuredDataReadonly = true;
        localStorage.setItem('insuredView', JSON.stringify(this.insured));
        this.shared.pgInsured.next(this.insured.userId);
        this.router.navigate(['/admin/insured']);
    }
  }

  getStatesAsObservable(token: string): Observable<any> {
    return this.api.getFinderResult(token).pipe();
  }

  /*----------------------------------------------------------------------------------------------------------------------------------------
   -----------------------------------C A M B I A R   D E   D A S H B O A R D---------------------------------------------------------------
   ---------------------------------------------------------------------------------------------------------------------------------------*/
  public changeDashboard(item) {
    this.shared.fAdminDashboard = item;
  }

  /*----------------------------------------------------------------------------------------------------------------------------------------
   -------------------------------------C A M B I A R   D E   C L I E N T E-----------------------------------------------------------------
   ---------------------------------------------------------------------------------------------------------------------------------------*/
  private invokeServiceForGetCustomerList() {
    this.api.getCustomerList().subscribe((response: any) => {
      if (response !== null && response !== undefined && response.length > 0) {
        this.lclCustomerList = response;
        this.customerSelected = response[0].customerId;
        this.shared.gCurrentBranchId.next(response[0].branchId);
        this.shared.gCurrentCustomerId.next(response[0].customerId);
        this.shared.gCurrentCustomerName.next(response[0].customerName);
        this.shared.gCurrentPolicyBenefit = response[0].policyBenefit;
        this.shared.gCurrentPolicyCar = response[0].policyCar;
        this.shared.gCurrentPolicyDebtorLife = response[0].policyDebtorlife;
      }
    }, error => {
      console.error(error);
    });
  }

  public changeClientToConsult() {
    this.shared.gCurrentCustomerId.next(this.customerSelected);
    this.api.getBranchsByClient().subscribe(
      (response: any) => {
        this.shared.gCurrentBranchId.next(response[0].idBranch);
      }, error => {
        this.toastr.info('Sin pólizas activas');
      }
    );

    for (let item of this.lclCustomerList) {
      if (this.customerSelected === item.customerId) {
        this.shared.gCurrentCustomerName.next(item.customerName);
        this.shared.gCurrentPolicyBenefit = item.policyBenefit;
        this.shared.gCurrentPolicyCar = item.policyCar;
        this.shared.gCurrentPolicyDebtorLife = item.policyDebtorlife;

      }
    }
  }
}
