import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SharedService} from '../shared-service.service';

declare var $: any;
@Component({
  selector: 'app-dashboard-ammia',
  templateUrl: './dashboard-ammia.component.html',
  styleUrls: ['./dashboard-ammia.component.css']
})
export class DashboardAmmiaComponent implements OnInit {
  // variables resumen general
  dataBranchGroup: any = {};
  totalEventsRecords = 0;
  eventsList: any = [];
  eventPage = 1;
  pagePolicy: number;
  pagePolicyTmp: number;
  policyAmmiaList: any = [];
  policyAmmia: boolean;
  dataIndicators: any = {};
  indicatorsAmmia: boolean;
  dataPolicy: any = {};
  policyCoverageList: any = [];
  policyAttachmentsList: any = [];
  // end resumen general
  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public shared: SharedService) {
    this.dataBranchGroup = [
      {
        idBranch: 1,
        nameBranch: 'BENEFICIOS',
        totalNetPremium: 1
      },
      {
        idBranch: 2,
        nameBranch: 'DAÑOS',
        totalNetPremium: 0
      },
      {
        idBranch: 3,
        nameBranch: 'AUTOS',
        totalNetPremium: 1
      }
    ];
  }

  ngOnInit(): void {
    this.policyAmmia = false;
    this.indicatorsAmmia = false;
    this.invokeServiceGetBranchesByGroupClients();
    this.invokeServiceForGetEventLogs();
    this.pagePolicy = 1;
    this.pagePolicyTmp = 1;
    this.invokeServiceGetPolicies();
    this.invokeServiceGetIndicators();

    // tslint:disable-next-line:only-arrow-functions
    $(document).ready(function() {
      const owl1 = $('#carousel1');

      owl1.owlCarousel({
        items: 4,
        singleItem: true,
        loop: true,
        autoPlay: true,
        nav: false,
        dots: true,
        autoPlayTimeout: 2000,
        responsive: {
          0: {
            items: 1.25
          },
          992: {
            items: 2
          },
          1200: {
            items: 3
          }
        }
      });
      // tslint:disable-next-line:only-arrow-functions
      $('.customNextBtn1').click(function() {
        owl1.trigger('next.owl.carousel');
      });

      // tslint:disable-next-line:only-arrow-functions
      $('.customPreviousBtn1').click(function() {
        owl1.trigger('prev.owl.carousel');
      });
    });
    // tslint:disable-next-line:only-arrow-functions
    $(document).ready(function() {
      const owl2 = $('#carousel2');

      owl2.owlCarousel({
        items: 1,
        singleItem: true,
        loop: true,
        autoPlay: true,
        nav: false,
        dots: true,
        autoPlayTimeout: 2000,
        //slideBy: 2,
        responsive: {
          0: {
            items: 2
          },
          768: {
            items: 2
          },
          992: {
            items: 2
          },
          1200: {
            items: 2
          },
        }
      });
      // tslint:disable-next-line:only-arrow-functions
      $('.customNextBtn2').click(function() {
        owl2.trigger('next.owl.carousel', [300]);
      });
      // tslint:disable-next-line:only-arrow-functions
      $('.customPreviousBtn2').click(function() {
        owl2.trigger('prev.owl.carousel', [300]);
      });
    });



  }


  invokeServiceGetBranchesByGroupClients() {
    this.api.getBranchesByGroupClient().subscribe(
      (response: any) => {
        this.dataBranchGroup = response;
      }, error => {
        this.toastr.info('Sin pólizas activas');
      }
    );
  }

  /*-----------------------------------------------------------------------------------------------------------
    -----------------------------------RESUMEN GENERAL-----------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------*/

  invokeFunctionRedirect(direct: string, activeMenu: number) {
    this.shared.activeMenu.next(activeMenu);
    this.router.navigate(['/admin/' + direct]);
  }

  onScrollDashboard() {
    if (this.totalEventsRecords === this.eventsList.length) {
      return;
    }
    this.eventPage++;
    this.invokeServiceForGetEventLogs();
  }

  /**
   * Método para obtener una lista de Eventos
   * la lista se obtiene a través del servicio REST @getEventLog
   * se indican los parametros de paginado Número de Pagina, Tamaño del pagiinado
   *
   * 1.- Invocamos el servicio rest y recuperamos la respuesta en nuestra variable @eventsList
   */
  invokeServiceForGetEventLogs() {
    this.api.getEventLog(this.eventPage, 10)
      .then(
        (response: any) => {
          if (response != null) {
            this.totalEventsRecords = response.total;
            this.eventsList = this.eventsList.concat(response.events);
          }
        }, error => {
          console.info(error);
        });
  }

  invokeFunctionPreviousPage() {
    this.pagePolicyTmp --;
    if (this.pagePolicyTmp % 2 === 0) {
      return;
    } else {
      this.pagePolicy --;
      if (this.pagePolicy > 0) {
        this.invokeServiceGetPolicies();
      }
    }
    console.log(this.pagePolicy);
  }
  invokeFunctionNextPage() {
    this.pagePolicyTmp ++;
    if (this.pagePolicyTmp % 2 === 0) {
      return;
    } else {
        this.pagePolicy ++;
        this.invokeServiceGetPolicies();
    }
    console.log(this.pagePolicy);
  }
  invokeServiceGetPolicies() {
    const dataGetPolicy = {
      page: this.pagePolicy,
      size: 4
    };

    this.api.getPoliciesAmmiaDashboard(dataGetPolicy)
      .then(
        (response: any) => {
          if (response != null) {
            this.policyAmmiaList = response;
            this.policyAmmia = true;
          }
        }, error => {
          console.info(error);
        });
  }

  invokeFunctionLoadDetailPolicy(policy: any) {
    console.log(policy);
    if (policy.branchId === 3) {
      this.router.navigate(['/admin/cars']);
      return;
    }
    this.dataPolicy = policy;
    this.policyCoverageList = [];
    this.policyAttachmentsList = [];
    this.invokeServiceForGetPolicyMoreDetail(this.dataPolicy.policyId);
    this.invokeServiceForGetPolicyAttachment(this.dataPolicy.policyId);

  }

  invokeServiceGetIndicators() {
    this.api.getIndicatorsAmmiaDashboard()
      .then(
        (response: any) => {
          if (response != null) {
            this.dataIndicators = response;
            this.indicatorsAmmia = true;
          }
        }, error => {
          console.info(error);
        });
  }

  invokeServiceForGetPolicyMoreDetail(policyId) {
    this.api.getDetailPolicyjson(policyId)
      .then(
        (response) => {
          this.policyCoverageList = response;
          this.policyCoverageList = this.policyCoverageList.slice(0, 9);
        }, error => {
          this.policyCoverageList = [];
          console.error(error);
        }
      );
  }

  invokeServiceForGetPolicyAttachment(policyId) {
    this.policyAttachmentsList = [];
    this.api.getAttachmentlPolicy(policyId)
      .then(
        (response) => {
          this.policyAttachmentsList = response;
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForDownloadPolicyAttachmentFile(attachment) {
    this.api.getPolicyAttachmentFile(attachment.attachmentId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForDownloadPolicyAttachmentZipFile(policy) {
    if (this.policyAttachmentsList.length === 0) {
      this.toastr.warning('Notificación', 'No hay anexos disponibles');
      return;
    }
    this.api.getPolicyAttachmentZip(policy.policyId)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  `anexos_poliza_${policy.number}.zip`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForDownloadPolicyFile(policy) {
    this.api.getPolicyFile(policy.policyId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', policy.number + '.pdf');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  public invokeServiceForDownloadFilePolicy() {
    const objData = {
      policyId: this.dataPolicy.policyId
    };
    this.api.getFileCsvPolicy(objData)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  this.dataPolicy.number + '.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

}
