<div class="modal-dialog modal-dialog-centered justify-content-center">
  <div class="modal-content evento" *ngIf="incidentId > 0">
    <div class="modal-header evento">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="invokeFunctionCloseModalIncident()"></button>
      <div class="d-column-start gap-8 w-100">

        <h3 class="d-flex gap-8 modal-title " id="verEventoLabel">
          <img src="../../assets/img/ammia/iconos/siniestro-rojo.svg" alt="">
          Siniestro reportado
        </h3>
        <div class="d-between">
          <h5>{{incidentDetail.subBranch | titlecase}}</h5>
          <div  [ngClass]="incidentDetail.phaseId == 5 ? 'tag green' : incidentDetail.phaseId == 6 ? 'tag red' : 'tag yellow' " data-bs-toggle="tooltip" data-bs-placement="top" title="{{incidentDetail.phase}}">
            {{incidentDetail.phase | titlecase}}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.76187 3.49403L5.11593 7.16393L3.7634 5.76587L2.99893 6.52315L4.58668 8.15422C4.64549 8.21247 4.7631 8.27073 4.88071 8.32898C4.99832 8.38723 5.11593 8.38723 5.17474 8.38723C5.23354 8.38723 5.40996 8.38723 5.46876 8.32898C5.58637 8.27073 5.64518 8.21247 5.76279 8.15422L9.58514 4.25131L8.76187 3.49403Z" />
              <path d="M10.291 1.74757C9.17365 0.640777 7.64471 0 5.99815 0C4.41041 0 2.88147 0.640777 1.76416 1.74757C0.64686 2.91262 0 4.36893 0 6C0 7.57282 0.64686 9.08738 1.76416 10.2524C2.88147 11.3592 4.41041 12 5.99815 12C7.5859 12 9.11484 11.3592 10.2321 10.2524C11.3494 9.14563 11.9963 7.63107 11.9963 6C12.0551 4.36893 11.4083 2.91262 10.291 1.74757ZM6.05696 10.835C3.35191 10.835 1.17611 8.62136 1.17611 5.94175C1.17611 3.26214 3.35191 1.04854 6.05696 1.04854C8.76201 1.04854 10.9378 3.26214 10.9378 5.94175C10.9378 8.67961 8.76201 10.835 6.05696 10.835Z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <div class="datos-grupo">
        <div class="datos-row">
          <div>
            <p class="label">Número de certificado</p>
            <p class="dato">{{incidentDetail.certificate}}</p>
          </div>
          <div>
            <p class="label">Póliza</p>
            <p class="dato">{{incidentDetail.policy}}</p>
          </div>
        </div>
        <div class="datos-row">
          <div>
            <p class="label">Titular</p>
            <p class="dato">{{incidentDetail.applicantName | titlecase}}</p>
          </div>
          <div>
            <p class="label">Edad</p>
            <p class="dato">{{incidentDetail.age}} años</p>
          </div>
        </div>
        <div class="datos-row">
          <div>
            <p class="label">Tipo de asegurado</p>
            <p class="dato">{{incidentDetail.kinship | titlecase}}</p>
          </div>
          <div>
            <p class="label">Fecha de alta</p>
            <p class="dato">{{incidentDetail.created}}</p>
          </div>
        </div>
        <div class="datos-row">
          <div>
            <p class="label">Número de folio</p>
            <p class="dato">{{incidentDetail.folio}}</p>
          </div>
          <div>
            <p class="label">Tipo de siniestro</p>
            <p class="dato">{{incidentDetail.typeIncident | titlecase}}</p>
          </div>
        </div>
        <div class="datos-row">
          <div>
            <p class="label">Razón social</p>
            <p class="dato">{{incidentDetail.client | titlecase}}</p>
          </div>
        </div>
        <div class="datos-row">
          <div>
            <p class="label">Descripción del reporte</p>
            <p class="dato">{{incidentDetail.incident | lowercase}}</p>
          </div>
        </div>
        <div class="datos-row" *ngIf="_branchId == 3">
          <div>
            <p class="label">Versión vehículo</p>
            <p class="dato">{{incidentDetail.version | lowercase}}</p>
          </div>
        </div>
      </div>


    </div>
    <div class="siniestro-anexo ">
      <div class=" separador-tarjetas  mt-0">
        <h4>Anexos</h4>
        <hr>
      </div>
      <div class="anexo-card mb-3" *ngFor="let a of attachmentList; index as i">
        <div class="d-center gap-8">
          <img src="../../../assets/img/ammia/iconos/icono_cargadocumento.svg" alt="">
          <div>
            <h5>Nombre de Anexo {{i + 1}}</h5>
            <p>{{a.name}}</p>
          </div>
        </div>
        <button type="button"  href="" class="enlace" (click)="invokeServiceForDownloadFileAttachment(a)">
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.7 9.6C15.4878 9.6 15.2843 9.68429 15.1343 9.83432C14.9843 9.98434 14.9 10.1878 14.9 10.4V13.6C14.9 13.8122 14.8157 14.0157 14.6657 14.1657C14.5157 14.3157 14.3122 14.4 14.1 14.4H2.9C2.68783 14.4 2.48434 14.3157 2.33431 14.1657C2.18429 14.0157 2.1 13.8122 2.1 13.6V10.4C2.1 10.1878 2.01571 9.98434 1.86569 9.83432C1.71566 9.68429 1.51217 9.6 1.3 9.6C1.08783 9.6 0.884344 9.68429 0.734315 9.83432C0.584285 9.98434 0.5 10.1878 0.5 10.4V13.6C0.5 14.2365 0.752856 14.847 1.20294 15.2971C1.65303 15.7471 2.26348 16 2.9 16H14.1C14.7365 16 15.347 15.7471 15.7971 15.2971C16.2471 14.847 16.5 14.2365 16.5 13.6V10.4C16.5 10.1878 16.4157 9.98434 16.2657 9.83432C16.1157 9.68429 15.9122 9.6 15.7 9.6ZM7.932 10.968C8.00808 11.0408 8.0978 11.0979 8.196 11.136C8.29176 11.1783 8.3953 11.2002 8.5 11.2002C8.6047 11.2002 8.70824 11.1783 8.804 11.136C8.9022 11.0979 8.99192 11.0408 9.068 10.968L12.268 7.768C12.4186 7.61736 12.5033 7.41304 12.5033 7.2C12.5033 6.98696 12.4186 6.78264 12.268 6.632C12.1174 6.48136 11.913 6.39673 11.7 6.39673C11.487 6.39673 11.2826 6.48136 11.132 6.632L9.3 8.472V0.8C9.3 0.587827 9.21572 0.384344 9.06569 0.234315C8.91566 0.0842855 8.71217 0 8.5 0C8.28783 0 8.08434 0.0842855 7.93431 0.234315C7.78429 0.384344 7.7 0.587827 7.7 0.8V8.472L5.868 6.632C5.79341 6.55741 5.70486 6.49824 5.6074 6.45787C5.50994 6.4175 5.40549 6.39673 5.3 6.39673C5.19451 6.39673 5.09006 6.4175 4.9926 6.45787C4.89514 6.49824 4.80659 6.55741 4.732 6.632C4.65741 6.70659 4.59824 6.79514 4.55787 6.8926C4.5175 6.99006 4.49673 7.09451 4.49673 7.2C4.49673 7.30549 4.5175 7.40994 4.55787 7.5074C4.59824 7.60486 4.65741 7.69341 4.732 7.768L7.932 10.968Z" />
          </svg>

          Descargar
        </button>
      </div>

    </div>


  </div>
</div>
