import {AfterViewInit, Component, ElementRef, Input, OnInit, Optional, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';
import { Chart } from 'chart.js/auto';
import {ToastrService} from 'ngx-toastr';
import { registerables} from 'chart.js';
import {BsModalService} from 'ngx-bootstrap/modal';
Chart.register(...registerables);
declare var $: any;
@Component({
  selector: 'app-graph-ammia-benefits',
  templateUrl: './graph-ammia-benefits.component.html',
  styleUrls: ['./graph-ammia-benefits.component.css']
})
export class GraphAmmiaBenefitsComponent implements OnInit, AfterViewInit {

  // tslint:disable-next-line:variable-name
  _branchId: number;
  @ViewChild('lineChart', {static: false}) private chartRef: ElementRef;
  @ViewChild('pieChart', {static: false}) private pieChartRef: ElementRef;
  @ViewChild('pieChart1', {static: false}) private pieChart1Ref: ElementRef;
  @ViewChild('line1Chart', {static: false}) private line1ChartRef: ElementRef;
  @ViewChild('line2Chart', {static: false}) private line2ChartRef: ElementRef;
  @ViewChild('line3Chart', {static: false}) private line3ChartRef: ElementRef;
  chart: any;
  pieChart: any;
  pieChart1: any;
  line1Chart: any;
  line2Chart: any;
  line3Chart: any;
  insuranceSelected: any = {};
  totalPopulations: any = [];
  lclSectionSelected: number;
  statisticsFilters: any = [];
  lclStatisticsFilterSelected: any = {};
  policyTypeList: any = [];
  policyTypeCollecionList: any = [];
  showStatics = true;
  dataTypeFilters: any = [];
  branchIdSelected: any;
  staticsIncidentPolicyTypeSelected: any = {};
  dataTypeFilterSelected: any = {};
  staticsAccidentsPolicyTypeSelected: any = {};
  staticsInsuranceList = [];
  staticsCollectionPolicyTypeSelected: any = {};
  insuredList: any = [];
  type: number;
  typeReport: number;
  policiesList: any = [];
  dataTypeFiltersDebtor: any = [];
  staticsCompanyTypeSelected: any = {};
  dataTypeDetorFilter: any = [];
  dataTypeFilterSelectedDebtorLife: any = {};
  statisticId: number;
  filter: any = {};
  isUpdatedForCustomer: boolean;
  idGraph: number;
  subBranchId: number;
  typeId: number;
  constructor(private router: Router,
              private api: ApiService,
              public shared: SharedService,
              private toastr: ToastrService,
              private modalService: BsModalService) {


    setTimeout(() => {
      // console.log('ramo ' +  this._branchId);
      this.shared.gCurrentCustomerId.next(1193);
      this.shared.gCurrentBranchId.next(this._branchId);
      this.loadFilterStatics();
    }, 500);
  }

  ngAfterViewInit(): void {

  }
  get branchId(): number {
    return this._branchId;
  }
  @Input()
  set branchId(value: number) {
    this._branchId = value;
  }

  ngOnInit(): void {
    this.idGraph = 2;

    this.branchIdSelected = this._branchId;
    localStorage.removeItem('insuredView');

    this.dataTypeFilters = [
      {
        id: 1,
        name: 'Cantidad'
      },
      {
        id: 2,
        name: 'Montos'
      }
    ];
    this.dataTypeFilterSelected = this.dataTypeFilters[0];
    this.lclSectionSelected = 0;
    this.dataTypeDetorFilter = [
      {
        id: 1,
        name: 'UDI'
      },
      {
        id: 2,
        name: 'PRIMA'
      }
    ];
    this.dataTypeFilterSelectedDebtorLife = this.dataTypeDetorFilter[0];
  }
  loadFilterStatics() {
    this.statisticId = 1;
    if (this.shared.gCurrentPolicyDebtorLife > 0 && this.shared.gCurrentPolicyBenefit === 0) {
      this.statisticsFilters = [
        {
          id: 5,
          description: 'Vida deudor'
        }
      ];
      this.statisticId = 0;
      this.lclSectionSelected = 3;
    } else if (this.shared.gCurrentPolicyDebtorLife > 0 && this.shared.gCurrentPolicyBenefit > 0) {
      this.statisticsFilters = [
        {
          id: 0,
          // tslint:disable-next-line:max-line-length
          description: this.branchIdSelected === 1 || this.branchIdSelected === '1' ? 'Siniestralidad de empleados' : 'Siniestralidad flotilla'
        },
        /*{
          id: 1,
          description: 'Estatus de usuarios'
        },*/
        {
          id: 2,
          description: 'Siniestralidad/Prima neta devengada'
        },
        {
          id: 3,
          description: 'Uso de póliza'
        },
        {
          id: 5,
          description: 'Vida deudor'
        },
        {
          id: 6,
          description: this.branchIdSelected === 1 || this.branchIdSelected === '1' ? 'Población total' : 'Parque Vehicular'
        },
        {
          id: 7,
          description: 'Estatus de cobranza'
        }/*,
                 {
                   id: 4,
                   description: 'Ver todas'
                 }*/
      ];
    } else {
      this.statisticsFilters = [
        {
          id: 0,
          // tslint:disable-next-line:max-line-length
          description: this.branchIdSelected === 1 || this.branchIdSelected === '1' ? 'Siniestralidad de empleados' : 'Siniestralidad flotilla'
        },
        /*{
          id: 1,
          description: 'Estatus de usuarios'
        },*/
        {
          id: 2,
          description: 'Siniestralidad/Prima neta devengada'
        },
        {
          id: 3,
          description: 'Uso de póliza'
        },
        {
          id: 6,
          description: this.branchIdSelected === 1 || this.branchIdSelected === '1' ? 'Población total' : 'Parque Vehicular'
        },
        {
          id: 7,
          description: 'Estatus de cobranza'
        },
        /*{
          id: 4,
          description: 'Ver todas'
        },*/
      ];
    }
    this.lclStatisticsFilterSelected = this.statisticsFilters[this.statisticId];
    this.getStaticsPolicyType();
  }

  getStaticsPolicyType() {
    if (this.statisticId === 1) {
      this.api.getStaticsPolicyType(this._branchId).then((dataPolicyType: any) => {
        this.policyTypeList = dataPolicyType;
        this.subBranchId = dataPolicyType[0].typePolicyId;
        if (this.policyTypeList !== null && this.policyTypeList.length > 0) {
          this.getChartAccidents(this.policyTypeList[0]);
        } else {
          this.staticsAccidentsPolicyTypeSelected = 'Sin tipo de póliza';
          this.showStatics = true;
          this.policyTypeList = [];
          if (this.line1Chart !== undefined) {
            this.line1Chart.destroy();
          }
        }
      });
    } else {
      this.getFilterDebtorLife();
    }
  }
  changeStatisticsFilter(statisticsFilter) {
    // console.log('entra a changeStatisticsFilter' + statisticsFilter, ' idgraph ' + this.idGraph);
    // console.log(statisticsFilter.target.options);
    this.lclStatisticsFilterSelected = { id: Number(this.idGraph), description: ''};
    this.idGraph = Number(this.idGraph);
    // console.log(this.idGraph);
    switch (this.idGraph) {
      case 0: {
        // console.log(0);
        if (this.policyTypeList !== null && this.policyTypeList.length > 0) {
          this.staticsIncidentPolicyTypeSelected = this.policyTypeList[0];
          this.typeId = 1;
          this.getChartIncident(this.staticsIncidentPolicyTypeSelected);
        }
        break;
      }
      case 1: {
        this.getChartStatusUser();
        break;
      }
      case 2: {
        if (this.staticsAccidentsPolicyTypeSelected.typePolicyId === null ||
          this.staticsAccidentsPolicyTypeSelected.typePolicyId === undefined) {
          if (this.policyTypeList.length !== null && this.policyTypeList.length > 0) {
            this.getChartAccidents(this.policyTypeList[0]);
          }
        } else {
          this.getChartAccidents(this.staticsAccidentsPolicyTypeSelected);
        }
        break;
      }
      case 3: {
        this.getChartInsurancePolicy();
        break;
      }
      case 4: {
        this.router.navigate(['/admin/statics']);
        break;
      }
      case 5: {
        this.getFilterDebtorLife();
        // this.getCharDebtorLife('DEUTSCHE BANK COAC');
        break;
      }
      case 6: {
        this.getStaticsPopulation();
        break;
      }
      case 7: {
        this.getChartStatusCollection(this.policyTypeList[0]);
        break;
      }

    }

  }

  getChartStatusCollection(typePolicy) {

    if (this.pieChart1 !== undefined) {
      this.pieChart1.destroy();
    }

    this.api.getStaticsPolicyType(this._branchId).then((dataPolicyType: any) => {
      this.policyTypeCollecionList = dataPolicyType;
      this.staticsCollectionPolicyTypeSelected = this.policyTypeCollecionList[0];
      if (this.policyTypeCollecionList !== null && this.policyTypeCollecionList.length > 0) {
        // this.getChartIncident(this.policyTypeList[0]);
        this.api.getStatusCollection(typePolicy.typePolicyId, this._branchId).then((data: any) => {
          const labels = [];
          const dt = [];
          const bckgnd = [];
          for (const obj in data) {
            labels.push(data[obj].title);
            dt.push(data[obj].value);
            bckgnd.push('#448AA6');
          }

          this.pieChart1 = new Chart(this.pieChart1Ref.nativeElement, {
            type: 'pie',
            data: {
              datasets: [{
                data: dt,
                backgroundColor: [
                  'rgba(113,192,192,0.79)',
                  'rgba(89, 89, 89, 1)',
                  'rgba(67, 101, 196, 1)',
                  'rgba(1, 24, 38, 1)',
                ]
              }],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels
            },
            options: {
              maintainAspectRatio: false,
            }
          });
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        });
      } else {
        this.staticsAccidentsPolicyTypeSelected = 'Sin tipo de póliza';
        this.showStatics = true;
        this.policyTypeList = [];
        if (this.line1Chart !== undefined) {
          this.line1Chart.clear();
          this.line1Chart.destroy();
        }
      }
    });




  }

  getChartStatusUser() {
    this.api.getStaticsUser(this._branchId).then((data: any) => {

      // console.log(data);
      const labels = [];
      const dt = [];
      const bckgnd = [];
      for (const obj in data) {
        labels.push(data[obj].title);
        dt.push(data[obj].value);
        bckgnd.push('#448AA6');

      }

      this.pieChart = new Chart(this.pieChartRef.nativeElement, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: dt,
            backgroundColor: [
              '#4A42A1',
              '#FD8A59',
              '#DE4C6C',
              '#88DEF9'
            ]
          }],

          // These labels appear in the legend and in the tooltips when hovering different arcs
          labels
        },
        options: {
          maintainAspectRatio: false,
        }
      });
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  changeBranch(branchid) {
    this.isUpdatedForCustomer = false;
    this.shared.gCurrentBranchId.next(branchid.id);
  }

  changePolicyTypeStaticsIncident(policyType) {
    policyType = {
      typePolicyId: this.subBranchId,
      description: ''
    };
    this.getChartIncident(policyType);
  }

  changePolicyTypeStaticsAccidents(policyType) {
    policyType = {
      typePolicyId: this.subBranchId,
      description: ''
    };
    this.getChartAccidents(policyType);
  }

  changePolicyTypeStaticsCollection(policyType) {
    this.getChartStatusCollection(policyType);
  }

  changeCompanyTypeStaticsDebtorLife(company) {
    this.staticsCompanyTypeSelected = company;
    this.getCharDebtorLife(company);
  }

  public changeDataTypeFilter(dataType) {
    // console.log('siniestralidad empleados');
    this.dataTypeFilterSelected = { id: this.typeId, name: ''};
    this.getChartIncident(this.staticsIncidentPolicyTypeSelected);
  }

  public changeDataTypeFilterDebtor(dataType) {
    this.dataTypeFilterSelectedDebtorLife = dataType;
    this.getCharDebtorLife(this.staticsCompanyTypeSelected);
  }

  getChartIncident(policyType) {
    this.staticsIncidentPolicyTypeSelected = policyType;
    this.api.getStaticsIncident(policyType.typePolicyId, this.dataTypeFilterSelected.id, this._branchId).then((data: any) => {

      if (data == null) {
        this.showStatics = true;
        return false;
      }
      if (this.chart !== undefined) {
        this.chart.destroy();
      }
      this.showStatics = false;
      const labels = [];
      const dt = [];
      const bckgnd = [];
      for (const obj in data) {
        labels.push(data[obj].incidentType);
        dt.push(data[obj].number);
        bckgnd.push('#448AA6');

      }

      let labelName = '';
      switch (this.dataTypeFilterSelected.id) {
        case 1:
          labelName = 'Casos';
          break;
        case 2:
          labelName = 'Monto';
          break;
      }
      this.chart = new Chart(this.chartRef.nativeElement, {
        type: 'bar',
        data: {
          labels,
          datasets: [{
            label: labelName,
            data: dt,
            backgroundColor: bckgnd,
            barPercentage: 0.1,
          }]
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true
            },
            x: {
              grid: {
                display: false
              }
            }
          },
          animation: {
            duration: 1000,
            delay: 0,
            loop: false
          },
          // responsiveAnimationDuration: true,
          responsive: true
        }
      });


    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }


  getChartAccidents(policyType) {
    this.staticsAccidentsPolicyTypeSelected = policyType;
    this.api.getStaticsAccidents(policyType.typePolicyId, this._branchId).then((data: any) => {
      if (data == null) {
        this.showStatics = true;
        return false;
      }

      this.showStatics = false;

      // console.log(data);
      const labels = [];
      const dt = [];
      const labelsC = [];
      const dtC = [];
      const bckgnd = [];
      for (const obj in data.incidents) {
        labels.push(data.incidents[obj].month);
        dt.push(data.incidents[obj].premium);
      }

      for (const obj in data.colletions) {
        labelsC.push(data.colletions[obj].month);
        dtC.push(data.colletions[obj].premium);
      }

      setTimeout(() => {
        if (this.line1Chart !== undefined) {
          this.line1Chart.render();
          this.line1Chart.destroy();
        }
        this.line1Chart = new Chart(this.line1ChartRef.nativeElement, {
          type: 'line',
          data: {
            labels, // your labels array
            datasets: [
              {
                label: 'Siniestralidad',
                data: dt, // your data array
                borderColor: '#5855B0',
                fill: false
              },
              {
                label: 'Prima neta devengada',
                data: dtC, // your data array
                borderColor: '#88DEF9',
                fill: false
              }
            ]
          },
          options: {
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
              },
            },
            scales: {
              x: {
                display: true
              },
              y: {
                display: true
              },
            }
          }
        });
      }, 500);


    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  selectInsurance(policyType) {
    policyType = {
      insuranceId: this.subBranchId,
      name: ''
    };
    this.insuranceSelected = policyType; // Se le envía el id del ramo seleccionado

    this.api.getStaticsInsurancePremium(policyType.typePolicyId, this._branchId).then((data: any) => {

      if (data == null) {
        this.showStatics = true;
        return false;
      }
      this.showStatics = false;

      // console.log(data);
      // console.log('*******************');
      const labels = [];
      const dt = [];
      const bckgnd = [];
      for (const obj in data) {
        labels.push(data[obj].month);
        dt.push(data[obj].premium);
        bckgnd.push('#448AA6');

      }

      this.line2Chart = new Chart(this.line2ChartRef.nativeElement, {
        type: 'line',
        data: {
          labels, // your labels array
          datasets: [
            {
              data: dt, // your data array
              borderColor: '#5855B0',
              fill: true
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
          },
          scales: {
            x: {
              display: true
            },
            y: {
              display: true
            },
          }
        }
      });

    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });


  }


  getChartInsurancePolicy() {
    // console.log('entra 3');
    this.api.getStaticsInsurance(this._branchId).then((dataI: any) => {

      // console.log(dataI);
      let list = [];
      list = dataI;

      this.staticsInsuranceList = dataI;
      this.subBranchId = dataI[0].insuranceId;

      if (list.length > 0 ) {

        this.insuranceSelected = dataI[0];


        this.api.getStaticsInsurancePremium(list[0].insuranceId, this._branchId).then((data: any) => {

          if (data == null) {
            this.showStatics = true;
            return false;
          }
          this.showStatics = false;


          // console.log(data);
          // console.log('*******************');
          const labels = [];
          const dt = [];
          const bckgnd = [];
          for (const obj in data) {
            labels.push(data[obj].month);
            dt.push(data[obj].premium);
            bckgnd.push('#448AA6');

          }
          setTimeout(() => {
            this.line2Chart = new Chart(this.line2ChartRef.nativeElement, {
            type: 'line',
            data: {
              labels, // your labels array
              datasets: [
                {
                  data: dt, // your data array
                  borderColor: '#5855B0',
                  fill: true
                }
              ]
            },
            options: {
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false
                },
              },
              scales: {
                x: {
                  display: true
                },
                y: {
                  display: true
                },
              }
            }
          });
          }, 500);
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        });


      }

    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  getFilterDebtorLife() {
    this.api.getStaticsDebtorLifeType().then( (data: any) => {
      this.dataTypeFiltersDebtor = data;
      if (this.dataTypeFiltersDebtor !== null) {
        this.staticsCompanyTypeSelected = this.dataTypeFiltersDebtor[0];
        this.getCharDebtorLife(this.dataTypeFiltersDebtor[0]);
      }
    });
  }
  getCharDebtorLife(company) {
    // this.staticsCompanyTypeSelected = company;
    this.api.getStaticsDebtorLife(company, this.dataTypeFilterSelectedDebtorLife.id).then((data: any) => {

      if (data == null) {
        this.showStatics = true;
        return false;
      }
      if (this.line3Chart !== undefined) {
        this.line3Chart.destroy();
      }
      this.showStatics = false;
      const labels = [];
      const dt = [];
      const bckgnd = [];
      for (const obj in data) {
        labels.push(data[obj].monthName);
        dt.push(data[obj].total);
        bckgnd.push('#448AA6');

      }

      let labelName = '';
      switch (this.dataTypeFilterSelectedDebtorLife.id) {
        case 1:
          labelName = 'UDI Pagada';
          break;
        case 2:
          labelName = 'Prima neta acumulada ';
          break;
      }
      this.line3Chart = new Chart(this.line3ChartRef.nativeElement, {
        type: 'bar',
        data: {
          labels,
          datasets: [{
            label: labelName,
            data: dt,
            backgroundColor: bckgnd,
            barPercentage: 0.1,
          }]
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true
            },
            x: {
              grid: {
                display: false
              }
            }
          },
          // responsiveAnimationDuration: true,
          animation: {
            duration: 1000,
            delay: 0,
            loop: false
          },
          responsive: true
        }
      });
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }
  getStaticsPopulation() {
    this.api.getStaticsPopulations(this._branchId)
      .then(
        (response: any) => {
          if (response != null) {
            this.showStatics = false;
            this.totalPopulations = response;
            // console.log(this.totalPopulations);
          }
        }, error => {
          console.info(error);
        });
  }
}
