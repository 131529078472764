import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';
import {environment} from '../../environments/environment';
import {ToastrService} from 'ngx-toastr';

const API_REST = environment.api_rest;
@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {
  detailPolicy: any = {};
  imageCompany;
  colorCompanyHex;
  annexesList: any = [];
  dependentsList: any = [];
  detailPolicyjson;
  url;
  url1;
  urlcertificate;
  lclPolicyId: number;
  loadservice1;

  constructor(private api: ApiService,
              public shared: SharedService,
              private toastr: ToastrService) {
    this.shared.gPolicyIdObservable().subscribe(
      gPolicyId => {
        this.lclPolicyId = gPolicyId;
        this.loadDetailPolicy(this.lclPolicyId);
      }
    );
  }

  ngOnInit() {
    this.loadservice1 = false;
    if (!this.loadservice1) {
      this.loadDetailPolicy(this.lclPolicyId);
    }
    this.url = API_REST + '/policies/attachments/document/free/';
    this.url1 = API_REST + '/policies/attachments/document/zip/';
    this.urlcertificate = API_REST + '/policies/attachments/certificate/free/';
  }

  loadDetailPolicy(policyId) {
    this.api.getDetailPolicy(policyId)
      .then(
        (data) => {
          this.detailPolicy = data;
          this.imageCompany = this.detailPolicy.imageCompany;
          this.colorCompanyHex = this.detailPolicy.colorCompanyHex;

          this.loadAttachment(this.detailPolicy.policyId);
          this.getDetail(this.detailPolicy.policyId);
          this.getPolicyDependents(this.lclPolicyId);
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  loadAttachment(policyId) {
    this.api.getAttachmentlPolicy(policyId)
      .then(
        (data) => {
          this.annexesList = data;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  getDetail(policyId) {
    this.api.getDetailPolicyjson(policyId)
      .then(
        (data) => {
          this.detailPolicyjson = null;
          this.detailPolicyjson = data;
          this.loadservice1 = true;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  getPolicyDependents(policyId) {
    this.api.getPolicyDependents(policyId)
      .then(
        (data) => {
          const oData: any = data;
          if (data !== null) {
            this.dependentsList = oData.dependentList;
          }
          console.info(data);
        }, error => {
          console.error(error);
        });
  }

  public invokeServiceForGetAttachmentFile(attachment) {
    this.api.getPolicyAttachmentFile(attachment.attachmentId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          console.error(error);
        }
      );
  }

  public invokeServiceForDownloadPolicyAttachmentZipFile(policy) {
    this.api.getPolicyAttachmentZip(policy.policyId)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  `anexos_poliza_${policy.number}.zip`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  /**
   * Descarga el PDF de la póliza
   */
  public invokeServiceForDownloadPolicyFile() {
    this.api.getPolicyCertificate(this.lclPolicyId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', this.detailPolicy.number + '.pdf');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  /*-----------------------------------------------------------------------------------*/
  public hexToRgb(hex, opacity) {
    const rgb = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
      , (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16));

    return `rgba(${rgb.toString()}, ${opacity})`;
  }

}
