<div style="position:relative;height: 100%;width: 340px;padding: 20px">
  <div style="height: 95px;width: 100%; text-align: center;">
    <span class="sx-iconsys-icon" aria-hidden="true" style="float: right;font-size: 22px;font-weight: normal;color: #C4C4C4;cursor: pointer;" (click)="shared.fCloseSidebar()">x</span>
    <p style="color: #448AA6;font-size: 16px;font-weight: 600;line-height: 19px;padding-top: 33px;padding-bottom: 33px">
      Detalle de Solicitud
    </p>
  </div>



  <div style="min-height: calc(100% - 150px);width: 100%;color: #326173">
    <div style="text-align: left;margin-bottom: 10px">
      <span style="display: inline-block;vertical-align: middle;color: #7A7875;font-weight: 500;font-size: 12px">Solicitud</span><span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 242px;border-top: 1px solid #E1E1E1;"></span>
    </div>
    <div *ngIf="lclRequestDetail[0].requestTypeId !==3">
      <div class="p-summary">
        <p class="title"><strong>Asunto: </strong>{{ lclRequestDetail.subject }}</p>
        <p class="data"><strong>Descripción: </strong>{{ lclRequestDetail.description }}</p>
        <p class="data"><strong>Solicitante: </strong>{{ lclRequestDetail.name }}</p>
        <p class="data"><strong>Beneficiario: </strong>{{ lclRequestDetail.benefit }}</p>
        <p class="data"><strong>Parentesco: </strong>{{ lclRequestDetail.typeKinship }}</p>
        <p class="data"><strong>Estatus: </strong> {{ lclRequestDetail.status }}</p>
        <p class="data"><strong>Solicitado el: </strong> {{ lclRequestDetail.created | date: 'dd/MM/yyyy' }}</p>
        <p class="data"><strong>Comentarios: </strong> {{ lclRequestDetail.comments }}</p>
      </div>
    </div>
    <div *ngIf="lclRequestDetail[0].requestTypeId ===3">
      <div class="p-summary">
        <p class="title"><strong>Asunto: </strong>{{ lclRequestDetail[0].subject }}</p>
        <p class="data"><strong>Descripción: </strong>{{ lclRequestDetail[0].description }}</p>
        <ng-container *ngFor="let requestDetail of lclRequestDetail">
          <p class="data"><strong>Poliza: </strong>{{ requestDetail.numberPolicy }}</p>
        </ng-container>

      </div>
    </div>
  </div>
</div>
