import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';

import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';

const API_REST = environment.api_rest_restructured;
const API_REST_AMMIA_WEB = environment.api_rest_ammia_web_surexs;
const API_REST_AMMIA = environment.api_rest_ammia_surexs;

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) { }


 /* ----------------------------------------------------------------------------------------------------
 ---------------------------- API ---------------------------------------------------------------------
 -----------------------------AMMIA -----------------------------------------------------------------
 -----------------------------GENERAL--------------------------------------------------------------
 ----------------------------------------------------------------------------------------------------- */
  // region api_ammia_general
  public getBranchesByGroupClient() {
    // return this.http.post(`http://localhost:8080/api/admin/general/ammia/getBranchByGroup`, null);
    return this.http.post(`${API_REST}/general/ammia/getBranchByGroup`, null);
  }

  public getIndicatorsAmmiaDashboard() {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/general/ammia/indicators', null).subscribe( res => {
      // this.http.post('http://localhost:8080/api/admin/general/ammia/indicators', null).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getInsuredAmmia(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/general/ammia/insured', data).subscribe( res => {
      // this.http.post('http://localhost:8080/api/admin/general/ammia/insured', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getFileInsuredAmmia() {
    return this.http.post(`${API_REST}/general/ammia/downloads/insureds`, null, {responseType: 'arraybuffer'});
    // return this.http.post('http://localhost:8080/api/admin/general/ammia/downloads/insureds', null, {responseType: 'arraybuffer'});
  }

  public getFileVehicle() {
    return this.http.post(`${API_REST}/general/ammia/downloads/cars`, null, {responseType: 'arraybuffer'});
    // return this.http.post(`http://localhost:8080/api/admin/general/ammia/downloads/cars`, null, {responseType: 'arraybuffer'});
  }

  public getCarsAmmia(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST +  '/general/ammia/cars/pages', data).subscribe( res => {
      // this.http.post('http://localhost:8080/api/admin/general/ammia/cars/pages', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getFinderInsuredAmmia(data) {
    // return this.http.post(`http://localhost:8080/api/admin/general/ammia/insuredSearch`, data);
    return this.http.post(`${API_REST}/general/ammia/insuredSearch`, data);
  }

  public getEventLog(page, size) {
    return new Promise((response, error) => {
      // this.http.get(`http://localhost:8080/api/admin/general/events/pages?page=${page}&size=${size}`).subscribe( res => {
      this.http.get(`${API_REST}/general/events/pages?page=${page}&size=${size}`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getFinderResult(param) {
    return this.http.get(`${API_REST}/general/searches?search=${param}`);
    // return this.http.get(`http://localhost:8080/api/admin/general/searches?search=${param}`);
  }
  // endregion
  /* ------------------------ E N D A P I A M M I A G E N E R A L------ -------------------------------- */
  /* ----------------------------------------------------------------------------------------------------
   ---------------------------- API ---------------------------------------------------------------------
   -----------------------------AMMIA -----------------------------------------------------------------
   -----------------------------INCIDENT--------------------------------------------------------------
   ----------------------------------------------------------------------------------------------------- */
  // region api_ammia_incident
  public getIncidentByBranch(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/incident/ammia/pages', data).subscribe( res => {
      // this.http.post('http://localhost:8082/api/admin/incident/ammia/pages', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getIncidentDetailById(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/incident/ammia/details', data).subscribe( res => {
      // this.http.post('http://localhost:8082/api/admin/incident/ammia/details', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getAttachmentsByIncident(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/incident/ammia/attachments', data).subscribe( res => {
     // this.http.post('http://localhost:8082/api/admin/incident/ammia/attachments', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getAttachmentIncidentById(data) {
    // return this.http.post('http://localhost:8082/api/admin/incident/ammia/attachments/downloads', data, {responseType: 'arraybuffer'});
    return this.http.post(API_REST +  '/incident/ammia/attachments/downloads', data, {responseType: 'arraybuffer'});
  }

  public getTypeIncidentsBySubBranches(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/incident/ammia/types', data).subscribe( res => {
      // this.http.post('http://localhost:8082/api/admin/incident/ammia/types', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveClaimDamage(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/incident/ammia', data).subscribe( res => {
      // this.http.post('http://localhost:8082/api/admin/incident/ammia', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  // endregion
  /* ------------------------ END API AMMIA INCIDENT------ -------------------------------- */
  /* ----------------------------------------------------------------------------------------------------
 ---------------------------- API ---------------------------------------------------------------------
 -----------------------------AMMIA -----------------------------------------------------------------
 -----------------------------POLICY--------------------------------------------------------------
 ----------------------------------------------------------------------------------------------------- */
  // region api_ammia_policy
  public getPoliciesAmmiaDashboard(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/policies/ammia/policies', data).subscribe( res => {
      //  this.http.post('http://localhost:8084/api/admin/policies/ammia/policies', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPoliciesAmmiaDashboardBenefits() {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/policies/ammia/beneficts/list', null).subscribe( res => {
      // this.http.post('http://localhost:8084/api/admin/policies/ammia/beneficts/list', null).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getPoliciesAmmiaDashboardBenefitsDetail(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/policies/ammia/beneficts', data).subscribe( res => {
      // this.http.post('http://localhost:8084/api/admin/policies/ammia/beneficts', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPoliciesAmmiaDashboardDamages() {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/policies/ammia/damages/list', null).subscribe( res => {
      // this.http.post('http://localhost:8084/api/admin/policies/ammia/damages/list', null).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getDetailPolicyjson(id) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8084/api/admin/policies/detail/json/' + id).subscribe( res => {
      this.http.get(API_REST +  '/policies/detail/json/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getAttachmentlPolicy(id) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8084/api/admin/policies/attachments/' + id).subscribe( res => {
      this.http.get(API_REST +  '/policies/attachments/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPolicyAttachmentZip(policyId) {
    return this.http.get(`${API_REST}/policies/attachments/document/zip/${policyId}`, {responseType: 'arraybuffer'});
   // return this.http.get(`http://localhost:8084/api/admin/policies/attachments/document/zip/${policyId}`, {responseType: 'arraybuffer'});
  }

  public getPolicyFile(policyId) {
   return this.http.get(`${API_REST}/policies/${policyId}/anexes`, {responseType: 'arraybuffer'});
   // return this.http.get(`http://localhost:8084/api/admin/policies/${policyId}/anexes`, {responseType: 'arraybuffer'});
  }

  public getFileCsvPolicy(data) {
    return this.http.post(`${API_REST}/policies/information/downloads`, data,  {responseType: 'arraybuffer'});
    // return this.http.post(`http://localhost:8084/api/admin/policies/information/downloads`, data, {responseType: 'arraybuffer'});
  }

  public getStaticsIncident(policyTypeId, newFilter, idBranch?) {
    const objData = {
      subbranchId: policyTypeId,
      filterId: newFilter,
      branchId: idBranch
    };
    return new Promise((response, error) => {
        this.http.post(API_REST +  '/statistics/ammia/subbranches/accidents/filters', objData).subscribe( res => {
        // this.http.post('http://localhost:8084/api/admin' +  '/statistics/ammia/subbranches/accidents/filters', objData).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsUser(idBranch?) {
    const objData = {
      branchId: idBranch
    };
    return new Promise((response, error) => {
        this.http.post(API_REST +  '/statistics/ammia/users', objData).subscribe( res => {
        // this.http.post('http://localhost:8084/api/admin' +  '/statistics/ammia/users', objData).subscribe( res => {
          response(res);
        }, (err) => {
          error(err);
        });
  });
  }

  public getStatusCollection(subranchId, idBranch?) {
    const objData = {
      subbranchId: subranchId,
      branchId: idBranch
    };
    return new Promise((response, error) => {
        this.http.post(API_REST +  '/statistics/ammia/collection/status/subbranches', objData).subscribe( res => {
       // this.http.post('http://localhost:8084/api/admin' +  '/statistics/ammia/collection/status/subbranches', objData).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsPopulations(idBranch?) {
    const objData = {
      branchId:  idBranch
    };
    return new Promise((response, error) => {
        this.http.post(API_REST +  '/statistics/ammia/populations/customers', objData).subscribe( res => {
        // this.http.post('http://localhost:8084/api/admin' +  '/statistics/ammia/populations/customers', objData).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsAccidents(policyTypeId, idBranch?) {
    const objdata = {
      subbranchId: policyTypeId,
      branchId: idBranch
    };
    return new Promise((response, error) => {
        // this.http.post( 'http://localhost:8084/api/admin/statistics/ammia/accidents/subbranches', objdata).subscribe( res => {
        this.http.post(API_REST +  '/statistics/ammia/accidents/subbranches', objdata).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsInsurance(idBranch?) {
    const objData = {
      branchId: idBranch
    };
    return new Promise((response, error) => {
        // this.http.post('http://localhost:8084/api/admin/statistics/ammia/insurances', objData).subscribe( res => {
        this.http.post(API_REST +  '/statistics/ammia/insurances', objData).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsInsurancePremium(insuranceId, id?) {
    const objData = {
      subbranchId: insuranceId,
      branchId: id
    };
    return new Promise((response, error) => {
         this.http.post(API_REST +  '/statistics/ammia/subbranches/premiums', objData).subscribe( res => {
        //  this.http.post('http://localhost:8084/api/admin' +  '/statistics/ammia/subbranches/premiums',  objData).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsPolicyType(id?) {
    const objData = {
      branchId: id
    };
    return new Promise((response, error) => {
        // this.http.post('http://localhost:8084/api/admin/statistics/ammia/types/policies', objData).subscribe( res => {
      this.http.post(API_REST +  '/statistics/ammia/types/policies', objData).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsDebtorLife(company, type) {
    return new Promise((response, error) => {
        this.http.get(API_REST +  '/statistics/debtors?company=' + company + '&type=' + type).subscribe( res => {
        // this.http.get('http://localhost:8078/api' +  '/statistics/debtors?company=' + company + '&type=' + type).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsDebtorLifeType() {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/statistics/debtors/filters').subscribe( res => {
        // this.http.get('http://localhost:8078/api' +  '/statistics/debtors/filters').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsTypeAmmia(data) {
    // return this.http.post(`http://localhost:8084/api/admin/reports/ammia/types`, data);
    return this.http.post(`${API_REST}/reports/ammia/types`, data);
  }
  public getReportFileAmmia(id) {
    const data = {
      reportId: id
    };
    return this.http.post(`${API_REST}/reports/ammia/downloads`, data, {responseType: 'arraybuffer'});
    // return this.http.post(`http://localhost:8084/api/admin/reports/ammia/downloads`, data,  {responseType: 'arraybuffer'});
  }

  public getReportDataByReportIdAmmia(data) {
    // return this.http.post(`http://localhost:8084/api/admin/reports/ammia/details`, data);
    return this.http.post(`${API_REST}/reports/ammia/details`, data);
  }

  public getPolicyDetailByCategoryId(categoryId) {
    return this.http.get(`${API_REST}/policies/details/${categoryId}/json`);
    // return this.http.get(`http://localhost:8084/api/admin/policies/details/${categoryId}/json`);
  }
  public getPoliciesByUser(data) {
    return this.http.post(`${API_REST}/policies/ammia/by/user`, data);
    // return this.http.post(`http://localhost:8084/api/admin/policies/ammia/by/user`, data);
  }
  public getPolicyDetailAmmia(data) {
     return this.http.post(`${API_REST}/policies/ammia/details/users`, data);
    // return this.http.post(`http://localhost:8084/api/admin/policies/ammia/details/users`, data);
  }
  public getCertificateById(data) {
     return this.http.post(`${API_REST}/policies/ammia/attachments/certificates`,  data, {responseType: 'arraybuffer'});
    // return this.http.post(`http://localhost:8084/api/admin/policies/ammia/attachments/certificates`, data, {responseType: 'arraybuffer'});
  }

  public getPolicyDetailAmmiaCar(data) {
    return this.http.post(`${API_REST}/policies/ammia/details/cars`, data);
    // return this.http.post(`http://localhost:8084/api/admin/policies/ammia/details/cars`, data);
  }

  public sharingCertificate(data) {
    return this.http.post(`${API_REST}/policies/ammia/sharing/certificates`, data);
    // return this.http.post(`http://localhost:8084/api/admin/policies/ammia/sharing/certificates`, data);
  }

  public getFilePolicy() {
    return this.http.get(`${API_REST}/policies/downloads`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8084/api/adminpolicies/downloads`, {responseType: 'arraybuffer'});
  }

  public getClientsByPolicy(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/policies/ammia/clients', data).subscribe( res => {
      // this.http.post('http://localhost:8084/api/admin/policies/ammia/clients', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReceiptsByBranch(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/receipts/ammia/pages', data).subscribe( res => {
      // this.http.post('http://localhost:8084/api/admin/receipts/ammia/pages', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStatusReceipts() {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/receipts/ammia/status', null).subscribe( res => {
      // this.http.post('http://localhost:8084/api/admin/receipts/ammia/status', null).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getPoliciesByBranch(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST +  '/receipts/ammia/filters/policies', data).subscribe( res => {
      // this.http.post('http://localhost:8084/api/admin/receipts/ammia/filters/policies', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getFileReceiptDownload(data) {
    return this.http.post(`${API_REST}/receipts/ammia/downloads`, data, {responseType: 'arraybuffer'});
    // return this.http.post(`http://localhost:8084/api/admin/receipts/ammia/downloads`,  data,   {responseType: 'arraybuffer'});
  }

  public getReceiptAttachmentZipFileAmmia(data) {
    return this.http.post(`${API_REST}/receipts/ammia/anexes/zip/`, data, {responseType: 'arraybuffer'});
    // return this.http.post(`http://localhost:8084/api/admin/receipts/ammia/anexes/zip/`, data,  {responseType: 'arraybuffer'});
  }

  public getSubgroupsCategoryByPolicy(id) {
    const dataRequest = {
      policyId: id
    };
    return this.http.post(`${API_REST}/policies/ammia/subgroups`, dataRequest);
    // return this.http.post(`http://localhost:8084/api/admin/policies/ammia/subgroups`, dataRequest);
  }

  public getClientsByRequest() {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/policies/ammia/clients/users', null).subscribe( res => {
      // this.http.post('http://localhost:8084/api/admin/policies/ammia/clients/users', null).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getPolicyClientsByRequest(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/policies/ammia/policiesClients', data).subscribe( res => {
      // this.http.post('http://localhost:8084/api/admin/policies/ammia/policiesClients', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPolicyClientsByRequestCars(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/policies/ammia/policiesClients/cars', data).subscribe( res => {
      // this.http.post('http://localhost:8084/api/admin/policies/ammia/policiesClients/cars', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPolicyClientsRequestByUser(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/policies/ammia/policySubranchCategoryByDependent', data).subscribe( res => {
      // this.http.post('http://localhost:8084/api/admin/policies/ammia/policySubranchCategoryByDependent', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getSubgroupsWithCategories(policyId) {
    return this.http.get(`${API_REST}/policies/groups/${policyId}`);
    // return this.http.get(`http://localhost:8084/api/admin/policies/groups/${policyId}`);
  }
  // endregion

  /* ------------------------ END API AMMIA POLICY------ -------------------------------- */
  /* ----------------------------------------------------------------------------------------------------
   ---------------------------- API ---------------------------------------------------------------------
   -----------------------------AMMIA -----------------------------------------------------------------
   -----------------------------REQUEST--------------------------------------------------------------
   ----------------------------------------------------------------------------------------------------- */
  // region api_ammia_request
  public getStatusRequest() {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:8086/api/admin/requests/ammia/status', null).subscribe( res => {
      this.http.post(API_REST +  '/requests/ammia/status', null).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getRequestByBranch(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/requests/ammia/pages', data).subscribe( res => {
      // this.http.post('http://localhost:8086/api/admin/requests/ammia/pages', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRequestDetailById(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/requests/ammia/details', data).subscribe( res => {
      // this.http.post('http://localhost:8086/api/admin/requests/ammia/details', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveRequestDamage(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:8086/api/admin/requests/ammia/general/damages', data).subscribe( res => {
      this.http.post(API_REST +  '/requests/ammia/general/damages', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveRequestBenefits(data) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:8086/api/admin/requests/ammia/general/benefits`, data).subscribe( res => {
      this.http.post(`${API_REST}/requests/ammia/general/benefits`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveRequestCars(data) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:8086/api/admin/requests/ammia/general/cars`, data).subscribe( res => {
      this.http.post(`${API_REST}/requests/ammia/general/cars`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postRequestInsuredRemoveAmmia(data) {
    // return this.http.post(`http://localhost:8086/api/admin/requests/ammia/insured/cancellations`, data);
    return this.http.post(`${API_REST}/requests/ammia/insured/cancellations`, data);
  }

  public getDependentsByUserAmmia(data) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:8086/api/admin/requests/ammia/dependents`, data).subscribe( res => {
        this.http.post(`${API_REST}/requests/ammia/dependents`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postRequestDependentRemoveAmmia(data) {
    // return this.http.post(`http://localhost:8086/api/admin/requests/ammia/dependents/cancellations`, data);
    return this.http.post(`${API_REST}/requests/ammia/dependents/cancellations`, data);
  }
  public postRequestInsuredAddAmmia(data) {
    // return this.http.post(`http://localhost:8086/api/admin/requests/ammia/insured/registrations`, data);
    return this.http.post(`${API_REST}/requests/ammia/insured/registrations`, data);
  }
  public postRequestDependentAddAmmia(data) {
    // return this.http.post(`http://localhost:8086/api/admin/requests/ammia/dependents/registrations`, data);
    return this.http.post(`${API_REST}/requests/ammia/dependents/registrations`, data);
  }
  public getLayoutMassiveLoadInsuredAmmia(data) {
    // return this.http.post(`http://localhost:8086/api/admin/requests/ammia/imports/layouts`, data,  {responseType: 'arraybuffer'});
    return this.http.post(`${API_REST}/requests/ammia/imports/layouts`, data,  {responseType: 'arraybuffer'});
  }
  public getRequestTempAmmia(data) {
    // return this.http.post('http://localhost:8086/api/admin/requests/ammia/getRequestTemp', data);
    return this.http.post(`${API_REST}/requests/ammia/getRequestTemp`, data);
  }

  public getPoliciesRequestTempAmmia(data) {
    // return this.http.post(`http://localhost:8086/api/admin/requests/ammia/policiesClients`, data);
    return this.http.post(`${API_REST}/requests/ammia/policiesClients`, data);
  }
  public getRequestTempUpdatedAmmia(data) {
    // return this.http.post(`http://localhost:8086/api/admin/requests/ammia/getRequestTemp/detail`, data);
    return this.http.post(`${API_REST}/requests/ammia/getRequestTemp/detail`, data);
  }
  public getRequestTempDetailAmmia(data) {
    // return this.http.post(`http://localhost:8086/api/admin/requests/ammia/getRequestTempDetail`, data);
    return this.http.post(`${API_REST}/requests/ammia/getRequestTempDetail`, data);
  }
  public processMassiveRequest(RequestTempDetailList) {
    // return this.http.post(`http://localhost:8086/api/admin/requests/imports`, RequestTempDetailList);
    return this.http.post(`${API_REST}/requests/imports`, RequestTempDetailList);
  }

  public postMassiveLoadLayoutInsured(data) {
    return this.http.post(`${API_REST}/requests/saveRequestTemp`, data);
    // return this.http.post(`http://localhost:8086/api/admin/requests/saveRequestTemp`, data);
  }
  public editRequestTempDetail(RequestTempDetailList) {
    // return this.http.post(`http://localhost:8086/api/admin/requests/editRequestTemp`, RequestTempDetailList);
    return this.http.post(`${API_REST}/requests/editRequestTemp`, RequestTempDetailList);
  }
  public postCancellationVehicleRequestData(data) {
    // return this.http.post(`http://localhost:8086/api/admin/requests/cars/cancellations`, data);
    return this.http.post(`${API_REST}/requests/cars/cancellations`, data);
  }
  public getVehiclePolicyByCarId(carId) {
    // return this.http.get(`http://localhost:8086/api/admin/requests/policies/cars/${carId}`);
    return this.http.get(`${API_REST}/requests/policies/cars/${carId}`);
  }
  public postRequestCarAdd(data) {
    // return this.http.post(`http://localhost:8086/api/admin/requests/cars/registrations`, data);
    return this.http.post(`${API_REST}/requests/cars/registrations`, data);
  }
  // endregion
  /* ------------------------ END API AMMIA REQUEST------ -------------------------------- */

  /* ----------------------------------------------------------------------------------------------------
 ---------------------------- API ---------------------------------------------------------------------
 -----------------------------AMMIA -----------------------------------------------------------------
 -----------------------------USER------------- ------------------------------------------------------
 ----------------------------------------------------------------------------------------------------- */
  // region api_ammia_user
  public authentication(credentials) {
    const formParam = new HttpParams()
      .set('username', credentials.username)
      .set('password', credentials.password);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      // this.http.post('http://localhost:8088/api/admin/security/login', formParam, {
      this.http.post(API_REST + '/security/login', formParam, {
        headers: HEADERS
      }).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });

  }

  public SendValidCode(userId) {
    const formParam = new HttpParams()
      .set('userId', userId);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.post(API_REST + '/security/send/valide/code', formParam, {
      // this.http.post('http://localhost:8088/api/admin' + '/security/send/valide/code', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public validatecode(code, userId) {
    const formParam = new HttpParams()
      .set('code', code)
      .set('userId', userId);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.post(API_REST + '/security/valide/code', formParam, {
      // this.http.post('http://localhost:8088/api/admin' + '/security/valide/code', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postRecoveyPassword(email) {
    const formParam = new HttpParams()
      .set('email', email);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.post(API_REST + '/security/sendemail/recovery', formParam, {
      // this.http.post('http://localhost:8088/api/admin' + '/security/sendemail/recovery', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public validateToken(token) {
    const formParam = new HttpParams()
      .set('token', token);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.post(API_REST + '/security/validete/token', formParam, {
      // this.http.post('http://localhost:8088/api/admin' + '/security/validete/token', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public recoveypassword(newPassword, token) {
    const formParam = new HttpParams()
      .set('token', token)
      .set('newPassword', newPassword);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.put(API_REST + '/security/recovery/password', formParam, {
      // this.http.put('http://localhost:8088/api/admin' + '/security/recovery/password', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getUserProfile() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8086/api/admin/users').subscribe( res => {
      this.http.get(API_REST +  '/users').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getUserInfo(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:8088/api/admin/users/ammia/info', data).subscribe( res => {
      this.http.post(API_REST +  '/users/ammia/info', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getUserRoles() {
    return new Promise((response, error) => {
      // this.http.get( 'http://localhost:8088/api/admin/users/roles').subscribe( res => {
      this.http.get(API_REST +  '/users/roles').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  // endregion
  /* ----------------------- END API AMMIA USER------------------------------------ */

  // region services_old
  public getStatusCatRequests() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8086/api/requests/status').subscribe( res => {
      this.http.get(API_REST +  '/requests/status').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getPoliciesSize() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8082/api/policies/size').subscribe( res => {
      this.http.get(API_REST  + '/policies/size').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getUserPolicies() {
    return new Promise((response, error) => {
      this.http.get(API_REST  + '/policies/thumbnail/top').subscribe( res => {
      // this.http.get('http://localhost:8082/api'  + '/policies/thumbnail/top').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getTypePolicies() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8082/api/admin/policies/type/user').subscribe( res => {
      this.http.get(API_REST  + '/policies/type/user').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getUserincident(typePolicyId) {
    return new Promise((response, error) => {
        this.http.get(API_REST  + '/incident/recents/' + typePolicyId).subscribe( res => {
        // this.http.get('http://localhost:8088/api/incident/recents/' + typePolicyId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getDetailIncident(incidentId) {
    return new Promise((response, error) => {
        this.http.get(API_REST  + '/incident/detail/' + incidentId).subscribe( res => {
        // this.http.get('http://localhost:8088/api/admin/incident/detail/' + incidentId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getIncidentsList(page, size) {
    return new Promise((response, error) => {
        this.http.get(`${API_REST}/incident/pages?page=${page}&size=${size}`).subscribe( res => {
        // this.http.get(`http://localhost:8088/api/incident/pages?page=${page}&size=${size}`).subscribe( res => {
        if (res == null) {
          response({total: 0, incidentAdminList: []});
        } else {
          response(res);
        }
      }, (err) => {
        error(err);
      });
    });
  }
  public getIncidentAttachments(incidentId) {
    return new Promise((response, error) => {
      this.http.get(API_REST  + '/incident/' + incidentId + '/anexes').subscribe( res => {
      // this.http.get('http://localhost:8088/api/admin/incident/' + incidentId + '/anexes').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getIncidentLog(incidentId) {
    return new Promise((response, error) => {
      this.http.get(API_REST  + '/incident/incident/' + incidentId).subscribe( res => {
      // this.http.get('http://localhost:8088/api/admin/incident/incident/' + incidentId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCommentsLog(eventId) {
    return new Promise((response, error) => {
        this.http.get(API_REST  + '/logs/comments/' + eventId).subscribe( res => {
        // this.http.get('http://localhost:8090/api'  + '/logs/comments/' + eventId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }



  public getLogsAttachmentZipFile(eventId) {
    return this.http.get(`${API_REST}/logs/attachments/zip/${eventId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8090/api/logs/attachments/zip/${eventId}`, {responseType: 'arraybuffer'});
  }
  public getLogsAttachmentFile(attachmentId) {
    return this.http.get(`${API_REST}/logs/attachment/document/${attachmentId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8090/api/logs/attachment/document/${attachmentId}`, {responseType: 'arraybuffer'});
  }

  public getUserPoliciesType(id) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8082/api/admin/policies/type/thumbnail/' + id).subscribe( res => {
      this.http.get(API_REST +  '/policies/type/thumbnail/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getUserTypeIncident(id, policyType, page, size) {
      return this.http.get(`${API_REST}/incident/status/${id}/${policyType}/pages?page=${page}&size=${size}`).pipe(
      // return this.http.get(`http://localhost:8088/api/incident/status/${id}/${policyType}/pages?page=${page}&size=${size}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, incidentList: []};
        } else {
          return res;
        }
      })
    );
  }

  public getDetailPolicy(id) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8082/api/policies/detail/' + id).subscribe( res => {
      this.http.get(API_REST +  '/policies/detail/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getAmountsDetailByCategoryId(categoryId) {
    return this.http.get(`${API_REST}/policies/details/${categoryId}/amounts`);
    // return this.http.get(`http://localhost:8082/api/policies/details/${categoryId}/amounts`);
  }


  public getUserStatusIncident(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/incident/status/' + id).subscribe( res => {
      //   this.http.get('http://localhost:8088/api/incident/status/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCatalogIncidentTypes(typeId) {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/incident/cat_type_incident/' + typeId).subscribe( res => {
        // this.http.get('http://localhost:8088/api/incident/cat_type_incident/' + typeId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updateImageProfile(image): Observable<any> {
    return this.http.put(API_REST +  '/users/imageProfile/web', image);
    // return this.http.put('http://localhost:8086/api' +  '/users/imageProfile/web', image);
  }

  public updatePassword(oldpass, newpass) {
    const formParam = new HttpParams()
      .set('oldPassword', oldpass)
      .set('newPassword', newpass);

    const HEADERS = new HttpHeaders().set('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.put(API_REST + '/users/password', formParam, {
      // this.http.put('http://localhost:8088/api/admin' + '/users/password', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postIncidentData(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/incident', data).subscribe( res => {
        // this.http.post('http://localhost:8088/api/incident', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getcountNotification() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8084/api/admin/notifications').subscribe( res => {
      this.http.get(API_REST +  '/notifications').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getNotificationUser() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8084/api/admin/notifications/user').subscribe( res => {
      this.http.get(API_REST +  '/notifications/user').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getUserId() {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/users/id').subscribe( res => {
        // this.http.get('http://localhost:8086/api' +  '/users/id').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getInsuredList(page, size) {
      return this.http.get(`${API_REST}/general/insured?page=${page}&size=${size}`).pipe(
      // return this.http.get(`http://localhost:8094/api/admin/general/insured?page=${page}&size=${size}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, insuredList: []};
        } else {
          return res;
        }
      })
    );
  }

  public putNotification(notificationId): Observable<any> {
    // return this.http.put(' http://localhost:8084/api' +  '/notifications/' + notificationId, null);
    return this.http.put(API_REST +  '/notifications/' + notificationId, null);
  }

  public deleteNotification(notificationId): Observable<any> {
    // return this.http.delete('http://localhost:8084/api' +  '/notifications/' + notificationId);
   return this.http.delete(API_REST +  '/notifications/' + notificationId);
  }

  public getPolicyCertificate(policyId) {
    return this.http.get(`${API_REST}/policies/attachments/certificate/${policyId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8082/api/policies/attachments/certificate/${policyId}`, {responseType: 'arraybuffer'});
  }

  public getCarCertificate(carId) {
    return this.http.post(`${API_REST}/policies/attachments/car/certificate`, carId, {responseType: 'arraybuffer'});
    // return this.http.post(`http://localhost:8084/api/admin/policies/attachments/car/certificate`, carId, {responseType: 'arraybuffer'});
  }

  public getPolicyAttachmentFile(attachmentFileId) {
    return this.http.get(`${API_REST}/policies/attachments/document/${attachmentFileId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8082/api/policies/attachments/document/${attachmentFileId}`, {responseType: 'arraybuffer'});
  }


  public getPolicyDependents(policyId) {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/policies/dependents/' + policyId).subscribe( res => {
         // this.http.get('http://localhost:8082/api' +  '/policies/dependents/' + policyId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRequestTypeGeneral(statusId, page, size) {
    // return this.http.get(`http://localhost:8086/api/requests/general/${statusId}/pages?page=${page}&size=${size}`).pipe(
    return this.http.get(`${API_REST}/requests/general/${statusId}/pages?page=${page}&size=${size}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, requestList: []};
        } else {
          return res;
        }
      })
    );
  }
  public postRequestData(data) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:8086/api/requests/general`, data).subscribe( res => {
      this.http.post(`${API_REST}/requests/general`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getRequestById(requestId) {
    // return this.http.get(`http://localhost:8086/api/admin/requests/general/${requestId}`);
    return this.http.get(`${API_REST}/requests/general/${requestId}`);
  }


  public getRequests(page, size) {
    // return this.http.get(`http://localhost:8086/api/admin/requests/pages?page=${page}&size=${size}`).pipe(
    return this.http.get(`${API_REST}/requests/pages?page=${page}&size=${size}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, requestAdminList: []};
        } else {
          return res;
        }
      })
    );
  }
  public getLogAttachments(eventId) {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/logs/attachments/' + eventId).subscribe( res => {
        // this.http.get('http://localhost:8090/api' +  '/logs/attachments/' + eventId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  public getInsuranceWithReceipts() {
    return new Promise((response, error) => {
      // this.http.get(`http://localhost:8096/api/admin/receipts/insurance`).subscribe( res => {
      this.http.get(`${API_REST}/receipts/insurance`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getReceiptStatusList() {
    return new Promise((response, error) => {
      // this.http.get(`http://localhost:8096/api/admin/receipts/status`).subscribe( res => {
      this.http.get(`${API_REST}/receipts/status`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getInsuranceReceiptsList(statusId, insuranceId, page, size) {
    return new Promise((response, error) => {
      // tslint:disable-next-line:max-line-length
      // this.http.get(`http://localhost:8096/api/receipts/${statusId}/insurance/${insuranceId}?page=${page}&size=${size}`).subscribe( res => {
      this.http.get(`${API_REST}/receipts/${statusId}/insurance/${insuranceId}?page=${page}&size=${size}`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getReceiptAttachment(receiptId) {
    return new Promise((response, error) => {
      // this.http.get(`http://localhost:8096/api/admin/receipts/${receiptId}/anexes`).subscribe( res => {
      this.http.get(`${API_REST}/receipts/${receiptId}/anexes`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getAttachmentFile(annexId) {
    return this.http.get(`${API_REST}/receipts/anexes/${annexId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8096/api/admin/receipts/anexes/${annexId}`, {responseType: 'arraybuffer'});
  }
  public getReceiptAttachmentZipFile(receiptId) {
    return this.http.get(`${API_REST}/receipts/anexes/zip/${receiptId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8096/api/receipts/anexes/zip/${receiptId}`, {responseType: 'arraybuffer'});
  }

  public getReportsType(dataType) {
    // return this.http.get(`http://localhost:8098/api/admin/reports/${dataType}/types`);
    return this.http.get(`${API_REST}/reports/${dataType}/types`);
  }

  public getReportDataByReportId(reportId) {
    // return this.http.get(`http://localhost:8098/api/admin/reports/${reportId}`);
    return this.http.get(`${API_REST}/reports/${reportId}`);
  }

  /**
   * @deprecated
   */
  public getProvisionalityReport() {
    return this.http.get(`${API_REST}/reports/requests`);
  }
  /**
   * @deprecated
   */
  public getAccidentRateReport() {
    return this.http.get(`${API_REST}/reports/incidents`);
  }

  public getReportFile(reportId) {
    return this.http.get(`${API_REST}/reports/downloads/${reportId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8098/api/admin/reports/downloads/${reportId}`, {responseType: 'arraybuffer'});
  }

  public getAgentsChatSurexs() {
    return this.http.get(`${API_REST}/general/users`);
    // return this.http.get(`http://localhost:8086/api/general/users`);
  }

  public getIncidentAnnexFile(annexId) {
    return this.http.get(`${API_REST}/incident/attachments/${annexId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8088/api/incident/attachments/${annexId}`, {responseType: 'arraybuffer'});
  }

  public getPoliciesMin() {
    // return this.http.get(`http://localhost:8086/api/requests/policies`);
    return this.http.get(`${API_REST}/requests/policies`);
  }

  public getPoliciesClientMin() {
    // return this.http.get(`http://localhost:8082/api/admin/policies/policiesClients`);
    return this.http.get(`${API_REST}/policies/policiesClients`);
  }

  public getPoliciesClientCarMin() {
    // return this.http.get(`http://localhost:8082/api/admin/policies/policiesCarClients`);
    return this.http.get(`${API_REST}/policies/policiesCarClients`);
  }

  public getPoliciesRequestTemp() {
    // return this.http.get(`http://localhost:8086/api/requests/policiesClients`);
    return this.http.get(`${API_REST}/requests/policiesClients`);
  }

  public getPoliciesDependent(dependentId) {
    // return this.http.get(`http://localhost:8086/api/requests/policies/dependent/${dependentId}`);
    return this.http.get(`${API_REST}/requests/policies/dependent/${dependentId}`);
  }
  public getVehiclePolicies() {
    // return this.http.get(`http://localhost:8086/api/requests/policies/cars`);
    return this.http.get(`${API_REST}/requests/policies/cars`);
  }

  public postVehicleRequestData(data) {
    // return this.http.post(`http://localhost:8086/api/requests/cars/registrations`, data);
    return this.http.post(`${API_REST}/requests/cars/registrations`, data);
  }
  public postGeneralVehicleRequestData(data) {
    // return this.http.post(`http://localhost:8086/api/requests/general/cars`, data);
    return this.http.post(`${API_REST}/requests/general/cars`, data);
  }

  public postRequestInsuredAdd(data) {
    // return this.http.post(`http://localhost:8086/api/requests/insured/registrations`, data);
    return this.http.post(`${API_REST}/requests/insured/registrations`, data);
  }
  public getFinderInsured(data) {
    // return this.http.get(`http://localhost:8094/api/admin/general/insuredSearch?search=${data}`);
    return this.http.get(`${API_REST}/general/insuredSearch?search=${data}`);
  }

  public getFinderCar(data) {
    // return this.http.get(`http://localhost:8094/api/admin/general/carSearch?search=${data}`);
    return this.http.get(`${API_REST}/general/carSearch?search=${data}`);
  }

  public getFinderALLCar(data) {
    // return this.http.post(`http://localhost:8080/api/admin/general/carAllSearch?search`, data);
    return this.http.post(`${API_REST}/general/carAllSearch?search`, data);
  }

  public getPoliciesMinByInsuredId(insuredId) {
    // return this.http.get(`http://localhost:8086/api/requests/policies/${insuredId}`);
    return this.http.get(`${API_REST}/requests/policies/${insuredId}`);
  }

  public getPoliciesClientMinByInsuredId(insuredId) {
    // return this.http.get(`http://localhost:8082/api/policies/policySubranchCategoryByDependent/${insuredId}`);
    return this.http.get(`${API_REST}/policies/policySubranchCategoryByDependent/${insuredId}`);
  }

  public getPoliciesClientCarId(carId) {
    // return this.http.post(`http://localhost:8082/api/admin/policies/policySubranchCategoryByCarId`, carId);
    return this.http.post(`${API_REST}/policies/policySubranchCategoryByCarId`, carId);
  }

  public getRelationshipCat() {
    // return this.http.get(`http://localhost:8086/api/requests/kinships`);
    return this.http.get(`${API_REST}/requests/kinships`);
  }
  public postRequestDependentAdd(data) {
    // return this.http.post(`http://localhost:8086/api/requests/dependents/registrations`, data);
    return this.http.post(`${API_REST}/requests/dependents/registrations`, data);
  }
  public postRequestInsuredRemove(data) {
    // return this.http.post(`http://localhost:8086/api/admin/requests/insured/cancellations`, data);
    return this.http.post(`${API_REST}/requests/insured/cancellations`, data);
  }
  public getDependentsByInsuredId(insuredId) {
    // return this.http.get(`http://localhost:8086/api/requests/${insuredId}/dependents`);
     return this.http.get(`${API_REST}/requests/${insuredId}/dependents`);
  }
  public postRequestDependentRemove(data) {
    // return this.http.post(`http://localhost:8086/api/requests/dependents/cancellations`, data);
    return this.http.post(`${API_REST}/requests/dependents/cancellations`, data);
  }
  public getGendersCatalog() {
    // return this.http.get(`http://localhost:8086/api/requests/genders`);
    return this.http.get(`${API_REST}/requests/genders`);
  }

  public getReportId(reportId) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8098/api/admin/reports/' + reportId).subscribe( res => {
      this.http.get(API_REST  + '/reports/' + reportId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReporFilters(reportId, id) {
    return new Promise((response, error) => {
      this.http.get(API_REST  + '/reports/' + reportId + '/filters/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReporFiltersSearch(reportId, kinship, date, movement) {
    return this.http.get(`${API_REST}/reports/${reportId}/filters?kinship=${kinship}&date=${date}&movement=${movement}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, requestList: []};
        } else {
          return res;
        }
      })
    );
  }

  public getFileRequest() {
    // return this.http.get(`http://localhost:8086/api/admin/requests/downloads`, {responseType: 'arraybuffer'});
    return this.http.get(`${API_REST}/requests/downloads`, {responseType: 'arraybuffer'});
  }
  public getFileVehicleRequest() {
    // return this.http.get(`http://localhost:8080/api/admin/requests/downloads/cars`, {responseType: 'arraybuffer'});
    return this.http.get(`${API_REST}/requests/downloads/cars`, {responseType: 'arraybuffer'});
  }

  public getFileReceipt() {
    return this.http.get(`${API_REST}/receipts/downloads`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8084/api/admin/receipts/downloads`, {responseType: 'arraybuffer'});
  }

  public getFileIncident() {
    return this.http.get(`${API_REST}/incident/downloads`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8088/api/incident/downloads`, {responseType: 'arraybuffer'});
  }




  public getFileInsured() {
    return this.http.get(`${API_REST}/general/downloads/insured`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8094/api/general/downloads/insured`, {responseType: 'arraybuffer'});
  }

  public getLayoutMassiveLoadInsured() {
    // return this.http.get(`http://localhost:8086/api/requests/imports/layouts`, {responseType: 'arraybuffer'});
    return this.http.get(`${API_REST}/requests/imports/layouts`, {responseType: 'arraybuffer'});
  }

  public getRequestTemp() {
      // return this.http.get('http://localhost:8086/api/requests/getRequestTemp');
      return this.http.get(`${API_REST}/requests/getRequestTemp`);
  }

  public getRequestTempDetail(idRequestTemp) {
    // return this.http.get(`http://localhost:8086/api/requests/getRequestTempDetail/` + idRequestTemp);
    return this.http.get(`${API_REST}/requests/getRequestTempDetail/` + idRequestTemp);
  }

  public getRequestTempUpdated(idRequestTemp) {
    // return this.http.get(`http://localhost:8086/api/requests/getRequestTemp/` + idRequestTemp);
    return this.http.get(`${API_REST}/requests/getRequestTemp/` + idRequestTemp);
  }

  public deleteRequestTemp(idRequestTemp) {
    // return this.http.delete(`http://localhost:8086/api/requests/deleteRequestTemp/` + idRequestTemp);
    return this.http.delete(`${API_REST}/requests/deleteRequestTemp/` + idRequestTemp);
  }

  public getLayoutMassiveLoadVehicle() {
    // return this.http.get(`http://localhost:8086/api/requests/imports/layouts/cars`, {responseType: 'arraybuffer'});
    return this.http.get(`${API_REST}/requests/imports/layouts/cars`, {responseType: 'arraybuffer'});
  }
  public postMassiveLoadLayoutVehicle(data) {
    // return this.http.post(`http://localhost:8086/api/requests/imports/cars`, data);
    return this.http.post(`${API_REST}/requests/imports/cars`, data);
  }
  public getVehicleRequests(page, size) {
    // return this.http.get(`http://localhost:8086/api/admin/requests/cars/pages?size=${size}&page=${page}`).pipe(
    return this.http.get(`${API_REST}/requests/cars/pages?size=${size}&page=${page}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, requestCarList: []};
        } else {
          return res;
        }
      })
    );
  }

  public getVehiclesList(carSearch) {
    return this.http.post(`${API_REST}/general/cars`, carSearch).pipe(
    // return this.http.post('http://localhost:8080/api/admin/general/cars', carSearch).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, carList: []};
        } else {
          return res;
        }
      })
    );
  }
  public getFinderVehicleSerial(data) {
    // return this.http.get(`http://localhost:8086/api/requests/cars?serialNumber=${data}`);
    return this.http.get(`${API_REST}/requests/cars?serialNumber=${data}`);
  }
  public getDataTypes() {
    // return this.http.get(`http://localhost:8086/api/admin/requests/types`);
    return this.http.get(`${API_REST}/requests/types`);
  }

  public getCustomerList() {
    // return this.http.get(`http://localhost:8094/api/admin/general/customers`);
    return this.http.get(`${API_REST}/general/customers`);
  }

  public getMonthsdebtors(type) {
    // return this.http.get(`http://localhost:8082/api/admin/policies/debtors/months/${type}`);
    return this.http.get(`${API_REST}/policies/debtors/months/${type}`);
  }

  public getFilesdebtors(months) {
    // return this.http.get(`http://localhost:8082/api/policies/debtors/files?monthId=${months.monthId}&year=${months.year}`);
    return this.http.get(`${API_REST}/policies/debtors/files?monthId=${months.monthId}&year=${months.year}`);
  }

  public getDebtorLifeDataByDebtorLifeId(id) {
    // return this.http.get(`http://localhost:8082/api/policies/debtors/files/${id}?page=1&size=10`);
    return this.http.get(`${API_REST}/policies/debtors/files/${id}?page=1&size=10`);
  }

  public getDebtoLifeFile(fileId) {
    // return this.http.get(`http://localhost:8082/api/policies/debtors/files/downloads?fileId=${fileId}`, {responseType: 'arraybuffer'});
    return this.http.get(`${API_REST}/policies/debtors/files/downloads?fileId=${fileId}`, {responseType: 'arraybuffer'});
  }

  public postLoadLayoutDebtorLife(data, monthId, year) {
    return this.http.post(`${API_REST}/policies/debtors/customers?monthId=${monthId}&year=${year}`, data);
    // return this.http.post(`http://localhost:8082/api/policies/debtors/customers?monthId=${monthId}&year=${year}`, data);
  }

  public postAuthorizeFileDebtorLife(fileDebtorId, explication, authorize) {
    const formParam = new HttpParams()
      .set('debtorLifeId', fileDebtorId)
      .set('explication', explication)
      .set('authorize', authorize);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.post(API_REST + '/policies/debtors/validations', formParam, {
        // this.http.post('http://localhost:8082/api' + '/policies/debtors/validations', formParam, {
        headers: HEADERS
      }).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
 --------------------------------  P O L I C Y   CAR----------------------------------------------------
 ----------------------------------------------------------------------------------------------------- */

  getPublicKey(): Observable<any> {
    return this.http.post(API_REST_AMMIA + '/system/policy/getPublicPolicy', null, );
  }
  public getMakeCatalogist(data) {
    return new Promise((response, error) => {
      // this.http.post('https://qa.ammia.store/api/ammia/v1.0/public/emission/catalog/getMakesCatalogList', data).subscribe( res => {
      this.http.post(API_REST_AMMIA_WEB +  '/emission/catalog/getMakesCatalogList', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getModelYearCatalogist(data) {
    return new Promise((response, error) => {
      // this.http.post('https://qa.ammia.store/api/ammia/v1.0/public/emission/catalog/getModelYearCatalogList').subscribe( res => {
      this.http.post(API_REST_AMMIA_WEB +  '/emission/catalog/getModelYearCatalogList', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCarVersionCatalogist(data) {
    return new Promise((response, error) => {
      // this.http.post('https://qa.ammia.store/api/ammia/v1.0/public/emission/catalog/getCarVersionCatalogList', data).subscribe( res => {
      this.http.post(API_REST_AMMIA_WEB +  '/emission/catalog/getCarVersionCatalogList', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  public getBranchsByClient() {
    // return this.http.get(`http://localhost:8080/api/admin/general/getBranchByClient`);
    return this.http.get(`${API_REST}/general/getBranchByClient`);
  }

  public getRequestByIdrequest(requestId) {
    // return this.http.get(`http://localhost:8086/api/admin/requests/requestById/${requestId}`);
    return this.http.get(`${API_REST}/requests/requestById/${requestId}`);
  }

  public getUseCatalog() {
    return new Promise((response, error) => {

      // this.http.post('http://localhost:8086/api/admin/requests/use', {}).subscribe( res => {
      this.http.post(`${API_REST}/requests/use`, {}).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReceptisByBranchid(filter) {
    // return this.http.post(`http://localhost:8096/api/admin/receipts/receipts`, filter);
    return this.http.post(`${API_REST}/receipts/receipts`, filter);
  }

  public getReceptisPaginatedByBranchid() {
    // return this.http.post(`http://localhost:8096/api/admin/receipts/receiptsPaginated`, {});
    return this.http.post(`${API_REST}/requests/receiptsPaginated`, {});
  }
  // endregion
}
