import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SharedService} from '../shared-service.service';

@Component({
  selector: 'app-request-ammia',
  templateUrl: './request-ammia.component.html',
  styleUrls: ['./request-ammia.component.css']
})
export class RequestAmmiaComponent implements OnInit {
  get branchId(): number {
    return this._branchId;
  }
  @Input()
  set branchId(value: number) {
    this._branchId = value;
  }

  // tslint:disable-next-line:variable-name
  protected _branchId: number;
  dataFilterRequest: any = {};
  requestList: any = [];
  requestListTable: any = [];
  requestDetail: any = {};
  requestPage: number;
  totalRequestRecords: number;
  totalRequestRecordsTable: number;
  requestPageTable: number;
  requestCarPage: number;
  totalCarRequestRecords: number;
  typeRequestList: any = [];
  statusRequestList: any = [];
  // solicitud general daños
  dataRequestDamage: any = {};
  policyAmmiaList: any = [];
  clientList: any = {};
  myFormRequestdamage: FormGroup;
  responseServiceDamage: number;
  reportGridViewType = true;

  requestsList: any = [];
  requestsVehicleList: any = [];
  vehiclesList: any = [];
  sizeRequest: number;
  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public shared: SharedService) {
    this.myFormRequestdamage = this.fb.group({
      client: ['', [Validators.required]],
      subject: ['',  [Validators.required, Validators.maxLength(50)]],
      description: ['']
    });

    this.shared.fUpdateRequestsObservable().subscribe(
      fRequests => {
        if (fRequests) {
          this.responseServiceDamage = 0;
          this.invokeServiceGetStatusRequest();
          this.requestPage = 1;
          this.requestPageTable = 1;
          this.requestCarPage = 1;
          this.sizeRequest = 5;
          this.invokeFunctionInitFilters();
          this.invokeServiceGetRequestPages();
          this.reportGridViewType = true;
        }
      }
    );
  }

  ngOnInit(): void {
    this.responseServiceDamage = 0;
    this.invokeServiceGetStatusRequest();
    this.requestPage = 1;
    this.requestPageTable = 1;
    this.requestCarPage = 1;
    this.sizeRequest = 5;
    this.invokeFunctionInitFilters();
    this.invokeServiceGetRequestPages();
    this.typeRequestList = [
      { typeId: 1, description: 'Solicitud de alta' },
      { typeId: 2, description: 'Solicitud de baja'},
      { typeId: 3, description: 'Solicitud general'},
      { typeId: 0, description: 'Todas' }
    ];
  }

  invokeFunctionInitFilters() {
    this.dataFilterRequest = {
      page: 1,
      size: 5,
      typeId: 0,
      statusId: 0,
      branchId: this._branchId
    };
  }

  onScrollRequest() {
    console.log('entra scroll solicitudes');
    if (this.totalRequestRecords === this.requestList.length) {
      return;
    }
    this.requestPage++;
    this.invokeServiceGetRequestPages();
  }

  invokeServiceGetRequestPages() {
    this.dataFilterRequest.page = this.requestPage;
    console.log(this.dataFilterRequest);
    this.api.getRequestByBranch(this.dataFilterRequest)
      .then(
        (response: any) => {
          if (response != null) {
            this.totalRequestRecords = response.total;
            this.requestList = this.requestList.concat(response.request);
          } else {
            this.toastr.info('No se han encontrado resultados con el filtro especificado.', 'Solicitudes');
          }
        }, error => {
          console.info(error);
        });
  }

  invokeFunctionGetDetailRequest(request: any) {
    const objectData = {
      requestId: request.requestId,
      requestDetailId: request.requestDetailId
    };
    this.api.getRequestDetailById(objectData)
      .then(
        (response: any) => {
          if (response != null) {
            this.requestDetail = response[0];
          }
        }, error => {
          console.info(error);
        });
  }

  invokeFunctionOpenDetailRequest(r: any) {
    /*const modalShadowElement = document.querySelector('.modal-shadow-evento');
    const shadowGeneral = document.querySelector('.modal-backdrop');
    modalShadowElement.classList.add('visible');
    shadowGeneral.classList.add('no-visible');
    console.log(r);*/
    this.invokeFunctionGetDetailRequest(r);
  }
  invokeFunctionCloseRequestDetail() {
    const modalShadowElement = document.querySelector('.modal-shadow-evento');
    modalShadowElement.classList.remove('visible');
    this.requestDetail = [];
  }

  invokeFunctionChangeTypeRequest() {
    this.requestList = [];
    this.requestPage = 1;
    this.invokeServiceGetRequestPages();
  }
  invokeServiceGetStatusRequest() {
    this.api.getStatusRequest()
      .then(
        (response: any) => {
          if (response != null) {
            this.statusRequestList = response;
          }
        }, error => {
          console.info(error);
        });
  }

  invokeServiceGetClientByPolicy(p: any) {
    this.dataRequestDamage.policyId = p.policyId;
    this.invokeServiceGetClientsByPolicy(p.policyId);
    console.log(p);
  }

  invokeServiceGetClientsByPolicy(idPolicy: number) {
    const data = {
      policyId: idPolicy
    };
    this.api.getClientsByPolicy(data)
      .then(
        (response: any) => {
          if (response != null) {
            this.clientList = response;
            this.dataRequestDamage.clientId = response[0].clientId;
          }
        }, error => {
          console.info(error);
        });
  }
  invokeFunctionLoadNewRequest() {
    this.dataRequestDamage = {};
    this.dataRequestDamage = {
      requestId: 0,
      subject: '',
      description: '',
      policyId: 0,
      categoryId: 0,
      groupId: 0,
      clientId: 0,
      userId: 0,
      statusId: 2,
      folio: ''
    };
    console.log(this.dataRequestDamage);
    this.invokeServiceGetPoliciesDamages();
  }
  invokeServiceGetPoliciesDamages() {
    this.api.getPoliciesAmmiaDashboardDamages()
      .then(
        (response: any) => {
          if (response != null) {
            this.policyAmmiaList = response;
          }
        }, error => {
          console.info(error);
        });
  }
  invokeFunctionSaveRequestGeneralDamage() {
    console.log(this.dataRequestDamage);
    this.api.saveRequestDamage(this.dataRequestDamage)
      .then(
        (response: any) => {
          this.responseServiceDamage = 1;
          this.requestList = [];
          this.invokeServiceGetRequestPages();
        }, error => {
          this.responseServiceDamage = 2;
          console.info(error);
        });
  }
  invokeFunctionShowRequest(id: number) {
    console.log('entra para saber que pintar');
    if (id === 1) {
      this.shared.fUpdateBenefits.next(true);
      this.shared.fUpdateDamages.next(false);
      this.shared.fUpdateCars.next(false);
    } else if (id === 3) {
      this.shared.fUpdateBenefits.next(false);
      this.shared.fUpdateDamages.next(false);
      this.shared.fUpdateCars.next(true);
    } else if (id === 2) {
      this.shared.fUpdateBenefits.next(false);
      this.shared.fUpdateDamages.next(true);
      this.shared.fUpdateCars.next(false);
    }
  }


  onScrollRequestTable() {
    if (this.totalRequestRecordsTable === this.requestListTable.length) {
      return;
    }
    this.requestPageTable++;
    this.invokeServiceForGetRequestList();
  }
  onScrollRequestCarTable() {
    if (this.totalCarRequestRecords === this.requestsVehicleList.length) {
      return;
    }
    this.requestCarPage++;
    this.invokeServiceForGetRequestList();
  }
  invokeServiceForGetRequestList() {
    this.api.getRequests(this.requestPage, this.sizeRequest)
      .subscribe(
        (response: any) => {
          if (response.total > 0) {
            this.totalRequestRecordsTable = response.total;
            this.requestListTable = this.requestListTable.concat(response.requestAdminList);
          }
          // this.requestsList = response.requestAdminList;
        }, error => {
          console.error(error);
        });
  }
  public invokeServiceForDownloadFileRequest() {
    this.shared.gCurrentBranchId.next(this._branchId);
    this.api.getFileRequest()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  'solicitudes.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }
  invokeFunctionLoadRequestTable() {
    this.shared.gCurrentCustomerId.next(0);
    this.shared.gCurrentBranchId.next(this._branchId);
    this.reportGridViewType = false;
    if (this._branchId === 1) {
      this.invokeServiceForGetRequestList();
    } else {
      console.log(this._branchId);
      this.invokeServiceForGetVehicleRequestList();
    }
  }

  invokeServiceForGetVehicleRequestList() {
    console.log(this._branchId, this.reportGridViewType);
    this.api.getVehicleRequests(this.requestCarPage, this.sizeRequest)
      .subscribe(
        (response: any) => {
          console.log(response);
          console.log(this._branchId, this.reportGridViewType);
          if (response.total > 0) {
            this.totalCarRequestRecords = response.total;
            this.requestsVehicleList = this.requestsVehicleList.concat(response.requestCarList);
            console.log(this.requestsVehicleList);
          }
        }, error => {
          console.error(error);
        });
  }
  invokeLoadAllDataTable() {
    if (this._branchId === 1) {
      this.sizeRequest = this.totalRequestRecordsTable;
      this.requestPageTable = 1;
      this.invokeServiceForGetRequestList();
    } else {
      this.sizeRequest = this.totalCarRequestRecords;
      this.requestCarPage = 1;
      this.invokeServiceForGetVehicleRequestList();
    }
  }
  invokeFunctionCloseModalTable() {
    this.sizeRequest = 5;
  }
}
