<div class="body">
  <div class="head">
    <span class="sx-iconsys-icon btn-close" aria-hidden="true" (click)="shared.fCloseSidebar()">x</span>
    <p class="title-head">Bitacora</p>
  </div>

  <div class="log-section">
 <div class="timeline">
      <div *ngFor="let l of incidentLog" class="event" (click)="invokeServiceForGetLogComments(l.eventId)">
        <div class="point" [class.selected]="l.eventId == eventIdSelected"></div>
        <div class="message" [class.selected]="l.eventId == eventIdSelected">
          {{l.title}}
        </div>
        <div class="time">{{l.created}}</div>
      </div>
    </div>
  </div>


  <div class="detail-section">
    <div *ngIf="eventIdSelected == undefined">
      <div class="title">{{detailIncident.title}}</div>
      <div class="subtitle">{{detailIncident.insuredName}}</div>
      <div class="item"><strong>Tipo de asegurado:</strong> {{detailIncident.insuredType}}</div>
      <div class="item"><strong>Fecha de alta:</strong> {{detailIncident.created}}</div>
      <div class="item"><strong>Tipo de siniestro:</strong> {{detailIncident.incidentType}}</div>
      <div class="item"><strong>Póliza:</strong> {{detailIncident.number}}</div>
      <div class="item"><strong>Descripción:</strong> {{detailIncident.description}}</div>
    </div>
    <div  *ngIf="eventIdSelected > -1" >
      <div class="title">Comentarios</div>
      <div *ngFor="let c of logComments" class="mt-10">
        <div class="d-head">
          <div class="h-title">Surexs</div>
          <div class="date">{{c.created}}</div>
        </div>
        <div class="comment">{{c.commentEvent}}</div>
      </div>
      <div class="row" *ngIf="logComments?.length <= 0">
        <div class="col-xs-12">
          <p class="text-muted i-text-muted">No existen comentarios</p>
        </div>
      </div>
    </div>

<!--
    <div class="separator">
      <span class="text">Anexos</span>
      <span class="line"></span>
      <span class="badge">{{attachmentsList?.length || 0}}</span>
    </div>
    <div class="i-annexes">
      <div class="row" *ngIf="attachmentsList?.length > 0">
        <div *ngFor="let oFile of attachmentsList; let i = index">
          <div class="col-md-4 text-center">
            <span class="sx-iconsys-icon">d</span>
            <div class="file-name">
              <a *ngIf="eventIdSelected == undefined" href="javascript:;" (click)="invokeServiceForDownloadAnnexFile(oFile)">{{oFile.name}}</a>
              <a *ngIf="eventIdSelected > -1" href="javascript:;" (click)="invokeServiceForDownloadEventAttachmentFile(oFile)">{{oFile.fileName}}</a>
            </div>
          </div>
          <div class="clearfix visible-lg-block" *ngIf="(i+1)%3==0"></div>
        </div>
      </div>
      <div class="row" *ngIf="attachmentsList?.length <= 0">
        <div class="col-xs-12">
          <p class="text-muted i-text-muted">No existen anexos</p>
        </div>
      </div>
    </div>
    <div class="text-center" *ngIf="eventIdSelected > -1 && attachmentsList?.length > 1">
      <button type="button" class="btn btn-xs btn-custom">
        <a href="javascript:;" (click)="invokeServiceForDownloadEventAttachmentZipFile()">Descargar todos</a>
      </button>
    </div>-->
  </div>

</div>
