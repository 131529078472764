<div style="position:relative;width: 340px;padding: 20px;height: 100%">
  <div style="height: 126px;width: 100%; text-align: center;">
    <span class="sx-iconsys-icon" aria-hidden="true" style="float: right;font-size: 22px;font-weight: normal;color: #C4C4C4;cursor: pointer;" (click)="shared.fCloseSidebar()">x</span>
    <p style="color: #448AA6;font-size: 16px;font-weight: 600;line-height: 19px;padding-top: 33px;padding-bottom: 33px">
      Vida Deudor
    </p>
    <div style="text-align: left">
      <span style="display: inline-block;vertical-align: middle;color: #326173;"></span><span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 235px;border-top: 1px solid #E1E1E1;"></span>
    </div>
  </div>

  <div style="position: relative;min-height: calc(100% - 160px);width: 100%;color: #326173;">
    <!--<div class="form-group form-group-sm" >
      <div  class="form-group" dropdown [dropup]="true" style="position:absolute;">
        <label class="control-label">Mes a cargar</label>
        <button id="button-data-types-debtor" dropdownToggle type="button" class="btn btn-md btn-link dropdown-toggle subtitle-section-menu" aria-controls="dropdown-data-types-reports">
          {{lclDataTypeForDebtorLifeSelected.monthName}} <span class="sx-iconsys-icon">1</span>
        </button>
        <ul id="dropdown-data-types-debtorlife" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu" aria-labelledby="button-data-types-reports">
          <li role="menuitem" *ngFor="let item of debtorlifeDataListMonths" >
            <a class="dropdown-item" href="javascript:;" (click)="changeLclDataType(item)">{{item.monthName}}</a>
          </li>
        </ul>
      </div>
    </div>
    <br/><br/>-->
    <form [formGroup]="registrationForm" (ngSubmit)="sendDebtorLifeData()">
      <!-- Gender -->
      <div class="group-gap">
        <h5 class="mb-3">Antes de Autorizar/Rechazar por favor verificar el archivo de ejecutivo</h5>
        <div class="d-block my-3">
          <div class="custom-control custom-radio">
            <input id="authorize" type="radio" class="custom-control-input" [value]="1" [(ngModel)]="flag" name="validation" formControlName="validation">
            <label class="custom-control-label" for="authorize">   Autorizar</label>
          </div>
          <div class="custom-control custom-radio">
            <input id="decline" type="radio" class="custom-control-input" [value]="2" [(ngModel)]="flag" name="validation" formControlName="validation">
            <label class="custom-control-label" for="decline">   Rechazar</label>
          </div>
          <div class="invalid-feedback" *ngIf="isSubmitted && myForm.errors?.required">
            <p>Please select either value</p>
          </div>
        </div>
      </div>
      <!-- Submit Button -->
      <!--<button type="submit" class="btn btn-danger btn-lg btn-block">Submit</button>-->
    </form><!-- Form ends -->
    <div class="custom-control" *ngIf="flag==2">
      <label class="custom-control-label">Ingresar el motivo de rechazo del archivo</label>
      <textarea type="text" class="custom-control-input col-md-12"  [(ngModel)]="explication"  rows="6"></textarea>
    </div>
  </div>
  <div class="text-center">
    <button type="submit" class="btn btn-sm btn-custom btn-block" (click)="sendDebtorLifeData()">Enviar Respuesta</button>
  </div>
</div>
