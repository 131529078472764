<div class="modal fade" id="deleteRegisterRequest" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered justify-content-center modal-sm" >
    <div class="modal-content evento">
      <div class="modal-header evento">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="d-column-start gap-8">
          <p class="d-flex gap-8 modal-title " id="exampleModalLabel">
            ¿ESTÁ SEGURO DE ELIMINAR EL REGISTRO?
          </p>
        </div>

      </div>
      <div class="modal-body">
        <button type="button" class="btn btn-custom  btn-srx"  (click)="deleteRequestTemp()" data-bs-dismiss="modal" aria-label="Close">SÍ</button>
        <button type="button" class="btn btn-custom  btn-srx" data-bs-dismiss="modal" aria-label="Close" >NO</button>
      </div>

    </div>

  </div>
</div>
