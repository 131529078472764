import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared-service.service';

@Component({
  selector: 'app-admin-home-concessionaire',
  templateUrl: './admin-home-concessionaire.component.html',
  styleUrls: ['./admin-home-concessionaire.component.css']
})
export class AdminHomeConcessionaireComponent implements OnInit {

  constructor(public shared: SharedService) { }

  ngOnInit() {
  }

}
