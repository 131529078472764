import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared-service.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {

  constructor(public shared: SharedService) { }

  ngOnInit() {
  }

}
