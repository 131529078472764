import {Component, HostListener, OnInit} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {SharedService} from '../shared-service.service';
import {browserRefresh} from '../app.component';



@Component({
  selector: 'app-admin-insured',
  templateUrl: './admin-insured.component.html',
  styleUrls: ['./admin-insured.component.css']
})
export class AdminInsuredComponent implements OnInit {

  customerIdFirstLoad: number;
  insuredSelected: any = {};
  public browserRefresh: boolean;

  constructor(private router: Router,
              private shared: SharedService) {
    /*this.shared.gCurrentCustomerIdObservable().subscribe(
      gCustomerId => {
        if (gCustomerId !== -1 && this.customerIdFirstLoad != null && gCustomerId !== this.customerIdFirstLoad) {
          this.goToBack();
        }
      }
    );*/
    this.shared.pgInuredObservable().subscribe(
      fInsured => {
        if (fInsured) {
          const insured = localStorage.getItem('insuredView');
          this.insuredSelected = JSON.parse(localStorage.getItem('insuredView'));
        }
      }
    );
  }

  ngOnInit() {
    this.customerIdFirstLoad = this.shared.gCurrentCustomerId.value;
    this.browserRefresh = browserRefresh;
    if (this.browserRefresh) {
      this.goToBack();
      return;
    }

    const insured = localStorage.getItem('insuredView');
    this.insuredSelected = JSON.parse(insured);
  }

  goToBack() {
    this.shared.fInsuredDataReadonly = false;
    this.router.navigate(['/admin/home']);
  }
}
