import { Component, OnInit } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from "@angular/router";

@Component({
  selector: 'app-recovery-password-mdl',
  templateUrl: './recovery-password-mdl.component.html',
  styleUrls: ['./recovery-password-mdl.component.css']
})
export class RecoveryPasswordMdlComponent implements OnInit {
  public onClose: Subject<boolean>;
  email;
  myForm: FormGroup;
  constructor(public bsModalRef: BsModalRef,
              private api: ApiService,
              private toastr: ToastrService,
              private router: Router,
              public fb: FormBuilder) {
    this.myForm = this.fb.group({
      email: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.onClose = new Subject();
  }
  public onConfirm(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  public hideConfirmationModal(): void {
    this.onClose.next(null);
    this.bsModalRef.hide();
  }
  sendemail(email) {
    if (this.email === '' || this.email === undefined) {
      this.toastr.info('Por favor ingrese el correo', 'Notificación');
      return;
    }
    console.log(email);
    this.api.postRecoveyPassword(email)
      .then(
        (user) => {
          // tslint:disable-next-line:max-line-length
            this.toastr.success('Se ha enviado una liga a tu correo para cambiar la contraseña y desde ahí puedes continuar con el proceso.', 'Notificación');
            // this.onConfirm();
        }, error => {
          if (error.status === 403) {
            this.toastr.warning('El correo que ingreso no está en nuestros registros.', 'Notificación');
          } else {
            this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
          }
        }
      );
  }

  invokeFunctionBackLogin() {
    this.router.navigate(['/login']);
  }
}
