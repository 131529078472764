import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SharedService} from '../shared-service.service';

@Component({
  selector: 'app-incident-detail-ammia',
  templateUrl: './incident-detail-ammia.component.html',
  styleUrls: ['./incident-detail-ammia.component.css']
})
export class IncidentDetailAmmiaComponent implements OnInit {
  incidentDetail: any = {};
  attachmentList: any = [];
  incidentId: number;
  _branchId: number;
  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public shared: SharedService) {

    this.shared.sIncidentIdObservable().subscribe(
      sIncidentId => {
        console.log('observable siniestro');
        console.log(sIncidentId);
        this.incidentId = sIncidentId;
        this.invokeFunctionDetailIncident(sIncidentId);

      }
    );
  }

  ngOnInit(): void {
    this.incidentId = 0;
  }

  invokeFunctionCloseModalIncident() {
    const modalShadowElement = document.querySelector('.modal-shadow-evento');
    modalShadowElement.classList.remove('visible');
    this.incidentDetail = [];
    this.incidentId = 0;
  }

  invokeFunctionDetailIncident(idIncident: number) {
    this.invokeServiceGetDetailIncident(idIncident);
    this.invokeServiceGetAttachmentsByIncident(idIncident);
  }

  invokeServiceGetAttachmentsByIncident(incident: number) {
    const objectData = {
      incidentId: incident
    };
    this.api.getAttachmentsByIncident(objectData)
      .then(
        (response: any) => {
          if (response != null) {
            this.attachmentList = response;
          }
        }, error => {
          console.info(error);
        });
  }
  invokeServiceGetDetailIncident(incident: number) {
    console.log(incident);
    const objectData = {
      incidentId: incident
    };
    this.api.getIncidentDetailById(objectData)
      .then(
        (response: any) => {
          if (response != null) {
            this.incidentDetail = response;
          }
        }, error => {
          console.info(error);
        });
  }

  public invokeServiceForDownloadFileAttachment(attachment: any)  {
    const objData = {
      attachmentId: attachment.attachmentId
    };
    this.api.getAttachmentIncidentById(objData)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

}
