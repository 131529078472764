<div style="width: 100%; height: 100%;" class="menu" >
  <div style="width: 100%; text-align: center; padding: 20px;">

    <div style="width: 100%; text-align: right;">
      <span class="sx-iconsys-icon" aria-hidden="true" style="font-size: 22px;font-weight: normal;color: #448AA6;cursor: pointer;" (click)="sharedService.fCloseSidebar()">x</span>
    </div>

    <div style="vertical-align: middle; color: #448AA6; margin-top: 30px;">
      <div style="font-size: 12px;line-height: 1; text-align: center;"><strong [innerHTML]="user.name"></strong></div>
      <div style="font-size: 10px;line-height: 1; text-align: center;" [innerHTML]="user.position"></div>
    </div>
    <div style="padding-top: 5px;">
      <div class="btn-group" dropdown>
        <img class="dropdown-toggle" src="{{user.imageProfile}}" id="dropdown-button-profile2" dropdownToggle aria-controls="dropdown-profile2" style="height: 50px;width: 50px;border-radius: 50%;cursor:pointer;"/>
        <!--<ul id="dropdown-profile" *dropdownMenu class="dropdown-menu dropdown-menu-center" role="menu" aria-labelledby="dropdown-button-profile">
          <li role="menuitem">
            <a class="dropdown-item" href="javascript:;" (click)="sharedService.fOpenSidebar(0)">Cuenta</a>
          </li>
          <li role="menuitem">
            <a class="dropdown-item" href="javascript:;" >Ayuda</a>
          </li>
          <li role="menuitem">
            <a class="dropdown-item" href="javascript:;" (click)="closeSession()">Cerrar sesión</a>
          </li>
        </ul>-->
      </div>
    </div>

    <div style="text-align: left">

    </div>
  </div>
  <div class="col-md-12 menu-item" (click)="sharedService.fOpenSidebar(0, 'full')">
    <div>
      <label>Cuenta</label>
    </div>
  </div>
  <div class="col-md-12 menu-item" *ngIf="lclShowAdminAccess" (click)="goToAdminProfile()">
    <div>
      <label>Cambiar a Administrador</label>
    </div>
  </div>
  <div class="col-md-12 menu-item" *ngIf="lclShowUserAccess" (click)="goToUserProfile()">
    <div>
      <label>Cambiar a Asegurado</label>
    </div>
  </div>
    <div class="col-md-12 menu-item" (click)="sharedService.fOpenSidebar(1, 'full')">
      <div>
          <span  class="notification" *ngIf="sharedService.gMessageCount>0">{{sharedService.gMessageCount}}</span>
        <label>Chat</label>
      </div>
    </div>
 <!-- <div class="col-md-12 menu-item" (click)="sharedService.fOpenSidebar(15, 'full')">
    <div>
      <label>Notificaciones</label>
    </div>
  </div>-->
  <div class="btn-group col-md-12 menu-item" dropdown [isDisabled]="notificationList?.length <= 0">
            <span class="sx-iconsys-icon" id="dropdown-button-notification" dropdownToggle aria-controls="dropdown-notification" title="Notificaciones">
              <label>Notificaciones </label>
              <span class="notification" *ngIf="notificationCount>0"> {{notificationCount}}</span>
            </span>
    <ul id="dropdown-notification" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu" aria-labelledby="dropdown-button-notification">
      <li>
        <a class="dropdown-item" href="javascript:;" ></a>
      </li>
      <li role="menuitem" *ngFor="let notification of notificationList">
        <a class="dropdown-item" href="javascript:;">
          <div class="row">
            <div class="col-xs-10" (click)="modalnotification(notification)">
              <div class="row" >
                <div class="col-xs-6" ><div class="title">{{notification.title}}</div></div>
                <div class="col-xs-6"><div class="date pull-right">{{notification.created_at | date: 'dd MMM yyyy'}}</div></div>
                <!--<div class="col-xs-2"><div class="pull-right"><button class="btn-default">x</button> </div></div>-->
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <div class="content">{{notification.description}}</div>
                </div>
              </div>
            </div>
            <div class="col-xs-1">
              <button type="button" class="close pull-left" aria-label="Close" (click)="deletenotification(notification)">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>

  <div class="col-md-12 menu-item"  (click)="closeSession()">
    <div>
      <label>Cerrar sesión</label>
    </div>
  </div>
</div>
