import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';

@Component({
  selector: 'app-new-incident',
  templateUrl: './new-incident.component.html',
  styleUrls: ['./new-incident.component.css']
})
export class NewIncidentComponent implements OnInit {
  policiesList: any = [];
  catIncidentTypes: any = [];
  dataToSend: any = {};
  thisStyle: any = {};

  constructor(private api: ApiService,
              private toastr: ToastrService,
              public shared: SharedService) {}

  ngOnInit() {
    this.dataToSend = {
      name: undefined,
      policyId: undefined,
      companyId: undefined,
      incidentTypeId: undefined,
      description: undefined,
      userId: undefined,
      contact: undefined
    };

    this.invokeServiceForGetPolicies();
  }

  private invokeServiceForGetPolicies() {
    this.api.getUserPoliciesType(0)
      .then(
        (data) => {
          this.policiesList = data;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  private getCatalogIncidentTypes(typeId) {
    this.api.getCatalogIncidentTypes(typeId).then((data) => {
          this.catIncidentTypes = data;
      }, error => {
        console.error(error);
      }
    );
  }

  selectThisPolicy(policy, index) {
    this.thisStyle.color = policy.colorCompanyHex;
    this.thisStyle.index = index;
    this.dataToSend.policyId = policy.policyId;
    this.dataToSend.companyId = policy.companyId;
    this.dataToSend.userId = localStorage.getItem('user-id');
    this.getCatalogIncidentTypes(policy.typeId);
  }
  sendIncidentData() {
    this.api.postIncidentData(this.dataToSend)
      .then(
        (response) => {
          this.toastr.success('Su registro se ha agregado correctamente.', 'Notificación');
          this.shared.fUpdateIncidents.next(true);
          this.shared.fCloseSidebar();
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        }
      );
  }
}
