import { Component, OnInit } from '@angular/core';
import {ApiService} from "../api.service";
import {FormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {BsModalService} from "ngx-bootstrap/modal";
import {SharedService} from "../shared-service.service";

@Component({
  selector: 'app-menu-ammia',
  templateUrl: './menu-ammia.component.html',
  styleUrls: ['./menu-ammia.component.css']
})
export class MenuAmmiaComponent implements OnInit {
  activeMenu: number;
  dataBranchGroup: any = {};
  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public shared: SharedService) {
    this.shared.activeMenuObservable().subscribe(
      activeMenu => {
        this.activeMenu = activeMenu;
      }
    );
  }

  ngOnInit(): void {
    this.activeMenu = 0;
    this.invokeServiceGetBranchesByGroupClients();
  }
  invokeFunctionRoute(route: string, active: number, activeOption: number) {
    if (activeOption > 0) {
      this.activeMenu = active;
      this.router.navigate(['/admin/' + route]);
    }
  }

  invokeServiceGetBranchesByGroupClients() {
    this.api.getBranchesByGroupClient().subscribe(
      (response: any) => {
        this.dataBranchGroup = response;
      }, error => {
        this.toastr.info('Sin pólizas activas');
      }
    );
  }

}
