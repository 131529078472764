<h4 class="mb-5">Reportes y análisis de cuenta</h4>
<div class="siniestros-contenedor">

  <!-- Analisis de cuenta 1 -->
  <div class="reconocimiento-antiguedad mt-0 " id="report" *ngFor="let r of reportList">
    <div class="col-md-4 d-center gap-8">
      <img src="../../assets/img/ammia/iconos/icono_cargadocumento-gris.svg" alt="">
      <div style="text-align:left !important; ">
        <h5>{{r.typeReport == 1 ? 'Reporte de Siniestralidad' : r.typeReport == 2 ? 'Reporte de Provisiones' : r.typeReport == 3 ? r.reportName: ''}}</h5>
        <p>{{r.reportDate  | date: 'MMM yyyy' }}</p>
      </div>

    </div>
    <div class="col-md-4 d-center gap-8">
      <button  class="btn secundario " type="button" title="Descargar reporte {{r.reportName}}"
               (click)="invokeServiceForDownloadReportFile(r.reportId, r.reportName)">
        <img src="../../assets/img/ammia/iconos/download-azul.svg" alt="Ícono de Agregar tarea" class="icono-sm">
        Descargar
      </button>
    </div>
    <div class="col-md-4 d-center gap-8">
      <button type="button"  href="" class="enlace"  data-bs-toggle="modal" data-bs-target="#reportesVistaPrevia" *ngIf="r.typeReport == 1" (click)="invokeServiceGetDetailReport(r)">
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.586 10.896C15.5455 10.7991 15.4887 10.7098 15.418 10.632L13.018 8.232C12.8674 8.08136 12.663 7.99673 12.45 7.99673C12.237 7.99673 12.0326 8.08136 11.882 8.232C11.7314 8.38264 11.6467 8.58696 11.6467 8.8C11.6467 9.01304 11.7314 9.21736 11.882 9.368L12.922 10.4H8.45C8.23783 10.4 8.03434 10.4843 7.88432 10.6343C7.73429 10.7843 7.65 10.9878 7.65 11.2C7.65 11.4122 7.73429 11.6157 7.88432 11.7657C8.03434 11.9157 8.23783 12 8.45 12H12.922L11.882 13.032C11.807 13.1064 11.7475 13.1949 11.7069 13.2923C11.6663 13.3898 11.6454 13.4944 11.6454 13.6C11.6454 13.7056 11.6663 13.8102 11.7069 13.9077C11.7475 14.0051 11.807 14.0936 11.882 14.168C11.9564 14.243 12.0449 14.3025 12.1423 14.3431C12.2398 14.3837 12.3444 14.4046 12.45 14.4046C12.5556 14.4046 12.6602 14.3837 12.7577 14.3431C12.8551 14.3025 12.9436 14.243 13.018 14.168L15.418 11.768C15.4921 11.6929 15.5493 11.6029 15.586 11.504C15.666 11.3092 15.666 11.0908 15.586 10.896ZM10.05 14.4H3.65C3.43783 14.4 3.23434 14.3157 3.08431 14.1657C2.93429 14.0157 2.85 13.8122 2.85 13.6V2.4C2.85 2.18783 2.93429 1.98434 3.08431 1.83431C3.23434 1.68429 3.43783 1.6 3.65 1.6H7.65V4C7.65 4.63652 7.90286 5.24697 8.35294 5.69706C8.80303 6.14714 9.41348 6.4 10.05 6.4H13.25C13.4079 6.39921 13.5621 6.35169 13.6931 6.26342C13.8241 6.17516 13.926 6.0501 13.986 5.904C14.0473 5.75831 14.064 5.59774 14.0341 5.44255C14.0042 5.28736 13.929 5.1445 13.818 5.032L9.018 0.232C8.95187 0.169773 8.87626 0.118465 8.794 0.0799999H8.722L8.498 0H3.65C3.01348 0 2.40303 0.252856 1.95294 0.702944C1.50286 1.15303 1.25 1.76348 1.25 2.4V13.6C1.25 14.2365 1.50286 14.847 1.95294 15.2971C2.40303 15.7471 3.01348 16 3.65 16H10.05C10.2622 16 10.4657 15.9157 10.6157 15.7657C10.7657 15.6157 10.85 15.4122 10.85 15.2C10.85 14.9878 10.7657 14.7843 10.6157 14.6343C10.4657 14.4843 10.2622 14.4 10.05 14.4ZM9.25 2.728L11.322 4.8H10.05C9.83783 4.8 9.63434 4.71571 9.48431 4.56569C9.33429 4.41566 9.25 4.21217 9.25 4V2.728Z" />
        </svg>
        <span>Vista previa</span>
      </button>
    </div>

  </div>

</div>
<div class="d-end w-100 mt-3" *ngIf="false">
  <button type="button"  href="" class="enlace" data-bs-toggle="modal" data-bs-target="#verHistorialReportes">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6ZM0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8ZM8 4C8.21217 4 8.41566 4.08429 8.56569 4.23431C8.71571 4.38434 8.8 4.58783 8.8 4.8V7.2H11.2C11.4122 7.2 11.6157 7.28429 11.7657 7.43431C11.9157 7.58434 12 7.78783 12 8C12 8.21217 11.9157 8.41566 11.7657 8.56569C11.6157 8.71571 11.4122 8.8 11.2 8.8H8.8V11.2C8.8 11.4122 8.71571 11.6157 8.56569 11.7657C8.41566 11.9157 8.21217 12 8 12C7.78783 12 7.58434 11.9157 7.43431 11.7657C7.28429 11.6157 7.2 11.4122 7.2 11.2V8.8H4.8C4.58783 8.8 4.38434 8.71571 4.23431 8.56569C4.08429 8.41566 4 8.21217 4 8C4 7.78783 4.08429 7.58434 4.23431 7.43431C4.38434 7.28429 4.58783 7.2 4.8 7.2H7.2V4.8C7.2 4.58783 7.28429 4.38434 7.43431 4.23431C7.58434 4.08429 7.78783 4 8 4Z"/>
    </svg>
    Ver historial de reportes
  </button>

</div>

<!-- Modal de VER REPORTES VISTA PREVIA -->
<div class="modal  fade " id="reportesVistaPrevia"  tabindex="-1" aria-labelledby="verEventoLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered justify-content-center modal-dialog-scrollable modal-xl xxl">
    <div class="modal-content evento">
      <div class="modal-header evento">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="d-column-start gap-8">
          <h3 class="d-flex gap-8 modal-title " id="verEventoLabel">
            Vista previa
          </h3>
          <h5>{{nameReport}}</h5>
        </div>

      </div>
      <div class="modal-body">
        <table class="table align-middle" >
          <thead>
          <tr>
            <th scope="col">Ramo</th>
            <th scope="col">Póliza</th>
            <th scope="col">No. Incidente</th>
            <th scope="col">Fecha de pago</th>
            <th scope="col">Importe reclamado</th>
            <th scope="col">Deducible</th>
            <th scope="col">Coaseguro</th>
            <th scope="col">Importe con IVA</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of reportDataList">
            <th scope="row d-flex">{{item.industry}}</th>
            <td>{{item.policy}}</td>
            <td>{{item.incidentNumber}}</td>
            <td>{{item.paymentDate}}</td>
            <td>{{item.amountClaimed | CustomCurrency}}</td>
            <td>{{item.deductible | CustomCurrency}}</td>
            <td>{{item.coaseguro | CustomCurrency}}</td>
            <td>{{item.amountPaidIva | CustomCurrency}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


