<div>
  <div style="height: 130px;width: 100%; border-bottom: 1px solid #C4C4C4; text-align: center;padding: 20px">
    <span class="sx-iconsys-icon" aria-hidden="true" style="float: right;font-size: 22px;font-weight: normal;color: #C4C4C4;cursor: pointer;" (click)="shared.fCloseSidebar()">x</span>
    <p style="color: #448AA6;font-size: 16px;font-weight: 600;line-height: 19px;margin-top: 33px">
      Cuenta
    </p>
  </div>
  <div class="row" style="height: calc(100% - 200px)">
    <div class="col-xs-12">
      <div class="modal-body">
        <div class="image-profile-container col-md-12">
          <img class="image-profile img-responsive" src="{{userUpdate.imageProfile}}" />
          <div class="btn-float-edit-image">
            <label><span class="sx-iconsys-icon i-icon">e</span>
              <input type="file" style="display: none;" (change)="onUploadChange($event)"  accept=".png, .jpg, .jpeg">
            </label>
            <!--<span class="sx-iconsys-icon i-icon">
              <input type="file" style="display: none;" (change)="onUploadChange($event)" >
            </span>-->
          </div>
        </div>
        <div class="title-profile col-md-12"><strong>{{userUpdate.name}}</strong></div><br>
        <div class="subtitle-profile col-md-12">{{userUpdate.position}}</div><br>
        <div class="subtitle-profile col-md-12">{{userUpdate.email}}</div><br>
        <div class="subtitle-profilecol-md-12 " *ngIf="!banderapass"><br>
          <span>************</span><br><br><br>
          <div class="btn-float-edit-data col-md-12">
            <span class="sx-iconsys-icon i-icon" (click)="changuepassword()">e</span>
          </div>
        </div>
        <div class="p-top-20">
          <div class="form-group form-group-sm">
            <label class="sr-only control-label" for="oldPass">Contraseña actual</label>
            <input type="password" id="oldPass" class="form-control" [(ngModel)]="oldpassword" *ngIf="banderapass" placeholder="Contraseña actual">
          </div>
          <div class="form-group form-group-sm">
            <label class="sr-only control-label" for="newPass">Contraseña nueva</label>
            <input type="password" id="newPAss" class="form-control" [(ngModel)]="newpassword" *ngIf="banderapass" placeholder="Contraseña nueva">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer text-center">
    <button class="btn btn-xs btn-custom" (click)="updateProfile()">Actualizar</button>
  </div>
</div>
