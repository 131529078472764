<div class="eventos-contenedor" id="eventosContainer">
  <h3 class=" mb-3"><b>Eventos</b></h3>
  <!-- NAV PILLS -->
  <div class="nav nav-pills mb-3 w-100 d-center gap-16" id="solicitudes-siniestros" role="tablist">
    <div class="nav-item" role="presentationCar">
      <button class="nav-link active" id="solicitudesCar" data-bs-toggle="pill" data-bs-target="#solicitudesCar-pill" type="button" role="tab" aria-controls="solicitudes-pill" aria-selected="true"
              (click)="invokeFunctionChangeTab(1)">
        Solicitudes
      </button>
    </div>
    <div class="nav-item" role="presentationCar">
      <button class="nav-link" id="siniestrosCar" data-bs-toggle="pill" data-bs-target="#siniestrosCar-pill" type="button" role="tab" aria-controls="siniestros-pill" aria-selected="false"
              (click)="invokeFunctionChangeTab(2)">
        Siniestros
      </button>
    </div>

  </div>
  <!-- CONTENEDOR DE FICHAS -->
  <div class="tab-content" id="solicitudes-siniestros-content"
       infinite-scroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="50"
       [scrollWindow]="false"
       (scrolled)="tabId == 1 ? onScrollRequest() : onScrollIncident()">
    <!-- Comienzan TARJETAS DE SOLICITUD -->
    <div class="tab-pane fade show active" id="solicitudesCar-pill" role="tabpanel" aria-labelledby="solicitudes-pill">
      <!--INICIA Tarjeta de solicitud-->
      <div class="estadisticas-card mb-3" *ngFor="let r of requestList"  [ngClass]="r.requestTypeId == 1 ? 'alta' : r.requestTypeId == 2 ? 'baja' : 'general' ">
        <div class="estadisticas-card-label" >
          <img *ngIf="r.requestTypeId == 1" src="../../assets/img/ammia/iconos/plus-azul-primario.svg" alt="reunion-presencial">
          <img *ngIf="r.requestTypeId == 2" src="../../assets/img/ammia/iconos/minus-rojo.svg" alt="reunion-presencial">
          <img *ngIf="r.requestTypeId == 3" src="../../assets/img/ammia/iconos/user-turquesa.svg" alt="reunion-presencial">
        </div>
        <div class="estadisticas-card-container">

          <div class="d-between w-100 mb-3">
            <h4>{{r.typeRequest | titlecase }}</h4>
            <div [ngClass]="r.statusId == 3 ? 'tag green' : r.statusId == 4 ? 'tag red' : 'tag yellow' " data-bs-toggle="tooltip" data-bs-placement="top" title="{{r.statusRequest | titlecase}}">
              {{r.statusRequest | titlecase }}
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="r.statusId == 3">
                <path d="M8.76187 3.49403L5.11593 7.16393L3.7634 5.76587L2.99893 6.52315L4.58668 8.15422C4.64549 8.21247 4.7631 8.27073 4.88071 8.32898C4.99832 8.38723 5.11593 8.38723 5.17474 8.38723C5.23354 8.38723 5.40996 8.38723 5.46876 8.32898C5.58637 8.27073 5.64518 8.21247 5.76279 8.15422L9.58514 4.25131L8.76187 3.49403Z" />
                <path d="M10.291 1.74757C9.17365 0.640777 7.64471 0 5.99815 0C4.41041 0 2.88147 0.640777 1.76416 1.74757C0.64686 2.91262 0 4.36893 0 6C0 7.57282 0.64686 9.08738 1.76416 10.2524C2.88147 11.3592 4.41041 12 5.99815 12C7.5859 12 9.11484 11.3592 10.2321 10.2524C11.3494 9.14563 11.9963 7.63107 11.9963 6C12.0551 4.36893 11.4083 2.91262 10.291 1.74757ZM6.05696 10.835C3.35191 10.835 1.17611 8.62136 1.17611 5.94175C1.17611 3.26214 3.35191 1.04854 6.05696 1.04854C8.76201 1.04854 10.9378 3.26214 10.9378 5.94175C10.9378 8.67961 8.76201 10.835 6.05696 10.835Z" />
              </svg>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="r.statusId != 3 && r.statusId != 4">
                <path d="M6 0C4.52275 0.00105318 3.09806 0.548311 2 1.5365V0.5C2 0.367392 1.94732 0.240215 1.85355 0.146447C1.75978 0.0526784 1.63261 0 1.5 0C1.36739 0 1.24021 0.0526784 1.14645 0.146447C1.05268 0.240215 1 0.367392 1 0.5V2C1 2.39782 1.15804 2.77935 1.43934 3.06066C1.72064 3.34196 2.10217 3.5 2.5 3.5H4C4.13261 3.5 4.25978 3.44732 4.35355 3.35355C4.44732 3.25978 4.5 3.13261 4.5 3C4.5 2.86739 4.44732 2.74021 4.35355 2.64645C4.25978 2.55268 4.13261 2.5 4 2.5H2.5C2.48314 2.4975 2.46644 2.49399 2.45 2.4895C3.26476 1.66891 4.33906 1.15654 5.48953 1.03983C6.64 0.923127 7.79531 1.20933 8.75826 1.84958C9.72121 2.48983 10.4321 3.44445 10.7696 4.55047C11.1071 5.6565 11.0502 6.84538 10.6088 7.91417C10.1673 8.98296 9.36861 9.8654 8.34897 10.4109C7.32934 10.9563 6.15199 11.131 5.01791 10.9051C3.88382 10.6792 2.86329 10.0667 2.13053 9.17209C1.39776 8.27753 0.998185 7.15637 1 6C1 5.86739 0.947321 5.74021 0.853553 5.64645C0.759785 5.55268 0.632608 5.5 0.5 5.5C0.367392 5.5 0.240215 5.55268 0.146447 5.64645C0.0526784 5.74021 0 5.86739 0 6C0 7.18669 0.351894 8.34672 1.01118 9.33342C1.67047 10.3201 2.60754 11.0891 3.7039 11.5433C4.80025 11.9974 6.00665 12.1162 7.17054 11.8847C8.33443 11.6532 9.40352 11.0818 10.2426 10.2426C11.0818 9.40352 11.6532 8.33443 11.8847 7.17054C12.1162 6.00665 11.9974 4.80025 11.5433 3.7039C11.0891 2.60754 10.3201 1.67047 9.33342 1.01118C8.34672 0.351894 7.18669 0 6 0Z" />
                <path d="M6.00017 3C5.86756 3 5.74038 3.05268 5.64662 3.14645C5.55285 3.24021 5.50017 3.36739 5.50017 3.5V6C5.5002 6.1326 5.55289 6.25975 5.64667 6.3535L7.14667 7.8535C7.24097 7.94458 7.36727 7.99497 7.49837 7.99383C7.62947 7.9927 7.75487 7.94011 7.84758 7.84741C7.94028 7.7547 7.99287 7.6293 7.99401 7.4982C7.99514 7.3671 7.94475 7.2408 7.85367 7.1465L6.50017 5.793V3.5C6.50017 3.36739 6.44749 3.24021 6.35372 3.14645C6.25995 3.05268 6.13278 3 6.00017 3Z" />
              </svg>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="r.statusId == 4">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 1.15254C3.32282 1.15254 1.15254 3.32282 1.15254 6C1.15254 8.67718 3.32282 10.8475 6 10.8475C8.67718 10.8475 10.8475 8.67718 10.8475 6C10.8475 3.32282 8.67718 1.15254 6 1.15254ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0346 3.9654C8.25965 4.19044 8.25965 4.55532 8.0346 4.78037L4.78037 8.0346C4.55532 8.25965 4.19044 8.25965 3.9654 8.0346C3.74035 7.80956 3.74035 7.44468 3.9654 7.21963L7.21963 3.9654C7.44468 3.74035 7.80956 3.74035 8.0346 3.9654Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9654 3.9654C4.19044 3.74035 4.55532 3.74035 4.78037 3.9654L8.0346 7.21963C8.25965 7.44468 8.25965 7.80956 8.0346 8.0346C7.80956 8.25965 7.44468 8.25965 7.21963 8.0346L3.9654 4.78037C3.74035 4.55532 3.74035 4.19044 3.9654 3.9654Z" />
              </svg>
            </div>

          </div>

          <p *ngIf="r.requestTypeId == 1 && _branchId == 1">
            Generada por <b>{{r.applicantName | titlecase}}</b> para <b>{{r.description | lowercase}}</b> en {{r.client | titlecase}} el {{r.created}} con el folio: <strong>{{r.folio}} </strong></p>
          <p *ngIf="r.requestTypeId == 2 && _branchId == 1">
            Generada por <b>{{r.applicantName | titlecase}}</b> para <b>{{r.description | lowercase }}</b> en {{r.client | titlecase}} el {{r.created}} con el folio: <strong>{{r.folio}} </strong></p>
          <p *ngIf="r.requestTypeId == 3 && _branchId == 1">
            Generada por <b>{{r.applicantName | titlecase}}</b> con asunto <b>{{r.description | lowercase }}</b> en {{r.client | titlecase}} el {{r.created}} con el folio: <strong>{{r.folio}} </strong></p>

          <p *ngIf="r.requestTypeId == 1 && _branchId == 3">
            Generada por <b>{{r.applicantName | titlecase}}</b>  para el vehículo <b>{{r.description | lowercase }}</b> en {{r.client | titlecase}} el {{r.created}} con el folio: <strong>{{r.folio}} </strong></p>
          <p *ngIf="r.requestTypeId == 2 && _branchId == 3">
            Generada por <b>{{r.applicantName | titlecase}}</b>  para el vehículo <b>{{r.description | lowercase }}</b> en {{r.client | titlecase}} el {{r.created}} con el folio: <strong>{{r.folio}} </strong></p>
          <p *ngIf="r.requestTypeId == 3 && _branchId == 3">
            Generada por <b>{{r.applicantName | titlecase}}</b> con asunto <b>{{r.description | lowercase }}</b> en {{r.client | titlecase}} el {{r.created}} con el folio: <strong>{{r.folio}} </strong></p>
          <p *ngIf="r.requestTypeId == 3 && _branchId == 3">
            Generada por <b>{{r.applicantName | titlecase}}</b> con asunto <b>{{r.description | lowercase }}</b> en {{r.client | titlecase}} el {{r.created}} con el folio: <strong>{{r.folio}} </strong></p>
          <div class="d-end w-100">
            <button type="button"  href="" class="enlace" data-bs-toggle="modal" data-bs-target="#modalCarRequest" (click)="invokeFunctionOpenDetailRequest(r)">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6ZM0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8ZM8 4C8.21217 4 8.41566 4.08429 8.56569 4.23431C8.71571 4.38434 8.8 4.58783 8.8 4.8V7.2H11.2C11.4122 7.2 11.6157 7.28429 11.7657 7.43431C11.9157 7.58434 12 7.78783 12 8C12 8.21217 11.9157 8.41566 11.7657 8.56569C11.6157 8.71571 11.4122 8.8 11.2 8.8H8.8V11.2C8.8 11.4122 8.71571 11.6157 8.56569 11.7657C8.41566 11.9157 8.21217 12 8 12C7.78783 12 7.58434 11.9157 7.43431 11.7657C7.28429 11.6157 7.2 11.4122 7.2 11.2V8.8H4.8C4.58783 8.8 4.38434 8.71571 4.23431 8.56569C4.08429 8.41566 4 8.21217 4 8C4 7.78783 4.08429 7.58434 4.23431 7.43431C4.38434 7.28429 4.58783 7.2 4.8 7.2H7.2V4.8C7.2 4.58783 7.28429 4.38434 7.43431 4.23431C7.58434 4.08429 7.78783 4 8 4Z"/>
              </svg>
              Ver detalle
            </button>

          </div>

        </div>
      </div>
      <!--Termina Tarjeta de solicitud 1-->
    </div>
    <!-- Comienzan TARJETAS DE SINIESTROS -->
    <div class="tab-pane fade" id="siniestrosCar-pill" role="tabpanel" aria-labelledby="siniestros-pill">
      <!--INICIA Tarjeta de siniestro -->
      <div class="estadisticas-card siniestro mb-3" *ngFor="let i of incidentList">
        <div class="estadisticas-card-label" >
          <img src="../../assets/img/ammia/iconos/siniestro-rojo.svg" alt="reunion-presencial">
        </div>
        <div class="estadisticas-card-container">

          <div class="d-between w-100 mb-3">
            <h4>{{i.subBranch | titlecase}}</h4>
            <div  [ngClass]="i.phaseId == 5 ? 'tag green' : i.phaseId == 6 ? 'tag red' : 'tag yellow' " data-bs-toggle="tooltip" data-bs-placement="top" title="{{i.phase | titlecase}}">
              {{i.phase | titlecase}}
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="i.phaseId == 5">
                <path d="M8.76187 3.49403L5.11593 7.16393L3.7634 5.76587L2.99893 6.52315L4.58668 8.15422C4.64549 8.21247 4.7631 8.27073 4.88071 8.32898C4.99832 8.38723 5.11593 8.38723 5.17474 8.38723C5.23354 8.38723 5.40996 8.38723 5.46876 8.32898C5.58637 8.27073 5.64518 8.21247 5.76279 8.15422L9.58514 4.25131L8.76187 3.49403Z" />
                <path d="M10.291 1.74757C9.17365 0.640777 7.64471 0 5.99815 0C4.41041 0 2.88147 0.640777 1.76416 1.74757C0.64686 2.91262 0 4.36893 0 6C0 7.57282 0.64686 9.08738 1.76416 10.2524C2.88147 11.3592 4.41041 12 5.99815 12C7.5859 12 9.11484 11.3592 10.2321 10.2524C11.3494 9.14563 11.9963 7.63107 11.9963 6C12.0551 4.36893 11.4083 2.91262 10.291 1.74757ZM6.05696 10.835C3.35191 10.835 1.17611 8.62136 1.17611 5.94175C1.17611 3.26214 3.35191 1.04854 6.05696 1.04854C8.76201 1.04854 10.9378 3.26214 10.9378 5.94175C10.9378 8.67961 8.76201 10.835 6.05696 10.835Z" />
              </svg>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="i.phaseId != 5 && i.phaseId != 6">
                <path d="M6 0C4.52275 0.00105318 3.09806 0.548311 2 1.5365V0.5C2 0.367392 1.94732 0.240215 1.85355 0.146447C1.75978 0.0526784 1.63261 0 1.5 0C1.36739 0 1.24021 0.0526784 1.14645 0.146447C1.05268 0.240215 1 0.367392 1 0.5V2C1 2.39782 1.15804 2.77935 1.43934 3.06066C1.72064 3.34196 2.10217 3.5 2.5 3.5H4C4.13261 3.5 4.25978 3.44732 4.35355 3.35355C4.44732 3.25978 4.5 3.13261 4.5 3C4.5 2.86739 4.44732 2.74021 4.35355 2.64645C4.25978 2.55268 4.13261 2.5 4 2.5H2.5C2.48314 2.4975 2.46644 2.49399 2.45 2.4895C3.26476 1.66891 4.33906 1.15654 5.48953 1.03983C6.64 0.923127 7.79531 1.20933 8.75826 1.84958C9.72121 2.48983 10.4321 3.44445 10.7696 4.55047C11.1071 5.6565 11.0502 6.84538 10.6088 7.91417C10.1673 8.98296 9.36861 9.8654 8.34897 10.4109C7.32934 10.9563 6.15199 11.131 5.01791 10.9051C3.88382 10.6792 2.86329 10.0667 2.13053 9.17209C1.39776 8.27753 0.998185 7.15637 1 6C1 5.86739 0.947321 5.74021 0.853553 5.64645C0.759785 5.55268 0.632608 5.5 0.5 5.5C0.367392 5.5 0.240215 5.55268 0.146447 5.64645C0.0526784 5.74021 0 5.86739 0 6C0 7.18669 0.351894 8.34672 1.01118 9.33342C1.67047 10.3201 2.60754 11.0891 3.7039 11.5433C4.80025 11.9974 6.00665 12.1162 7.17054 11.8847C8.33443 11.6532 9.40352 11.0818 10.2426 10.2426C11.0818 9.40352 11.6532 8.33443 11.8847 7.17054C12.1162 6.00665 11.9974 4.80025 11.5433 3.7039C11.0891 2.60754 10.3201 1.67047 9.33342 1.01118C8.34672 0.351894 7.18669 0 6 0Z" />
                <path d="M6.00017 3C5.86756 3 5.74038 3.05268 5.64662 3.14645C5.55285 3.24021 5.50017 3.36739 5.50017 3.5V6C5.5002 6.1326 5.55289 6.25975 5.64667 6.3535L7.14667 7.8535C7.24097 7.94458 7.36727 7.99497 7.49837 7.99383C7.62947 7.9927 7.75487 7.94011 7.84758 7.84741C7.94028 7.7547 7.99287 7.6293 7.99401 7.4982C7.99514 7.3671 7.94475 7.2408 7.85367 7.1465L6.50017 5.793V3.5C6.50017 3.36739 6.44749 3.24021 6.35372 3.14645C6.25995 3.05268 6.13278 3 6.00017 3Z" />
              </svg>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="i.phaseId == 6">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 1.15254C3.32282 1.15254 1.15254 3.32282 1.15254 6C1.15254 8.67718 3.32282 10.8475 6 10.8475C8.67718 10.8475 10.8475 8.67718 10.8475 6C10.8475 3.32282 8.67718 1.15254 6 1.15254ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0346 3.9654C8.25965 4.19044 8.25965 4.55532 8.0346 4.78037L4.78037 8.0346C4.55532 8.25965 4.19044 8.25965 3.9654 8.0346C3.74035 7.80956 3.74035 7.44468 3.9654 7.21963L7.21963 3.9654C7.44468 3.74035 7.80956 3.74035 8.0346 3.9654Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9654 3.9654C4.19044 3.74035 4.55532 3.74035 4.78037 3.9654L8.0346 7.21963C8.25965 7.44468 8.25965 7.80956 8.0346 8.0346C7.80956 8.25965 7.44468 8.25965 7.21963 8.0346L3.9654 4.78037C3.74035 4.55532 3.74035 4.19044 3.9654 3.9654Z" />
              </svg>
            </div>

          </div>

          <p>Siniestro generado por <b>{{i.applicationName | titlecase}}</b> de <b> {{i.typeIncident | titlecase}}</b> el {{i.created}}</p>

          <div class="d-end w-100">
            <button type="button"  href="" class="enlace" data-bs-toggle="modal" data-bs-target="#modalCarIncident" (click)="invokeFunctionDetailIncident(i)">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6ZM0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8ZM8 4C8.21217 4 8.41566 4.08429 8.56569 4.23431C8.71571 4.38434 8.8 4.58783 8.8 4.8V7.2H11.2C11.4122 7.2 11.6157 7.28429 11.7657 7.43431C11.9157 7.58434 12 7.78783 12 8C12 8.21217 11.9157 8.41566 11.7657 8.56569C11.6157 8.71571 11.4122 8.8 11.2 8.8H8.8V11.2C8.8 11.4122 8.71571 11.6157 8.56569 11.7657C8.41566 11.9157 8.21217 12 8 12C7.78783 12 7.58434 11.9157 7.43431 11.7657C7.28429 11.6157 7.2 11.4122 7.2 11.2V8.8H4.8C4.58783 8.8 4.38434 8.71571 4.23431 8.56569C4.08429 8.41566 4 8.21217 4 8C4 7.78783 4.08429 7.58434 4.23431 7.43431C4.38434 7.28429 4.58783 7.2 4.8 7.2H7.2V4.8C7.2 4.58783 7.28429 4.38434 7.43431 4.23431C7.58434 4.08429 7.78783 4 8 4Z"/>
              </svg>
              Ver detalle
            </button>

          </div>

        </div>
      </div>
      <!--Termina Tarjeta de siniestro -->
    </div>
  </div>

  <!-- Modal de VER detalle de solicitud-->
  <div class="modal fade " id="modalCarRequest"
       data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="verEventoLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered justify-content-center">
      <div class="modal-content evento">
        <div class="modal-header evento">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="invokeFunctionCloseRequestDetail()"></button>
          <div class="d-column-start gap-8 w-100">
            <div class="d-between">
              <h3 class="d-flex gap-8 modal-title " id="verEventoLabel">
                <img *ngIf="requestDetail.requestTypeId == 1" src="../../assets/img/ammia/iconos/plus-azul-primario.svg" alt="reunion-presencial">
                <img *ngIf="requestDetail.requestTypeId == 2" src="../../assets/img/ammia/iconos/minus-rojo.svg" alt="reunion-presencial">
                <img *ngIf="requestDetail.requestTypeId == 3" src="../../assets/img/ammia/iconos/user-turquesa.svg" alt="reunion-presencial">
                {{requestDetail.movement | titlecase}}
              </h3>
              <div [ngClass]="requestDetail.statusId == 3 ? 'tag green' : requestDetail.statusId == 4 ? 'tag red' : 'tag yellow' " data-bs-toggle="tooltip" data-bs-placement="top" title="{{requestDetail.status}}">
                {{requestDetail.status | titlecase}}
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="requestDetail.statusId == 3">
                  <path d="M8.76187 3.49403L5.11593 7.16393L3.7634 5.76587L2.99893 6.52315L4.58668 8.15422C4.64549 8.21247 4.7631 8.27073 4.88071 8.32898C4.99832 8.38723 5.11593 8.38723 5.17474 8.38723C5.23354 8.38723 5.40996 8.38723 5.46876 8.32898C5.58637 8.27073 5.64518 8.21247 5.76279 8.15422L9.58514 4.25131L8.76187 3.49403Z" />
                  <path d="M10.291 1.74757C9.17365 0.640777 7.64471 0 5.99815 0C4.41041 0 2.88147 0.640777 1.76416 1.74757C0.64686 2.91262 0 4.36893 0 6C0 7.57282 0.64686 9.08738 1.76416 10.2524C2.88147 11.3592 4.41041 12 5.99815 12C7.5859 12 9.11484 11.3592 10.2321 10.2524C11.3494 9.14563 11.9963 7.63107 11.9963 6C12.0551 4.36893 11.4083 2.91262 10.291 1.74757ZM6.05696 10.835C3.35191 10.835 1.17611 8.62136 1.17611 5.94175C1.17611 3.26214 3.35191 1.04854 6.05696 1.04854C8.76201 1.04854 10.9378 3.26214 10.9378 5.94175C10.9378 8.67961 8.76201 10.835 6.05696 10.835Z" />
                </svg>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="requestDetail.statusId != 3 && requestDetail.statusId != 4">
                  <path d="M6 0C4.52275 0.00105318 3.09806 0.548311 2 1.5365V0.5C2 0.367392 1.94732 0.240215 1.85355 0.146447C1.75978 0.0526784 1.63261 0 1.5 0C1.36739 0 1.24021 0.0526784 1.14645 0.146447C1.05268 0.240215 1 0.367392 1 0.5V2C1 2.39782 1.15804 2.77935 1.43934 3.06066C1.72064 3.34196 2.10217 3.5 2.5 3.5H4C4.13261 3.5 4.25978 3.44732 4.35355 3.35355C4.44732 3.25978 4.5 3.13261 4.5 3C4.5 2.86739 4.44732 2.74021 4.35355 2.64645C4.25978 2.55268 4.13261 2.5 4 2.5H2.5C2.48314 2.4975 2.46644 2.49399 2.45 2.4895C3.26476 1.66891 4.33906 1.15654 5.48953 1.03983C6.64 0.923127 7.79531 1.20933 8.75826 1.84958C9.72121 2.48983 10.4321 3.44445 10.7696 4.55047C11.1071 5.6565 11.0502 6.84538 10.6088 7.91417C10.1673 8.98296 9.36861 9.8654 8.34897 10.4109C7.32934 10.9563 6.15199 11.131 5.01791 10.9051C3.88382 10.6792 2.86329 10.0667 2.13053 9.17209C1.39776 8.27753 0.998185 7.15637 1 6C1 5.86739 0.947321 5.74021 0.853553 5.64645C0.759785 5.55268 0.632608 5.5 0.5 5.5C0.367392 5.5 0.240215 5.55268 0.146447 5.64645C0.0526784 5.74021 0 5.86739 0 6C0 7.18669 0.351894 8.34672 1.01118 9.33342C1.67047 10.3201 2.60754 11.0891 3.7039 11.5433C4.80025 11.9974 6.00665 12.1162 7.17054 11.8847C8.33443 11.6532 9.40352 11.0818 10.2426 10.2426C11.0818 9.40352 11.6532 8.33443 11.8847 7.17054C12.1162 6.00665 11.9974 4.80025 11.5433 3.7039C11.0891 2.60754 10.3201 1.67047 9.33342 1.01118C8.34672 0.351894 7.18669 0 6 0Z" />
                  <path d="M6.00017 3C5.86756 3 5.74038 3.05268 5.64662 3.14645C5.55285 3.24021 5.50017 3.36739 5.50017 3.5V6C5.5002 6.1326 5.55289 6.25975 5.64667 6.3535L7.14667 7.8535C7.24097 7.94458 7.36727 7.99497 7.49837 7.99383C7.62947 7.9927 7.75487 7.94011 7.84758 7.84741C7.94028 7.7547 7.99287 7.6293 7.99401 7.4982C7.99514 7.3671 7.94475 7.2408 7.85367 7.1465L6.50017 5.793V3.5C6.50017 3.36739 6.44749 3.24021 6.35372 3.14645C6.25995 3.05268 6.13278 3 6.00017 3Z" />
                </svg>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="requestDetail.statusId == 4">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 1.15254C3.32282 1.15254 1.15254 3.32282 1.15254 6C1.15254 8.67718 3.32282 10.8475 6 10.8475C8.67718 10.8475 10.8475 8.67718 10.8475 6C10.8475 3.32282 8.67718 1.15254 6 1.15254ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0346 3.9654C8.25965 4.19044 8.25965 4.55532 8.0346 4.78037L4.78037 8.0346C4.55532 8.25965 4.19044 8.25965 3.9654 8.0346C3.74035 7.80956 3.74035 7.44468 3.9654 7.21963L7.21963 3.9654C7.44468 3.74035 7.80956 3.74035 8.0346 3.9654Z" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9654 3.9654C4.19044 3.74035 4.55532 3.74035 4.78037 3.9654L8.0346 7.21963C8.25965 7.44468 8.25965 7.80956 8.0346 8.0346C7.80956 8.25965 7.44468 8.25965 7.21963 8.0346L3.9654 4.78037C3.74035 4.55532 3.74035 4.19044 3.9654 3.9654Z" />
                </svg>
              </div>

            </div>
            <h5>{{requestDetail.subBranch | titlecase}}</h5>
          </div>

        </div>
        <div class="modal-body">
          <div class="datos-grupo">
            <div class="datos-row">
              <div>
                <p class="label">Número de folio</p>
                <p class="dato">{{requestDetail.folio}}</p>
              </div>
            </div>
            <div class="datos-row">
              <div>
                <p class="label">Solicitante</p>
                <p class="dato">{{requestDetail.applicantName | titlecase}}</p>
              </div>
              <div>
                <p class="label">Póliza</p>
                <p class="dato">{{requestDetail.number}}</p>
              </div>
            </div>
            <div class="datos-row">
              <div>
                <p class="label" *ngIf="_branchId == 1 && (requestDetail.requestTypeId == 1 || requestDetail.requestTypeId == 2)">Asegurado</p>
                <p class="label" *ngIf="_branchId == 3 && (requestDetail.requestTypeId == 1 || requestDetail.requestTypeId == 2)">Vehículo</p>
                <p class="label" *ngIf="requestDetail.requestTypeId == 3">Asunto</p>
                <p class="dato">{{requestDetail.description | lowercase }}</p>
              </div>
              <div *ngIf="requestDetail.requestTypeId != 3">
                <p class="label">Tipo de asegurado</p>
                <p class="dato">{{requestDetail.type}}</p>
              </div>
            </div>
            <div class="datos-row">
              <div>
                <p class="label">Razón social</p>
                <p class="dato">{{requestDetail.client}}</p>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
  <!-- Modal de VER SNIESTRO-->
  <div class="modal fade " id="modalCarIncident" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="verEventoLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered justify-content-center">
      <div class="modal-content evento">
        <div class="modal-header evento">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="invokeFunctionCloseModalIncident()"></button>
          <div class="d-column-start gap-8 w-100">

            <h3 class="d-flex gap-8 modal-title " id="verEventoLabel">
              <img src="../../assets/img/ammia/iconos/siniestro-rojo.svg" alt="">
              Siniestro reportado
            </h3>
            <div class="d-between">
              <h5>{{incidentDetail.subBranch | titlecase}}</h5>
              <div  [ngClass]="incidentDetail.phaseId == 5 ? 'tag green' : incidentDetail.phaseId == 6 ? 'tag red' : 'tag yellow' " data-bs-toggle="tooltip" data-bs-placement="top" title="{{incidentDetail.phase}}">
                {{incidentDetail.phase | titlecase}}
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.76187 3.49403L5.11593 7.16393L3.7634 5.76587L2.99893 6.52315L4.58668 8.15422C4.64549 8.21247 4.7631 8.27073 4.88071 8.32898C4.99832 8.38723 5.11593 8.38723 5.17474 8.38723C5.23354 8.38723 5.40996 8.38723 5.46876 8.32898C5.58637 8.27073 5.64518 8.21247 5.76279 8.15422L9.58514 4.25131L8.76187 3.49403Z" />
                  <path d="M10.291 1.74757C9.17365 0.640777 7.64471 0 5.99815 0C4.41041 0 2.88147 0.640777 1.76416 1.74757C0.64686 2.91262 0 4.36893 0 6C0 7.57282 0.64686 9.08738 1.76416 10.2524C2.88147 11.3592 4.41041 12 5.99815 12C7.5859 12 9.11484 11.3592 10.2321 10.2524C11.3494 9.14563 11.9963 7.63107 11.9963 6C12.0551 4.36893 11.4083 2.91262 10.291 1.74757ZM6.05696 10.835C3.35191 10.835 1.17611 8.62136 1.17611 5.94175C1.17611 3.26214 3.35191 1.04854 6.05696 1.04854C8.76201 1.04854 10.9378 3.26214 10.9378 5.94175C10.9378 8.67961 8.76201 10.835 6.05696 10.835Z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="datos-grupo">
            <div class="datos-row">
              <div>
                <p class="label">Número de certificado</p>
                <p class="dato">{{incidentDetail.certificate}}</p>
              </div>
              <div>
                <p class="label">Póliza</p>
                <p class="dato">{{incidentDetail.policy}}</p>
              </div>
            </div>
            <div class="datos-row">
              <div>
                <p class="label">Titular</p>
                <p class="dato">{{incidentDetail.applicantName | titlecase}}</p>
              </div>
              <div>
                <p class="label">Edad</p>
                <p class="dato">{{incidentDetail.age}} años</p>
              </div>
            </div>
            <div class="datos-row">
              <div>
                <p class="label">Tipo de asegurado</p>
                <p class="dato">{{incidentDetail.kinship | titlecase}}</p>
              </div>
              <div>
                <p class="label">Fecha de alta</p>
                <p class="dato">{{incidentDetail.created}}</p>
              </div>
            </div>
            <div class="datos-row">
              <div>
                <p class="label">Número de folio</p>
                <p class="dato">{{incidentDetail.folio}}</p>
              </div>
              <div>
                <p class="label">Tipo de siniestro</p>
                <p class="dato">{{incidentDetail.typeIncident | titlecase}}</p>
              </div>
            </div>
            <div class="datos-row">
              <div>
                <p class="label">Razón social</p>
                <p class="dato">{{incidentDetail.client | titlecase}}</p>
              </div>
            </div>
            <div class="datos-row">
              <div>
                <p class="label">Descripción del reporte</p>
                <p class="dato">{{incidentDetail.incident | lowercase}}</p>
              </div>
            </div>
            <div class="datos-row" *ngIf="_branchId == 3">
              <div>
                <p class="label">Versión vehículo</p>
                <p class="dato">{{incidentDetail.version | lowercase}}</p>
              </div>
            </div>
          </div>


        </div>
        <div class="siniestro-anexo ">
          <div class=" separador-tarjetas  mt-0">
            <h4>Anexos</h4>
            <hr>
          </div>
          <div class="anexo-card mb-3" *ngFor="let a of attachmentList; index as i">
            <div class="d-center gap-8">
              <img src="../../../assets/img/ammia/iconos/icono_cargadocumento.svg" alt="">
              <div>
                <h5>Nombre de Anexo {{i + 1}}</h5>
                <p>{{a.name}}</p>
              </div>
            </div>
            <button type="button"  href="" class="enlace" (click)="invokeServiceForDownloadFileAttachment(a)">
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.7 9.6C15.4878 9.6 15.2843 9.68429 15.1343 9.83432C14.9843 9.98434 14.9 10.1878 14.9 10.4V13.6C14.9 13.8122 14.8157 14.0157 14.6657 14.1657C14.5157 14.3157 14.3122 14.4 14.1 14.4H2.9C2.68783 14.4 2.48434 14.3157 2.33431 14.1657C2.18429 14.0157 2.1 13.8122 2.1 13.6V10.4C2.1 10.1878 2.01571 9.98434 1.86569 9.83432C1.71566 9.68429 1.51217 9.6 1.3 9.6C1.08783 9.6 0.884344 9.68429 0.734315 9.83432C0.584285 9.98434 0.5 10.1878 0.5 10.4V13.6C0.5 14.2365 0.752856 14.847 1.20294 15.2971C1.65303 15.7471 2.26348 16 2.9 16H14.1C14.7365 16 15.347 15.7471 15.7971 15.2971C16.2471 14.847 16.5 14.2365 16.5 13.6V10.4C16.5 10.1878 16.4157 9.98434 16.2657 9.83432C16.1157 9.68429 15.9122 9.6 15.7 9.6ZM7.932 10.968C8.00808 11.0408 8.0978 11.0979 8.196 11.136C8.29176 11.1783 8.3953 11.2002 8.5 11.2002C8.6047 11.2002 8.70824 11.1783 8.804 11.136C8.9022 11.0979 8.99192 11.0408 9.068 10.968L12.268 7.768C12.4186 7.61736 12.5033 7.41304 12.5033 7.2C12.5033 6.98696 12.4186 6.78264 12.268 6.632C12.1174 6.48136 11.913 6.39673 11.7 6.39673C11.487 6.39673 11.2826 6.48136 11.132 6.632L9.3 8.472V0.8C9.3 0.587827 9.21572 0.384344 9.06569 0.234315C8.91566 0.0842855 8.71217 0 8.5 0C8.28783 0 8.08434 0.0842855 7.93431 0.234315C7.78429 0.384344 7.7 0.587827 7.7 0.8V8.472L5.868 6.632C5.79341 6.55741 5.70486 6.49824 5.6074 6.45787C5.50994 6.4175 5.40549 6.39673 5.3 6.39673C5.19451 6.39673 5.09006 6.4175 4.9926 6.45787C4.89514 6.49824 4.80659 6.55741 4.732 6.632C4.65741 6.70659 4.59824 6.79514 4.55787 6.8926C4.5175 6.99006 4.49673 7.09451 4.49673 7.2C4.49673 7.30549 4.5175 7.40994 4.55787 7.5074C4.59824 7.60486 4.65741 7.69341 4.732 7.768L7.932 10.968Z" />
              </svg>

              Descargar
            </button>
          </div>

        </div>


      </div>
    </div>
  </div>

  <div class="modal-shadow-evento"></div>

</div>
