<!--<div class="modal-header">
  <h4 class="modal-title pull-left">Validación de código</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="myForm" novalidate>
    <p class="description">Puede encontrar el código de validación en su correo electrónico</p>
    <input type="text" class="form-control border-input" [(ngModel)]="code" placeholder="Ingresa código" [maxLength]="4" formControlName="code">
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-custom" (click)="onCancel()" >Cancelar</button>
  <button class="btn btn-custom" (click)="sendcode(code)" [disabled]="myForm.invalid">Aceptar</button>
</div>-->


<!-- CONTAINER GENERAL -->
<main class="container-fluid container-general">


  <!--  Contenedor row index -->
  <div class="row contenedor-inicio">
    <div class="col-12 col-lg-4 ">
      <div class="row inicio-sesion-contenedor inicio">
        <div class="row inicio-sesion-contenedor inicio" >
          <!-- Inicia barra de CTA-->
          <div class="col-12 d-center">
            <img class="img-fluid" src="../../assets/img/ammia/logos/ammiaadmin-logo.svg" alt="logotipo">

          </div>
          <!-- Termina barra de CTA-->
          <div class="col-12 d-center">
            <h3>Ingresa el código</h3>
          </div>
          <div>
            <p>Si no encuentras el código en tu bandeja de entrada, verifica tu bandeja de spam o da click en Reenviar código.</p>
          </div>
          <form [formGroup]="myForm" novalidate>
            <div class="col-12 ">
              <div class="d-center gap-16">
                <div class=" ">
                  <input type="text" class="form-control ammiaAdmin-style inicio text-align-center" id="nombreTarea" formControlName="code1" maxlength="1">
                </div>
                <div class="">
                  <input type="text" class="form-control ammiaAdmin-style inicio" id="nombreTarea" name="nombreTarea" formControlName="code2" maxlength="1">
                </div>
                <div class="">
                  <input type="text" class="form-control ammiaAdmin-style inicio" id="nombreTarea" name="nombreTarea" formControlName="code3" maxlength="1">
                </div>
                <div class="">
                  <input type="text" class="form-control ammiaAdmin-style inicio" id="nombreTarea" name="nombreTarea" formControlName="code4" maxlength="1">
                </div>
               <!-- <div class="">
                  <input type="text" class="form-control ammiaAdmin-style inicio" id="nombreTarea" name="nombreTarea" placeholder="" >
                </div>-->

              </div>
            </div>
          </form>
            <br><br>
            <div class="col-8 d-column-center gap-3">
              <button class="btn primario lg mb-4" (click)="sendcode()">
                <img  class="icono-sm"  src="../../assets/img/ammia/iconos/lock-azul.svg" alt="checked">
                Ingresar código
              </button>
            </div>

          <div class="col-10 d-center ">


            <a href="javascript:;"  (click)="invokeServiceGetValidCode()">
              <button type="button" data-toggle="modal" data-target="#" href="" class="enlace btn-link">
                Reenviar código
              </button>
            </a>
          </div>
          <div class="col-10 d-center ">


            <a href="javascript:;" (click)="invokeFunctionGetLogin()">
              <button type="button" data-toggle="modal" data-target="#" href="" class="enlace gris btn-link">
                Volver a Iniciar sesión
              </button>
            </a>
          </div>




        </div>
        <!-- Fin Segundo row: col-container tareas -->
      </div>
      <!-- Fin de la columna "tareas" -->
    </div>

    <!-- Columna "resumen-funnel" -->
    <div class="d-none d-lg-flex col p-0 foto-inicio-container">
      <img class="foto-inicio" src="../../assets/img/ammia/imagenes/foto-inicio.jpg" alt="foto inicio">
    </div>
    <!-- Fin de la columna "resumen-funnel" -->

  </div>
  <!--End  Contenedor row index -->
</main>
<!--TERMINA CONTAINER GENERAL -->
