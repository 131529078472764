import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';
import {environment} from '../../environments/environment';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.css']
})
export class IncidentComponent implements OnInit {
  lclIncidentId: number;
  detailIncident: any = {};
  incidentLog: any = [];
  attachmentsList: any = [];
  logComments: any = [];
  eventIdSelected: number;
  dataTimeline: any = [];

  constructor(private api: ApiService,
              public shared: SharedService,
              private toastr: ToastrService) {
    this.shared.gIncidentIdObservable().subscribe(
      gIncidentId => {
        this.lclIncidentId = gIncidentId;
        this.invokeServiceForGetDetailIncident(this.lclIncidentId);
        this.invokeServiceForGetIncidentLog(this.lclIncidentId);
        this.invokeServiceForGetIncidentAttachments(this.lclIncidentId);

      }
    );
  }

  ngOnInit() {
  }

  invokeServiceForGetDetailIncident(incidentId) {
    this.api.getDetailIncident(incidentId)
      .then(
        (data) => {
          this.detailIncident = data;
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForGetIncidentLog(incidentId) {
    this.api.getIncidentLog(incidentId)
      .then(
        (data) => {
        this.incidentLog = data;
      }, error => {
        console.error(error);
      });
  }

  invokeServiceForGetIncidentAttachments(incidentId) {
    this.api.getIncidentAttachments(incidentId)
      .then(
        (data) => {
        this.attachmentsList = data;
      }, error => {
        console.error(error);
      });
  }

  invokeServiceForGetLogComments(eventId) {
    this.eventIdSelected = eventId;
    this.invokeServiceForGetLogAttachments(eventId);

    this.api.getCommentsLog(eventId)
      .then(
        (data) => {
          this.logComments = data;
        }, error => {
          console.error(error);
        }
      );
  }
  invokeServiceForGetLogAttachments(eventId) {
    this.api.getLogAttachments(eventId)
      .then(
        (data) => {
          this.attachmentsList = data;
        }, error => {
          console.error(error);
        });
  }

  public invokeServiceForDownloadEventAttachmentZipFile() {
    this.api.getLogsAttachmentZipFile(this.eventIdSelected)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  `anexos_de_evento_en_bitacora.zip`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }
  public invokeServiceForDownloadEventAttachmentFile(attachment) {
    this.api.getLogsAttachmentFile(attachment.attachmentId)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForDownloadAnnexFile(annex) {
    this.api.getIncidentAnnexFile(annex.attachmentId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          if (annex.name) {
            downloadLink.setAttribute('download', annex.name);
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        });
  }
}
