<!-- Alta VEHICULO -->
<div id="" class="">
  <div *ngIf="responseService == 0">
    <form action="">
      <!-- selección de cliente --->
      <div class="form-row">
        <div class="form-group">
          <label >Razón Social</label>
          <select class="form-select ammiaAdmin-style  mb-3" aria-label=" example" [(ngModel)]="dataToSend.clientId"
                  name="relationshipDep" required (change)="invokeFunctionGetPoliciesByClient()" >
            <option *ngFor="let item of clientList" [value]="item.clientId"
            >{{item.name}}</option>
          </select>

        </div>
      </div>

      <!-- selección de póliza --->
      <div class="form-group form-group-sm">
        <label class="control-label">Pólizas</label>
        <div class="w-100 d-center">
          <ng-container *ngFor="let subranch of policiesList;  let i = index">
            <div class="poliza-vehiculo-card">
              <h5>{{subranch.subranch }}</h5>
              <h6>{{ subranch.numberPolicy}}</h6>
              <ng-container *ngFor="let category of subranch.policyCategoriesList; let cat = index">
                <input type="checkbox" [(ngModel)]="category.selected" [ngModelOptions]="{standalone: true}"/>
                <label
                  style="font-size: xx-small; vertical-align: middle; color: #326173"> {{category.category}}</label>
                <br>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- SEPARADOR -->
      <div class="separador-tarjetas">
        <h4>Datos del vehículo</h4>
        <hr>
      </div>
      <!-- Marca -->
      <div class="form-group form-group-sm">
        <label class="control-label">Marca</label>
        <ng-select (change)="typeaheadOnSelectMarca($event)"
                   [items]="dataSourceMarca"
                   bindLabel="name"
                   bindValue="name"
                   notFoundText="No se encontraron registros"
                   [(ngModel)]="dataToSend.mark"
                   name="mark"
                   id="mark">
        </ng-select>
      </div>
      <!-- Modelo-->
      <div class="form-group form-group-sm">
        <label class="control-label">Modelo</label>
        <ng-select (change)="typeaheadOnSelectModelo($event)"
                   [items]="dataSourceModelo"
                   bindLabel="name"
                   bindValue="id"
                   notFoundText="No se encontraron registros"
                   [(ngModel)]="dataToSend.model"
                   name="model"
                   id="modelo">
        </ng-select>
      </div>

      <!----Version ---->
      <div class="form-group form-group-sm">
        <label class="control-label">Versión</label>
        <ng-select
          [items]="dataSourceVersion"
          bindLabel="name"
          bindValue="name"
          notFoundText="No se encontraron registros"
          [(ngModel)]="dataToSend.version"
          id="version"
          name="version">
        </ng-select>
      </div>

      <!-- Placa / No. serie / No. motor -->
      <div class="form-row">
        <div class="form-group">
          <label class="control-label">Placa</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.plate" name="plate" required
                 upperCaseText maxlength="10">
        </div>
        <div class="form-group">
          <label class="control-label">No. Serie</label>
          <input type="text" class="form-control" pattern="^[0-9A-HJ-NPR-Za-hj-npr-z\\d]{11}[0-9A-Z]{6}$" maxlength="17"
                 [(ngModel)]="dataToSend.serialNumber" name="serie" required #serie="ngModel">
          <div *ngIf="serie.invalid && serie.touched" class="alert alert-danger">
            Número de serie no válido
          </div>
        </div>
        <div class="form-group">
          <label class="control-label">Número de motor</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.engineNumber" name="motor"
                 required>
        </div>
      </div>

      <!--  la factura tiene menos de 10 días -->
      <div *ngIf="dataToSend.model === yearActual || dataToSend.model === (yearActual + 1) || (monthActual === 1 && yearActual - 1)"
           class="form-group form-group-sm">
        <label class="control-label">¿La factura tiene menos de 10 días de ser emitida?</label>
        <div class="d-block my-3">
          <div class="form-check form-check-inline">
            <input id="authorize" type="radio" class="custom-control-input" [value]="1"
                   (click)="changeValorFactura(1)"
                   [(ngModel)]="facturevalue" aria-selected="true" name="facturevalue">
            <label class="form-check-label  mx-0" for="authorize"> Si</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="decline" type="radio" class="custom-control-input" [value]="2" (click)="changeValorFactura(2)"
                   [(ngModel)]="facturevalue"
                   name="facturevalue">
            <label class="form-check-label  mx-0" for="decline"> No</label>
          </div>
        </div>
      </div>
      <!--- subir documento de valor factura-->
      <div *ngIf="facturevalue === 1 || facturevalue === '1'" class="form-group form-group-sm">
        <label class="control-label">Valor factura</label>
        <div class="input-group">
          <div class="input-group-addon">$</div>
          <input type="number" step="0.01" class="form-control" [(ngModel)]="dataToSend.valueInvoice" name="valueInvoice">
        </div>

        <div class="reconocimiento-antiguedad" id="reconocimientoAntiguedad">
          <div class="d-center gap-8">
            <img src="../../assets/img/ammia/iconos/icono_cargadocumento-gris.svg" alt="">
            <div>
              <h5>Archivo adjunto de la factura</h5>
              <div class="form-group">
                <label class="text-primary" *ngIf="fileUpload == undefined" [hidden]="error.active">----------</label>
                <label class="text-primary" style="width: 100%" *ngIf="fileUpload != undefined" [hidden]="error.active">
                  {{ fileUpload.name }} <span class="pull-right">{{shared.formatBytes(fileUpload.size,2)}}</span>
                </label>
                <label class="text-danger" [hidden]="!error.active">{{ error.msg }}</label>
              </div>
            </div>

          </div>
          <div class="">
            <div class="form-group">
              <label class="btn btn-custom btn-sm btn-block">Subir documento
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6ZM0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8ZM8 4C8.21217 4 8.41566 4.08429 8.56569 4.23431C8.71571 4.38434 8.8 4.58783 8.8 4.8V7.2H11.2C11.4122 7.2 11.6157 7.28429 11.7657 7.43431C11.9157 7.58434 12 7.78783 12 8C12 8.21217 11.9157 8.41566 11.7657 8.56569C11.6157 8.71571 11.4122 8.8 11.2 8.8H8.8V11.2C8.8 11.4122 8.71571 11.6157 8.56569 11.7657C8.41566 11.9157 8.21217 12 8 12C7.78783 12 7.58434 11.9157 7.43431 11.7657C7.28429 11.6157 7.2 11.4122 7.2 11.2V8.8H4.8C4.58783 8.8 4.38434 8.71571 4.23431 8.56569C4.08429 8.41566 4 8.21217 4 8C4 7.78783 4.08429 7.58434 4.23431 7.43431C4.38434 7.28429 4.58783 7.2 4.8 7.2H7.2V4.8C7.2 4.58783 7.28429 4.38434 7.43431 4.23431C7.58434 4.08429 7.78783 4 8 4Z"/>
                </svg>
                <input type="file" style="display: none" (change)="onChangeInputFile($event)" name="antiquie">
              </label>
            </div>
          </div>
        </div>
      </div>
      <!---- fecha de alta ------>
      <div class="form-row">
        <div class="form-group form-group-sm">
          <label class="control-label">Fecha de alta</label>
          <div class="input-group">
            <div class="input-group-addon"><i class="bi bi-calendar"></i></div>
            <input type="text"
                   placeholder="00-00-0000"
                   class="form-control"
                   bsDatepicker
                   appDateText
                   [bsConfig]="bsEntryDate"
                   [(ngModel)]="dataToSend.startDate"
                   name="entryDate"
                   maxlength="10"
                   required>
          </div>
        </div>
      </div>
      <!--- uso de carro --->
      <div class="form-row">
        <div class="form-group form-group-sm">
          <label class="control-label title-element-car-policie">Uso</label>
          <ng-select [items]="dataSourceUse"
                     bindLabel="name"
                     bindValue="id"
                     notFoundText="No se encontraron registros"
                     id="use"
                     name="use"
                     [(ngModel)]="dataToSend.use"
                     dropdownPosition="bottom"
                     class="select-policies">
          </ng-select>
        </div>
      </div>
      <!--- propietario o conductor habitual --->
      <div class="form-row">
        <div class="form-group form-group-sm">
          <label for="addTitular">¿El vehículo tiene propietario o conductor habitual?</label>
          <ui-switch (change)="onChangeAddTitular($event)"
                     checkedLabel="Si"
                     uncheckedLabel="No"
                     checkedTextColor="#FFFFFF"
                     uncheckedTextColor="#000000"
                     color="#3232cd"
                     size="small"
                     [checked]="addTitular"
                     name="addTitular"
                     id="addTitular"
                     switchColor="#233568">
          </ui-switch>
        </div>
      </div>

      <div *ngIf="addTitular">
        <div style="text-align: left;padding-bottom: 10px">
          <span style="display: inline-block;vertical-align: middle;color: #326173;">Propietario o conductor del vehículo</span>
          <span
            style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 220px;border-top: 1px solid #E1E1E1;"></span>

          <div class="form-group form-group-sm">
            <label class="control-label">Nombre(s)</label>
            <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" upperCaseText>
          </div>
          <div class="form-group form-group-sm">
            <label class="control-label">Apellido Paterno</label>
            <input type="text" class="form-control" [(ngModel)]="dataToSend.firstName" name="pLastname" upperCaseText>
          </div>
          <div class="form-group form-group-sm">
            <label class="control-label">Apellido Materno</label>
            <input type="text" class="form-control" [(ngModel)]="dataToSend.lastName" name="sLastname" upperCaseText>
          </div>
          <div class="form-group form-group-sm">
            <label class="control-label">Correo electrónico</label>
            <div class="input-group">
              <div class="input-group-addon"><i class="bi bi-envelope"></i></div>
              <input type="text" class="form-control" [(ngModel)]="dataToSend.email" name="email" required>
            </div>
          </div>
        </div>
      </div>

      <!-- Tiene beneficiario preferente-->

      <div class="form-group form-group-sm">
        <label for="addBenefit">¿El vehículo tiene beneficiario preferente?</label>
        <ui-switch (change)="onChangeAddBenefit($event)"
                   checkedLabel="Si"
                   uncheckedLabel="No"
                   checkedTextColor="#FFFFFF"
                   uncheckedTextColor="#000000"
                   color="#3232cd"
                   size="small"
                   [checked]="addBenefit"
                   name="addBenefit"
                   id="addBenefit"
                   switchColor="#233568">
        </ui-switch>
      </div>

      <div *ngIf="addBenefit">
        <div style="text-align: left;padding-bottom: 10px">
          <span style="display: inline-block;vertical-align: middle;color: #326173;">Beneficiario Preferente</span>
          <span
            style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 220px;border-top: 1px solid #E1E1E1;"></span>

          <div class="form-group form-group-sm">
            <label class="control-label">Nombre o Razon Social</label>
            <input type="text" class="form-control" [(ngModel)]="dataToSend.tradename" name="tradename" upperCaseText>
          </div>
          <div class="form-group form-group-sm">
            <label class="control-label">RFC</label>
            <input type="text" class="form-control" [(ngModel)]="dataToSend.rfc" name="rfc" upperCaseText>
          </div>
        </div>
      </div>

      <!---------------||||| Adaptaciones de auto |||||------------------->
      <div style="text-align: left;padding-bottom: 10px">
              <span style="display: inline-block;vertical-align: middle;color: #326173;cursor: pointer;"
                    title="Agregar Adaptaciones" (click)="activateAdaptationRegistrationInCar()">
                <i class="bi bi-plus-circle"></i>&nbsp;Adaptaciones
              </span>
        <span
          style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 145px;border-top: 1px solid #E1E1E1;"></span>
        <span style="display: inline-block;vertical-align: middle;color: #326173;" (click)="showAdaptations(templateAdaptationInfo)">
                <i  class="bi bi-ev-front"></i>
                +{{invoiceAdaptationList?.length}}
              </span>
      </div>
      <div *ngIf="attachAdaptatio">
        <div class="form-group form-group-sm">
          <label class="control-label">Nombre de la adaptación</label>
          <input type="text" class="form-control" [(ngModel)]="adaptation.name" name="adaptationname"
                 upperCaseText>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Valor de la adaptación</label>
          <div class="input-group">
            <div class="input-group-addon">$</div>
            <input type="text" class="form-control" [(ngModel)]="adaptation.value" name="value"
                   upperCaseText>
          </div>
        </div>
        <div class="reconocimiento-antiguedad" id="reconocimientoAntiguedad2">
          <div class="d-center gap-8">
            <img src="../../assets/img/ammia/iconos/icono_cargadocumento-gris.svg" alt="">
            <div>
              <h5>Archivo adjunto de la factura</h5>
              <div class="form-group">
                <label class="text-primary" *ngIf="fileUploadAdaptation == undefined" [hidden]="error.active">----------</label>
                <label class="text-primary" style="width: 100%" *ngIf="fileUploadAdaptation != undefined" [hidden]="error.active">
                  {{ fileUploadAdaptation.name }} <span class="pull-right">{{shared.formatBytes(fileUploadAdaptation.size,2)}}</span>
                </label>
                <label class="text-danger" [hidden]="!error.active">{{ error.msg }}</label>
              </div>
            </div>
          </div>
          <div class="">
            <div class="form-group">
              <label class="btn btn-custom btn-sm btn-block">Subir documento
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6ZM0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8ZM8 4C8.21217 4 8.41566 4.08429 8.56569 4.23431C8.71571 4.38434 8.8 4.58783 8.8 4.8V7.2H11.2C11.4122 7.2 11.6157 7.28429 11.7657 7.43431C11.9157 7.58434 12 7.78783 12 8C12 8.21217 11.9157 8.41566 11.7657 8.56569C11.6157 8.71571 11.4122 8.8 11.2 8.8H8.8V11.2C8.8 11.4122 8.71571 11.6157 8.56569 11.7657C8.41566 11.9157 8.21217 12 8 12C7.78783 12 7.58434 11.9157 7.43431 11.7657C7.28429 11.6157 7.2 11.4122 7.2 11.2V8.8H4.8C4.58783 8.8 4.38434 8.71571 4.23431 8.56569C4.08429 8.41566 4 8.21217 4 8C4 7.78783 4.08429 7.58434 4.23431 7.43431C4.38434 7.28429 4.58783 7.2 4.8 7.2H7.2V4.8C7.2 4.58783 7.28429 4.38434 7.43431 4.23431C7.58434 4.08429 7.78783 4 8 4Z"/>
                </svg>
                <input type="file" style="display: none" (change)="onChangeInputFileAdaptation($event)" name="antiquie">
              </label>
            </div>
          </div>
        </div>
        <!--
        <div class="form-group form-group-sm">
          <label class="control-label">Archivo adjunto de la factura</label>
          <div class="form-group">
            <label class="btn btn-custom btn-sm btn-block"><i class="fa fa-folder-open"></i>&nbsp;Buscar...
              <input type="file" style="display: none" (change)="onChangeInputFileAdaptation($event)" name="antiquie">
            </label>
          </div>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Nombre de archivo</label>
          <div class="form-group">
            <label class="text-primary" *ngIf="fileUploadAdaptation == undefined"
                   [hidden]="error.active">----------</label>
            <label class="text-primary" style="width: 100%" *ngIf="fileUploadAdaptation != undefined"
                   [hidden]="error.active">
              {{ fileUploadAdaptation.name }} <span
              class="pull-right">{{shared.formatBytes(fileUploadAdaptation.size, 2)}}</span>
            </label>
            <label class="text-danger" [hidden]="!error.active">{{ error.msg }}</label>
          </div>
        </div>-->
        <div class="btn-group" role="group"
             *ngIf="(attachAdaptatio)">
          <button type="button" class="btn deshabilitado" (click)="addNewAdaptation()">Nueva adaptación</button>
        </div>
      </div>
      <!---- botón de procesar solicitud --->
      <div class=" offcanvas-footer d-center mt-3">
        <button class="btn primario " id="btnGenerarSolicitudAddDepe" type="button"
                (click)="invokeFunctionSaveRequestAddCar()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5237 6.98805L10.2319 14.3279L7.52681 11.5317L5.99787 13.0463L9.17336 16.3084C9.29097 16.4249 9.52619 16.5415 9.76142 16.658C9.99664 16.7745 10.2319 16.7745 10.3495 16.7745C10.4671 16.7745 10.8199 16.7745 10.9375 16.658C11.1727 16.5415 11.2904 16.4249 11.5256 16.3084L19.1703 8.50262L17.5237 6.98805Z" />
            <path d="M20.5819 3.49515C18.3473 1.28155 15.2894 0 11.9963 0C8.82081 0 5.76293 1.28155 3.52833 3.49515C1.29372 5.82524 0 8.73786 0 12C0 15.1456 1.29372 18.1748 3.52833 20.5049C5.76293 22.7184 8.82081 24 11.9963 24C15.1718 24 18.2297 22.7184 20.4643 20.5049C22.6989 18.2913 23.9926 15.2621 23.9926 12C24.1102 8.73786 22.8165 5.82524 20.5819 3.49515ZM12.1139 21.6699C6.70382 21.6699 2.35222 17.2427 2.35222 11.8835C2.35222 6.52427 6.70382 2.09709 12.1139 2.09709C17.524 2.09709 21.8756 6.52427 21.8756 11.8835C21.8756 17.3592 17.524 21.6699 12.1139 21.6699Z" />
          </svg>

          Generar solicitud
        </button>
      </div>
    </form>
  </div>
  <div class="w-100 d-column-center p-3 p-md-5" *ngIf="responseService == 2">
    <a href="javascript:;"   aria-controls="offcanvasRight">
      <dotlottie-player src="https://lottie.host/bb64e4a0-a834-4f97-8152-fba5e5234f5a/3vObIm0Ks5.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
    </a>
    <p class="">Se ha generado el reporte de tu solicitud. Un ejecutivo Surexs te contactará lo antes posible y te brindará atención.</p>
    <div class="d-center w-100 mt-5">
      <button class="btn primario" id="btnGenerarSolicitudVehiculog" type="button" data-bs-dismiss="offcanvas" aria-label="Close" (click)="invokeFunctionClear()">
        <img src="../../assets/img/ammia/iconos/check-azuld2.svg" alt="" class="icono-sm">
        Entendido
      </button>
    </div>
  </div>
  <div class=""  *ngIf="responseService == 1">
    <div class="w-100 d-column-center">
      <a href="javascript:;"  aria-controls="offcanvasRight" >
        <dotlottie-player src="https://lottie.host/06fc7b75-f5d2-4524-86f8-fbbe96bf456a/VGiGPUrFSr.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
      </a>
      <div>
        <p class="solicitud-parrafo">Estamos validando tu solicitud. No cierres ni actualices esta venta. </p>
      </div>
    </div>
  </div>
  <div  *ngIf="responseService == 3">
    <a href="javascript:;"   aria-controls="offcanvasRight" >
      <dotlottie-player src="https://lottie.host/4c9dabcd-e6c6-41c2-84c9-7cf1b79890af/yn2rFJ1ICK.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
    </a>
    <div>
      <p class="solicitud-parrafo">Se ha producido un error al dar de alta la solicitud, por favor contacta al administrador.
      </p>
    </div>
    <div class="d-center w-100 mt-5">
      <button class="btn primario" id="btnGenerarSolicitudVehiculogg" type="button" data-bs-dismiss="offcanvas" aria-label="Close" (click)="invokeFunctionClear()">
        <img src="../../assets/img/ammia/iconos/check-azuld2.svg" alt="" class="icono-sm">
        cerrar
      </button>
    </div>
  </div>
</div>
<ng-template #templateAdaptationInfo>
  <div class="modal-header">
    ADAPTACIONES
  </div>
  <div class="modal-body">
    <div class="insured-tab-body">
      <table class="table table-condensed table-striped">
        <thead>
        <th>Adaptación</th>
        <th>Valor</th>
        <th>Nombre factura</th>
        <th>Opciones</th>
        </thead>
        <tbody>
        <tr *ngFor="let i of invoiceAdaptationList; index as in" style="cursor: pointer">
          <td>{{i.name}}</td>
          <td>{{i.value}}</td>
          <td>{{i.nameFileInvoice}}</td>
          <td>
            <button type="button" class="btn btn-sm btn-custom" (click)="deleteAdaptation(in)">Eliminar</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
