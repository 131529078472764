import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';

@Component({
  selector: 'app-request-detail-ammia',
  templateUrl: './request-detail-ammia.component.html',
  styleUrls: ['./request-detail-ammia.component.css']
})
export class RequestDetailAmmiaComponent implements OnInit {
  requestId: number;
  requestDetail: any = {};
  // tslint:disable-next-line:variable-name
  _branchId: number;
  constructor(private api: ApiService,
              public shared: SharedService) {
    this.shared.gRequestIdObservable().subscribe(
      gRequestId => {
        console.info(gRequestId);
        this.requestId = gRequestId;
        this.invokeFunctionGetDetailRequest(this.requestId, Number(localStorage.getItem('requestAux')));
      }
    );
  }

  ngOnInit(): void {
    this.requestId = 0;
    this._branchId = 1;
  }

  invokeFunctionCloseRequestDetail() {
    const modalShadowElement = document.querySelector('.modal-shadow-evento');
    modalShadowElement.classList.remove('visible');
    this.requestDetail = {};
    this.requestId = 0;
  }
  invokeFunctionGetDetailRequest(idRequest: number, idRequestDetail: number) {
    const objectData = {
      requestId: idRequest,
      requestDetailId: idRequestDetail
    };
    this.api.getRequestDetailById(objectData)
      .then(
        (response: any) => {
          if (response != null) {
            this.requestDetail = response[0];
          }
        }, error => {
          console.info(error);
        });
  }

}
