<div>
  <div
    style="background-color: #ffffff; border-radius: 5px; padding-top: 30px;box-shadow: 0 5px 25px 0 rgba(41, 63, 121, 0.05);">
    <div class="row">
      <div class="col-md-12">
        <div class="title-section pull-left" style="text-align: left;margin-bottom: 10px">
          <span class="title-section pull-left">MOVIMIENTOS</span>
        </div>
        <div class="pull-right">
          <!--<i class="glyphicon glyphicon-glass"  style="display: inline-block; margin-left: 5px;padding: 4px;vertical-align: middle;cursor: pointer;" ></i>-->
          <i class="glyphicon glyphicon-resize-small"
             style="display: inline-block; margin-left: 5px;padding: 4px;vertical-align: middle;cursor: pointer"
             (click)="goToBack()"></i>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="btn-group pull-left">
          <button class="btn btn-xs btn-custom pull-right" href="javascript:;" (click)="addMovment()">
            <i class="glyphicon glyphicon-plus-sign"></i>&nbsp;&nbsp;Agregar movimiento
          </button>
        </div>
        <div class="btn-group pull-left">
          <button class="btn btn-xs btn-custom pull-right" href="javascript:;" (click)="invokeEditRequestTempDetail()">
            <i class="glyphicon glyphicon glyphicon-floppy-disk"></i>&nbsp;&nbsp;Guardar movimientos
          </button>
        </div>
        <div class="btn-group pull-left">
          <button class="btn btn-xs btn-custom pull-right" href="javascript:;"
                  (click)="invokeServiceProcessMassiveRequest()">
            <i class="glyphicon glyphicon-cloud-upload"></i>&nbsp;&nbsp;Procesar Solicitudes
          </button>
        </div>
        <div class="pull-left subtitle-section-menu">
          Última vez
          guardado {{requestTemp === undefined ? '' : requestTemp.lastUpdate === undefined ? '' : requestTemp.lastUpdate | date: 'dd/MM/yyyy HH:mm:ss'}}
        </div>
      </div>
      <div class="col-md-10 col-xs-12">
        <div class="row request-tab-body">
          <table class="table table-condensed table-striped" [formGroup]="requestsForm">
            <thead>
            <tr>
              <th class="title-th">OPERACIÓN</th>
              <th class="title-th">PARENTESCO</th>
              <th class="title-th">NOMBRE</th>
              <th class="title-th">APELLIDO PATERNO</th>
              <th class="title-th">APELLIDO MATERNO</th>
              <th class="title-th">EMAIL</th>
              <th class="title-th">GENERO</th>
              <th class="title-th">FECHA DE NACIMIENTO</th>
              <th class="title-th">NUMERO EMPLEADO</th>
              <th class="title-th">FECHA ALTA/BAJA</th>
              <th class="title-th">SUELDO</th>
              <ng-container
                *ngFor="let subranch of policyList(requestDetail.controls[0]).controls">
                <th class="title-th" style="border: #4E4E4E; border-radius: 15px; background-color: rgba(92,94,94,0.18); text-align: center" [colSpan]="categories(subranch).length">
                  {{subranch.value.subranch + ' ' +subranch.value.numberPolicy}}
                </th>
              </ng-container>
              <th class="title-th">ERROR</th>
              <th class="title-th">OPERACIÓN</th>
            </tr>
            </thead>
            <tbody formArrayName="requestDetail">
            <tr *ngFor="let detail of requestDetail.controls; let i = index" [formGroupName]="i"
                [ngStyle]="{'background-color': (getIsValid(detail)) ? 'rgba(255,0,0,0.2)':''}">
              <td>
                    <span>
                      <select class="form-control selectors" formControlName="operacion"
                              id="{{'select' + detail.get('requestTempDetailId').value}}">
                        <option *ngFor="let operation of getOperationsType()" [value]="operation">{{operation}}</option>
                      </select>
                   </span>
              </td>
              <td>
                    <span>
                      <select class="form-control selectors" formControlName="parentesco" required
                              id="{{'kinshipSelect' + detail.get('requestTempDetailId').value}}">
                        <option *ngFor="let kinship of getKinshipType()" [value]="kinship">{{kinship}}</option>
                      </select>
                   </span>
              </td>
              <td><input style="width: 120px; text-transform: uppercase" class="form-control inputs-request"
                         formControlName="nombre" contenteditable="true"/></td>
              <td><input style="width: 120px; text-transform: uppercase" class="form-control inputs-request"
                         formControlName="apellidoPaterno" contenteditable="true"/></td>
              <td><input style="width: 120px; text-transform: uppercase" class="form-control inputs-request"
                         formControlName="apellidoMaterno" contenteditable="true"/></td>
              <td><input style="width: 120px" class="form-control inputs-request" formControlName="email"
                         contenteditable="true"/></td>
              <td>
                     <span>
                      <select class="form-control selectors" formControlName="genero">
                        <option *ngFor="let gender of getGenderType()" [value]="gender">{{gender}}</option>
                      </select>
                   </span>
              </td>
              <td><input type="text"
                         placeholder="00-00-0000"
                         class="form-control date-picker-request"
                         bsDatepicker
                         [bsConfig]="bsBirthdate"
                         name="birthdate"
                         formControlName="fechaNacimiento"
                         required></td>
              <td><input class="form-control inputs-request" formControlName="numeroEmpleado" contenteditable="true"/>
              </td>
              <td><input type="text"
                         placeholder="00-00-0000"
                         class="form-control date-picker-request"
                         bsDatepicker
                         [bsConfig]="bsDateMovement"
                         name="dateMovement"
                         formControlName="fechaAltaBaja"
                         required></td>
              <td><input class="form-control inputs-request" formControlName="sueldo" contenteditable="true"/></td>
              <ng-container formArrayName="policySubranchCategories">
                <ng-container *ngFor="let subranch of policyList(detail).controls;  let i = index" [formGroupName]="i">
                  <ng-container formArrayName="policyCategoriesList">
                    <td *ngFor="let category of categories(subranch).controls; let cat = index"
                                  [formGroupName]="cat">
                      <span>
                      <label class="categories-options"> <input type="checkbox" formControlName="selected"/> {{category.value.category}}</label>
                      </span>
                    </td>
                  </ng-container>
                </ng-container>
              </ng-container>
              <td><input style="width: min-content" disabled class="form-control inputs-request"
                         formControlName="error"/></td>
              <td style="text-align: center">
                <div id="icon" style="display: inline-block;width: 30%;">
                  <i class="glyphicon glyphicon-remove-circle" style="color: #326173;" (click)="removeMovement(i)"></i>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <!--</div>-->
        </div>
      </div>
    </div>
  </div>
</div>
