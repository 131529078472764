<div style="position:relative;height: 100%;width: 340px;padding: 20px">
  <div style="height: 95px;width: 100%; text-align: center;">
    <span class="sx-iconsys-icon" aria-hidden="true" style="float: right;font-size: 22px;font-weight: normal;color: #C4C4C4;cursor: pointer;" (click)="shared.fCloseSidebar()">x</span>
    <p style="color: #448AA6;font-size: 16px;font-weight: 600;line-height: 19px;padding-top: 33px;padding-bottom: 33px">
      {{ detailPolicy.nameCompany }}
    </p>
  </div>



  <div style="min-height: calc(100% - 150px);width: 100%;color: #326173">
    <div style="text-align: left;margin-bottom: 10px">
      <span style="display: inline-block;vertical-align: middle;color: #7A7875;font-weight: 500;font-size: 12px">Póliza</span><span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 258px;border-top: 1px solid #E1E1E1;"></span>
    </div>
    <div>
      <div class="p-summary">
        <p class="title">{{ detailPolicy.nameCompany }}</p>
        <p class="data">{{ detailPolicy.type }}</p>
        <p class="data">{{ detailPolicy.number }}</p>
        <p class="data"><strong>Vigencia:</strong> {{ detailPolicy.expirationDate | date: 'dd/MM/yyyy' }}</p>
        <p class="data"><strong>Contratado:</strong> {{ detailPolicy.startDate | date: 'dd/MM/yyyy' }}</p>
        <p><a *ngIf="shared.gCurrentRoleId==3" href="javascript:;" (click)="invokeServiceForDownloadPolicyFile()">Versión impresa</a></p>
      </div>
      <div class="p-badge" [ngStyle]="{'background': 'linear-gradient(206.57deg, rgba(255, 255, 255, 0.2) -90%, #326173 60%)'
                                        ,'box-shadow': '0 15px 30px 0 ' + hexToRgb(detailPolicy.colorCompanyHex, 0.5)}">
        <img src="{{ imageCompany }}">
      </div>
    </div>

    <div style="text-align: left; padding-top: 15px">
      <span style="display: inline-block;vertical-align: middle;color: #7A7875;font-weight: 500;font-size: 12px">Detalles</span><span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 244px;border-top: 1px solid #E1E1E1;"></span>
    </div>
    <div class="p-detail">
      <div class="row" *ngIf="detailPolicyjson == null">
        <div class="col-md-6 item">
          <div>Plan contratado</div>
          <div>{{detailPolicy.type}}</div>
        </div>
        <div class="col-md-6 item">
          <div>Derechos</div>
          <div>{{ detailPolicy.rights | CustomCurrency}}</div>
        </div>
        <div class="col-md-6 item">
          <div>Suma asegurada</div>
          <div>{{ detailPolicy.netPremiun | CustomCurrency}}</div>
        </div>
      </div>
      <div class="row" *ngIf="loadservice1">
        <div class="item-align" *ngFor="let p of detailPolicyjson" >
          <div class="col-md-6 item" *ngIf="p.visible">
            <div>{{p.description}}</div>
            <div>{{p.value}}</div>
          </div>
        </div>
      </div>
    </div>

    <div style="text-align: left; padding-top: 15px">
      <span style="display: inline-block;vertical-align: middle;color: #7A7875;font-weight: 500;font-size: 12px">Dependientes</span><span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 210px;border-top: 1px solid #E1E1E1;"></span>
    </div>
    <div>
      <div class="row"  *ngIf="dependentsList?.length > 0">
        <div class="col-xs-12" *ngFor="let item of dependentsList" style="height: 5px;margin-top: 5px;margin-bottom: 5px;color:#9B9B9B;">
          <div style="font-size: 12px;font-weight: 600;line-height: 14px;float: left;">{{item.value}}</div>
          <div style="font-size: 12px;font-weight: 400;line-height: 14px;float:right;">{{item.title}}</div>
        </div>
      </div>
      <div class="row" *ngIf="dependentsList?.length <= 0">
        <div class="col-xs-12">
          <p class="text-muted p-text-muted">No existen dependientes</p>
        </div>
      </div>
    </div>


    <div style="text-align: left; padding-top: 15px">
      <span style="display: inline-block;vertical-align: middle;color: #7A7875;font-weight: 500;font-size: 12px">Anexos</span><span style="margin-left: 8px;margin-right: 8px;display: inline-block;vertical-align: middle;width: 221px;border-top: 1px solid #E1E1E1;"></span><span class="badge">{{annexesList?.length || 0}}</span>
    </div>
    <div class="p-annexes">
      <div class="row" *ngIf="annexesList?.length > 0">
        <div  *ngFor="let oFile of annexesList; let i = index">
          <div class="col-md-4 text-center">
            <a style="text-decoration: none;cursor: pointer;" target="_blank" [attr.href]="oFile.typeAttachment==2?oFile.url:undefined" (click)="oFile.typeAttachment==1?invokeServiceForGetAttachmentFile(oFile):undefined" title="{{oFile.name}}">
              <span class="sx-iconsys-icon" style="color: #EFF2F7;font-size: 50px">d</span>
              <div class="file-name">
                {{oFile.name}}
              </div>
            </a>
          </div>
          <div class="clearfix visible-lg-block" *ngIf="(i+1)%3==0"></div>
        </div>
      </div>
      <div class="row" *ngIf="annexesList?.length <= 0">
        <div class="col-xs-12">
          <p class="text-muted p-text-muted">No existen anexos</p>
        </div>
      </div>
    </div>


  </div>
  <div class="text-center" style="margin-bottom: 20px">
    <button type="button" class="btn btn-xs btn-custom"><a style="text-decoration: none;color: #448AA6;cursor:pointer;" href="javascript:;" (click)="invokeServiceForDownloadPolicyAttachmentZipFile(detailPolicy)">Descargar todos</a></button>
  </div>
</div>
