import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-request-delete-ammia',
  templateUrl: './request-delete-ammia.component.html',
  styleUrls: ['./request-delete-ammia.component.css']
})
export class RequestDeleteAmmiaComponent implements OnInit {

  idRequestTemp: number;
  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public shared: SharedService,
              private datePipe: DatePipe,
              private formBuilder: FormBuilder) {
    this.shared.ffUpdateRequestsDeleteObservable().subscribe(
      fRequestsdelete => {
        if (fRequestsdelete) {
          console.log('entra eliminar solicitud de importación masiva');
          this.idRequestTemp = Number(localStorage.getItem('requestTemp-id'));
          console.log(this.idRequestTemp);
        }
      }
    );
  }

  ngOnInit(): void {
  }

  deleteRequestTemp() {
    this.api.deleteRequestTemp(this.idRequestTemp)
      .subscribe(
        response => {
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.shared.fUpdateRequestsMassImport.next(true);
        }, error => {
          console.error();
        }
      );
  }

}
