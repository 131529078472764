<div class="">
  <div class="row">
    <div class="col-md-8">
      <p class="title-section pull-left">Pólizas&nbsp;&nbsp;<span class="badge badge-custom">{{listPolicies?.length}}</span></p>
      <div class="btn-group pull-right" dropdown>
        <button id="button-policy-types" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" aria-controls="dropdown-policy-types">
          {{lclPolicyType.description}} <span class="sx-iconsys-icon">1</span>
        </button>
        <ul id="dropdown-policy-types" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-policy-types">
          <li role="menuitem" *ngFor="let typepolicy of listtypepolicy">
            <a class="dropdown-item" href="javascript:;" (click)="loadpolicy(typepolicy)">{{typepolicy.description}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4" *ngFor="let u of listPolicies">
      <div class="policy-card">
        <div class="p-badge card" title="Ver detalle" (click)="displayPolicyDetail(u.policyId)" [class.flip]="idFlipCard == u.policyId" [ngStyle]="{'background': 'linear-gradient(206.57deg, rgba(255, 255, 255, 0.2) -90%, #326173 60%)'}">
          <div class="front">
            <div class="btn-flip" (click)="flipThisCard(u.policyId,$event)" title="Ver reverso">
              <i class="glyphicon glyphicon-refresh"></i>
            </div>
            <img src="{{u.imageCompany}}" />
          </div>
          <div class="back">
            <div class="btn-flip flip" (click)="flipThisCard(u.policyId,$event)" title="Ver reverso">
              <i class="glyphicon glyphicon-refresh"></i>
            </div>
            <div class="p-summary">
              <span class="item"><strong>No. Póliza:</strong> {{lclPolicyDetail.number}}</span>
              <br>
              <span class="item"><strong>Desde:</strong> {{lclPolicyDetail.startDate | date: 'dd/MM/yyyy'}}</span>
              <br>
              <span class="item"><strong>Hasta:</strong> {{lclPolicyDetail.expirationDate | date: 'dd/MM/yyyy'}}</span>
              <br>
              <span class="item"><a href="javascript:;" *ngIf="lclPolicyDetail.certificate!=''" (click)="$event.stopPropagation();invokeServiceForGetPolicyCertificateFile(lclPolicyDetail)" title="Descargar certificado">Descargar certificado</a></span>
            </div>
          </div>
        </div>
        <div class="p-summary">
          <span class="title">{{u.nameCompany}}</span>
          <br>
          <span class="subtitle">{{u.description}}</span>
          <br>
          <!--span class="number">117-2</span-->
        </div>
      </div>
    </div>
  </div>

  <!--------------------------------------------------------------------------------------------------------------------
  --------------------------------------S I N I E S T R O S ------------------------------------------------------------
  --------------------------------------------------------------------------------------------------------------------->
  <div class="row" style="margin-top: 30px!important;margin-bottom: 18px;">
    <div class="col-md-10">
      <div class="title-section pull-left" style="padding: 4px">
        <p style="margin: 0;">Siniestros&nbsp;&nbsp;<sub style="font-weight: lighter;"> Mostrando {{incidentsList?.length}} de {{pgIncidentTotalItems || '0'}}</sub></p>
      </div>
      <div class="pull-right">
        <div class="subtitle-section" style="display: inline-block; margin-right: 20px; vertical-align: middle;">
          <div class="btn-group pull-left" dropdown [dropup]="true">
            <button id="button-incidents-types" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" aria-controls="dropdown-incidents-types">
              {{lclIncidentType.description}} <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-incidents-types" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu" aria-labelledby="button-incidents-types">
              <li role="menuitem" *ngFor="let typeinciden of listtypeinciden">
                <a class="dropdown-item" href="javascript:;" (click)="loadincidents(typeinciden)">{{typeinciden.description}}</a>
              </li>
            </ul>
          </div>
        </div>
        <i class="glyphicon glyphicon-list" [ngClass]="!incidentGridViewType?'view-select':''" style="display: inline-block; margin-left: 5px;padding: 4px;vertical-align: middle;cursor: pointer;" (click)="setIncidentGridView(false)"></i>
        <i class="glyphicon glyphicon-th-large" [ngClass]="incidentGridViewType?'view-select':''" style="display: inline-block; margin-left: 5px;padding: 4px;vertical-align: middle;cursor: pointer" (click)="setIncidentGridView(true)"></i>
      </div>
    </div>
  </div>
  <div *ngIf="incidentGridViewType">
    <div class="row">
      <div class="col-md-10" style="padding: 0">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" *ngFor=" let i of incidentsList; let $i=index">
            <div class="incident-card" *ngIf="$i<2">
              <span class="sx-iconsys-icon i-icon">s</span>
              <div class="i-summary">
                <div class="title">{{i.name}}</div>
                <div class="subtitle">{{i.company}}</div>
                <div class="number">{{i.type}}</div>
                <div class="status">{{i.status}}</div>
              </div>
              <div class="i-date pull-right">
                <div>{{i.createDate }}</div>
                <button class="btn btn-custom btn-xs" (click)="displayIncidentDetail(i.incidentId)">Ver</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2" style="min-height: 120px;" *ngIf="!shared.fInsuredDataReadonly">
        <div class="container-btn-plus">
          <button class="btn-plus" (click)="shared.fOpenSidebar(2)" title="Agregar siniestros">
            <span class="sx-iconsys-icon">+</span>
          </button>
          <label style="vertical-align: middle;color: #448AA6;">&nbsp;Agregar siniestro</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10"   style="padding: 0">
        <div class="row">
          <div *ngFor=" let i of incidentsList; let $i=index">
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" *ngIf="$i>1">
              <div class="incident-card">
                <span class="sx-iconsys-icon i-icon">s</span>
                <div class="i-summary">
                  <div class="title">{{i.name}}</div>
                  <div class="subtitle">{{i.company}}</div>
                  <div class="number">{{i.type}}</div>
                  <div class="status">{{i.status}}</div>
                </div>
                <div class="i-date pull-right">
                  <div>{{i.createDate }}</div>
                  <button class="btn btn-custom btn-xs" (click)="displayIncidentDetail(i.incidentId)">Ver</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!incidentGridViewType">
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-xs-12">
            <table class="table table-striped table-hover table-condensed">
              <thead>
              <tr>
                <th>Siniestro</th>
                <th *ngIf="widthSize> 700">Aseguradora</th>
                <th *ngIf="widthSize> 700">Tipo de siniestro</th>
                <th>Captura</th>
                <th>Estatus</th>
                <th><i class="glyphicon glyphicon-cog"></i></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let oIncident of incidentsList">
                <td>{{oIncident.name}}</td>
                <td *ngIf="widthSize> 700">{{oIncident.company}}</td>
                <td *ngIf="widthSize> 700">{{oIncident.type}}</td>
                <td>{{oIncident.createDate}}</td>
                <td>{{oIncident.status}}</td>
                <td><button class="btn btn-custom-tbl btn-xs" (click)="displayIncidentDetail(oIncident.incidentId)">Ver</button></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-2" style="min-height: 120px;" *ngIf="!shared.fInsuredDataReadonly">
        <div class="container-btn-plus">
          <button class="btn-plus" (click)="shared.fOpenSidebar(2)" title="Agregar siniestros">
            <span class="sx-iconsys-icon">+</span>
          </button>
          <label style="vertical-align: middle;color: #448AA6;">&nbsp;Agregar siniestro</label>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
        <pagination class="pull-right"
                    [boundaryLinks]="pgIncidentBoundaryLinks"
                    [totalItems]="pgIncidentTotalItems"
                    [(ngModel)]="pgIncidentCurrentPage"
                    [maxSize]="pgIncidentMaxSize"
                    [itemsPerPage]="pgIncidentItemsPerPage"
                    previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                    (pageChanged)="pageIncidentChanged($event)"></pagination>
    </div>
  </div>


  <!--------------------------------------------------------------------------------------------------------------------
  --------------------------------------S O L I C I T U D E S-----------------------------------------------------------
  --------------------------------------------------------------------------------------------------------------------->
  <div class="row" style="margin-top: 30px!important;margin-bottom: 18px;">
    <div class="col-md-10">
      <div class="title-section pull-left" style="padding: 4px">
        <p style="margin: 0;">Solicitudes&nbsp;&nbsp;<sub style="font-weight: lighter;"> Mostrando {{requestList?.length}} de {{pgRequestTotalItems  || '0'}}</sub></p>
      </div>
      <div class="pull-right">
        <div class="subtitle-section" style="display: inline-block; margin-right: 20px; vertical-align: middle;">
          <div class="btn-group pull-left" dropdown [dropup]="true">
            <button id="button-request-status" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" aria-controls="dropdown-request-status">
              {{lclStatusRequestSelected.description}} <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-request-status" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu" aria-labelledby="button-request-status">
              <li role="menuitem" *ngFor="let status of statusCatRequestsList">
                <a class="dropdown-item" href="javascript:;" (click)="invokeServiceForGetRequests(status)">{{status.description}}</a>
              </li>
            </ul>
          </div>
        </div>
        <i class="glyphicon glyphicon-list" [ngClass]="!requestGridViewType?'view-select':''" style="display: inline-block; margin-left: 5px;padding: 4px;vertical-align: middle;cursor: pointer;" (click)="setRequestGridView(false)"></i>
        <i class="glyphicon glyphicon-th-large" [ngClass]="requestGridViewType?'view-select':''" style="display: inline-block; margin-left: 5px;padding: 4px;vertical-align: middle;cursor: pointer" (click)="setRequestGridView(true)"></i>
      </div>
    </div>
  </div>
  <div *ngIf="requestGridViewType">
    <div class="row">
      <div class="col-md-10" style="padding: 0">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" *ngFor=" let r of requestList; let $i=index">
            <div class="incident-card" *ngIf="$i<2">
              <span class="sx-iconsys-icon i-icon">s</span>
              <div class="i-summary">
                <div class="title">{{r.subject}}</div>
                <div class="subtitle">{{r.name}}</div>
                <div class="number">{{r.description}}</div>
                <div class="status">{{r.status}}</div>
              </div>
              <div class="i-date pull-right">
                <div>{{r.created }}</div>
                <button class="btn btn-custom btn-xs" (click)="displayRequestDetail(r.requestId)">Ver</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2" style="min-height: 120px;" *ngIf="!shared.fInsuredDataReadonly">
        <div class="container-btn-plus">
          <button class="btn-plus" (click)="shared.fOpenSidebar(3)" title="Agregar siniestros">
            <span class="sx-iconsys-icon">+</span>
          </button>
          <label style="vertical-align: middle;color: #448AA6;">&nbsp;Nueva solicitud</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10"   style="padding: 0">
        <div class="row">
          <div *ngFor=" let r of requestList; let $i=index">
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" *ngIf="$i>1">
              <div class="incident-card">
                <span class="sx-iconsys-icon i-icon">s</span>
                <div class="i-summary">
                  <div class="title">{{r.subject}}</div>
                  <div class="subtitle">{{r.name}}</div>
                  <div class="number">{{r.description}}</div>
                  <div class="status">{{r.status}}</div>
                </div>
                <div class="i-date pull-right">
                  <div>{{r.created }}</div>
                  <button class="btn btn-custom btn-xs" (click)="displayRequestDetail(r.requestId)">Ver</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!requestGridViewType">
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-xs-12">
            <table class="table table-striped table-hover table-condensed">
              <thead>
              <tr>
                <th>Asunto</th>
                <th *ngIf="widthSize> 700">Nombre</th>
                <th *ngIf="widthSize> 700">Descripción</th>
                <th>Captura</th>
                <th>Estatus</th>
                <th><i class="glyphicon glyphicon-cog"></i></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let oRequest of requestList">
                <td>{{oRequest.subject}}</td>
                <td *ngIf="widthSize> 700">{{oRequest.name}}</td>
                <td *ngIf="widthSize> 700">{{oRequest.description}}</td>
                <td>{{oRequest.created}}</td>
                <td>{{oRequest.status}}</td>
                <td><button class="btn btn-custom-tbl btn-xs" (click)="displayRequestDetail(oRequest.requestId)">Ver</button></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-2" style="min-height: 120px;" *ngIf="!shared.fInsuredDataReadonly">
        <div class="container-btn-plus">
          <button class="btn-plus" (click)="shared.fOpenSidebar(2)" title="Agregar siniestros">
            <span class="sx-iconsys-icon">+</span>
          </button>
          <label style="vertical-align: middle;color: #448AA6;">&nbsp;Nueva solicitud</label>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <pagination class="pull-right"
                  [boundaryLinks]="pgRequestBoundaryLinks"
                  [totalItems]="pgRequestTotalItems"
                  [(ngModel)]="pgRequestCurrentPage"
                  [maxSize]="pgRequestMaxSize"
                  [itemsPerPage]="pgRequestItemsPerPage"
                  previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                  (pageChanged)="pageRequestChanged($event)"></pagination>
    </div>
  </div>
</div>
