<h3 class="mb-3">Estadísticas</h3>

<div class="d-between gap-48 mb-3">

    <div class="form-group">
      <label class="active" id="input-labelGraph">Seleccionar Gráfica</label>
      <select class="form-control" [(ngModel)]="idGraph"
              (change)="changeStatisticsFilter($event)" name="relationshipDep" required >
        <option *ngFor="let item of statisticsFilters" [value]="item.id"
        >{{item.description}}</option>
      </select>
    </div>
    <div class="form-group" *ngIf="lclStatisticsFilterSelected.id === 2">
      <label class="active" id="input-labelGraph1">Seleccionar Subramo</label>
      <select class="form-control" [(ngModel)]="subBranchId"
              (change)="changePolicyTypeStaticsAccidents($event)" name="relationshipDep" required >
        <option *ngFor="let item of policyTypeList" [value]="item.typePolicyId"
        >{{item.description}}</option>
      </select>
    </div>
    <div class="form-group" *ngIf="lclStatisticsFilterSelected.id === 0">
      <label class="active" >Seleccionar SubRamo</label>
      <select class="form-control" [(ngModel)]="subBranchId"
              (change)="changePolicyTypeStaticsIncident($event)" name="relationshipDep" required >
        <option *ngFor="let item of policyTypeList" [value]="item.typePolicyId"
        >{{item.description}}</option>
      </select>
    </div>
    <div class="form-group" *ngIf="lclStatisticsFilterSelected.id === 0">
      <label class="active" >Seleccionar Tipo</label>
      <select class="form-control" [(ngModel)]="typeId"
              (change)="changeDataTypeFilter($event)" name="relationshipDep" required >
        <option *ngFor="let item of dataTypeFilters" [value]="item.id"
        >{{item.name}}</option>
      </select>
    </div>
    <div class="form-group" *ngIf="lclStatisticsFilterSelected.id === 3">
      <label class="active" >Seleccionar SubRamo</label>
      <select class="form-control" [(ngModel)]="subBranchId"
              (change)="selectInsurance($event)" name="relationshipDep" required >
        <option *ngFor="let item of staticsInsuranceList" [value]="item.insuranceId"
        >{{item.name}}</option>
      </select>
    </div>
    <div class="form-group" *ngIf="lclStatisticsFilterSelected.id === 7">
      <label class="active" >Seleccionar SubRamo</label>
      <select class="form-control" [(ngModel)]="subBranchId"
              (change)="changePolicyTypeStaticsCollection($event)" name="relationshipDep" required >
        <option *ngFor="let item of policyTypeList" [value]="item.typePolicyId"
        >{{item.description}}</option>
      </select>
    </div>



  <!--<div class="row">
    <div class="col-xs-12">
      <div class="btn-group pull-right" dropdown>
        <button id="button-chart-types" dropdownToggle type="button"
                class="btn btn-link dropdown-toggle subtitle-section-menu"
                aria-controls="dropdown-chart-types">yy
          {{lclStatisticsFilterSelected.description}} <span class="sx-iconsys-icon">1</span>
        </button>
        <ul id="dropdown-chart-types" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
            aria-labelledby="button-chart-types">
          <li role="menuitem" *ngFor="let item of statisticsFilters">
            <a class="dropdown-item" href="javascript:;"
               (click)="changeStatisticsFilter(item)">{{item.description}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>-->
  <ng-container>
    <div class="row col-md-12">
<!--
      <div class="col-md-12" *ngIf="lclStatisticsFilterSelected.id === 0">
        <div class="title-section pull-right">
          <div class="btn-group pull-right" dropdown>
            <button id="button-data-types" dropdownToggle type="button"
                    class="btn btn-link dropdown-toggle subtitle-section-menu">
              {{dataTypeFilterSelected.name}}
              <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-data-type" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-data-types">
              <li role="menuitem" *ngFor="let item of dataTypeFilters" (click)="changeDataTypeFilter(item)">
                <a class="dropdown-item" href="javascript:;">{{item.name}}</a>
              </li>
            </ul>
          </div>

          <div class="btn-group pull-right" dropdown>
            <button id="button-report-types_1" dropdownToggle type="button"
                    class="btn btn-link dropdown-toggle subtitle-section-menu">
              {{staticsIncidentPolicyTypeSelected.description}}
              <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-report-type_1" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                role="menu" aria-labelledby="button-report-types">
              <li role="menuitem" *ngFor="let policyType of policyTypeList"
                  (click)="changePolicyTypeStaticsIncident(policyType)">
                <a class="dropdown-item" href="javascript:;">{{policyType.description}}</a>
              </li>

            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="lclStatisticsFilterSelected.id === 2">
        <div class="title-section pull-right">
          <div class="btn-group pull-right" dropdown>
            <button id="button-report-types" dropdownToggle type="button"
                    class="btn btn-link dropdown-toggle subtitle-section-menu">
              {{staticsAccidentsPolicyTypeSelected.description}}
              <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-report-type" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-report-types">
              <li role="menuitem" *ngFor="let policyType of policyTypeList"
                  (click)="changePolicyTypeStaticsAccidents(policyType)">
                <a class="dropdown-item" href="javascript:;">{{policyType.description}}</a>
              </li>

              <ng-template #nobranch>
                <li role="menuitem">
                  <a class="dropdown-item" href="javascript:;">Sin tipo de póliza</a>
                </li>
              </ng-template>
            </ul>
          </div>
        </div>
      </div>


      <div class="col-md-12" *ngIf="lclStatisticsFilterSelected.id === 3">
        <div class="title-section pull-right">
          <div class="btn-group pull-right" dropdown>
            <button id="button-policy-use-types" dropdownToggle type="button"
                    class="btn btn-link dropdown-toggle subtitle-section-menu"
                    aria-controls="dropdown-policy-use-type">
              {{insuranceSelected.name}}
              <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-policy-use-type" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                role="menu" aria-labelledby="button-policy-use-types">
              <li role="menuitem" *ngFor="let item of staticsInsuranceList" (click)="selectInsurance(item)">
                <a class="dropdown-item" href="javascript:;">{{item.name}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="lclStatisticsFilterSelected.id === 5">
        <div class="title-section pull-right">
          <div class="btn-group pull-right" dropdown>
            <button id="button-types-companies" dropdownToggle type="button"
                    class="btn btn-link dropdown-toggle subtitle-section-menu">
              {{staticsCompanyTypeSelected}}
              <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-type-company" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-report-types">
              <li role="menuitem" *ngFor="let company of dataTypeFiltersDebtor"
                  (click)="changeCompanyTypeStaticsDebtorLife(company)">
                <a class="dropdown-item" href="javascript:;">{{company}}</a>
              </li>

            </ul>
          </div>

          <div class="btn-group pull-right" dropdown>
            <button id="button-data-types2" dropdownToggle type="button"
                    class="btn btn-link dropdown-toggle subtitle-section-menu">
              {{dataTypeFilterSelectedDebtorLife.name}}
              <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-data-type2" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-data-types">
              <li role="menuitem" *ngFor="let item of dataTypeDetorFilter"
                  (click)="changeDataTypeFilterDebtor(item)">
                <a class="dropdown-item" href="javascript:;">{{item.name}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="lclStatisticsFilterSelected.id === 7">
        <div class="title-section pull-right">
          <div class="btn-group pull-right" dropdown>
            <button id="button-report-type" dropdownToggle type="button"
                    class="btn btn-link dropdown-toggle subtitle-section-menu">
              {{staticsCollectionPolicyTypeSelected.description}}
              <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-report-type2" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-report-type">
              <div *ngIf="policyTypeCollecionList !== null && policyTypeCollecionList.length > 0 else nobranch">
                <li role="menuitem" *ngFor="let policyType of policyTypeList"
                    (click)="changePolicyTypeStaticsCollection(policyType)">
                  <a class="dropdown-item" href="javascript:;">{{policyType.description}}</a>
                </li>
              </div>

              <ng-template #nobranch>
                <li role="menuitem">
                  <a class="dropdown-item" href="javascript:;">Sin tipo de póliza</a>
                </li>
              </ng-template>
            </ul>
          </div>
        </div>
      </div>
-->

      <div class="col-xs-12 chart-container display-flex" *ngIf="showStatics">
        <div class="col-md-12 text-center" style="font-size: 20px; font-weight: bold; margin: auto;">Sin datos
          que mostrar
        </div>
      </div>

      <div class="col-xs-12" *ngIf="lclStatisticsFilterSelected.id === 0">
        <div class="chart-container">
          <canvas #lineChart id="lineChart">{{ chart }}</canvas>
        </div>
      </div>
      <div class="col-xs-12" *ngIf="lclStatisticsFilterSelected.id === 1" [hidden]="showStatics">
        <div class="chart-container">
          <canvas #pieChart id="pieChart">{{ pieChart }}</canvas>
        </div>
      </div>

      <div class="col-xs-12" *ngIf="lclStatisticsFilterSelected.id === 7" [hidden]="showStatics">
        <div class="chart-container">
          <canvas #pieChart1 id="pieChart1">{{ pieChart1 }}</canvas>
        </div>
      </div>

      <div class="col-xs-12" *ngIf="lclStatisticsFilterSelected.id === 2" [hidden]="showStatics">
        <div class="chart-container">
          <canvas #line1Chart id="line1Chart">{{ line1Chart }}</canvas>
        </div>
      </div>
      <div class="col-xs-12" *ngIf="lclStatisticsFilterSelected.id === 3" [hidden]="showStatics">
        <div class="row">
          <div class="col-xs-6">
            <p class="title-section">Uso de póliza</p>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="chart-container">
            <canvas id="line2Chart" #line2Chart></canvas>
          </div>

        </div>
      </div>
      <div class="col-xs-12" *ngIf="lclStatisticsFilterSelected.id === 5" [hidden]="showStatics">

        <div class="chart-container">
          <canvas #line3Chart id="line3Chart">{{ chart }}</canvas>
        </div>
      </div>
      <div class="" *ngIf="lclStatisticsFilterSelected.id === 6" [hidden]="showStatics">
        <br/><br/><br/><br/><br/><br/>
        <div class="chart-container">
          <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let p of totalPopulations">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-12 receipt-detail">
                    <div class="head">
                      <br>
                      <h4>{{p.name}}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer ">
                <hr/>
                <div class="stats">
                  <strong>Asegurados: {{p.insured}} </strong><br/>
                  <strong>Dependientes: {{p.kinship}}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
