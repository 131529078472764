import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-debtorlife-validation',
  templateUrl: './debtorlife-validation.component.html',
  styleUrls: ['./debtorlife-validation.component.css']
})
export class DebtorlifeValidationComponent implements OnInit {
  isSubmitted = false;
  explication: string;
  flag = 1;
  fileDebtorId: number;
  statsend: boolean;
  constructor(public shared: SharedService,
              private api: ApiService,
              private toastr: ToastrService,
              public fb: FormBuilder) { }

  registrationForm = this.fb.group({
    validation: ['authorize', [Validators.required]]
  });
  // Getter method to access form control
  get myForm() {
    return this.registrationForm.get('validation');
  }

  ngOnInit() {
    this.fileDebtorId = this.shared.gFileDebtorLifeId;
    this.statsend = false;
  }
  sendDebtorLifeData() {
    if (this.statsend) {
      return;
    }
    this.statsend = true;
    if (this.registrationForm.value.validation === '2' && this.explication === undefined) {
      this.toastr.warning('Para completar el proceso debe de ingresar el motivo de rechazo', 'Notificación');
      return;
    }
    this.api.postAuthorizeFileDebtorLife(this.fileDebtorId, this.explication, this.registrationForm.value.validation)
      .then(
        (response) => {
          this.toastr.success('Se ha notificado al ejecutivo de su respuesta', 'Notificación');
          this.shared.fUpdateDebtorLife.next(true);
          this.shared.fCloseSidebar();
        }, error => {
          this.toastr.warning('Ocurrio un problema con su solicitud ', 'Notificación');
          console.error(error);
          this.statsend = false;
        }
      );
  }

}
