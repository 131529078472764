import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';

@Component({
  selector: 'app-admin-reports',
  templateUrl: './admin-reports.component.html',
  styleUrls: ['./admin-reports.component.css']
})
export class AdminReportsComponent implements OnInit {
  listdemo: any = [];
  lclDemo: any = {};
  lclMovement: any = {};
  listMovement: any = [];
  lclFilterDates: any = {};
  listFilterDates: any = [];
  listkinship: any = [];
  lclkinship: any = {};
  listReports: any = [];
  list: any = [];
  pgReportItemsPerPage = 4;
  reportGridViewType: boolean;
  reportId: number;
  report: any = {};
  type: number;
  typeReport: number;

  lclReportSelected: number;

  dataTypesList: any = {};
  lclDataTypeForReportsSelected: any = {};

  widthSize: number = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.widthSize = window.innerWidth;
  }

  constructor(private router: Router,
              private api: ApiService,
              private shared: SharedService) {
    /*this.shared.gCurrentCustomerIdObservable().subscribe(
      gCustomerId => {
        if (gCustomerId !== -1) {
          this.invokeServiceForGetDataTypes();
        }
      }
    );*/
  }

  ngOnInit() {
    this.reportGridViewType = true;
  }

  /**
   * Método para recuperar los tipos de datos que se van a mostrar
   * 1 = Beneficios
   * 2 = Autos
   * 3 = Otros
   */
  private invokeServiceForGetDataTypes() {
    this.api.getDataTypes().subscribe(response => {
      this.dataTypesList = response;
      for (const item of this.dataTypesList) {
        if (item.enabled) {
          this.lclDataTypeForReportsSelected = item;
          this.loadReports();
          break;
        }
      }
    }, error => {
      console.error(error);
    });
  }

  public changeLclDataTypeReport(dataTypeSelected) {
    this.lclDataTypeForReportsSelected = dataTypeSelected;
    this.loadReports();
  }

  loadDemo(reportId) {
    this.api.getReporFilters(reportId, 1).then((data: any) => {
      this.listFilterDates = data;
      this.lclFilterDates = this.listFilterDates[0];
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
    this.listMovement = ['TODOS', 'ALTA', 'BAJA', 'GENERAL'];
    this.lclMovement = this.listMovement[0];
    this.api.getReporFilters(reportId, 2).then((data: any) => {
      this.listkinship = data;
      this.lclkinship = this.listkinship[0];
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);

    });
  }

  setReportGridView(value) {
    this.pgReportItemsPerPage = value ? 4 : 10;
    this.reportGridViewType = value;
  }

  goToBack() {
    this.router.navigate(['/admin/home']);
  }

  loadTable(reportId) {
    this.api.getReportId(reportId).then((data: any) => {
      this.list = data;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  loadReports() {
    this.api.getReportsType(this.lclDataTypeForReportsSelected.requestTypeId).subscribe((response: any) => {
      if (response != null) {
        this.listReports = response;
        this.listReports.map(x => x.reportDate = new Date(x.reportDate.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')));
        this.onReportItemSelect(this.listReports[0]);
      } else {
        this.listReports = [];
        this.list = [];
      }
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  /**
   * Método para invocar los datos correspondientes al seleccionar un reporte en específico
   * 1.- Seteamos el identificador de reporte a la variable local @lclReportSelected
   * 2.- Invocamos el método que recupera la información del reporte
   *
   * @param report Identificador del reporte seleccionado
   */
  onReportItemSelect(report) {
    this.report = report;
    this.lclReportSelected = report.reportId;
    this.loadTable(report.reportId);
    this.loadDemo(report.reportId);
    this.reportId = report.reportId;
    this.type = report.type;
    this.typeReport = report.typeReport;
    console.log(this.type);
  }

  invokeServiceForDownloadReportFile(report) {
    this.api.getReportFile(report.reportId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', report.reportName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }
  getReportFilter(filter, valor) {
    switch (filter) {
      case 1:
        this.lclkinship = valor;
        break;
      case 2:
        this.lclMovement = valor;
        break;
      case 3:
        this.lclFilterDates = valor;
        break;
    }
    this.api.getReporFiltersSearch(this.reportId, this.lclkinship, this.lclFilterDates, this.lclMovement)
      .subscribe((data) => {
        this.list = data;
      }, error => {
        console.info(error);
      });
  }
}
