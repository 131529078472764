import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public onClose: Subject<boolean>;
  user;
  userUpdate;
  base64textString: string;
  image;
  banderapass;
  oldpassword;
  newpassword;
  banderaclose;
  constructor(
              private api: ApiService,
              private toastr: ToastrService,
              public shared: SharedService) {
    this.getProfile();
  }

  ngOnInit() {
    this.userUpdate = {
      imageProfile: '../../assets/img/userprofile.png',
      name: 'Nombre de usuario',
      position: 'Puesto de usuario'
    };
    this.onClose = new Subject();
    this.banderapass = false;
    this.newpassword = '';
    this.oldpassword = '';
    this.banderaclose = false;
  }

  getProfile() {
    this.api.getUserProfile()
      .then(
        (data) => {
          this.user = data;
          this.userUpdate = Object.assign({}, this.user);
        }, error => {
          console.log(error);
        }
      );
  }

  onUploadChange(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e) {
    this.base64textString = 'data:image/png;base64,' + btoa(e.target.result);
    this.userUpdate.imageProfile = this.base64textString;
    this.image = { imageProfile: this.base64textString};
  }

  updateProfile() {
    if (this.base64textString === undefined && !this.banderapass) {
      this.toastr.success('No ha actualizado su perfil.', 'Notificación');
    } else if (this.base64textString !== undefined && this.banderapass) {
        this.updateImageprofile(false);
        this.updatepassword(true);
    }  else if (this.base64textString === undefined && this.banderapass) {
      this.updatepassword(true);
    } else if (this.base64textString !== undefined && !this.banderapass) {
      this.updateImageprofile(true);
    }
  }

  changuepassword() {
    this.banderapass = true;
  }

  updateImageprofile(bandera) {
    this.api.updateImageProfile(this.image).subscribe(response => {
      if (bandera) {
        this.toastr.success('Su información ha sido actualizada correctamente.', 'Notificación');
        this.shared.fUpdateProfile.next(true);
        this.shared.fCloseSidebar();
      }
    }, error => {
    });
  }

  updatepassword(bandera) {
    if (this.oldpassword !== '' && this.newpassword !== '') {
      this.api.updatePassword(this.oldpassword, this.newpassword)
        .then(
          (user) => {
            localStorage.setItem('token', window.btoa(this.userUpdate.email + ':' + this.newpassword));
            if (bandera) {
              this.toastr.success('Su información ha sido actualizada correctamente.', 'Notificación');
              this.shared.fUpdateProfile.next(true);
              this.shared.fCloseSidebar();
            }
          }, error => {
            this.banderaclose = false;
            if (error.status === 403) {
              this.toastr.warning('La contraseña actual no corresponde. Por favor, revisa e inténtalo de nuevo.', 'Notificación');
            } else {
              this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
            }
          }
        );
    } else {
      this.banderaclose = false;
      this.toastr.warning('Por favor ingrese los datos solicitados.', 'Notificación');
    }
  }


}
