import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {HomeComponent} from './home/home.component';
import {MainPanelComponent} from './main-panel/main-panel.component';
import {RecoveryComponent} from './recovery/recovery.component';
import {AdminHomeComponent} from './admin-home/admin-home.component';
import {AdminReportsComponent} from './admin-reports/admin-reports.component';
import {AdminPoliciesComponent} from './admin-policies/admin-policies.component';
import {AdminStaticsComponent} from './admin-statics/admin-statics.component';
import {AdminInsuredComponent} from './admin-insured/admin-insured.component';
import {RequestTempDetailComponent} from './request-temp-detail/request-temp-detail.component';
import {DashboardAmmiaComponent} from './dashboard-ammia/dashboard-ammia.component';
import {DashboardBenefitsComponent} from './dashboard-benefits/dashboard-benefits.component';
import {DashboardCarsComponent} from './dashboard-cars/dashboard-cars.component';
import {DashboardDamagesComponent} from './dashboard-damages/dashboard-damages.component';
import {DashboardPaymentsComponent} from './dashboard-payments/dashboard-payments.component';
import {RecoveryPasswordMdlComponent} from './recovery-password-mdl/recovery-password-mdl.component';
import {ValidateCodeMdlComponent} from './validate-code-mdl/validate-code-mdl.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'recovery',
    component: RecoveryPasswordMdlComponent
  },
  {
    path: 'recovery-password',
    component: RecoveryComponent
  },
  {
    path: 'validate-code',
    component: ValidateCodeMdlComponent
  },
  {
    path: 'user',
    component: MainPanelComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent
      }
    ]
  },
  {
    path: 'admin',
    component: MainPanelComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: DashboardAmmiaComponent
      },
      {
        path: 'benefits',
        component: DashboardBenefitsComponent
      },
      {
        path: 'cars',
        component: DashboardCarsComponent
      },
      {
        path: 'damages',
        component: DashboardDamagesComponent
      },
      {
        path: 'payments',
        component: DashboardPaymentsComponent
      },
      {
        path: 'reports',
        component: AdminReportsComponent
      },
      {
        path: 'policies',
        component: AdminPoliciesComponent
      },
      {
        path: 'statics',
        component: AdminStaticsComponent
      },
      {
        path: 'insured',
        component: AdminInsuredComponent
      },
      {
        path: 'request-detail',
        component: RequestTempDetailComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
