import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SharedService} from '../shared-service.service';

@Component({
  selector: 'app-request-general-damage-ammia',
  templateUrl: './request-general-damage-ammia.component.html',
  styleUrls: ['./request-general-damage-ammia.component.css']
})
export class RequestGeneralDamageAmmiaComponent implements OnInit {

  // solicitud general daños
  dataRequestDamage: any = {};
  policyAmmiaList: any = [];
  clientList: any = {};
  myFormRequestdamage: FormGroup;
  responseServiceDamage: number;
  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public shared: SharedService) {
    this.myFormRequestdamage = this.fb.group({
      client: ['', [Validators.required]],
      subject: ['',  [Validators.required, Validators.maxLength(50)]],
      description: ['']
    });

    this.shared.fUpdateRequestDamagesChangeObservable().subscribe(
      fUpdateDamagesChanges => {
        if (fUpdateDamagesChanges) {
          this.responseServiceDamage = 0;
          this.invokeFunctionLoadNewRequest();
        }
      }
    );
  }

  ngOnInit(): void {
  }

  invokeServiceGetClientByPolicy(p: any) {
    this.dataRequestDamage.policyId = p.policyId;
    this.invokeServiceGetClientsByPolicy(p.policyId);
    console.log(p);
  }

  invokeServiceGetClientsByPolicy(idPolicy: number) {
    const data = {
      policyId: idPolicy
    };
    this.api.getClientsByPolicy(data)
      .then(
        (response: any) => {
          if (response != null) {
            this.clientList = response;
            this.dataRequestDamage.clientId = response[0].clientId;
          }
        }, error => {
          console.info(error);
        });
  }

  invokeFunctionLoadNewRequest() {
    this.dataRequestDamage = {};
    this.dataRequestDamage = {
      requestId: 0,
      subject: '',
      description: '',
      policyId: 0,
      categoryId: 0,
      groupId: 0,
      clientId: 0,
      userId: 0,
      statusId: 2,
      folio: ''
    };
    console.log(this.dataRequestDamage);
    this.invokeServiceGetPoliciesDamages();
  }

  invokeServiceGetPoliciesDamages() {
    this.api.getPoliciesAmmiaDashboardDamages()
      .then(
        (response: any) => {
          if (response != null) {
            this.policyAmmiaList = response;
          }
        }, error => {
          console.info(error);
        });
  }

  invokeFunctionSaveRequestGeneralDamage() {
    console.log(this.dataRequestDamage);
    this.responseServiceDamage = 1;
    this.api.saveRequestDamage(this.dataRequestDamage)
      .then(
        (response) => {
          this.shared.fUpdateRequests.next(true);
          this.shared.fUpdateDamagesChanges.next(true);
          this.responseServiceDamage = 2;
          console.log(this.responseServiceDamage);
        }, error => {
          this.responseServiceDamage = 3;
          console.info(error);
        });
  }

  invokeFunctionClear() {
    this.responseServiceDamage = 0;
    this.shared.fUpdateRequests.next(true);
    this.shared.fUpdateDamagesChanges.next(true);
  }

}
