import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {browserRefresh} from '../app.component';
import {SharedService} from '../shared-service.service';

const API_REST = environment.api_rest;

@Component({
  selector: 'app-admin-policies',
  templateUrl: './admin-policies.component.html',
  styleUrls: ['./admin-policies.component.css']
})
export class AdminPoliciesComponent implements OnInit {

  typePolicyList = []; // almacena el catálogo de Tipos de Pólizas
  policyList: any = []; // almacena la lista de Pólizas
  subgroupList: any = []; // almacena la lista de Subgrupos, tendra una propiedad 'categoryList' para almacenar la lista de categorías
  policyDetailJson: any = [];
  policyAttachmentList = [];
  policyDetail: any = {};
  amountDetail: any = {}; // almacena el detalle de los montos de la Póliza

  policyType: any = {};
  typePolicySelected: any = {};
  lclSubgroupSelected: any = {};

  lclCategorySelected: any = {};
  url: string;
  url1: string;
  urlcertificate: string;
  reportGridViewType = true;
  reportGridViewTypeLeft = true;

  constructor(
    private api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private shared: SharedService
  ) {
    /*this.shared.gCurrentCustomerIdObservable().subscribe(
      gCustomerId => {
        if (gCustomerId !== -1) {
          this.getPoliciesTypesCat();
        }
      }
    );*/
  }

  ngOnInit() {
    this.url = API_REST + '/policies/attachments/document/free/';
    this.url1 = API_REST + '/policies/attachments/document/zip/';
    this.urlcertificate = API_REST + '/policies/attachments/certificate/free/';
  }

  goToBack() {
    this.router.navigate(['/admin/home']);
  }

  /**
   * Método para obtener el catálogo de Tipos de Pólizas
   */
  private getPoliciesTypesCat() {
    this.api.getTypePolicies().then((data: any) => {
      this.typePolicyList = data;
      if (this.typePolicyList !== null && this.typePolicyList !== undefined && this.typePolicyList.length > 0) {
        this.typePolicySelected = this.typePolicyList[0];
        this.getPoliciesByTypeId(this.typePolicySelected);
      }
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  /**
   * Método para obtener la lista de Pólizas a través del Id de Tipo de Póliza especificado
   *
   * @param policyType [JSONObject] Tipo de póliza seleccionado
   */
  public getPoliciesByTypeId(policyType) {
    this.typePolicySelected = policyType;
    this.api.getUserPoliciesType(this.typePolicySelected.typeId).then((data: any) => {
      this.policyList = data;
      if (this.policyList !== null && this.policyList !== undefined && this.policyList.length > 0) {
        this.invokeServiceForGetSubgroupsWithCategories(this.policyList[0].policyId);
        this.displayPolicyDetail(this.policyList[0].policyId);
        this.getAttachmentPolicy(this.policyList[0].policyId);
      }
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  private invokeServiceForGetSubgroupsWithCategories(policyId) {
    this.api.getSubgroupsWithCategories(policyId)
      .subscribe(
        response => {
          if (response != null) {
            this.subgroupList = response;
            this.lclSubgroupSelected = this.subgroupList[0];
            this.lclCategorySelected = this.lclSubgroupSelected.categoryList[0];
            this.invokeServiceForGetPolicyDetailByCategoryId(this.lclCategorySelected.categoryId);
            this.invokeServiceForGetAmountsDetailByCategoryId(this.lclCategorySelected.categoryId);
          }
        }, error => {
          console.error(error);
        }
      );
  }

  private invokeServiceForGetPolicyDetailByCategoryId(categoryId) {
    this.api.getPolicyDetailByCategoryId(categoryId)
      .subscribe(
        response => {
          this.policyDetailJson = response;
        }, error => {
          console.error(error);
        }
      );
  }

  private invokeServiceForGetAmountsDetailByCategoryId(categoryId) {
    this.api.getAmountsDetailByCategoryId(categoryId)
      .subscribe(response => {
        this.amountDetail = response;
      }, error => {
        console.error(error);
      }
    );
  }

  /**
   * Este meotodo si se usa
   * @param id
   */
  displayPolicyDetail(id) {
    console.info('displayPolicyDetail');
    this.api.getDetailPolicy(id).then((data: any) => {
      this.policyDetail = data;
      this.invokeServiceForGetSubgroupsWithCategories(this.policyDetail.policyId);
      this.getAttachmentPolicy(this.policyDetail.policyId);
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  /**
   * @deprecated
   * @param id
   */
  getDetailPolicyJson(id) {
    this.api.getDetailPolicyjson(id).then((data: any) => {
      this.policyDetailJson = data;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  getAttachmentPolicy(id) {
    this.api.getAttachmentlPolicy(id).then((data: any) => {
      if (data != null) {
        this.policyAttachmentList = data;
      }
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  public invokeServiceForGetAttachmentFile(attachment) {
    this.api.getPolicyAttachmentFile(attachment.attachmentId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          console.error(error);
        }
      );
  }

  public invokeServiceForGetPolicyAttachmentZipFile(policy) {
    this.api.getPolicyAttachmentZip(policy.policyId)
      .subscribe(
      (response: any) => {
        if (response == null) {
          this.toastr.info('No existen archivos para descargar');
          return;
        }
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download',  `anexos_poliza_${policy.number}.zip`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      }, error => {
        console.error(error);
      }
    );
  }

  public changeLclSubgroupSelected(subgroup) {
    this.lclSubgroupSelected = subgroup;
    this.changeLclCategorySelected(this.lclSubgroupSelected.categoryList[0]);
  }
  public changeLclCategorySelected(category) {
    this.lclCategorySelected = category;
    this.invokeServiceForGetPolicyDetailByCategoryId(this.lclCategorySelected.categoryId);
    this.invokeServiceForGetAmountsDetailByCategoryId(this.lclCategorySelected.categoryId);
  }


  /*--------------------------------------------------------------------------------*/
  public hexToRgb(hex, opacity) {
    const rgb = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
      , (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16));

    return `rgba(${rgb.toString()}, ${opacity})`;
  }


  /**
   * Descarga el PDF de la póliza
   */
  public invokeServiceForDownloadPolicyFile() {
    this.api.getPolicyFile(this.policyDetail.policyId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', this.policyDetail.number + '.pdf');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }
}
