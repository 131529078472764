import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  /** :fUpdateProfile Bandera para identificar si es necesario recargar los datos de perfil de usuario */
  public fUpdateProfile: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :fUpdateIncident Bandera para identificar si es necesario recargar el apartado de Siniestros */
  public fUpdateIncidents: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :fUpdateRequests Bandera para identificar si es necesario recargar el apartado de Solicitudes */
  public fUpdateRequests: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :gPolicyId Variable global para almacenar el Id de la póliza seleccionada */
  public gPolicyId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  /** :gIncidentId Variable global para almacenar el Id del Siniestro seleccionado */
  public gIncidentId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  /** :gRequestId Variable global para almacenar el Id de la Solicitud seleccionada */
  public gRequestId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  /** :fStreamRoomMessages Bandera para identificar si es posible realizar el stream de la sala de chat */
  public fStreamRoomMessages: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :gMessageCount Variable global para almacenar el número de mensajes no leídos en el chat */
  public gMessageCount = 0;
  /** :fInsuredDataReadonly Bandera para identificar si la información del asegurado es de solo lectura */
  public fInsuredDataReadonly = false;
  /** :fAdminDashboard Bandera para identificar sí se muestra el dashboard General o el de Concesionarios
   * 0 = General
   * 1 = Concesionarios
   */
  public fAdminDashboard = 0;
  /** :gCurrentRole Variable global para almacenar el Id del rol del usuario en sesión */
  public gCurrentRoleId: number;
  /** :gCurrentCustomerId Variable global para almacenar el Id del Cliente que se esta mostrando su información */
  public gCurrentCustomerId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  /** :gCurrentBranchId Variable global para almacenar el Id del ramo seleccionado que se esta mostrando su información */
  public gCurrentBranchId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  /** :gCurrentCustomerName Variable global para almacenar el nombre del Cliente que se esta mostrando su información */
  public gCurrentCustomerName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  /** :gCurrentPolicyBenefit Variable global para almacenar si el cliente tiene pólizas de beneficios */
  public gCurrentPolicyBenefit: 0;
  /** :gCurrentPolicyDebtorLife Variable global para almacenar si el cliente tiene pólizas de vida deudor */
  public gCurrentPolicyDebtorLife: 0;
  /** :gCurrentBranchId Variable global para almacenar el Id del ramo seleccionado que se esta mostrando su información */
  public gCurrentBranchIdInit: number;
  /** :gCurrentPolicyCar Variable global para almacenar si el cliente tiene pólizas de autos */
  public gCurrentPolicyCar: 0;
  /** :fUpdateDebtorlife Bandera para identificar si es necesario recargar el apartado de Vida Deudor */
  public fUpdateDebtorLife: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :fUpdateProfile Bandera para identificar si es necesario recargar los datos de view asegurado */
  public fUpdateViewInsured: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** :gProfileImg Variable global para almacenar la imagen de perfil del usuario logeado */
  public gProfileImg: string;

  /** :gProfileImg Variable global para almacenar el nombre de perfil del usuario logeado */
  public gProfileName: string;

  /** :gProfileImg Variable global para almacenar la posiciòn de perfil del usuario logeado */
  public gProfilePosition: string;

  /** :gUserRoles Variable global para almacenar los roles del usuario logeado */
  public gUserRoles: any = [];

  /** :gFileDebtorLifeId variable global para almacenar el valor del archivo a validar de vida deudor */
  public gFileDebtorLifeId: number;

  public pgincident: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public pgInsured: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  // Arreglo global para indicar los registros por página en paginados
  public pgItemPerPageOpt = [5, 10, 15];

  // :fIncidentGeneral Almacena el tipo de filtro que se debe de aplicar en el módulo de Siniestros (Menú superior)
  public fIncidentGeneral: BehaviorSubject<number> = new BehaviorSubject(0); // <- por default 'Todos'

  // :fContentSidebar Almacena el identificador para el componente que se debe de mostrar en el sidebar
  public fContentSidebar: BehaviorSubject<number> = new BehaviorSubject(-1);

  /** :gLogo Variable global para almacenar la lista de mensajes del chat */
  public gMsgList: any = [];

  /** :activeMenu Variable global para almacenar el Id del menu seleccionado */
  public activeMenu: BehaviorSubject<number> = new BehaviorSubject<number>(-1);

  /** :sIncidentId Variable global para almacenar el Id del Siniestro seleccionado de la busqueda */
  public sIncidentId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);

  /** :fUpdateRequestsGeneral Bandera para identificar si es necesario recargar el componente de Solicitudes */
  public fUpdateRequestsGeneral: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :fUpdateRequestsGeneral Bandera para identificar si es necesario recargar el componente de Solicitudes */
  public fUpdateRequestsAdd: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :fUpdateRequestsGeneral Bandera para identificar si es necesario recargar el componente de Solicitudes */
  public fUpdateRequestsGeneralCar: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :fUpdateRequestsGeneral Bandera para identificar si es necesario recargar el componente de Solicitudes */
  public fUpdateRequestsCancellation: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** :fUpdateRequestsDelete Bandera para identificar si es necesario recargar el componente de Solicitudes */
  public fUpdateRequestsDelete: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** :fUpdateRequestsMassImport Bandera para identificar si es necesario recargar el componente de Solicitudes masivas */
  public fUpdateRequestsMassImport: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** :fUpdateRequestsMassImportProcess Bandera para identificar si es necesario recargar el componente de Solicitudes masivas proceso */
  public fUpdateRequestsMassImportProcess: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** :fUpdateRequestsCancellationCar Bandera para identificar si es necesario recargar el componente de Solicitudes */
  public fUpdateRequestsCancellationCar: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** :fUpdateRequestsAddCar Bandera para identificar si es necesario recargar el componente de Solicitudes */
  public fUpdateRequestsAddCar: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** :fUpdateBenefits Bandera para identificar si es necesario mostrar el componente de Solicitudes beneficios */
  public fUpdateBenefits: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :fUpdateDamages Bandera para identificar si es necesario mostrar el componente de Solicitudes daños */
  public fUpdateDamages: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :fUpdateCars Bandera para identificar si es necesario mostrar el componente de Solicitudes carros */
  public fUpdateCars: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** :fUpdateBenefitsChanges Bandera para identificar si es necesario recargar el componente de Solicitudes beneficios */
  public fUpdateBenefitsChanges: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :fUpdateDamagesChanges Bandera para identificar si es necesario recargar el componente de Solicitudes daños */
  public fUpdateDamagesChanges: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :fUpdateCarsChanges Bandera para identificar si es necesario recargar el componente de Solicitudes carros */
  public fUpdateCarsChanges: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * Convierte un número de bytes a su equivalente en unidad de medida mas grande
   *
   * @param bytes Número de bytes
   * @param decimals Número de puunto decimales a utilizar
   * @returns {string} Resultado de la conversión
   */
  public formatBytes(bytes, decimals) {
    if (bytes === 0) {return '0 Bytes'; }
    const k = 1000;
    const  dm = decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /**
   * Observable para monitorear los cambios en la variable :fUpdateProfile
   * true: Actualiza los datos de perfil
   * @see NavbarComponent
   */
  fUpdateProfileObservable(): Observable<boolean> {
    return this.fUpdateProfile.asObservable();
  }

  /**
   * Observable para monitorear los cambios en la variable :fUpdateIncidents
   * true: Actualiza los registros de Siniestros
   * @see HomeComponent
   */
  fUpdateIncidentsObservable(): Observable<boolean> {
    return this.fUpdateIncidents.asObservable();
  }

  /**
   * Observable para monitorear los cambios en la variable :fUpdateRequests
   * true: Actualiza los registros de Solicitudes
   * @see HomeComponent
   */
  fUpdateRequestsObservable(): Observable<boolean> {
    return this.fUpdateRequests.asObservable();
  }

  fUpdateRequestsGeneralObservable(): Observable<boolean> {
    return this.fUpdateRequestsGeneral.asObservable();
  }
  fUpdateRequestsAddObservable(): Observable<boolean> {
    return this.fUpdateRequestsAdd.asObservable();
  }
  fUpdateRequestsGeneralCarObservable(): Observable<boolean> {
    return this.fUpdateRequestsGeneralCar.asObservable();
  }
  fUpdateRequestsCancellationObservable(): Observable<boolean> {
    return this.fUpdateRequestsCancellation.asObservable();
  }
  ffUpdateRequestsDeleteObservable(): Observable<boolean> {
    return this.fUpdateRequestsDelete.asObservable();
  }
  fUpdateRequestsMassImportObservable(): Observable<boolean> {
    return this.fUpdateRequestsMassImport.asObservable();
  }
  fUpdateRequestsMassImportProcessObservable(): Observable<boolean> {
    return this.fUpdateRequestsMassImportProcess.asObservable();
  }
  fUpdateRequestsCancellationCarObservable(): Observable<boolean> {
    return this.fUpdateRequestsCancellationCar.asObservable();
  }
  fUpdateRequestAddCarObservable(): Observable<boolean> {
    return this.fUpdateRequestsAddCar.asObservable();
  }
  fUpdateRequestBenefitsChange(): Observable<boolean> {
    return this.fUpdateBenefitsChanges.asObservable();
  }
  fUpdateRequestDamagesChangeObservable(): Observable<boolean> {
    return this.fUpdateDamagesChanges.asObservable();
  }
  fUpdateRequestCarChange(): Observable<boolean> {
    return this.fUpdateCarsChanges.asObservable();
  }

  /**
   * Observable para monitorear los cambios en la variable :gCurrentCustomerId
   * Al sufrir un cambio debe invocar todos los servicios del dashboard Admin
   * @see HomeComponent
   */
  gCurrentCustomerIdObservable(): Observable<number> {
    return this.gCurrentCustomerId.asObservable();
  }

  /**
   * Observable para monitorear los cambios en la variable :gCurrentCustomerId
   * Al sufrir un cambio debe invocar todos los servicios del dashboard Admin
   * @see HomeComponent
   */
  gCurrentBranchIdObservable(): Observable<number> {
    return this.gCurrentBranchId.asObservable();
  }

  /**
   * Observable para monitorear los cambios en la variable :gCurrentCustomerName
   * Al sufrir un cambio debe invocar todos los servicios del dashboard Admin
   * @see HomeComponent
   */
  gCurrentCustomerNameObservable(): Observable<string> {
    return this.gCurrentCustomerName.asObservable();
  }



  /**
   * Observable para monitorear los cambios en la variable :fUpdateDebtorLife
   * true: Actualiza los registros de vida deudor
   * @see HomeComponent
   */
  fUpdateDebtorLifeObservable(): Observable<boolean> {
    return this.fUpdateDebtorLife.asObservable();
  }

  /**
   * Observable para monitorear los cambios en la variable :gPolicyId
   * Almacena el ID de la póliza seleccionada
   * @see HomeComponent
   * @see PolicyComponent
   */
  gPolicyIdObservable(): Observable<number> {
    return this.gPolicyId.asObservable();
  }
  gIncidentIdObservable(): Observable<number> {
    return this.gIncidentId.asObservable();
  }
  gRequestIdObservable(): Observable<number> {
    return this.gRequestId.asObservable();
  }
  fStreamRoomMessagesObservable(): Observable<boolean> {
    return this.fStreamRoomMessages.asObservable();
  }

  fIncidentGeneralObservable(): Observable<number> {
    return this.fIncidentGeneral.asObservable();
  }

  fContentSidebarObservable(): Observable<number> {
    return this.fContentSidebar.asObservable();
  }

  pgIncidentObservable(): Observable<any> {
    return this.pgincident.asObservable();
  }

  pgInuredObservable(): Observable<any> {
    return this.pgInsured.asObservable();
  }

  /**
   * Observable para monitorear los cambios en la variable :activeMenu
   * Almacena el ID del menu seleccionado
   */
  activeMenuObservable(): Observable<number> {
    return this.activeMenu.asObservable();
  }

  /***
   monitorea los cambios de la busqueda de siniestros
   **/
  sIncidentIdObservable(): Observable<number> {
    return this.sIncidentId.asObservable();
  }
  /* Set the width of the sidebar to 340px and the left margin of the page content to 340px */
  fOpenSidebar(sbIdentifier, size?) {
    let sidebarSize;
    const small = '340px';
    const middle = '500px';
    const large = '800px';
    const full = '97%';
    switch (size) {
      case 'lg':
        sidebarSize = large;
        break;
      case 'md':
        sidebarSize = middle;
        break;
      case 'full':
        sidebarSize = full;
        break;
      default:
        sidebarSize = small;
        break;
    }
    this.fContentSidebar.next(sbIdentifier);
    document.getElementById('sidebar').style.width = sidebarSize;
    // document.getElementById('main').style.marginRight = sidebarSize;
    document.getElementById('navbar').style.marginRight = sidebarSize;
  }
  fCloseSidebar() {
    document.getElementById('sidebar').style.width = '0';
    document.getElementById('main').style.marginRight = '0';
    document.getElementById('navbar').style.marginRight = '0';
    setTimeout(() => {
      this.fContentSidebar.next(-1);
    }, 500);
  }
}
