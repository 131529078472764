import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-massive-request-vehicle',
  templateUrl: './massive-request-vehicle.component.html',
  styleUrls: ['./massive-request-vehicle.component.css']
})
export class MassiveRequestVehicleComponent implements OnInit {

  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'];

  constructor(public shared: SharedService,
              private api: ApiService,
              private toastr: ToastrService) { }

  ngOnInit() {
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  public invokeServiceForGetLayoutMassiveLoadVehicle() {
    this.api.getLayoutMassiveLoadVehicle()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', 'LAYOUT_SOLICITUDES_VEHICULOS.xlsx');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  sendRequestData() {
    if (this.error.active === true || this.fileUpload === undefined) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
      return;
    }
    console.info(this.fileUpload);
    const formData = new FormData();
    formData.append('file', this.fileUpload);
    this.api.postMassiveLoadLayoutVehicle(formData)
      .subscribe(
        response => {
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.shared.fUpdateRequests.next(true);
          this.shared.fCloseSidebar();
        }, error => {
          console.error();
        }
      );
  }

}
