<div>
  <div *ngIf="responseServiceDamage == 0">
    <form [formGroup]="myFormRequestdamage">
      <div class="mb-3">
        <p class="solicitud-parrafo">Solicita correcciones o modificaciones de los datos de bienes asegurados o de las pólizas.</p>

      </div>

      <!-- SEPARADOR -->
      <div class="separador-tarjetas">
        <h4>Pólizas</h4>
        <hr>
      </div>

      <div class="d-between" >
        <div  class="form-check form-check-inline mb-3" *ngFor="let p of policyAmmiaList">
          <!--<a href="javascript:;" (click)="invokeServiceGetClientByPolicy(p)">
            <h5>Daños</h5>
            <h6>{{p.subBranch | titlecase}}</h6>
            <h6>{{p.number}}</h6>
          </a>-->

          <input class="form-check-input" type="radio" name="polizaMaestraSolicitud" id="polizaMaestraSolicitud {{p.policyId}}"
                 value="option{{p.policyId}}" (click)="invokeServiceGetClientByPolicy(p)"
                 onchange="nuevaSolicitudCheck2(this);"
          >
          <label class="form-check-label poliza-maestra mx-0" for="polizaMaestraSolicitud {{p.policyId}}" id="{{p.policyId}}">
            <h4 class="">{{p.subBranch | titlecase}} <br> {{p.number}}</h4>
            <h5>{{p.insurance}}</h5>
          </label>
        </div>
      </div>
      <br>
      <!--- cliente de siniestro --->
      <div class="form-row">
        <div class="form-group">
          <label >Razón Social</label>
          <select class="form-select ammiaAdmin-style  mb-3" aria-label=" example" [(ngModel)]="dataRequestDamage.clientId"
                  name="relationshipDep" required formControlName="client">
            <option *ngFor="let item of clientList" [value]="item.clientId"
            >{{item.name}}</option>
          </select>

        </div>
      </div>

      <!-- Asunto de la solicitud-->
      <div class="form-row">
        <div class="form-group">
          <label for="asuntoSolicitud">Asunto de la solicitud</label>
          <input type="text" class="form-control ammiaAdmin-style"
                 id="asuntoSolicitud" name="asuntoSolicitud" required placeholder="Ingresa asunto" formControlName="subject"
                 [(ngModel)]="dataRequestDamage.subject">
          <div class="invalid-feedback">
            Please choose a username.
          </div>
        </div>
      </div>

      <!-- Descripcion de la solicitud -->
      <div class="form-row">
        <div class="form-group">
          <label for="descripcionSolicitud">Descripción de la solicitud</label>
          <textarea type="text" class="form-control ammiaAdmin-style descripcion" id="descripcionSolicitud" name="descripcionSolicitud"
                    formControlName="description" [(ngModel)]="dataRequestDamage.description"></textarea>
        </div>
      </div>


      <!-- SEPARADOR -->
      <!--<div class="separador-tarjetas">
        <h4>Datos del bien asegurado</h4>
        <hr>
      </div>
      <div class="form-row mt-4">
        <div class="form-group">
          <label for="buscarAsegurado">Buscar bien asegurado</label>
          <div class="search-container d-between w-100">
            <input type="text" class=" form-control ammiaAdmin-style" placeholder="Buscar por tipo de bien, descripción o número de póliza"  >
            <img src="../../assets/img/ammia/iconos/search.svg" alt="Ícono de Agregar tarea" class="search-icon">
          </div>
        </div>
      </div>-->


    </form>

    <div class=" offcanvas-footer d-center mt-3">
      <button class="btn primario" id="" type="button" aria-controls="offcanvasRight"
              [disabled]="myFormRequestdamage.invalid" (click)="invokeFunctionSaveRequestGeneralDamage()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5237 6.98805L10.2319 14.3279L7.52681 11.5317L5.99787 13.0463L9.17336 16.3084C9.29097 16.4249 9.52619 16.5415 9.76142 16.658C9.99664 16.7745 10.2319 16.7745 10.3495 16.7745C10.4671 16.7745 10.8199 16.7745 10.9375 16.658C11.1727 16.5415 11.2904 16.4249 11.5256 16.3084L19.1703 8.50262L17.5237 6.98805Z" />
          <path d="M20.5819 3.49515C18.3473 1.28155 15.2894 0 11.9963 0C8.82081 0 5.76293 1.28155 3.52833 3.49515C1.29372 5.82524 0 8.73786 0 12C0 15.1456 1.29372 18.1748 3.52833 20.5049C5.76293 22.7184 8.82081 24 11.9963 24C15.1718 24 18.2297 22.7184 20.4643 20.5049C22.6989 18.2913 23.9926 15.2621 23.9926 12C24.1102 8.73786 22.8165 5.82524 20.5819 3.49515ZM12.1139 21.6699C6.70382 21.6699 2.35222 17.2427 2.35222 11.8835C2.35222 6.52427 6.70382 2.09709 12.1139 2.09709C17.524 2.09709 21.8756 6.52427 21.8756 11.8835C21.8756 17.3592 17.524 21.6699 12.1139 21.6699Z" />
        </svg>

        Generar solicitud
      </button>
    </div>
  </div>

  <div class="" *ngIf="responseServiceDamage == 2">
    <a href="javascript:;"   aria-controls="offcanvasRight">
      <dotlottie-player src="https://lottie.host/bb64e4a0-a834-4f97-8152-fba5e5234f5a/3vObIm0Ks5.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
    </a>

    <p class="">Se ha generado el reporte de tu solicitud. Un ejecutivo Surexs te contactará lo antes posible y te brindará atención.</p>


    <div class="d-center w-100 mt-5">
      <button class="btn primario" id="btnGenerarSolicitudVehiculog" type="button" data-bs-dismiss="offcanvas" aria-label="Close" (click)="invokeFunctionClear()">
        <img src="../../assets/img/ammia/iconos/check-azuld2.svg" alt="" class="icono-sm">
        Entendido
      </button>
    </div>
  </div>
  <div class=""  *ngIf="responseServiceDamage == 1">
    <div class="w-100 d-column-center">
      <a href="javascript:;"  aria-controls="offcanvasRight" >
        <dotlottie-player src="https://lottie.host/06fc7b75-f5d2-4524-86f8-fbbe96bf456a/VGiGPUrFSr.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
      </a>
      <div>
        <p class="solicitud-parrafo">Estamos validando tu solicitud. No cierres ni actualices esta venta. </p>
      </div>
    </div>
  </div>
  <div  *ngIf="responseServiceDamage == 3">
    <a href="javascript:;"   aria-controls="offcanvasRight" >
      <dotlottie-player src="https://lottie.host/4c9dabcd-e6c6-41c2-84c9-7cf1b79890af/yn2rFJ1ICK.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
    </a>
    <div>
      <p class="solicitud-parrafo">Se ha producido un error al dar de alta la solicitud, por favor contacta al administrador.
      </p>
    </div>
    <div class="d-center w-100 mt-5">
      <button class="btn primario" id="btnGenerarSolicitudVehiculogg" type="button" data-bs-dismiss="offcanvas" aria-label="Close" (click)="invokeFunctionClear()">
        <img src="../../assets/img/ammia/iconos/check-azuld2.svg" alt="" class="icono-sm">
        cerrar
      </button>
    </div>
  </div>
</div>

