<div style="position:relative;min-height: 100%;width: 340px;padding: 20px">
  <div style="height: 126px;width: 100%; text-align: center;">
    <span class="sx-iconsys-icon" aria-hidden="true" style="float: right;font-size: 22px;font-weight: normal;color: #C4C4C4;cursor: pointer;" (click)="shared.fCloseSidebar()">x</span>
    <p style="color: #448AA6;font-size: 16px;font-weight: 600;line-height: 19px;padding-top: 33px;padding-bottom: 33px">
      Nueva Solicitud
    </p>
    <div style="text-align: left">
      <span style="display: inline-block;vertical-align: middle;color: #326173;">Vehículo</span><span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 235px;border-top: 1px solid #E1E1E1;"></span>
    </div>
  </div>



  <div style="position: relative;min-height: calc(100% - 160px);width: 100%;color: #326173">
    <div class="form-group form-group-sm">
      <label>Tipo de solicitud</label>
      <div class="btn-group btn-group-justified" role="group" aria-label="...">
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-default" [ngClass]="lclRequestType==0?'action-selected':''" (click)="selectRequestType(0)" style="height: 30px;color: #9499B8;font-size: 11px;font-weight: 500;">Alta</button>
        </div>
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-default" [ngClass]="lclRequestType==1?'action-selected':''" (click)="selectRequestType(1)" style="height: 30px;color: #9499B8;font-size: 11px;font-weight: 500;">Baja</button>
        </div>
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-default" [ngClass]="lclRequestType==2?'action-selected':''" (click)="selectRequestType(2)" style="height: 30px;color: #9499B8;font-size: 11px;font-weight: 500;">General</button>
        </div>
      </div>
    </div>

    <form id="requestForm" [ngSwitch]="lclRequestType" #f="ngForm" (ngSubmit)="sendRequestData()" [noValidate]="false">

      <!--+---------------------------------------------------------------------------------------------------+
          |-------------------------------------------|  A L T A  |-------------------------------------------|
          +---------------------------------------------------------------------------------------------------+-->

      <div *ngSwitchCase="0">
        <div class="form-group form-group-sm">
          <label class="control-label">Póliza</label>
          <div class="row">
            <div class="col-xs-12">
              <ng-multiselect-dropdown
                [placeholder]="'Pólizas seleccionadas'"
                [data]="policiesList"
                [(ngModel)]="policySelected"
                [settings]="dropdownSettingsPolicies"
                (onSelect)="onSelectPolicy()"
                name="policy">
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>

        <div class="form-group form-group-sm">
          <label class="control-label">Marca</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.brand" name="brand" required>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Modelo</label>
          <input type="number" min="1500" max="9999" class="form-control" [(ngModel)]="dataToSend.model" name="model" required (ngModelChange)="setInvoiceRequire()">
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Versión</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.version" name="version" required>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Número de Motor</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.engine" name="engine" required>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Número de Serie</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.serialNumber" name="serialNumber" required>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Fecha de alta</label>
          <div class="input-group">
            <div class="input-group-addon"><i class="fa fa-calendar-alt"></i></div>
            <input type="text"
                   placeholder="00-00-0000"
                   class="form-control"
                   bsDatepicker
                   [bsConfig]="bsEntryDate"
                   [(ngModel)]="dataToSend.entryDate"
                   name="entryDateDep"
                   required>
          </div>
        </div>

        <div class="form-group form-group-sm">
          <label class="control-label">Cargar factura</label>
          <div class="form-group">
            <label class="btn btn-custom btn-sm btn-block" [ngClass]="disableInvoice?'disabled':''"><i class="fa fa-folder-open"></i>&nbsp;Buscar...
              <input type="file" style="display: none" (change)="onChangeInputFile($event)" [required]="!disableInvoice" [disabled]="disableInvoice">
            </label>
          </div>
          <label class="text-primary" style="width: 100%" *ngIf="fileUpload != undefined" [hidden]="error.active">
            {{ fileUpload.name }} <span class="pull-right">{{shared.formatBytes(fileUpload.size,2)}}</span>
          </label>
          <label class="text-danger" [hidden]="!error.active">{{ error.msg }}</label>
        </div>
      </div>

      <!--+---------------------------------------------------------------------------------------------------+
          |-------------------------------------------|  B A J A  |-------------------------------------------|
          +---------------------------------------------------------------------------------------------------+-->
      <div *ngSwitchCase="1">
        <div class="form-group has-feedback">
          <label class="control-label">Número de serie</label>
          <span class="glyphicon glyphicon-search form-control-feedback" aria-hidden="true"></span>
          <input [(ngModel)]="searchItemSelected"
                 [typeaheadAsync]="true"
                 [typeahead]="dataSource"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 [typeaheadOptionsLimit]="7"
                 typeaheadWaitMs="1000"
                 typeaheadOptionField="serialNumber"
                 placeholder="Buscar…"
                 class="form-control"
                 name="serial"
                 autocomplete="off">
          <div *ngIf="typeaheadLoading">Loading</div>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Póliza</label>
          <div class="input-group">
            {{lclVehiclePolicy.number || '-------------------'}}
          </div>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Fecha de baja</label>
          <div class="input-group">
            <div class="input-group-addon"><i class="fa fa-calendar-alt"></i></div>
            <input type="text"
                   placeholder="00-00-0000"
                   class="form-control"
                   bsDatepicker
                   [bsConfig]="bsEntryDate"
                   [(ngModel)]="dataToSend.entryDate"
                   name="entryDateDep"
                   required>
          </div>
        </div>
      </div>

      <!--+---------------------------------------------------------------------------------------------------+
          |----------------------------------------|  G E N E R A L  |----------------------------------------|
          +---------------------------------------------------------------------------------------------------+-->
      <div *ngSwitchCase="2">
        <div class="form-group has-feedback">
          <label class="control-label">Número de serie</label>
          <span class="glyphicon glyphicon-search form-control-feedback" aria-hidden="true"></span>
          <input [(ngModel)]="searchItemSelected"
                 [typeaheadAsync]="true"
                 [typeahead]="dataSource"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 [typeaheadOptionsLimit]="7"
                 typeaheadWaitMs="1000"
                 typeaheadOptionField="serialNumber"
                 placeholder="Buscar…"
                 class="form-control"
                 name="serial"
                 autocomplete="off">
          <div *ngIf="typeaheadLoading">Loading</div>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Auto</label>
          <div class="input-group">
            {{lclSerialSelected.description || '-------------------'}}
          </div>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Asunto</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.subject" name="subject" required>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Descripción</label>
          <textarea rows="3" class="form-control" [(ngModel)]="dataToSend.description" name="description" required></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="text-center">
      <button type="submit" class="btn btn-sm btn-custom btn-block" form="requestForm" >Reportar</button>
  </div>
</div>
