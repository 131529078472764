<div style="position:relative;width: 340px;padding: 20px;height: 100%" *ngIf="debtorlifeban">
  <div style="height: 126px;width: 100%; text-align: center;">
    <span class="sx-iconsys-icon" aria-hidden="true" style="float: right;font-size: 22px;font-weight: normal;color: #C4C4C4;cursor: pointer;" (click)="shared.fCloseSidebar()">x</span>
    <p style="color: #448AA6;font-size: 16px;font-weight: 600;line-height: 19px;padding-top: 33px;padding-bottom: 33px">
      Vida Deudor
    </p>
    <div style="text-align: left">
      <span style="display: inline-block;vertical-align: middle;color: #326173;"></span><span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 235px;border-top: 1px solid #E1E1E1;"></span>
    </div>
  </div>

  <div style="position: relative;min-height: calc(100% - 160px);width: 100%;color: #326173;">
    <div class="form-group form-group-sm" >

      <div  class="form-group" dropdown [dropup]="true" style="position:absolute;">
        <!--<button class="btn btn-custom btn-sm btn-block" (click)="invokeServiceForGetLayoutMassiveLoadInsured()"><i class="fa fa-file-download"></i>&nbsp;Descargar</button>-->
        <label class="control-label">Mes a cargar</label>
        <button id="button-data-types-debtor" dropdownToggle type="button" class="btn btn-md btn-link dropdown-toggle subtitle-section-menu" aria-controls="dropdown-data-types-reports">
          {{lclDataTypeForDebtorLifeSelected.monthName}} <span class="sx-iconsys-icon">1</span>
        </button>
        <ul id="dropdown-data-types-debtorlife" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu" aria-labelledby="button-data-types-reports">
          <li role="menuitem" *ngFor="let item of debtorlifeDataListMonths" >
            <a class="dropdown-item" href="javascript:;" (click)="changeLclDataType(item)">{{item.monthName}}</a>
          </li>
        </ul>
      </div>
    </div>
  <br/><br/>
    <form id="requestForm" #f="ngForm" (ngSubmit)="sendDebtorLifeData()" [noValidate]="false">
      <div class="form-group form-group-sm">
        <label class="control-label">Cargar layout</label>
        <div class="form-group">
          <label class="btn btn-custom btn-sm btn-block"><i class="fa fa-folder-open"></i>&nbsp;Buscar...
            <input type="file" style="display: none" (change)="onChangeInputFile($event)" required>
          </label>
        </div>
      </div>

      <div class="form-group form-group-sm">
        <label class="control-label">Nombre de archivo</label>
        <div class="form-group">
          <label class="text-primary" *ngIf="fileUpload == undefined" [hidden]="error.active">----------</label>
          <label class="text-primary" style="width: 100%" *ngIf="fileUpload != undefined" [hidden]="error.active">
            {{ fileUpload.name }} <span class="pull-right">{{shared.formatBytes(fileUpload.size,2)}}</span>
          </label>
          <label class="text-danger" [hidden]="!error.active">{{ error.msg }}</label>
        </div>
      </div>

      <div class="form-group form-group-sm">
        <label class="control-label" *ngIf="lclDataTypeForDebtorLifeSelected.monthLoad && !lclDataTypeForDebtorLifeSelected.executiveLoad">Ya se ha subido el archivo del mes, si realiza de nuevo la operación se remplazara.</label>
        <label class="control-label" *ngIf="lclDataTypeForDebtorLifeSelected.executiveLoad">Ya no se puede subir el archivo.</label>
      </div>

    </form>
  </div>
  <div class="text-center">
    <button type="submit" class="btn btn-sm btn-custom btn-block" form="requestForm" *ngIf="!lclDataTypeForDebtorLifeSelected.executiveLoad" >Subir</button>
  </div>
</div>
