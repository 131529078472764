import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared-service.service';
import {WebsocketService} from '../websocket.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {NotificationMdlComponent} from '../notification-mdl/notification-mdl.component';
import {ApiService} from '../api.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  roleId = Number(localStorage.getItem('roleId')); // Rol del usuario
  user: any = {}; // Variable donde se almacena el perfil del usuario
  lclShowAdminAccess: boolean;
  lclShowUserAccess: boolean;
  lclShowDashboardMenu: boolean;
  notificationList: any = [];
  bsModalRef: BsModalRef;
  notificationCount;
  constructor(
    private api: ApiService,
    public sharedService: SharedService,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private wsRocket: WebsocketService
  ) {}

  ngOnInit() {
    this.user.imageProfile = this.sharedService.gProfileImg;
    this.user.name = this.sharedService.gProfileName;
    this.user.position = this.sharedService.gProfilePosition;
    this.lclShowAdminAccess = false;
    this.lclShowUserAccess = false;
    this.sharedService.gUserRoles.forEach((item, index) => {
      if (item.roleId === 3 && this.sharedService.gCurrentRoleId !== 3) {
        this.lclShowAdminAccess = true;
        this.lclShowUserAccess = false;
      } else if (item.roleId === 4 && this.sharedService.gCurrentRoleId === 3) {
        this.lclShowUserAccess = true;
        this.lclShowAdminAccess = false;
      }
      if (this.sharedService.gCurrentRoleId === 3) {
        this.lclShowDashboardMenu = true;
      }
    });
    this.getNotificationUser();
    this.getcountNotification();
  }

  closeSession() {
    localStorage.clear();
    this.sharedService.fStreamRoomMessages.next(false);
    this.wsRocket.disconnectToHost();
    // this.router.navigate(['/login']).then();
    window.open('/', '_self');
  }

  goToAdminProfile() {
    this.lclShowAdminAccess = false;
    this.lclShowUserAccess = true;
    localStorage.setItem('roleId', '3');
    this.router.navigate(['/admin/home']);
  }
  goToUserProfile() {
    this.lclShowAdminAccess = true;
    this.lclShowUserAccess = false;
    localStorage.setItem('roleId', '4');
    this.router.navigate(['/user/home']);
  }

  private getNotificationUser() {
    this.api.getNotificationUser().then((data) => {
        this.notificationList = data;
      }, error => {
        console.error(error);
      }
    );
  }

  private getcountNotification() {
    this.api.getcountNotification().then((data) => {
        if (data === null) {
          this.notificationCount = 0;
        } else {
          this.notificationCount = data;
        }
      }, error => {
        console.error(error);
        if (error.status === 204) {
          this.notificationCount = 0;
        }
      }
    );
  }

  modalnotification(notication) {
    const initialState = {
      list: [
        'Open a modal with component',
        'Pass your data',
        'Do something else',
        '...'
      ],
      title: 'Modal with component',
      notification: notication
    };
    this.bsModalRef = this.modalService.show(NotificationMdlComponent, {initialState});
    this.bsModalRef.setClass('modal-md');
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.getNotificationUser();
        this.getcountNotification();
      } else if (result === false) {
        console.info('Modal cancel');
      } else {
        console.info('Modal close');
      }
    });
  }

  deletenotification(notification) {
    this.api.deleteNotification(notification.id_notification).subscribe( response => {
      this.getNotificationUser();
    }, error => {
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
    });
  }
}
