import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-dashboard-payments',
  templateUrl: './dashboard-payments.component.html',
  styleUrls: ['./dashboard-payments.component.css']
})
export class DashboardPaymentsComponent implements OnInit {

  receiptList: any = [];
  statusList: any = [];
  policyList: any = [];
  statusId: number;
  policyId: number;
  dataFilterReceipt: any = {};
  pageReceipt: number;
  totalRecordsReceipt: number;
  branchId: number;
  dataBranchGroup: any = [];
  constructor(private router: Router,
              private api: ApiService,
              public shared: SharedService,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public fb: FormBuilder) { }

  ngOnInit(): void {
    this.invokeServiceGetBranchesByGroupClients();
  }
  invokeServiceGetBranchesByGroupClients() {
    this.api.getBranchesByGroupClient().subscribe(
      (response: any) => {
        this.dataBranchGroup = response;
        this.invokeFunctionGetReceipts(this.dataBranchGroup[0].idBranch);
      }, error => {
        this.toastr.info('Sin pólizas activas');
      }
    );
  }

  invokeFunctionGetReceipts(idBranch: number) {
    this.statusId = 0;
    this.policyId = 0;
    this.policyList = [];
    this.statusList = [];
    this.pageReceipt = 1;
    this.branchId = idBranch;
    this.receiptList = [];
    this.dataFilterReceipt = {
      branchId: idBranch,
      statusId: this.statusId,
      policyId: this.policyId,
      page: this.pageReceipt,
      size: 6
    };
    this.invokeServiceForGetReceiptsList();
    this.invokeServiceGetStatusReceipt();
    this.invokeServiceGetPolicyReceiptByBranch(idBranch);
  }

  invokeServiceForGetReceiptsList() {
    this.dataFilterReceipt.page = this.pageReceipt;
    this.api.getReceiptsByBranch(this.dataFilterReceipt)
      .then(
        (response: any) => {
          if (response.total > 0) {
            this.totalRecordsReceipt = response.total;
            this.receiptList = this.receiptList.concat(response.receipts);
          }
        }, error => {
          console.error(error);
        });
  }
  invokeServiceGetStatusReceipt() {
    this.api.getStatusReceipts()
      .then(
        (response: any) => {
          this.statusList = response;
        }, error => {
          console.error(error);
        });
  }

  invokeServiceGetPolicyReceiptByBranch(idBranch: number) {
    const dataRequest = {
      branchId:  idBranch
    };
    this.api.getPoliciesByBranch(dataRequest)
      .then(
        (response: any) => {
          this.policyList = response;
        }, error => {
          console.error(error);
        });
  }
  invokeFunctionLoadChangeFilter() {
    this.receiptList = [];
    this.pageReceipt = 1;
    this.invokeServiceForGetReceiptsList();
  }
  onScrollReceipts() {
    if (this.totalRecordsReceipt === this.receiptList.length) {
      return;
    }
    this.pageReceipt++;
    this.invokeServiceForGetReceiptsList();
  }

  public invokeServiceForDownloadFileReceipt() {
    this.api.getFileReceiptDownload(this.dataFilterReceipt)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  'recibos.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForDownloadReceiptAttachmentZipFile(receipt) {
    const dataRequest = {
      receiptId: receipt.receiptId
    };
    this.api.getReceiptAttachmentZipFileAmmia(dataRequest)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', `recibos_poliza_${receipt.numberPolicy}.zip`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        });
  }

}
