<div style="position:relative;width: 340px;padding: 20px;height: 100%">
  <div style="height: 126px;width: 100%; text-align: center;">
    <span class="sx-iconsys-icon" aria-hidden="true" style="float: right;font-size: 22px;font-weight: normal;color: #C4C4C4;cursor: pointer;" (click)="shared.fCloseSidebar()">x</span>
    <p style="color: #448AA6;font-size: 16px;font-weight: 600;line-height: 19px;padding-top: 33px;padding-bottom: 33px">
      Solicitudes Masivas Beneficios
    </p>
    <div style="text-align: left">
      <span style="display: inline-block;vertical-align: middle;color: #326173;">Solicitud</span><span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 235px;border-top: 1px solid #E1E1E1;"></span>
    </div>
  </div>

  <div style="position: relative;min-height: calc(100% - 160px);width: 100%;color: #326173;">
    <div class="form-group form-group-sm">
      <label class="control-label">Descargar layout</label>
      <div class="form-group">
        <button class="btn btn-custom btn-sm btn-block" (click)="invokeServiceForGetLayoutMassiveLoadInsured()"><i class="fa fa-file-download"></i>&nbsp;Descargar</button>
      </div>
    </div>

    <form id="requestForm" #f="ngForm" (ngSubmit)="sendRequestData()" [noValidate]="false">
      <div class="form-group form-group-sm">
        <label class="control-label">Cargar layout</label>
        <div class="form-group">
          <label class="btn btn-custom btn-sm btn-block"><i class="fa fa-folder-open"></i>&nbsp;Buscar...
            <input type="file" style="display: none" (change)="onChangeInputFile($event)" required>
          </label>
        </div>
      </div>

      <div class="form-group form-group-sm">
        <label class="control-label">Nombre de archivo</label>
        <div class="form-group">
          <label class="text-primary" *ngIf="fileUpload == undefined" [hidden]="error.active">----------</label>
          <label class="text-primary" style="width: 100%" *ngIf="fileUpload != undefined" [hidden]="error.active">
            {{ fileUpload.name }} <span class="pull-right">{{shared.formatBytes(fileUpload.size,2)}}</span>
          </label>
          <label class="text-danger" [hidden]="!error.active">{{ error.msg }}</label>
        </div>
      </div>
    </form>
    <div style="text-align: left">
      <span style="display: inline-block;vertical-align: middle;color: #326173;">Tus solicitudes precargadas</span><span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 100px;border-top: 1px solid #E1E1E1;"></span>
    </div>
    <div id="benefits">
      <table class="table table-condensed table-striped">
        <thead>
        <th>Fecha creación</th>
        <th></th>
        </thead>
        <tbody>
        <tr *ngFor="let reqTemp of listRequestTemp" style="cursor: pointer"  >
          <td>{{reqTemp.fechaRequestCreated | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
          <td>
            <button type="button" class="btn btn-xs btn-custom pull-right " title="ELIMINAR" (click)="openModalDeleteRegister(templateDeleteRegister, reqTemp.idRequestTemp)">
              <i class="glyphicon glyphicon-erase"></i>
            </button>
            <button type="button" class="btn btn-xs btn-custom pull-right " title="VER DETALLES" (click)="seeRequestTempDetail(reqTemp.idRequestTemp)">
            <i class="glyphicon glyphicon-eye-open"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="text-center">
    <button type="submit" class="btn btn-sm btn-custom btn-block" form="requestForm" >Cargar</button>
  </div>
</div>

<ng-template #templateDeleteRegister>
  <div class="modal-body text-center">
    <p>¿ESTÁ SEGURO DE ELIMINAR EL REGISTRO?</p>
    <button type="button" class="btn btn-custom  btn-srx" (click)="deleteRequestTemp()" >SÍ</button>
    <button type="button" class="btn btn-custom  btn-srx" (click)="closeModal()" >NO</button>
  </div>
</ng-template>
