import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {DatePipe} from '@angular/common';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-vehicle-request',
  templateUrl: './vehicle-request.component.html',
  styleUrls: ['./vehicle-request.component.css']
})
export class VehicleRequestComponent implements OnInit {

  lclRequestType: number; // Almacena el tipo de Solicitud que se enviará
  dataToSend: any = {}; // Almacena los datos de envío
  // Variables para el multiselect de Pólizas ->
  dropdownSettingsPolicies = {};
  policiesList: any = []; // Almacena la lista de pólizas retornada por el servicio
  policySelected: any = [];
  // Objetos para los DatePickers ->
  bsEntryDate: Partial<BsDatepickerConfig>;
  fileUpload: File;
  fileB64: string;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];
  disableInvoice = true;

  // Variables para typeahead (búsqueda de serie) ->
  searchItemSelected: string;
  typeaheadLoading: boolean;
  dataSource: Observable<any>;
  lclSerialSelected: any = {};

  lclVehiclePolicy: any = {};

  constructor(public shared: SharedService,
              private api: ApiService,
              private datePipe: DatePipe,
              private toastr: ToastrService) {
    /**
     * Observable para monitorear los cambios en typeahead de Número de Serie
     */
    this.dataSource = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.searchItemSelected);
    }).pipe(
      mergeMap((token: string) => this.getSerialAsObservable(token))
    );
  }

  ngOnInit() {
    this.lclRequestType = 0; // Por default mostramos el registro de solitud General
    // Inicializamos configuración para dropdown de Pólizas ->
    this.dropdownSettingsPolicies = {
      singleSelection: true,
      idField: 'policyId',
      textField: 'number',
      selectAllText: 'Todas',
      unSelectAllText: 'Ninguna',
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'No se encontraron pólizas',
      searchPlaceholderText: 'Buscar',
      closeDropDownOnSelection: true
    };
    // Iniciamos configuración para DatePicker de Fecha de Alta ->
    this.bsEntryDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      minDate: new Date()
    });

    this.invokeServiceForGetVehiclePolicies();
  }

  /**
   * Método para cambiar variables o invocar servicios según el tipo de registro que se realizará
   *
   * @param item [number] Identificador el tipo de Solicitud
   * 0 = Alta
   * 1 = Baja
   * 2 = General
   */
  public selectRequestType(item) {
    this.lclRequestType = item;
    this.dataToSend = {};
    this.policySelected = [];
    this.policiesList = [];
    this.searchItemSelected = undefined;
    this.lclVehiclePolicy = {};
    this.lclSerialSelected = {};
    this.fileUpload = undefined;

    switch (this.lclRequestType) {
      case 0:
        this.invokeServiceForGetVehiclePolicies();
        break;
      case 1:
        // TODO
        break;
      case 2:
        // TODO
        break;
    }
  }

  /**
   * Cambia el elemento typeahead para búsqueda de 'Asegurados' a modo 'Loading'
   * @param e
   */
  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.lclSerialSelected = e.item;
    this.invokeServiceForGetVehiclePolicyByCarId();
  }

  private invokeServiceForGetVehiclePolicyByCarId() {
    this.api.getVehiclePolicyByCarId(this.lclSerialSelected.carId)
      .subscribe(
        response => {
          this.lclVehiclePolicy = response;
        },  error => {
          console.error(error);
        }
      );
  }
  private invokeServiceForGetVehiclePolicies() {
    this.api.getVehiclePolicies()
      .subscribe(
        (data) => {
          this.policiesList = data;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  public onSelectPolicy() {
    console.info(this.policySelected);
  }

  onChangeInputFile(e) {
    const files = e.target.files;
    this.fileUpload = files[0];

    this.error = {active: false, msg: ''};
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }

    if (files && this.fileUpload) {
      const reader = new FileReader();

      reader.onload = (readerEvt: any) => {
        const binaryString = readerEvt.target.result;
        this.fileB64 = btoa(binaryString);
      };

      reader.readAsBinaryString(this.fileUpload);
    }
  }
  public sendRequestData() {
    switch (this.lclRequestType) {
      case 0:
        const objToSend0 = {
          policyId: this.policySelected[0].policyId,
          mark: this.dataToSend.brand,
          model: this.dataToSend.model,
          version: this.dataToSend.version,
          engineNumber: this.dataToSend.engine,
          serialNumber: this.dataToSend.serialNumber,
          startDate: this.datePipe.transform(this.dataToSend.entryDate, 'yyyy-MM-dd'),
          invoicePath: this.fileB64
        };

        this.api.postRequestCarAdd(objToSend0)
          .subscribe(
            response => {
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              this.shared.fUpdateRequests.next(true);
              this.shared.fCloseSidebar();
            }, error => {
              console.error(error);
            }
          );
        break;
      case 1:
        const objToSend1 = {
          carId: this.lclSerialSelected.carId,
          policyId: this.lclVehiclePolicy.policyId,
          startDate: this.datePipe.transform(this.dataToSend.entryDate, 'yyyy-MM-dd')
        };
        console.info(objToSend1);
        this.api.postCancellationVehicleRequestData(objToSend1)
          .subscribe(
            response => {
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              this.shared.fUpdateRequests.next(true);
              this.shared.fCloseSidebar();
            }, error => {
              console.error(error);
            }
          );
        break;
      case 2:
        const objToSend2 = {
          carId: this.lclSerialSelected.carId,
          subject: this.dataToSend.subject,
          description: this.dataToSend.description
        };
        console.info(objToSend2);
        this.api.postGeneralVehicleRequestData(objToSend2)
          .subscribe(
            response => {
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              this.shared.fUpdateRequests.next(true);
              this.shared.fCloseSidebar();
            }, error => {
              console.error(error);
            }
          );
        break;
    }
  }

  /**
   * Función para recuperar las coincidencias de la búsqueda en el typeahead de 'Número de Serie'
   *
   * 1.- Invocamos el servicio REST @getFinderInsured y retornamos la respuesta
   * @param token [string] Filtro para realizar la búsqueda
   */
  getSerialAsObservable(token: string): Observable<any> {
    return this.api.getFinderVehicleSerial(token).pipe();
  }

  setInvoiceRequire() {
    if (this.dataToSend.model >= new Date().getFullYear()) {
      this.disableInvoice = false;
      this.error = {active: true, msg: 'Seleccione la factura'};
    } else {
      this.disableInvoice = true;
      this.error = {active: false, msg: ''};
    }
  }
}
