import {Component, OnInit} from '@angular/core';
import {Event as NavigationEvent, NavigationEnd, Router} from '@angular/router';
import {SharedService} from '../shared-service.service';

import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ProfileComponent} from '../profile/profile.component';
import {ApiService} from '../api.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  lclContentSidebar: number;

  dashboard: string;
  policies: string;
  incidents: string;
  pgNotFound: string;
  bsModalRef: BsModalRef;
  user: any = {};
  bandera: boolean;
  constructor(private router: Router,
              private shared: SharedService,
              private modalService: BsModalService,
              private api: ApiService) {
    this.bandera = false;
    this.router = router;
  }

  ngOnInit() {
    this.user = {
      imageProfile: '../../assets/img/userprofile.png',
      name: '<i>Nombre de usuario</i>',
      position: '<i>Puesto de usuario</i>'
    };

    /**
     * Observable para habilitar el componente que se debe mostrar dentro del sidebar
     * 0 = Cuenta -> ProfileComponent
     * 1 = Chat -> ChatComponent
     * 2 = NewIncident -> NewIncidentComponent
     * 3 = NewRequest -> NewRequestComponent
     * @param filter [number]Continene el identificador del filtro
     */
    this.shared.fContentSidebarObservable().subscribe(
      sbId => this.lclContentSidebar = sbId
    );

    this.dashboard = 'Inicio';
    this.policies = 'Pólizas';
    this.incidents = 'Siniestros';
    this.pgNotFound = 'Página no encontrada';

  }




}
