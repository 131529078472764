<div class="modal-dialog modal-dialog-centered justify-content-center">
  <div class="modal-content evento" *ngIf="requestId >0">
    <div class="modal-header evento">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="invokeFunctionCloseRequestDetail()"></button>
      <div class="d-column-start gap-8 w-100">
        <div class="d-between">
          <h3 class="d-flex gap-8 modal-title " id="verEventoLabel">
            <img *ngIf="requestDetail.requestTypeId == 1" src="../../assets/img/ammia/iconos/plus-azul-primario.svg" alt="reunion-presencial">
            <img *ngIf="requestDetail.requestTypeId == 2" src="../../assets/img/ammia/iconos/minus-rojo.svg" alt="reunion-presencial">
            <img *ngIf="requestDetail.requestTypeId == 3" src="../../assets/img/ammia/iconos/user-turquesa.svg" alt="reunion-presencial">
            {{requestDetail.movement | titlecase}}
          </h3>
          <div [ngClass]="requestDetail.statusId == 3 ? 'tag green' : requestDetail.statusId == 4 ? 'tag red' : 'tag yellow' " data-bs-toggle="tooltip" data-bs-placement="top" title="{{requestDetail.status}}">
            {{requestDetail.status | titlecase}}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="requestDetail.statusId == 3">
              <path d="M8.76187 3.49403L5.11593 7.16393L3.7634 5.76587L2.99893 6.52315L4.58668 8.15422C4.64549 8.21247 4.7631 8.27073 4.88071 8.32898C4.99832 8.38723 5.11593 8.38723 5.17474 8.38723C5.23354 8.38723 5.40996 8.38723 5.46876 8.32898C5.58637 8.27073 5.64518 8.21247 5.76279 8.15422L9.58514 4.25131L8.76187 3.49403Z" />
              <path d="M10.291 1.74757C9.17365 0.640777 7.64471 0 5.99815 0C4.41041 0 2.88147 0.640777 1.76416 1.74757C0.64686 2.91262 0 4.36893 0 6C0 7.57282 0.64686 9.08738 1.76416 10.2524C2.88147 11.3592 4.41041 12 5.99815 12C7.5859 12 9.11484 11.3592 10.2321 10.2524C11.3494 9.14563 11.9963 7.63107 11.9963 6C12.0551 4.36893 11.4083 2.91262 10.291 1.74757ZM6.05696 10.835C3.35191 10.835 1.17611 8.62136 1.17611 5.94175C1.17611 3.26214 3.35191 1.04854 6.05696 1.04854C8.76201 1.04854 10.9378 3.26214 10.9378 5.94175C10.9378 8.67961 8.76201 10.835 6.05696 10.835Z" />
            </svg>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="requestDetail.statusId != 3 && requestDetail.statusId != 4">
              <path d="M6 0C4.52275 0.00105318 3.09806 0.548311 2 1.5365V0.5C2 0.367392 1.94732 0.240215 1.85355 0.146447C1.75978 0.0526784 1.63261 0 1.5 0C1.36739 0 1.24021 0.0526784 1.14645 0.146447C1.05268 0.240215 1 0.367392 1 0.5V2C1 2.39782 1.15804 2.77935 1.43934 3.06066C1.72064 3.34196 2.10217 3.5 2.5 3.5H4C4.13261 3.5 4.25978 3.44732 4.35355 3.35355C4.44732 3.25978 4.5 3.13261 4.5 3C4.5 2.86739 4.44732 2.74021 4.35355 2.64645C4.25978 2.55268 4.13261 2.5 4 2.5H2.5C2.48314 2.4975 2.46644 2.49399 2.45 2.4895C3.26476 1.66891 4.33906 1.15654 5.48953 1.03983C6.64 0.923127 7.79531 1.20933 8.75826 1.84958C9.72121 2.48983 10.4321 3.44445 10.7696 4.55047C11.1071 5.6565 11.0502 6.84538 10.6088 7.91417C10.1673 8.98296 9.36861 9.8654 8.34897 10.4109C7.32934 10.9563 6.15199 11.131 5.01791 10.9051C3.88382 10.6792 2.86329 10.0667 2.13053 9.17209C1.39776 8.27753 0.998185 7.15637 1 6C1 5.86739 0.947321 5.74021 0.853553 5.64645C0.759785 5.55268 0.632608 5.5 0.5 5.5C0.367392 5.5 0.240215 5.55268 0.146447 5.64645C0.0526784 5.74021 0 5.86739 0 6C0 7.18669 0.351894 8.34672 1.01118 9.33342C1.67047 10.3201 2.60754 11.0891 3.7039 11.5433C4.80025 11.9974 6.00665 12.1162 7.17054 11.8847C8.33443 11.6532 9.40352 11.0818 10.2426 10.2426C11.0818 9.40352 11.6532 8.33443 11.8847 7.17054C12.1162 6.00665 11.9974 4.80025 11.5433 3.7039C11.0891 2.60754 10.3201 1.67047 9.33342 1.01118C8.34672 0.351894 7.18669 0 6 0Z" />
              <path d="M6.00017 3C5.86756 3 5.74038 3.05268 5.64662 3.14645C5.55285 3.24021 5.50017 3.36739 5.50017 3.5V6C5.5002 6.1326 5.55289 6.25975 5.64667 6.3535L7.14667 7.8535C7.24097 7.94458 7.36727 7.99497 7.49837 7.99383C7.62947 7.9927 7.75487 7.94011 7.84758 7.84741C7.94028 7.7547 7.99287 7.6293 7.99401 7.4982C7.99514 7.3671 7.94475 7.2408 7.85367 7.1465L6.50017 5.793V3.5C6.50017 3.36739 6.44749 3.24021 6.35372 3.14645C6.25995 3.05268 6.13278 3 6.00017 3Z" />
            </svg>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="requestDetail.statusId == 4">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6 1.15254C3.32282 1.15254 1.15254 3.32282 1.15254 6C1.15254 8.67718 3.32282 10.8475 6 10.8475C8.67718 10.8475 10.8475 8.67718 10.8475 6C10.8475 3.32282 8.67718 1.15254 6 1.15254ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0346 3.9654C8.25965 4.19044 8.25965 4.55532 8.0346 4.78037L4.78037 8.0346C4.55532 8.25965 4.19044 8.25965 3.9654 8.0346C3.74035 7.80956 3.74035 7.44468 3.9654 7.21963L7.21963 3.9654C7.44468 3.74035 7.80956 3.74035 8.0346 3.9654Z" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9654 3.9654C4.19044 3.74035 4.55532 3.74035 4.78037 3.9654L8.0346 7.21963C8.25965 7.44468 8.25965 7.80956 8.0346 8.0346C7.80956 8.25965 7.44468 8.25965 7.21963 8.0346L3.9654 4.78037C3.74035 4.55532 3.74035 4.19044 3.9654 3.9654Z" />
            </svg>
          </div>

        </div>
        <h5>{{requestDetail.subBranch | titlecase}}</h5>
      </div>

    </div>
    <div class="modal-body">
      <div class="datos-grupo">
        <div class="datos-row">
          <div>
            <p class="label">Número de folio</p>
            <p class="dato">{{requestDetail.folio}}</p>
          </div>
        </div>
        <div class="datos-row">
          <div>
            <p class="label">Solicitante</p>
            <p class="dato">{{requestDetail.applicantName | titlecase}}</p>
          </div>
          <div>
            <p class="label">Póliza</p>
            <p class="dato">{{requestDetail.number}}</p>
          </div>
        </div>
        <div class="datos-row">
          <div>
            <p class="label" *ngIf="_branchId == 1 && (requestDetail.requestTypeId == 1 || requestDetail.requestTypeId == 2)">Asegurado</p>
            <p class="label" *ngIf="_branchId == 3 && (requestDetail.requestTypeId == 1 || requestDetail.requestTypeId == 2)">Vehículo</p>
            <p class="label" *ngIf="requestDetail.requestTypeId == 3">Asunto</p>
            <p class="dato">{{requestDetail.description | titlecase }}</p>
          </div>
          <div>
            <p class="label">Tipo de asegurado</p>
            <p class="dato">{{requestDetail.type}}</p>
          </div>
        </div>
        <div class="datos-row">
          <div>
            <p class="label">Razón social</p>
            <p class="dato">{{requestDetail.client}}</p>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
