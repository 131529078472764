<div id="sidebar" class="sidebar">
  <app-profile *ngIf="lclContentSidebar == 0"></app-profile>
  <!--<app-chat [hidden]="lclContentSidebar != 1"></app-chat>-->
  <app-new-incident *ngIf="lclContentSidebar == 2"></app-new-incident>
  <app-new-request *ngIf="lclContentSidebar == 3"></app-new-request>
  <app-policy *ngIf="lclContentSidebar == 4"></app-policy>
  <app-incident *ngIf="lclContentSidebar == 5"></app-incident>
  <app-request *ngIf="lclContentSidebar == 6"></app-request>
  <app-massive-request *ngIf="lclContentSidebar == 7"></app-massive-request>
  <app-vehicle-request *ngIf="lclContentSidebar == 8"></app-vehicle-request>
  <app-massive-request-vehicle *ngIf="lclContentSidebar == 10"></app-massive-request-vehicle>
  <app-debtorlife *ngIf="lclContentSidebar == 13"></app-debtorlife>
  <app-debtorlife-validation *ngIf="lclContentSidebar == 14"></app-debtorlife-validation>
  <app-new-request-car *ngIf="lclContentSidebar == 15"></app-new-request-car>

  <!--+-----------------------------------------------------------------------------------+
      |-----------------------|  C O N C E S I O N A R I O S  |---------------------------|
      +-----------------------------------------------------------------------------------+-->

  <app-quote-detail *ngIf="lclContentSidebar == 9"></app-quote-detail>
  <app-quote-form  *ngIf="lclContentSidebar == 11"></app-quote-form>
  <app-quote-history *ngIf="lclContentSidebar == 12"></app-quote-history>
  <app-menu *ngIf="lclContentSidebar == 20"></app-menu>
</div>




