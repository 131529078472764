import {Component, HostListener, OnInit} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {SharedService} from '../shared-service.service';
import {timeout} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';

const API_REST = environment.api_rest;
const URL_PDF_INCIDENT = API_REST + '/policies/attachments/certificate/free/';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  URL_PDF_INCIDENT = URL_PDF_INCIDENT;
  USER_ID = localStorage.getItem('user-id');
  // -Varibales para paginado de Siniestros->
  pgIncidentBoundaryLinks = false; // Bandera que indidca si se muestra Inicio/Fin del paginado
  pgIncidentMaxSize = 3; // Número de links en paginado
  pgIncidentItemsPerPage = 4; // Número de registros por página
  pgIncidentTotalItems: number; // Total de registros
  pgIncidentCurrentPage = 1; // Página actual
  // -Varibales para paginado de Siniestro<-

  // -Varibales para paginado de Solicitudes->
  pgRequestBoundaryLinks = false; // Bandera que indidca si se muestra Inicio/Fin del paginado
  pgRequestMaxSize = 3; // Número de links en paginado
  pgRequestItemsPerPage = 4; // Número de registros por página
  pgRequestTotalItems: number; // Total de registros
  pgRequestCurrentPage = 1; // Página actual
  // -Varibales para paginado de Solicitudes<-

  idFlipCard: number; // -Almacena el Id de la póliza para mostrar su reverso
  lclPolicyDetail: any = {}; // -Almacena el detalle de la póliza al consultar el reverso

  countPolicy: any;
  listPolicies: any = [];
  listIncidents: any = [];
  listtypepolicy: any = [];
  listtypeinciden: any = [];
  lclPolicyType: any = {};
  lclIncidentType: any = {};
  policyType;
  policyId;
  bsModalRef: BsModalRef;
  incidentGridViewType: boolean;
  requestGridViewType: boolean;

  incidentsList: any = [];

  statusCatRequestsList: any = [];
  lclStatusRequestSelected: any = {};
  requestList: any = [];
  widthSize: number = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.widthSize = window.innerWidth;
  }

  constructor(private router: Router,
              private api: ApiService,
              private modalService: BsModalService,
              public shared: SharedService) {
    this.shared.pgInuredObservable().subscribe(
      fInsured => {
        if (fInsured) {
          // console.info('ACTUALIZA VIEW');
          this.incidentGridViewType = true;
          this.requestGridViewType = true;
          this.getPolicySize();
          this.gettypepolicyuser();

          this.invokeServiceForGetStatusCatRequests();
        }
      }
    );
    this.shared.fUpdateIncidentsObservable().subscribe(
      fIncidents => {
        if (fIncidents) {
          // console.info('ACTUALIZA LOS ISNIESTROS');
          this.getIncidentuser(this.lclPolicyType.typeId);
          this.loadincidents(this.lclIncidentType);
          this.shared.fUpdateIncidents.next(false);
        }
      }
    );
    this.shared.fUpdateRequestsObservable().subscribe(
      fRequests => {
        if (fRequests) {
          // console.info('ACTUALIZA LAS SOLICICTUDES');
          this.invokeServiceForGetRequests(this.lclStatusRequestSelected);
          this.shared.fUpdateIncidents.next(false);
        }
      }
    );

    this.shared.fInsuredDataReadonly = this.router.url === '/admin/insured';
  }

  ngOnInit() {
    this.incidentGridViewType = true;
    this.requestGridViewType = true;
    this.getPolicySize();
    this.gettypepolicyuser();

    this.invokeServiceForGetStatusCatRequests();
  }

  flipThisCard(policyId, event) {
    event.stopPropagation();
    this.lclPolicyDetail = {};
    if (this.idFlipCard === policyId) {
      this.idFlipCard = undefined;
    } else {
      this.idFlipCard = policyId;
      this.getPolicyDetail(policyId);
    }
  }

  getPolicyDetail(policyId) {
    this.api.getDetailPolicy(policyId)
      .then(
        (data) => {
          this.lclPolicyDetail = data;
        }, error => {
          console.error(error);
        });
  }

  getPolicySize() {
    this.api.getPoliciesSize()
      .then(
        (data) => {
          this.countPolicy = data;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  invokeServiceForGetPolicyCertificateFile(policy) {
    this.api.getPolicyCertificate(policy.policyId).
      subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  `certificado_poliza_${policy.number}.pdf`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }
/*
  getThumbailtop() {
    this.api.getUserPolicies()
      .then(
        (data) => {
          this.listPolicies = data;
          this.loadpolicy(this.listPolicies[0].typeId);
          // console.log(this.listPolicies);
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }
 */

  getIncidentuser(typePolicyId) {
    this.api.getUserincident(typePolicyId)
      .then(
        (data) => {
          this.listIncidents = data;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  gettypepolicyuser() {
    this.api.getTypePolicies()
      .then(
        (data) => {
          this.listtypepolicy = data;
          this.loadpolicy(data[0]);
          this.lclPolicyType = data[0];
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  invoketypeincidents() {
    this.listtypeinciden = [
      {
        id: 0,
        description: 'Todos'
      },
      {
        id: 1,
        description: 'Aceptado'
      },
      {
        id: 2,
        description: 'Pendiente'
      },
      {
        id: 3,
        description: 'Rechazado'
      }
    ];
    this.lclIncidentType = this.listtypeinciden[0];
    this.loadincidents(this.listtypeinciden[0]);
  }

  loadpolicy(policyType) {
    this.lclPolicyType = policyType;
    this.api.getUserPoliciesType(policyType.typeId)
      .then(
        (data) => {
          this.listPolicies = data;
          this.policyType = policyType.typeId;
          this.getIncidentuser(policyType.typeId);
          this.invoketypeincidents();
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  loadincidents(incident) {
    this.lclIncidentType = incident;
    this.api.getUserTypeIncident(incident.id, this.policyType, this.pgIncidentCurrentPage, this.pgIncidentItemsPerPage)
      .subscribe(
        (data) => {
          const oData: any = data;
          this.pgIncidentTotalItems = oData.total;
          this.incidentsList = oData.incidentList;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  /*openModalWithComponent() {
    this.bsModalRef = this.modalService.show(NewIncidentComponent);
    this.bsModalRef.setClass('modal-md');
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.getIncidentuser(this.lclPolicyType.typeId);
        this.loadincidents(this.lclIncidentType);
      } else if (result === false) {
        console.info('Modal cancel');
      } else {
        console.info('Modal close');
      }
    });
  }*/

  setIncidentGridView(value) {
    this.pgIncidentCurrentPage = 1;
    this.pgIncidentItemsPerPage = value ? 4 : 10;
    this.incidentGridViewType = value;
    this.loadincidents(this.lclIncidentType);
  }
  displayPolicyDetail(policyId) {
    this.shared.fOpenSidebar(4);
    this.shared.gPolicyId.next(policyId);
  }

  displayIncidentDetail(incidentId) {
    this.shared.fOpenSidebar(5);
    this.shared.gIncidentId.next(incidentId);
  }

  /**
   * Cálcula el inicio y fin del páginado
   *
   * @param PageChangedEvent event Información del páginadod
   */
  pageIncidentChanged(event: PageChangedEvent): void {
    this.pgIncidentCurrentPage = event.page;
    this.loadincidents(this.lclIncidentType);
  }

  /*----------------------------------------------------------------------------------------------------------------------------------------
   ------------------------------------------------------S O L I C I T U D E S--------------------------------------------------------------
   ---------------------------------------------------------------------------------------------------------------------------------------*/

  invokeServiceForGetStatusCatRequests() {
    this.api.getStatusCatRequests()
      .then(
        (data) => {
          this.statusCatRequestsList = data;
          this.lclStatusRequestSelected = this.statusCatRequestsList[0];
          this.invokeServiceForGetRequests(this.lclStatusRequestSelected);
        }, error => {
          console.error(error);
        });
  }
  invokeServiceForGetRequests(status) {
    this.lclStatusRequestSelected = status;
    this.api.getRequestTypeGeneral(this.lclStatusRequestSelected.statusId, this.pgRequestCurrentPage, this.pgRequestItemsPerPage)
      .subscribe((data) => {
        const oData: any = data;
        this.pgRequestTotalItems = oData.total;
        this.requestList = oData.requestList;
      }, error => {
        console.info(error);
      });
  }
  setRequestGridView(value) {
    this.pgRequestCurrentPage = 1;
    this.pgRequestItemsPerPage = value ? 4 : 10;
    this.requestGridViewType = value;
    this.invokeServiceForGetRequests(this.lclStatusRequestSelected);
  }

  displayRequestDetail(requestId) {
    this.shared.fOpenSidebar(6);
    this.shared.gRequestId.next(requestId);
  }

  /**
   * Cálcula el inicio y fin del páginado
   *
   * @param PageChangedEvent event Información del páginadod
   */
  pageRequestChanged(event: PageChangedEvent): void {
    this.pgRequestCurrentPage = event.page;
    this.invokeServiceForGetRequests(this.lclStatusRequestSelected);
  }


}
