import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';
import { Chart } from 'chart.js/auto';
import {ToastrService} from 'ngx-toastr';
import { registerables} from 'chart.js';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';


Chart.register(...registerables);
declare var $: any;
@Component({
  selector: 'app-dashboard-benefits',
  templateUrl: './dashboard-benefits.component.html',
  styleUrls: ['./dashboard-benefits.component.css']
})
export class DashboardBenefitsComponent implements OnInit {
  policyAmmiaList: any = [];
  policyAmmia: boolean;
  policyInitId: number;
  p: any = {};
  indexPolicy: number;
  subgroupList: any = []; // almacena la lista de Subgrupos, tendra una propiedad 'categoryList' para almacenar la lista de categorías
  subgroupId: number;
  categoryId: number;
  policyCoverageList: any = [];
  categoryList: any = [];
  constructor(private router: Router,
              private api: ApiService,
              public shared: SharedService,
              private toastr: ToastrService,
              private modalService: BsModalService) {
  }

  ngOnInit(): void {
    // tslint:disable-next-line:only-arrow-functions
    $(document).ready(function () {
      const owl = $('#carouselPolizasMaestras');

      owl.owlCarousel({
        items: 1,
        singleItem: true,
        loop: true,
        autoPlay: true,
        nav: false,
        dots: true,
        autoPlayTimeout: 2000,
        responsive: {
          0: {
            items: 1
          },
        }
      });

      // tslint:disable-next-line:only-arrow-functions
      $('.customNextBtnPolizasMaestras').click(function () {
        owl.trigger('next.owl.carousel');
      });

      // tslint:disable-next-line:only-arrow-functions
      $('.customPreviousBtnPolizasMaestras').click(function () {
        owl.trigger('prev.owl.carousel');
      });
    });
    this.indexPolicy = 0;
    this.invokeServiceGetPolicies();
  }

  invokeServiceGetPolicies() {
    this.api.getPoliciesAmmiaDashboardBenefits()
      .then(
        (response: any) => {
          if (response != null) {
            this.policyAmmiaList = response;
            this.policyInitId = this.policyAmmiaList[0].policyId;
            this.policyAmmia = true;
            this.invokeServiceGetPolicyDetail(this.policyInitId);
          }
        }, error => {
          console.info(error);
        });
  }
  invokeServiceGetPolicyDetail(id: number) {
    this.policyInitId = id;
    console.log(id);
    const dataPolicy = {
      policyId: id
    };
    this.api.getPoliciesAmmiaDashboardBenefitsDetail(dataPolicy)
      .then(
        (response: any) => {
          if (response != null) {
            this.p = response[0];
            this.invokeServiceForGetSubgroupsWithCategories(this.p.policyId);
          }
        }, error => {
          console.info(error);
        });
  }
  invokeFunctionNextPolicy() {
    console.log('next-policy');
    if (this.indexPolicy === 0) {
      this.indexPolicy = 1;
    } else if (this.indexPolicy === (this.policyAmmiaList.length - 1)) {
      this.indexPolicy = 0;
    } else {
      this.indexPolicy = this.indexPolicy + 1;
    }
    this.invokeServiceGetPolicyDetail(this.policyAmmiaList[this.indexPolicy].policyId);
  }
  invokeFunctionPreviousPolicy() {
    console.log('prev-policy');
    if (this.indexPolicy === 0) {
      this.indexPolicy = this.policyAmmiaList.length - 1;
    } else if (this.indexPolicy === (this.policyAmmiaList.length - 1)) {
      this.indexPolicy = 0;
    } else {
      this.indexPolicy = this.indexPolicy - 1;
    }
    this.invokeServiceGetPolicyDetail(this.policyAmmiaList[this.indexPolicy].policyId);
  }

  invokeServiceForDownloadPolicyAttachmentZipFile(policy) {
    this.api.getPolicyAttachmentZip(policy.policyId)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  `anexos_poliza_${policy.number}.zip`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForDownloadPolicyAttachmentFile(attachment) {
    this.api.getPolicyAttachmentFile(attachment.attachmentId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForDownloadPolicyFile(policy) {
    this.api.getPolicyFile(policy.policyId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', policy.number + '.pdf');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  public invokeServiceForDownloadFilePolicy() {
    const objData = {
      policyId: this.p.policyId
    };
    this.api.getFileCsvPolicy(objData)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  this.p.number + '.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  private invokeServiceForGetSubgroupsWithCategories(policyId) {
    this.api.getSubgroupsCategoryByPolicy(policyId)
      .subscribe(
        response => {
          if (response != null) {
            this.subgroupList = response;
            this.subgroupId = this.subgroupList[0].subGroupId;
            this.categoryList = this.subgroupList[0].categoryList;
            this.categoryId = this.categoryList[0].categoryId;
            this.invokeServiceForGetPolicyDetailByCategoryId(this.categoryId);
            // this.invokeServiceForGetAmountsDetailByCategoryId(this.lclCategorySelected.categoryId);
          }
        }, error => {
          console.error(error);
        }
      );
  }

  private invokeServiceForGetPolicyDetailByCategoryId(categoryId) {
    this.api.getPolicyDetailByCategoryId(categoryId)
      .subscribe(
        response => {
          this.policyCoverageList = response;
        }, error => {
          console.error(error);
        }
      );
  }

  invokeFunctionChangeTypeSubgroup() {
    console.log(this.subgroupId);
    this.subgroupList.forEach( item => {
      if (item.subGroupId === this.subgroupId) {
        this.categoryList = item.categoryList;
      }
    });
  }

  invokeFunctionChangeTypeCategory() {
    console.log(this.categoryId);
    this.invokeServiceForGetPolicyDetailByCategoryId(this.categoryId);
  }


  invokeFunctionDownloadFilePolicy() {
    this.api.getFilePolicy()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  'Polizas.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }
}
