import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-debtorlife',
  templateUrl: './debtorlife.component.html',
  styleUrls: ['./debtorlife.component.css']
})
export class DebtorlifeComponent implements OnInit {

  // Variables para vida deudor ->
  debtorlifeDataListMonths: any = [];
  lclDataTypeForDebtorLifeSelected: any = [];
  debtorlifeban: boolean;
  statsend: boolean;
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'];

  constructor(public shared: SharedService,
              private api: ApiService,
              private toastr: ToastrService) {
    this.debtorlifeban  = false;
    this.invokeServiceForGetMonthsDebtors();
  }

  ngOnInit() {
    this.statsend = false;
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  sendDebtorLifeData() {
    if (this.statsend) {
      return;
    }
    this.statsend = true;
    if (this.error.active === true || this.fileUpload === undefined) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
      this.statsend = false;
      return;
    }
    const formData = new FormData();
    formData.append('file', this.fileUpload);
    this.api.postLoadLayoutDebtorLife(formData, this.lclDataTypeForDebtorLifeSelected.monthId, this.lclDataTypeForDebtorLifeSelected.year)
      .subscribe(
        response => {
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.shared.fUpdateDebtorLife.next(true);
          this.shared.fCloseSidebar();
        }, error => {
          this.toastr.warning(error.error);
          console.error();
          this.statsend = false;
        }
      );
  }

  changeLclDataType(item) {
    this.lclDataTypeForDebtorLifeSelected = item;
  }
  private invokeServiceForGetMonthsDebtors() {
    this.api.getMonthsdebtors(2)
      .subscribe(
        (response: any) => {
          if (response !== null && response !== undefined && response.length > 0) {
            this.debtorlifeDataListMonths = response;
            /*for (const item of this.debtorlifeDataListMonths) {
              this.lclDataTypeForDebtorLifeSelected = item;
            }*/
            this.debtorlifeban = true;
            this.lclDataTypeForDebtorLifeSelected = this.debtorlifeDataListMonths[0];
          } else {
            this.debtorlifeDataListMonths = [];
          }
        }, error => {
          console.error(error);
        }
      );
  }

}
