<div class="modal-header">
  <h4 class="modal-title pull-left">Notificación</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h4>{{notification.title}}</h4>
  <p>{{notification.description}}</p>
</div>
<div class="modal-footer">
  <button class="btn btn-custom-danger" (click)="deletenotification(notification)">Eliminar</button>
  <button class="btn btn-custom" (click)="onCancel()">Cerrar</button>
</div>
