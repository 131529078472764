import {Component, OnInit, TemplateRef} from '@angular/core';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-massive-request',
  templateUrl: './massive-request.component.html',
  styleUrls: ['./massive-request.component.css']
})
export class MassiveRequestComponent implements OnInit {

  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'];
  statsend: boolean;
  listRequestTemp: any[];
  requestTemp: any;
  modalRef: BsModalRef;
  idRequestTemp: any;
  constructor(public shared: SharedService,
              private api: ApiService,
              private toastr: ToastrService,
              private router: Router,
              private modalService: BsModalService) { }

  ngOnInit() {
    this.statsend = false;
    this.invokeServiceForGetRequestLayoutTemp();

  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  public invokeServiceForGetLayoutMassiveLoadInsured() {
    this.api.getLayoutMassiveLoadInsured()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', 'LAYOUT_SOLICITUDES_BENEFICIOS.xlsx');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForGetRequestLayoutTemp() {
    this.api.getRequestTemp().subscribe(
      (response: any) => {
        this.listRequestTemp = response;

      }, error => {
        console.error(error);
      }
    );
  }

  sendRequestData() {
    if (this.statsend) {
      return;
    }
    this.statsend = true;
    if (this.error.active === true || this.fileUpload === undefined) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
      return;
    }
    console.info(this.fileUpload);
    const formData = new FormData();
    formData.append('file', this.fileUpload);
    this.api.postMassiveLoadLayoutInsured(formData)
      .subscribe(
        response => {
          this.requestTemp = response;
          this.shared.fUpdateRequests.next(true);
          localStorage.removeItem('requestTemp-id');
          localStorage.setItem('requestTemp-id', this.requestTemp.idRequestTemp);
          this.router.navigate(['/admin/request-detail']);
          this.shared.fCloseSidebar();
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          // this.invokeServiceForGetRequestLayoutTemp();
        }, error => {
          console.error();
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
          this.statsend = false;
        }
      );
  }

  seeRequestTempDetail(idRequestTemp) {
    localStorage.removeItem('requestTemp-id');
    localStorage.setItem('requestTemp-id', idRequestTemp);
    this.router.navigate(['/admin/request-detail']);
  }

  deleteRequestTemp() {
    this.api.deleteRequestTemp(this.idRequestTemp)
      .subscribe(
        response => {
          this.invokeServiceForGetRequestLayoutTemp();
          this.modalRef.hide();
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          console.error();
        }
      );
  }

  openModalDeleteRegister(template: TemplateRef<any>, requestTempId) {
    this.idRequestTemp = requestTempId;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false});
  }

  closeModal() {
    this.modalRef.hide();
    this.idRequestTemp = 0;
  }
}
