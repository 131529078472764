import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';

@Component({
  selector: 'app-report-ammia',
  templateUrl: './report-ammia.component.html',
  styleUrls: ['./report-ammia.component.css']
})
export class ReportAmmiaComponent implements OnInit {

  get branchId(): number {
    return this._branchId;
  }
  @Input()
  set branchId(value: number) {
    this._branchId = value;
  }
  // tslint:disable-next-line:variable-name
  protected _branchId: number;
  reportList: any = [];
  nameReport: string;
  reportDataList: any = [];
  constructor(private api: ApiService,
              public shared: SharedService) { }

  ngOnInit(): void {
    this.invokeServiceGetReportByBranch();
  }

  invokeServiceGetReportByBranch() {
    const objectData = {
      branchId: this._branchId
    };
    this.api.getReportsTypeAmmia(objectData)
      .subscribe(
        (response: any) => {
          if (response != null) {
            this.reportList = response;
            this.reportList.map(x => x.reportDate = new Date(x.reportDate.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')));
          }
        }, error => {
          console.info(error);
        });
  }

  invokeServiceForDownloadReportFile(reportId, name) {
    this.api.getReportFileAmmia(reportId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }
  invokeServiceGetDetailReport(report: any) {
    this.nameReport = report.remportName;
    const data = {
      reportId: report.reportId
    };
    this.api.getReportDataByReportIdAmmia(data)
      .subscribe(response => {
          this.reportDataList = response;
        },  error => {
          console.error(error);
        }
      );
  }

}
