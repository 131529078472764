import { Component, OnInit } from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-request-process-mass-import-ammia',
  templateUrl: './request-process-mass-import-ammia.component.html',
  styleUrls: ['./request-process-mass-import-ammia.component.css']
})
export class RequestProcessMassImportAmmiaComponent implements OnInit {
  dataRequest: any = {};
  clientList: any = [];

  // variables para detalle de importación
  requestTempDetail: any[];
  requestTemp: any;
  policies: any[];
  idRequestTemp: number;
  requestsForm: FormGroup;
  // Objetos para los DatePickers ->
  bsBirthdate: Partial<BsDatepickerConfig>;
  bsDateMovement: Partial<BsDatepickerConfig>;
  idInterval: any;
  subranchCategories: [];
  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public shared: SharedService,
              private datePipe: DatePipe,
              private formBuilder: FormBuilder) {
    this.requestsForm = this.formBuilder.group({
      requestDetail: this.formBuilder.array([])
    });

    this.shared.fUpdateRequestsMassImportProcessObservable().subscribe(
      fRequestsMassImportProcess => {
        console.log('observador mass import request procesamiento de información');
        if (fRequestsMassImportProcess ) {
          // asignar cookies en observador falta
          this.idRequestTemp = Number(localStorage.getItem('requestTemp-id'));
          this.dataRequest.clientId = Number(localStorage.getItem('requestTemp-clientId'));
          if (this.idRequestTemp > 0 && this.dataRequest.clientId > 0) {
            console.log('iniciar componente de carga masiva procesamiento');
            this.invokeServiceTiGetPolicies();
            this.invokeServiceGetRequestTempUpdated();
            this.invokeServiceToGetRequestTempDetail();
            this.idInterval = setInterval(() => {
              this.invokeEditRequestTempDetailSilence();
            }, 300000);
          }
        }
      }
    );
  }

  ngOnInit(): void {

    // Iniciamos configuración para DatePicker de Fecha de Nacimiento ->
    this.bsBirthdate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      adaptivePosition: true,
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD',
      maxDate: new Date()
    });
    // Iniciamos configuración para DatePicker de Fecha de Nacimiento ->
    this.bsDateMovement = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      adaptivePosition: true,
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD',
      maxDate: new Date()
    });
  }

  // detalle de solicitud

  createForm() {
    this.requestsForm = this.formBuilder.group({
      requestDetail: this.formBuilder.array(
        this.requestTempDetail.map(requestDetail => this.formBuilder.group({
          requestTempId: [requestDetail.requestTempId],
          requestTempDetailId: [requestDetail.requestTempDetailId, [Validators.required]],
          operacion: [requestDetail.operacion, [Validators.required]],
          parentesco: [requestDetail.parentesco, [Validators.required]],
          nombre: [requestDetail.nombre, [Validators.required]],
          apellidoPaterno: [requestDetail.apellidoPaterno, [Validators.required]],
          apellidoMaterno: [requestDetail.apellidoMaterno, [Validators.required]],
          email: [requestDetail.email, [Validators.required]],
          fechaNacimiento: [this.datePipe.transform(requestDetail.fechaNacimiento, 'yyyy-MM-dd'), [Validators.required]],
          genero: [requestDetail.genero, [Validators.required]],
          numeroEmpleado: [requestDetail.numeroEmpleado, [Validators.required]],
          fechaAltaBaja: [this.datePipe.transform(requestDetail.fechaAltaBaja, 'yyyy-MM-dd'), [Validators.required]],
          sueldo: [requestDetail.sueldo, [Validators.required]],
          policyList : new FormArray([]),
          policySubranchCategories: this.formBuilder.array( requestDetail.policySubranchCategories.length === 0 ? [] :
            requestDetail.policySubranchCategories
              .map(subranchs => this.addTopolicySubranchCategories(subranchs))),
          error:  [requestDetail.error]
        }))
      )
    });
  }

  addTopolicySubranchCategories(subranchCategory): FormGroup {
    return this.formBuilder.group({
      idPolicy: new FormControl(subranchCategory.idPolicy ),
      idSubranch: new FormControl(subranchCategory.idSubranch ),
      subranch: new FormControl(subranchCategory.subranch),
      numberPolicy: new FormControl(subranchCategory.numberPolicy),
      policyCategoriesList: this.formBuilder.array(
        subranchCategory.policyCategoriesList.map(policyCategorie => this.addCategories(policyCategorie)))
    });
  }

  addCategories(categories): FormGroup {
    return this.formBuilder.group({
      idCategory: new FormControl(categories.idCategory ),
      category: new FormControl(categories.category ),
      selected: new FormControl(categories.selected )
    });
  }

  invokeServiceToGetRequestTempDetail() {
    const dataRequest = {
      requestTempId: this.idRequestTemp,
      clientId: this.dataRequest.clientId
    };
    this.api.getRequestTempDetailAmmia(dataRequest)
      .subscribe(
        (response: any) => {
          this.requestTempDetail = response;
          this.subranchCategories = response.policySubranchCategories;
          this.createForm();
        }, error => {
          console.error(error);

        }
      );
  }

  invokeServiceTiGetPolicies() {
    const dataRequest = {
      clientId: this.dataRequest.clientId
    };
    this.api.getPoliciesRequestTempAmmia(dataRequest)
      .subscribe(
        (response: any) => {
          this.policies = response;
          this.createForm();
        }, error => {
          console.error(error);
        }
      );
  }

  invokeEditRequestTempDetail() {
    this.api.editRequestTempDetail(this.requestsForm.value.requestDetail)
      .subscribe(
        (response: any) => {
          this.requestTempDetail = response;
          this.createForm();
          this.invokeServiceGetRequestTempUpdated();
          this.shared.fUpdateRequests.next(true);
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
          console.error(error);

        }
      );
  }


  invokeEditRequestTempDetailSilence() {
    this.api.editRequestTempDetail(this.requestsForm.value.requestDetail)
      .subscribe(
        (response: any) => {
          this.requestTempDetail = response;
          this.invokeServiceGetRequestTempUpdated();
          this.createForm();
        }, error => {
          console.error(error);

        }
      );
  }

  invokeServiceGetRequestTempUpdated() {
    const dataRequest = {
      idRequestTemp: this.idRequestTemp,
      clientId : this.dataRequest.clientId
    };
    this.api.getRequestTempUpdatedAmmia(dataRequest)
      .subscribe(
        (response: any) => {
          this.requestTemp = response;
        }, error => {

        });
  }

  invokeServiceProcessMassiveRequest() {
    this.shared.gCurrentCustomerId.next(this.dataRequest.clientId);
    if (this.requestsForm.valid) {
      // Se guardan los cambios realizados
      this.api.editRequestTempDetail(this.requestsForm.value.requestDetail)
        .subscribe(
          (response: any) => {
            this.requestTempDetail = response;
            this.api.processMassiveRequest(this.requestTempDetail)
              .subscribe(
                () => {
                  this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
                  this.shared.fUpdateRequests.next(true);
                }, error => {
                  if (error.status === 500) {
                    if (error.error.message !== undefined) {
                      this.toastr.warning(error.error.message, 'Notificación');
                    } else {
                      this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                    }
                  } else {
                    this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                  }
                  console.error(error);
                }
              );

          }, error => {
            console.error(error);
          }
        );
    } else {
      this.toastr.warning('Existen registros no válidos, si no son requeridos, favor de eliminarlos', 'Notificación');
    }
  }

  get requestDetail(): FormArray {
    return this.requestsForm.get('requestDetail') as FormArray;
  }

  getIsValid(detail: AbstractControl): boolean {
    return detail.status === 'INVALID';
  }

  getOperationsType(): any[] {
    const operations = [
      'ALTA', 'BAJA'
    ];
    return operations;
  }

  getGenderType(): any[] {
    const gender = [
      'HOMBRE', 'MUJER'
    ];
    return gender;
  }

  getKinshipType(): any[] {
    const kinship = [
      'TITULAR', 'CONYUGE', 'HIJO(A)', 'HIJA(O)'
    ];
    return kinship;
  }

  newMovement(): FormGroup {
    return this.formBuilder.group({
      requestTempId: this.idRequestTemp,
      requestTempDetailId: 0,
      operacion: undefined,
      parentesco: undefined,
      nombre: undefined,
      apellidoPaterno: undefined,
      apellidoMaterno: undefined,
      email: undefined,
      fechaNacimiento: undefined,
      genero: undefined,
      numeroEmpleado: undefined,
      fechaAltaBaja: undefined,
      sueldo: undefined,
      error: undefined,
      policyList: new FormArray([]),
      policySubranchCategories: this.formBuilder.array( this.subranchCategories.length === 0 ? [] :
        this.subranchCategories
          .map(subranchs => this.addTopolicySubranchCategories(subranchs))),
    });
  }

  addMovment() {
    this.requestDetail.push(this.newMovement());
  }

  goToBack() {
    this.shared.fUpdateRequests.next(true);
    this.router.navigate(['/admin/home']);
  }

  removeMovement(i: number) {
    this.requestDetail.removeAt(i);
  }

  policyList(policyCategorySubranch): FormArray {
    return policyCategorySubranch === undefined ? new FormArray([]) :
      policyCategorySubranch.get('policySubranchCategories') === undefined ?
        new FormArray([]) : policyCategorySubranch.get('policySubranchCategories') as FormArray;
  }

  categories(policySubranchCategories): FormArray {
    return policySubranchCategories === undefined ? new FormArray([]) : policySubranchCategories.get('policyCategoriesList') as FormArray;
  }

}
