<nav id="navbar" class="navbar fixed" style="background: #FEFEFE">
  <div class="container-nav">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" [ngClass]="{'button-tootle-xs': widthSize <= 991}" data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
      </button>
      <img src="../../assets/img/ammialogo-sx.png" class="h-logo">
    </div>
    <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">

      <!--+--------------------------------------------------------------------------------------------------------+
          |---------------------------------------- M E N Ú  C E N T R A L ----------------------------------------|
          +--------------------------------------------------------------------------------------------------------+-->
      <!--<ul class="nav navbar-nav navbar-center" *ngIf="lclShowDashboardMenu">
        <li class="nav-item" [ngClass]="shared.fAdminDashboard==0?'active':''" (click)="changeDashboard(0)">General</li>
        <li class="nav-item"  [ngClass]="shared.fAdminDashboard==1?'active':''" (click)="changeDashboard(1)">Concecionarios</li>
      </ul>-->

      <!--+--------------------------------------------------------------------------------------------------------+
          |---------------------------------------- M E N Ú  D E R E C H O ----------------------------------------|
          +--------------------------------------------------------------------------------------------------------+-->
      <ul class="nav navbar-nav navbar-right">
        <li class="icon-nav">
          <div class="btn-group " dropdown [isDisabled]="notificationList?.length <= 0">
            <span class="sx-iconsys-icon" id="dropdown-button-notification" dropdownToggle aria-controls="dropdown-notification" title="Notificaciones">b
              <span class="notification" *ngIf="notificationCount>0">{{notificationCount}}</span>
            </span>
            <ul id="dropdown-notification" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-button-notification">
              <li>
                <a class="dropdown-item" href="javascript:;" >Notificaciones</a>
              </li>
              <li role="menuitem" *ngFor="let notification of notificationList">
                <a class="dropdown-item" href="javascript:;">
                  <div class="row">
                    <div class="col-xs-11" (click)="modalnotification(notification)">
                      <div class="row" >
                        <div class="col-xs-6" ><div class="title">{{notification.title}}</div></div>
                        <div class="col-xs-6"><div class="date pull-right">{{notification.created_at | date: 'dd MMM yyyy'}}</div></div>
                        <!--<div class="col-xs-2"><div class="pull-right"><button class="btn-default">x</button> </div></div>-->
                      </div>
                      <div class="row">
                        <div class="col-xs-12">
                          <div class="content">{{notification.description}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-1">
                      <button type="button" class="close pull-right" aria-label="Close" (click)="deletenotification(notification)">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li class="icon-nav">
          <span class="sx-iconsys-icon"  (click)="shared.fOpenSidebar(1)" title="Mensajes">c
            <span class="notification" *ngIf="shared.gMessageCount>0">{{shared.gMessageCount}}</span>
          </span>
        </li>
        <li>
          <div style="border-left: 2px solid #d5d5d5; padding-left: 10px;">
            <div style="display: inline-block;vertical-align: middle">
              <div style="font-size: 12px;line-height: 1"><strong [innerHTML]="user.name"></strong></div>
              <div style="font-size: 10px;line-height: 1" [innerHTML]="user.position"></div>
            </div>
            <div style="display: inline-block;vertical-align: middle; padding-left: 10px">
              <div class="btn-group" dropdown>

                <img class="dropdown-toggle" src="{{user.imageProfile}}" id="dropdown-button-profile" dropdownToggle aria-controls="dropdown-profile" style="height: 50px;width: 50px;border-radius: 50%;cursor:pointer;"/>

                <ul id="dropdown-profile" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-button-profile">
                  <li role="menuitem">
                    <a class="dropdown-item" href="javascript:;" (click)="shared.fOpenSidebar(0)">Cuenta</a>
                  </li>
                 <!-- <li role="menuitem">
                    <a class="dropdown-item" href="javascript:;" >Ayuda</a>
                  </li>-->
                  <!--
                  <li role="menuitem" *ngIf="lclShowAdminAccess">
                    <a class="dropdown-item" href="javascript:;" (click)="goToAdminProfile()">Cambiar a Administrador</a>
                  </li>
                  <li role="menuitem" *ngIf="lclShowUserAccess" >
                    <a class="dropdown-item" href="javascript:;" (click)="goToUserProfile()" >Cambiar a Asegurado</a>
                  </li>-->
                  <li role="menuitem">
                    <a class="dropdown-item" href="javascript:;" (click)="closeSession()">Cerrar sesión</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <ng-template #customItemTemplate let-model="item" let-index="index">
      <p>{{(model.type==1?'Póliza: ':model.type==2?'Siniestro: ':model.type==3?'Solicitud: ':model.type==4?'Asegurado: ':'Desconocido: ') + model.title + ' - ' +model.description}}</p>
    </ng-template>

    <div class="row input-area">
      <div [ngClass]="shared.gCurrentRoleId==3?'col-lg-9 col-md-8 col-sm-7 col-xs-12':'col-lg-12 col-md-11 col-sm-7 col-xs-12'">
        <div class="form-group has-feedback">
          <label for="search" class="control-label sr-only"></label>
          <span class="glyphicon glyphicon-search form-control-feedback" aria-hidden="true"></span>
          <input [(ngModel)]="searchItemSelected"
                 [typeaheadAsync]="true"
                 [typeahead]="dataSource"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 [typeaheadOptionsLimit]="7"
                 [typeaheadItemTemplate]="customItemTemplate"
                 typeaheadWaitMs="1000"
                 typeaheadOptionField="title"
                 placeholder="Buscar pólizas, siniestros, solicitudes, asegurados…"
                 class="form-control"
                 id="search">
          <div *ngIf="typeaheadLoading">Loading</div>
          <!--input id="search" type="text" class="form-control input-no-border" placeholder="Buscar pólizas, siniestros, solicitudes…"-->
        </div>
        <!--div class="sbm-item" [ngClass]="incidentFilter==0?'active':''" (click)="changeIncidentFilter(0)">Todos</div>
        <div class="sbm-item" [ngClass]="incidentFilter==1?'active':''" (click)="changeIncidentFilter(1)">Pendientes</div>
        <div class="sbm-item" [ngClass]="incidentFilter==2?'active':''" (click)="changeIncidentFilter(2)">Aceptados</div>
        <div class="sbm-item" [ngClass]="incidentFilter==3?'active':''" (click)="changeIncidentFilter(3)">Rechazados</div>
        <button class="btn btn-custom pull-right" (click)="openModalWithComponent()">Reportar siniestro</button-->
      </div>
      <div class="col-lg-3 col-md-4 col-sm-5 col-xs-12" *ngIf="shared.gCurrentRoleId==3">
        <select class="form-control" [(ngModel)]="customerSelected" name="relationship" (ngModelChange)="changeClientToConsult()">
          <option [value]="0" disabled [selected]="true">Seleccionar...</option>
          <option *ngFor="let item of lclCustomerList" [value]="item.customerId">{{item.customerName}}</option>
        </select>
      </div>
    </div>
  </div>

</nav>
