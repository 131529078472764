import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';

@Component({
  selector: 'app-validate-code-mdl',
  templateUrl: './validate-code-mdl.component.html',
  styleUrls: ['./validate-code-mdl.component.css']
})
export class ValidateCodeMdlComponent implements OnInit {
  public onClose: Subject<boolean>;
  code;
  myForm: FormGroup;
  user;
  userId: number;
  constructor(public bsModalRef: BsModalRef,
              private api: ApiService,
              private toastr: ToastrService,
              public fb: FormBuilder,
              private router: Router,
              public shared: SharedService) {
    this.myForm = this.fb.group({
      code1: ['', [Validators.required, Validators.maxLength(1)]],
      code2: ['', [Validators.required, Validators.maxLength(1)]],
      code3: ['', [Validators.required, Validators.maxLength(1)]],
      code4: ['', [Validators.required, Validators.maxLength(1)]]
    });
    this.userId = Number(localStorage.getItem('user-id'));
    console.log(this.userId);
  }

  ngOnInit() {
    this.onClose = new Subject();
  }
  public onConfirm(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  public hideConfirmationModal(): void {
    this.onClose.next(null);
    this.bsModalRef.hide();
  }

  sendcode() {
    if (this.myForm.get('code1').value === '' && this.myForm.get('code2').value === ''
        && this.myForm.get('code3').value === '' && this.myForm.get('code4').value === '') {
      this.toastr.warning('Notificación', 'Por favor ingrese el código que le llego al correo');
      return;
    }
    const code = this.myForm.get('code1').value + '' + this.myForm.get('code2').value + ''
      + this.myForm.get('code3').value + '' + this.myForm.get('code4').value;
    console.log(code);
    this.api.validatecode(code, this.userId)
      .then(
        (user) => {
          this.toastr.success('Se ha completado el proceso de recuperar contraseña.', 'Notificación');
          this.redirectCorrectHome();
        }, error => {
          if (error.status === 404) {
            this.toastr.warning('El código es invalido.', 'Notificación');
          } else {
            this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
          }
        }
      );
  }

  redirectCorrectHome() {
    this.api.getUserRoles()
      .then(
        (data: any) => {
          let goToAdmin = false;
          this.shared.gUserRoles = data;
          localStorage.setItem('userRoles', JSON.stringify(data));
          data.forEach((item, index) => {
            // if (item.roleId === 3 && item.defaultRole) {
            if (item.roleId === 3) {
              goToAdmin = true;
              localStorage.setItem('roleId', item.roleId);
            }
            if (index === (data.length - 1) && goToAdmin) {
              this.router.navigate(['/admin/home']);
            }
          });
        }, error => {
          console.info(error);
        });
  }
  invokeFunctionGetLogin() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  invokeServiceGetValidCode() {
    this.api.SendValidCode(this.userId)
      .then(
        (user) => {
          this.toastr.success('Se ha enviado el código a su correo.', 'Notificación');
        }, error => {
          if (error.status === 404) {
            this.toastr.warning('El código es invalido.', 'Notificación');
          } else {
            this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
          }
        }
      );
  }

}
