import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.css']
})
export class RecoveryComponent implements OnInit {
  recoveryForm: FormGroup;
  loading = false;
  token;
  urlTree;
  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService) {
    this.recoveryForm = this.fb.group({
      password: ['', [ Validators.required,
        Validators.pattern('^(?=\\w*\\d)(?=\\w*[A-Z])(?=\\w*[a-z])\\S{12,16}$')]],
      password2: ['', [ Validators.required]]
    });
  }

  ngOnInit() {
    console.log('entra a recovery password');
    this.urlTree = this.router.parseUrl(this.router.url);
    this.token = this.urlTree.queryParams['sttkn'];
    this.validatetoken();
  }

  recovery() {
    console.log('validación');
    if (this.recoveryForm.get('password').value !== this.recoveryForm.get('password2').value) {
      this.toastr.warning('Las contraseña no coinciden, por favor verifique su información', 'Notificación');
      return;
    }
    this.api.recoveypassword(this.recoveryForm.get('password').value, this.token)
      .then(
        (user) => {
          this.toastr.success('Su información ha sido actualizada correctamente.', 'Notificación');
          this.router.navigate(['/login']);
        }, error => {
          this.loading = false;
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        }
      );
  }

  validatetoken() {
    this.api.validateToken(this.token)
      .then(
        (user) => {
          this.loading = true;
        }, error => {
          this.loading = false;
          if (error.status === 403) {
            this.toastr.warning('El token ha expirado, por favor inicie de nuevo el proceso de recuperación.', 'Notificación');
          } else if (error.status === 404) {
            this.toastr.warning('El token no es valido.', 'Notificación');
          } else {
            this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
          }
        }
      );
  }

}
