<div style="background-color: #ffffff; border-radius: 5px; padding-top: 30px;box-shadow: 0 5px 25px 0 rgba(41, 63, 121, 0.05);">
  <div class="row">
    <div class="col-md-12">
      <div class="title-section pull-left"><i class="fa fa-eye fa-lg"></i>&nbsp;VIENDO COMO {{insuredSelected.name}}</div>
      <i class="glyphicon glyphicon-resize-small pull-right" (click)="goToBack()" style="cursor: pointer;" ></i>
    </div>
  </div>
  <hr>
  <app-home *ngIf="!browserRefresh"></app-home>

</div>
