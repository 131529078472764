import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-policy-detail-ammia',
  templateUrl: './policy-detail-ammia.component.html',
  styleUrls: ['./policy-detail-ammia.component.css']
})
export class PolicyDetailAmmiaComponent implements OnInit {

  dataPolicy: any = {};
  subgroupList: any = []; // almacena la lista de Subgrupos, tendra una propiedad 'categoryList' para almacenar la lista de categorías
  subgroupId: number;
  categoryId: number;
  policyCoverageList: any = [];
  categoryList: any = [];
  policyId: number;
  constructor(private api: ApiService,
              private toastr: ToastrService,
              public shared: SharedService) {


    this.shared.gPolicyIdObservable().subscribe(
      gPolicyId => {
        this.policyId = gPolicyId;
        this.invokeServiceGetPolicyDetail(gPolicyId);
      }
    );
  }

  ngOnInit(): void {
    this.policyId = 0;
  }

  invokeServiceGetPolicyDetail(id: number) {
    console.log(id);
    const dataPolicy = {
      policyId: id
    };
    this.api.getPoliciesAmmiaDashboardBenefitsDetail(dataPolicy)
      .then(
        (response: any) => {
          if (response != null) {
            this.dataPolicy = response[0];
            this.invokeServiceForGetSubgroupsWithCategories(id);
          }
        }, error => {
          console.info(error);
        });
  }

  private invokeServiceForGetSubgroupsWithCategories(policyId) {
    this.api.getSubgroupsCategoryByPolicy(policyId)
      .subscribe(
        response => {
          if (response != null) {
            this.subgroupList = response;
            if (this.subgroupList.length > 0) {
              this.subgroupId = this.subgroupList[0].subGroupId;
              this.categoryList = this.subgroupList[0].categoryList;
              this.categoryId = this.categoryList[0].categoryId;
              this.invokeServiceForGetPolicyDetailByCategoryId(this.categoryId);
              // this.invokeServiceForGetAmountsDetailByCategoryId(this.lclCategorySelected.categoryId);
            }
          }
        }, error => {
          console.error(error);
        }
      );
  }

  private invokeServiceForGetPolicyDetailByCategoryId(categoryId) {
    this.api.getPolicyDetailByCategoryId(categoryId)
      .subscribe(
        response => {
          this.policyCoverageList = response;
        }, error => {
          this.policyCoverageList = [];
          console.error(error);
        }
      );
  }

  public invokeServiceForDownloadFilePolicy() {
    const objData = {
      policyId: this.dataPolicy.policyId
    };
    this.api.getFileCsvPolicy(objData)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  this.dataPolicy.number + '.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForDownloadPolicyFile(policy) {
    this.api.getPolicyFile(policy.policyId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', policy.number + '.pdf');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }
  invokeServiceForDownloadPolicyAttachmentZipFile(policy) {
    this.api.getPolicyAttachmentZip(policy.policyId)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  `anexos_poliza_${policy.number}.zip`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }
  invokeServiceForDownloadPolicyAttachmentFile(attachment) {
    this.api.getPolicyAttachmentFile(attachment.attachmentId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          console.error(error);
        }
      );
  }

  invokeFunctionChangeTypeSubgroup() {
    console.log(this.subgroupId);
    this.subgroupList.forEach( item => {
      if (item.subGroupId === this.subgroupId) {
        this.categoryList = item.categoryList;
      }
    });
  }

  invokeFunctionChangeTypeCategory() {
    console.log(this.categoryId);
    this.invokeServiceForGetPolicyDetailByCategoryId(this.categoryId);
  }

  invokeFunctionCloseModalPolicy() {
    this.policyId = 0;
  }
}
