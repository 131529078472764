import {Component, OnDestroy, OnInit} from '@angular/core';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-request-temp-detail',
  templateUrl: './request-temp-detail.component.html',
  styleUrls: ['./request-temp-detail.component.css']
})
export class RequestTempDetailComponent implements OnInit, OnDestroy {


  requestTempDetail: any[];
  requestTemp: any;
  policies: any[];
  idRequestTemp: string;
  requestsForm: FormGroup;
  // Objetos para los DatePickers ->
  bsBirthdate: Partial<BsDatepickerConfig>;
  bsDateMovement: Partial<BsDatepickerConfig>;
  idInterval: any;
  subranchCategories: [];

  constructor(public shared: SharedService,
              private api: ApiService,
              private toastr: ToastrService,
              private formBuilder: FormBuilder,
              private datePipe: DatePipe,
              private router: Router) {

    this.requestsForm = this.formBuilder.group({
      requestDetail: this.formBuilder.array([])
    });
    this.idRequestTemp = localStorage.getItem('requestTemp-id');
    this.invokeServiceTiGetPolicies();
    this.invokeServiceGetRequestTempUpdated();
    this.invokeServiceToGetRequestTempDetail();
  }

  ngOnInit() {
    this.idInterval = setInterval(() => {
      this.invokeEditRequestTempDetailSilence();
    }, 300000);
    // Iniciamos configuración para DatePicker de Fecha de Nacimiento ->
    this.bsBirthdate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      adaptivePosition: true,
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD',
      maxDate: new Date()
    });
    // Iniciamos configuración para DatePicker de Fecha de Nacimiento ->
    this.bsDateMovement = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      adaptivePosition: true,
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD',
      maxDate: new Date()
    });

  }

  ngOnDestroy(): void {
    if (this.idInterval) {
      clearInterval(this.idInterval);
    }
  }

  createForm() {
    this.requestsForm = this.formBuilder.group({
      requestDetail: this.formBuilder.array(
        this.requestTempDetail.map(requestDetail => this.formBuilder.group({
          requestTempId: [requestDetail.requestTempId],
          requestTempDetailId: [requestDetail.requestTempDetailId, [Validators.required]],
          operacion: [requestDetail.operacion, [Validators.required]],
          parentesco: [requestDetail.parentesco, [Validators.required]],
          nombre: [requestDetail.nombre, [Validators.required]],
          apellidoPaterno: [requestDetail.apellidoPaterno, [Validators.required]],
          apellidoMaterno: [requestDetail.apellidoMaterno, [Validators.required]],
          email: [requestDetail.email, [Validators.required]],
          fechaNacimiento: [this.datePipe.transform(requestDetail.fechaNacimiento, 'yyyy-MM-dd'), [Validators.required]],
          genero: [requestDetail.genero, [Validators.required]],
          numeroEmpleado: [requestDetail.numeroEmpleado, [Validators.required]],
          fechaAltaBaja: [this.datePipe.transform(requestDetail.fechaAltaBaja, 'yyyy-MM-dd'), [Validators.required]],
          sueldo: [requestDetail.sueldo, [Validators.required]],
          policyList : new FormArray([]),
          policySubranchCategories: this.formBuilder.array( requestDetail.policySubranchCategories.length === 0 ? [] :
              requestDetail.policySubranchCategories
                .map(subranchs => this.addTopolicySubranchCategories(subranchs))),
          error:  [requestDetail.error]
        }))
      )
    });
  }

  addTopolicySubranchCategories(subranchCategory): FormGroup {
    return this.formBuilder.group({
      idPolicy: new FormControl(subranchCategory.idPolicy ),
      idSubranch: new FormControl(subranchCategory.idSubranch ),
      subranch: new FormControl(subranchCategory.subranch),
      numberPolicy: new FormControl(subranchCategory.numberPolicy),
      policyCategoriesList: this.formBuilder.array(
        subranchCategory.policyCategoriesList.map(policyCategorie => this.addCategories(policyCategorie)))
    });
  }

  addCategories(categories): FormGroup {
    return this.formBuilder.group({
      idCategory: new FormControl(categories.idCategory ),
      category: new FormControl(categories.category ),
      selected: new FormControl(categories.selected )
    });
  }

  invokeServiceToGetRequestTempDetail() {
    this.api.getRequestTempDetail(this.idRequestTemp)
      .subscribe(
        (response: any) => {
          this.requestTempDetail = response;
          this.subranchCategories = response.policySubranchCategories;
          this.createForm();
        }, error => {
          console.error(error);

        }
      );
  }

  invokeServiceTiGetPolicies() {
    this.api.getPoliciesRequestTemp()
      .subscribe(
        (response: any) => {
          this.policies = response;
          this.createForm();
        }, error => {
          console.error(error);
        }
      );
  }

  invokeEditRequestTempDetail() {
    this.api.editRequestTempDetail(this.requestsForm.value.requestDetail)
      .subscribe(
        (response: any) => {
          this.requestTempDetail = response;
          this.createForm();
          this.invokeServiceGetRequestTempUpdated();
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
          console.error(error);

        }
      );
  }


  invokeEditRequestTempDetailSilence() {
    this.api.editRequestTempDetail(this.requestsForm.value.requestDetail)
      .subscribe(
        (response: any) => {
          this.requestTempDetail = response;
          this.invokeServiceGetRequestTempUpdated();
          this.createForm();
        }, error => {
          console.error(error);

        }
      );
  }

  invokeServiceGetRequestTempUpdated() {
    this.api.getRequestTempUpdated(this.idRequestTemp)
      .subscribe(
        (response: any) => {
          this.requestTemp = response;
        }, error => {

        });
  }

  invokeServiceProcessMassiveRequest() {
    if (this.requestsForm.valid) {
      // Se guardan los cambios realizados
      this.api.editRequestTempDetail(this.requestsForm.value.requestDetail)
        .subscribe(
          (response: any) => {
            this.requestTempDetail = response;
            this.api.processMassiveRequest(this.requestTempDetail)
              .subscribe(
                () => {
                  this.goToBack();
                  this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
                }, error => {
                  if (error.status === 500) {
                    if (error.error.message !== undefined) {
                      this.toastr.warning(error.error.message, 'Notificación');
                    } else {
                      this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                    }
                  } else {
                    this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                  }
                  console.error(error);
                }
              );

          }, error => {
            console.error(error);
          }
        );
    } else {
      this.toastr.warning('Existen registros no válidos, si no son requeridos, favor de eliminarlos', 'Notificación');
    }
  }

  get requestDetail(): FormArray {
    return this.requestsForm.get('requestDetail') as FormArray;
  }

  getIsValid(detail: AbstractControl): boolean {
    return detail.status === 'INVALID';
  }

  getOperationsType(): any[] {
    const operations = [
      'ALTA', 'BAJA'
    ];
    return operations;
  }

  getGenderType(): any[] {
    const gender = [
      'HOMBRE', 'MUJER'
    ];
    return gender;
  }

  getKinshipType(): any[] {
    const kinship = [
      'TITULAR', 'CONYUGE', 'HIJO(A)', 'HIJA(O)'
    ];
    return kinship;
  }

  newMovement(): FormGroup {
    return this.formBuilder.group({
      requestTempId: this.idRequestTemp,
      requestTempDetailId: 0,
      operacion: undefined,
      parentesco: undefined,
      nombre: undefined,
      apellidoPaterno: undefined,
      apellidoMaterno: undefined,
      email: undefined,
      fechaNacimiento: undefined,
      genero: undefined,
      numeroEmpleado: undefined,
      fechaAltaBaja: undefined,
      sueldo: undefined,
      error: undefined,
      policyList: new FormArray([]),
      policySubranchCategories: this.formBuilder.array( this.subranchCategories.length === 0 ? [] :
        this.subranchCategories
          .map(subranchs => this.addTopolicySubranchCategories(subranchs))),
    });
  }

  addMovment() {
    this.requestDetail.push(this.newMovement());
  }

  goToBack() {
    this.shared.fUpdateRequests.next(true);
    this.router.navigate(['/admin/home']);
  }

  removeMovement(i: number) {
    this.requestDetail.removeAt(i);
  }

  policyList(policyCategorySubranch): FormArray {
    return policyCategorySubranch === undefined ? new FormArray([]) :
      policyCategorySubranch.get('policySubranchCategories') === undefined ?
        new FormArray([]) : policyCategorySubranch.get('policySubranchCategories') as FormArray;
  }

  categories(policySubranchCategories): FormArray {
    return policySubranchCategories === undefined ? new FormArray([]) : policySubranchCategories.get('policyCategoriesList') as FormArray;
  }

}
