import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SharedService} from './shared-service.service';
import {ApiService} from './api.service';
import {switchMap} from 'rxjs/operators';
import * as Forge from 'node-forge';
@Injectable({
  providedIn: 'root'
})
export class RequestOptionsInterceptor implements HttpInterceptor {

  constructor(private shared: SharedService, private api: ApiService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth header from the service.
    const authHeader = 'Basic ' + localStorage.getItem('token');
    const roleId = localStorage.getItem('roleId');
    const roleHeader = '' + roleId;

    let  newHeaders = req.headers.set('Authorization', authHeader);
    newHeaders = newHeaders.set('roleId', roleHeader);

    // Sí la vista es de solo lectura para un asegurado
    if (this.shared.fInsuredDataReadonly) {
      const insured = JSON.parse(localStorage.getItem('insuredView'));
      newHeaders = newHeaders.set('insuredId', insured.userId.toString());
    }
    if (Number(roleId) === 3 && (!req.url.includes('/ammia/v1.0/protected') && !req.url.includes('/ammia/v1.0/public'))) {
      newHeaders = newHeaders.set('customerId', this.shared.gCurrentCustomerId.value.toString());
      newHeaders = newHeaders.set('branchId', this.shared.gCurrentBranchId.value.toString());
    } else {
      newHeaders = newHeaders.set('customerId', this.shared.gCurrentCustomerId.value.toString());
      newHeaders = req.headers.set('Authorization',
        // tslint:disable-next-line:max-line-length
        'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJjaGFuZ2VQYXNzd29yZEFtbWlhIjp0cnVlLCJpZFNhYXNDbGllbnQiOjEsInNob3J0TmFtZVNhYXNDbGllbnQiOiJzdXJleHMiLCJ1c2VySWQiOjc1MDUsImlhdCI6MTY4NjMyODYzNiwic3ViIjoic3VyZXhzIn0.njband_h0pkm-qva8jothoY1JwEKMNR_h-loDGUjUHa1aDGSR6fUEmn_rSGRf9P1pNPf7C_hbK2ZlZpteINKCg01VykZ3xnJkojRVmzRKPD3cn4OUrmY7bT-NaDNdhMQ88l13L4eAPneRx7bKJPSjN8SysI_1s9UFpLytAMXpByNcC64co8fvKNFzXuECOCrutozJaaYaPIVlojptV1srza9otkXXcCYWUZlQ-_4BI0flyw8JZpdTVALphs_p3MwkpA1LWPSnNu1F5ycgNiDUYGSJmiY-I0-kuLYGK2LPa0kZo-MJUBntGrTtHfgvSXLKvxTDe68ZFIYszjFW0vH6Q');
      const checkUrl = req.url.indexOf('protected/sso/system/policy/getPublicPolicy');
      if (checkUrl === -1 && req.url.includes('/ammia/v1.0/protected')) {
        return this.api.getPublicKey().pipe(
          switchMap((data: any) => {
            const body = req.body;
            const value = JSON.stringify(body);
            const encryptado = this.encryptWithPublicKeyLong(value, data.mswSANXnWqh);
            const ob = {
              mswSANXnWqh: encryptado,
              vRPbyweQpC: data.vRPbyweQpC
            };
            const newReq1 = req.clone({headers: newHeaders, body: ob });
            // setTimeout(() => {
            return next.handle(newReq1);
          })
        );
      }
    }

    // Clone the request to add the new header.
    const headReq = req.clone(
      {headers: newHeaders});
    // Pass on the cloned request instead of the original request.
    return next.handle(headReq);
  }

  encryptWithPublicKeyLong(valueToEncrypt: string, keyPublica: string): any {
    let keyFinal = '-----BEGIN PUBLIC KEY-----  ';
    keyFinal = keyFinal + keyPublica;
    keyFinal = keyFinal + '  -----END PUBLIC KEY-----';

    const rsa = Forge.pki.publicKeyFromPem(keyFinal);
    const val = this.splitPlaintext(rsa, valueToEncrypt.toString());

    return val;
  }

  splitPlaintext(publicKey, plaintext) {
    plaintext = Forge.util.encodeUtf8(plaintext);
    const len = 80;
    if (plaintext.length < len) {
      return [Forge.util.bytesToHex(publicKey.encrypt(plaintext))];
    }
    const result = [];
    const splitCount = Math.ceil(plaintext.length / len);
    for (let i = 0; i < splitCount; i++) {
      const str = plaintext.substring(i * len, (i + 1) * len);
      result.push(Forge.util.bytesToHex(publicKey.encrypt(str)));
    }

    return result;
  }
}
