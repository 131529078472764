<div>
  <div style="background-color: #ffffff; border-radius: 5px; padding-top: 30px;box-shadow: 0 5px 25px 0 rgba(41, 63, 121, 0.05);">
    <div class="row">
      <div class="col-md-12">
        <div class="title-section pull-left" style="text-align: left;margin-bottom: 10px">
          <span class="title-section pull-left">Reportes</span>
        </div>
        <div class="pull-right">
          <!--<i class="glyphicon glyphicon-glass"  style="display: inline-block; margin-left: 5px;padding: 4px;vertical-align: middle;cursor: pointer;" ></i>-->
          <i class="glyphicon glyphicon-resize-small"  style="display: inline-block; margin-left: 5px;padding: 4px;vertical-align: middle;cursor: pointer" (click)="goToBack()"></i>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="btn-group pull-left" dropdown [dropup]="false" style="position:absolute;">
          <button id="button-data-types-reports" dropdownToggle type="button" class="btn btn-xs btn-link dropdown-toggle subtitle-section-menu" aria-controls="dropdown-data-types-reports">
            {{lclDataTypeForReportsSelected.description}} <span class="sx-iconsys-icon">1</span>
          </button>
          <ul id="dropdown-data-types-reports" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu" aria-labelledby="button-data-types-reports">
            <li role="menuitem" *ngFor="let item of dataTypesList">
              <a class="dropdown-item" href="javascript:;" (click)="changeLclDataTypeReport(item)" *ngIf="item.enabled">{{item.description}}</a>
            </li>
          </ul>
        </div>

        <div class="btn-group pull-right" dropdown>
          <button id="button-dowload-types" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu dropdown-menu-right" (click)="invokeServiceForDownloadReportFile(report)" >
            Descargar detalle <i class="glyphicon glyphicon-arrow-down"></i>
          </button>
        </div>
        <!--<div class="btn-group pull-right" dropdown>
          <button id="button-iva-types" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" >
            IVA {{lclDemo.description}} <span class="sx-iconsys-icon">1</span>
          </button>
          <ul id="dropdown-iva-types" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-iva-types">
            <li role="menuitem" *ngFor="let c of listdemo">
              <a class="dropdown-item" href="javascript:;" >{{c.description}}</a>
            </li>
          </ul>
        </div>-->
        <div class="btn-group pull-right" dropdown>
          <button id="button-date-types" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" >
            Fecha: {{lclFilterDates}} <span class="sx-iconsys-icon">1</span>
          </button>
          <ul id="dropdown-date-types" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-date-types">
            <li role="menuitem" *ngFor="let c of listFilterDates" (click)="getReportFilter(3,c)">
              <a class="dropdown-item" href="javascript:;" >{{c}}</a>
            </li>
          </ul>
        </div>
        <div class="btn-group pull-right" dropdown>
          <button id="button-request-types" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" >
            Movimiento: {{lclMovement}} <span class="sx-iconsys-icon">1</span>
          </button>
          <ul id="dropdown-reques-types" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-request-types">
            <li role="menuitem" *ngFor="let c of listMovement" (click)="getReportFilter(2,c)">
              <a class="dropdown-item" href="javascript:;" >{{c}}</a>
            </li>
          </ul>
        </div>
        <div class="btn-group pull-right" dropdown>
          <button id="button-dependent-types" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" >
            Parentesto: {{lclkinship}} <span class="sx-iconsys-icon">1</span>
          </button>
          <ul id="dropdown-dependent-types" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-dependent-types">
            <li role="menuitem" *ngFor="let c of listkinship" (click)="getReportFilter(1,c)">
              <a class="dropdown-item" href="javascript:;" >{{c}}</a>
            </li>
          </ul>
        </div>
        <!--<div class="btn-group pull-right" dropdown>
          <button id="button-endoso-types" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" >
            Endoso: {{lclDemo.description}} <span class="sx-iconsys-icon">1</span>
          </button>
          <ul id="dropdown-endoso-type" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-endoso-types">
            <li role="menuitem" *ngFor="let c of listdemo">
              <a class="dropdown-item" href="javascript:;" >{{c.description}}</a>
            </li>
          </ul>
        </div>-->
      </div>

    </div>

    <div class="row">
      <div class="col-md-2">
        <div *ngIf="false" class="title-section pull-right" style="padding: 4px">
          <div class="btn-group pull-right" dropdown>
            <button id="button-report-types" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" >
              {{lclDemo.description}} <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-report-type" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-report-types">
              <li role="menuitem" *ngFor="let c of listdemo">
                <a class="dropdown-item" href="javascript:;" >{{c.description}}</a>
              </li>
            </ul>
          </div>
        </div>
        <br/><br/>
        <div class="row">
          <div class="col-md-12" *ngFor="let r of listReports">
            <div  class="card" [ngClass]="lclReportSelected==r.reportId?'r-selected':''" (click)="onReportItemSelect(r)">
              <div id="img" style="width: 100%;border-bottom: 1px solid #CDD0D8; padding: 10px 5px;text-align: center">
                <i class="glyphicon glyphicon-file" style="font-size: 30px" [ngStyle]="{'color':(r.typeReport==1?'#50E3B6':r.typeReport==2?'#F5A623':'gray-light')}"></i>
                <p>{{r.typeReport==1?'Siniestralidad':r.typeReport==2?'Provisiones':'Otro'}}</p>
              </div>
              <div id="footer" style="width: 100%;display: flex;padding: 3px;color: #7A7875;">
                <div id="name" style="display: inline-block;text-align: center;width: 70%;font-size: 12px">
                  {{r.reportDate | date: 'MMM yyyy'}}
                </div>
                <div id="icon" style="display: inline-block;border-left: 1px solid #CDD0D8;width: 30%;text-align: center">
                  <i class="glyphicon glyphicon-download" style="color: #326173;"  (click)="invokeServiceForDownloadReportFile(r)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-10 col-xs-12">
        <div class="row">
         <!-- <div class="">-->
            <table class="table table-condensed table-striped" *ngIf="type==1 && typeReport == 1">
              <thead>
              <tr>
                <th>Ramo</th>
                <th>Póliza</th>
                <th *ngIf="widthSize> 700">Número de incidente</th>
                <th>Fecha de pago</th>
                <th *ngIf="widthSize> 700">Sexo</th>
                <th>Edad</th>
                <th *ngIf="widthSize> 700">Parentesco</th>
                <th *ngIf="widthSize> 700">Importe reclamado</th>
                <th *ngIf="widthSize> 1024">Deducible</th>
                <th *ngIf="widthSize> 1024">Coaseguro</th>
                <th>Importe Pagado con Iva</th>
                <th *ngIf="widthSize> 1024">IVA</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let oRequest of list">
                <td>{{oRequest.industry}}</td>
                <td>{{oRequest.policy}}</td>
                <td *ngIf="widthSize> 700">{{oRequest.incidentNumber}}</td>
                <td>{{oRequest.paymentDate}}</td>
                <td *ngIf="widthSize> 700">{{oRequest.sex}}</td>
                <td>{{oRequest.age}}</td>
                <td *ngIf="widthSize> 700">{{oRequest.kinship}}</td>
                <td *ngIf="widthSize> 700">{{oRequest.amountClaimed | CustomCurrency}}</td>
                <td *ngIf="widthSize> 1024">{{oRequest.deductible | CustomCurrency}}</td>
                <td *ngIf="widthSize> 1024">{{oRequest.coaseguro}}</td>
                <td>{{oRequest.amountPaidIva | CustomCurrency}}</td>
                <td *ngIf="widthSize> 1024">{{oRequest.iva | CustomCurrency }}</td>
              </tr>
              </tbody>
            </table>
            <table class="table table-condensed table-striped" *ngIf="type==1 && typeReport == 2">
              <thead>
              <tr>
                <th>Sub Grupo</th>
                <th>Póliza</th>
                <th>Nombre</th>
                <th>Parentesco</th>
                <th>Movimiento</th>
                <th>Fecha de alta y/o baja</th>
                <th>Prima neta</th>
                <th>Derecho de póliza</th>
                <th>IVA</th>
                <th>Total</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let oRequest of list">
                <td>{{oRequest.subgroup}}</td>
                <td>{{oRequest.policy}}</td>
                <td>{{oRequest.name}}</td>
                <td>{{oRequest.kinship}}</td>
                <td>{{oRequest.movement}}</td>
                <td>{{oRequest.created}}</td>
                <td>{{oRequest.netPremium | CustomCurrency}}</td>
                <td>{{oRequest.rights | CustomCurrency}}</td>
                <td>{{oRequest.iva}}</td>
                <td>{{oRequest.total | CustomCurrency}}</td>
              </tr>
              </tbody>
            </table>
            <table class="table table-condensed table-striped" *ngIf="type==2">
              <thead>
              <tr>
                <th>PÓLIZA</th>
                <th>NOMASEG</th>
                <th>VEHÍCULO</th>
                <th>NÚMERO DE SERIE</th>
                <th>TIPOSIN</th>
                <th>FECOCU</th>
                <th>SINTOTAL</th>
                <th>ENTIDAD</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let oRequest of list">
                <td>{{oRequest.policy}}</td>
                <td>{{oRequest.nomaSeg}}</td>
                <td>{{oRequest.car}}</td>
                <td>{{oRequest.serieNumber}}</td>
                <td>{{oRequest.tipoSin}}</td>
                <td>{{oRequest.fecocu}}</td>
                <td>{{oRequest.sinTotal | CustomCurrency}}</td>
                <td>{{oRequest.entidad }}</td>
              </tr>
              </tbody>
            </table>
          <!--</div>-->
        </div>
      </div>
    </div>
  </div>
</div>
