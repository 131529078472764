import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ApiService} from '../api.service';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-notification-mdl',
  templateUrl: './notification-mdl.component.html',
  styleUrls: ['./notification-mdl.component.css']
})
export class NotificationMdlComponent implements OnInit {
  public onClose: Subject<boolean>;
  notification;
  constructor(public bsModalRef: BsModalRef,
              private api: ApiService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.onClose = new Subject();
    this.readnotification(this.notification);
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  public hideConfirmationModal(): void {
    this.onClose.next(null);
    this.bsModalRef.hide();
  }

  deletenotification(notification) {
    this.api.deleteNotification(notification.id_notification).subscribe( response => {
      this.toastr.success('Se ha elimando correctamente.', 'Notificación');
      this.onClose.next(true);
      this.bsModalRef.hide();
    }, error => {
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
    });
  }
  readnotification(notification) {
    this.api.putNotification(notification.id_notification).subscribe( response => {
    }, error => {
    });
  }

}
