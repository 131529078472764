<div class="eventos-contenedor" id="eventosContainer">
  <div class="d-between mb-3">
    <h4 class=" ">Solicitudes</h4>
    <div class="pull-right">
      <i class="bi bi-microsoft" [ngClass]="reportGridViewType?'view-select':''" style="display: inline-block; margin-left: 5px;padding: 4px;vertical-align: middle;cursor: pointer;" (click)="reportGridViewType = true;"></i>
      <i class="bi bi-list-ul" [ngClass]="!reportGridViewType?'view-select':''" style="display: inline-block; margin-left: 5px;padding: 4px;vertical-align: middle;cursor: pointer" (click)="invokeFunctionLoadRequestTable()" *ngIf="_branchId != 2"></i>
    </div>
    <button class="btn primario " type="button" data-bs-toggle="offcanvas" data-bs-target="#nuevaSolicitud" aria-controls="offcanvasRight"
            *ngIf="_branchId== 1" (click)="invokeFunctionShowRequest(1)">
      <img src="../../assets/img/ammia/iconos/plus-azuld2.svg" alt="Ícono de Agregar tarea" class="icono-sm">
      Hacer solicitud
    </button>
    <button class="btn primario " type="button" data-bs-toggle="offcanvas" data-bs-target="#nuevaSolicitud" aria-controls="offcanvasRight"
    *ngIf="_branchId == 2"  (click)="invokeFunctionShowRequest(2)">
      <img src="../../assets/img/ammia/iconos/plus-azuld2.svg" alt="Ícono de Agregar tarea" class="icono-sm">
      Hacer solicitud
    </button>
    <button class="btn primario " type="button" data-bs-toggle="offcanvas" data-bs-target="#nuevaSolicitud" aria-controls="offcanvasRight"
            *ngIf="_branchId== 3" (click)="invokeFunctionShowRequest(3)">
      <img src="../../assets/img/ammia/iconos/plus-azuld2.svg" alt="Ícono de Agregar tarea" class="icono-sm">
      Hacer solicitud
    </button>
  </div>
    <div class="d-between gap-48 mb-3" [hidden]="!reportGridViewType">
      <div class="form-group">
        <select class="form-select ammiaAdmin-style  mb-3" aria-label=" example" [(ngModel)]="dataFilterRequest.typeId"
                (change)="invokeFunctionChangeTypeRequest()" name="relationshipDep" required >
          <option *ngFor="let item of typeRequestList" [value]="item.typeId"
          >{{item.description}}</option>
        </select>
      </div>
      <div class="form-group">
        <label class="" id="input-label"></label>
        <select class="form-select ammiaAdmin-style  mb-3" aria-label=" example" [(ngModel)]="dataFilterRequest.statusId"
                (change)="invokeFunctionChangeTypeRequest()" name="relationshipDep" required >
          <option *ngFor="let item of statusRequestList" [value]="item.statusId"
          >{{item.description}}</option>
        </select>
      </div>
    </div>
    <!-- CONTENEDOR DE FICHAS -->
    <div class="tab-content" id="solicitudes-siniestros-content"
         infinite-scroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         [scrollWindow]="false"
         (scrolled)="onScrollRequest()" [hidden]="!reportGridViewType">
      <!-- Comienzan TARJETAS DE SOLICITUD -->
      <!--INICIA Tarjeta de solicitud-->
      <div class="estadisticas-card mb-3" *ngFor="let r of requestList"  [ngClass]="r.requestTypeId == 1 ? 'alta' : r.requestTypeId == 2 ? 'baja' : 'general' ">
        <div class="estadisticas-card-label" >
          <img *ngIf="r.requestTypeId == 1" src="../../assets/img/ammia/iconos/plus-azul-primario.svg" alt="reunion-presencial">
          <img *ngIf="r.requestTypeId == 2" src="../../assets/img/ammia/iconos/minus-rojo.svg" alt="reunion-presencial">
          <img *ngIf="r.requestTypeId == 3" src="../../assets/img/ammia/iconos/user-turquesa.svg" alt="reunion-presencial">
        </div>
        <div class="estadisticas-card-container">

          <div class="d-between w-100 mb-3">
            <h4>{{r.typeRequest | titlecase }}</h4>
            <div [ngClass]="r.statusId == 3 ? 'tag green' : r.statusId == 4 ? 'tag red' : 'tag yellow' " data-bs-toggle="tooltip" data-bs-placement="top" title="{{r.statusRequest | titlecase}}">
              {{r.statusRequest | titlecase }}
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="r.statusId == 3">
                <path d="M8.76187 3.49403L5.11593 7.16393L3.7634 5.76587L2.99893 6.52315L4.58668 8.15422C4.64549 8.21247 4.7631 8.27073 4.88071 8.32898C4.99832 8.38723 5.11593 8.38723 5.17474 8.38723C5.23354 8.38723 5.40996 8.38723 5.46876 8.32898C5.58637 8.27073 5.64518 8.21247 5.76279 8.15422L9.58514 4.25131L8.76187 3.49403Z" />
                <path d="M10.291 1.74757C9.17365 0.640777 7.64471 0 5.99815 0C4.41041 0 2.88147 0.640777 1.76416 1.74757C0.64686 2.91262 0 4.36893 0 6C0 7.57282 0.64686 9.08738 1.76416 10.2524C2.88147 11.3592 4.41041 12 5.99815 12C7.5859 12 9.11484 11.3592 10.2321 10.2524C11.3494 9.14563 11.9963 7.63107 11.9963 6C12.0551 4.36893 11.4083 2.91262 10.291 1.74757ZM6.05696 10.835C3.35191 10.835 1.17611 8.62136 1.17611 5.94175C1.17611 3.26214 3.35191 1.04854 6.05696 1.04854C8.76201 1.04854 10.9378 3.26214 10.9378 5.94175C10.9378 8.67961 8.76201 10.835 6.05696 10.835Z" />
              </svg>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="r.statusId != 3 && r.statusId != 4">
                <path d="M6 0C4.52275 0.00105318 3.09806 0.548311 2 1.5365V0.5C2 0.367392 1.94732 0.240215 1.85355 0.146447C1.75978 0.0526784 1.63261 0 1.5 0C1.36739 0 1.24021 0.0526784 1.14645 0.146447C1.05268 0.240215 1 0.367392 1 0.5V2C1 2.39782 1.15804 2.77935 1.43934 3.06066C1.72064 3.34196 2.10217 3.5 2.5 3.5H4C4.13261 3.5 4.25978 3.44732 4.35355 3.35355C4.44732 3.25978 4.5 3.13261 4.5 3C4.5 2.86739 4.44732 2.74021 4.35355 2.64645C4.25978 2.55268 4.13261 2.5 4 2.5H2.5C2.48314 2.4975 2.46644 2.49399 2.45 2.4895C3.26476 1.66891 4.33906 1.15654 5.48953 1.03983C6.64 0.923127 7.79531 1.20933 8.75826 1.84958C9.72121 2.48983 10.4321 3.44445 10.7696 4.55047C11.1071 5.6565 11.0502 6.84538 10.6088 7.91417C10.1673 8.98296 9.36861 9.8654 8.34897 10.4109C7.32934 10.9563 6.15199 11.131 5.01791 10.9051C3.88382 10.6792 2.86329 10.0667 2.13053 9.17209C1.39776 8.27753 0.998185 7.15637 1 6C1 5.86739 0.947321 5.74021 0.853553 5.64645C0.759785 5.55268 0.632608 5.5 0.5 5.5C0.367392 5.5 0.240215 5.55268 0.146447 5.64645C0.0526784 5.74021 0 5.86739 0 6C0 7.18669 0.351894 8.34672 1.01118 9.33342C1.67047 10.3201 2.60754 11.0891 3.7039 11.5433C4.80025 11.9974 6.00665 12.1162 7.17054 11.8847C8.33443 11.6532 9.40352 11.0818 10.2426 10.2426C11.0818 9.40352 11.6532 8.33443 11.8847 7.17054C12.1162 6.00665 11.9974 4.80025 11.5433 3.7039C11.0891 2.60754 10.3201 1.67047 9.33342 1.01118C8.34672 0.351894 7.18669 0 6 0Z" />
                <path d="M6.00017 3C5.86756 3 5.74038 3.05268 5.64662 3.14645C5.55285 3.24021 5.50017 3.36739 5.50017 3.5V6C5.5002 6.1326 5.55289 6.25975 5.64667 6.3535L7.14667 7.8535C7.24097 7.94458 7.36727 7.99497 7.49837 7.99383C7.62947 7.9927 7.75487 7.94011 7.84758 7.84741C7.94028 7.7547 7.99287 7.6293 7.99401 7.4982C7.99514 7.3671 7.94475 7.2408 7.85367 7.1465L6.50017 5.793V3.5C6.50017 3.36739 6.44749 3.24021 6.35372 3.14645C6.25995 3.05268 6.13278 3 6.00017 3Z" />
              </svg>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="r.statusId == 4">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 1.15254C3.32282 1.15254 1.15254 3.32282 1.15254 6C1.15254 8.67718 3.32282 10.8475 6 10.8475C8.67718 10.8475 10.8475 8.67718 10.8475 6C10.8475 3.32282 8.67718 1.15254 6 1.15254ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0346 3.9654C8.25965 4.19044 8.25965 4.55532 8.0346 4.78037L4.78037 8.0346C4.55532 8.25965 4.19044 8.25965 3.9654 8.0346C3.74035 7.80956 3.74035 7.44468 3.9654 7.21963L7.21963 3.9654C7.44468 3.74035 7.80956 3.74035 8.0346 3.9654Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9654 3.9654C4.19044 3.74035 4.55532 3.74035 4.78037 3.9654L8.0346 7.21963C8.25965 7.44468 8.25965 7.80956 8.0346 8.0346C7.80956 8.25965 7.44468 8.25965 7.21963 8.0346L3.9654 4.78037C3.74035 4.55532 3.74035 4.19044 3.9654 3.9654Z" />
              </svg>
            </div>

          </div>

          <p *ngIf="r.requestTypeId == 1 && _branchId == 1">
            Generada por <b>{{r.applicantName | titlecase}}</b> para <b>{{r.description | lowercase}}</b> en {{r.client | titlecase}} el {{r.created}} con el folio: <strong>{{r.folio}} </strong></p>
          <p *ngIf="r.requestTypeId == 2 && _branchId == 1">
            Generada por <b>{{r.applicantName | titlecase}}</b> para <b>{{r.description | lowercase }}</b> en {{r.client | titlecase}} el {{r.created}} con el folio: <strong>{{r.folio}} </strong></p>
          <p *ngIf="r.requestTypeId == 3 && _branchId == 1">
            Generada por <b>{{r.applicantName | titlecase}}</b> con asunto <b>{{r.description | lowercase }}</b> en {{r.client | titlecase}} el {{r.created}} con el folio: <strong>{{r.folio}} </strong></p>

          <p *ngIf="r.requestTypeId == 1 && _branchId == 3">
            Generada por <b>{{r.applicantName | titlecase}}</b>  para el vehículo <b>{{r.description | lowercase }}</b> en {{r.client | titlecase}} el {{r.created}} con el folio: <strong>{{r.folio}} </strong></p>
          <p *ngIf="r.requestTypeId == 2 && _branchId == 3">
            Generada por <b>{{r.applicantName | titlecase}}</b>  para el vehículo <b>{{r.description | lowercase }}</b> en {{r.client | titlecase}} el {{r.created}} con el folio: <strong>{{r.folio}} </strong></p>
          <p *ngIf="r.requestTypeId == 3 && _branchId == 2">
            Generada por <b>{{r.applicantName | titlecase}}</b> con asunto <b>{{r.description | lowercase }}</b> en {{r.client | titlecase}} el {{r.created}} con el folio: <strong>{{r.folio}} </strong></p>
          <p *ngIf="r.requestTypeId == 3 && _branchId == 3">
            Generada por <b>{{r.applicantName | titlecase}}</b> con asunto <b>{{r.description | lowercase }}</b> en {{r.client | titlecase}} el {{r.created}} con el folio: <strong>{{r.folio}} </strong></p>
          <div class="d-end w-100">
            <button type="button"  href="" class="enlace" data-bs-toggle="modal" data-bs-target="#altaAsegurado" (click)="invokeFunctionOpenDetailRequest(r)">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6ZM0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8ZM8 4C8.21217 4 8.41566 4.08429 8.56569 4.23431C8.71571 4.38434 8.8 4.58783 8.8 4.8V7.2H11.2C11.4122 7.2 11.6157 7.28429 11.7657 7.43431C11.9157 7.58434 12 7.78783 12 8C12 8.21217 11.9157 8.41566 11.7657 8.56569C11.6157 8.71571 11.4122 8.8 11.2 8.8H8.8V11.2C8.8 11.4122 8.71571 11.6157 8.56569 11.7657C8.41566 11.9157 8.21217 12 8 12C7.78783 12 7.58434 11.9157 7.43431 11.7657C7.28429 11.6157 7.2 11.4122 7.2 11.2V8.8H4.8C4.58783 8.8 4.38434 8.71571 4.23431 8.56569C4.08429 8.41566 4 8.21217 4 8C4 7.78783 4.08429 7.58434 4.23431 7.43431C4.38434 7.28429 4.58783 7.2 4.8 7.2H7.2V4.8C7.2 4.58783 7.28429 4.38434 7.43431 4.23431C7.58434 4.08429 7.78783 4 8 4Z"/>
              </svg>
              Ver detalle
            </button>

          </div>

        </div>
      </div>
      <!--Termina Tarjeta de solicitud 1-->
    </div>

    <div class="d-between mb-3" [hidden]="reportGridViewType">
      <button class="btn secundario" href="javascript:;" aria-controls="offcanvasRight" *ngIf="!reportGridViewType"
              (click)="invokeServiceForDownloadFileRequest()">
        <img src="../../assets/img/ammia/iconos/download-azul.svg" alt="Descargar Información" class="icono-sm">Descargar Reporte
      </button>
      <button type="button" data-bs-toggle="modal" data-bs-target="#tablaCompleta" data-bs-dismiss="offcanvas" aria-label="Close" (click)="invokeLoadAllDataTable()">
        <i class="bi bi-arrows-angle-expand"></i>
      </button>
    </div>
    <div class="tab-content" infinite-scroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         [scrollWindow]="false"
         (scrolled)="_branchId == 1 ? onScrollRequestTable(): onScrollRequestCarTable()" [hidden]="reportGridViewType" >
      <div id="benefits" *ngIf=" _branchId == 1" >
        <table class="table align-middle">
          <thead>
          <th scope="col">Folio</th>
          <th scope="col">Titular</th>
          <!--<th scope="col">Parentesco</th>-->
          <th scope="col">Tipo</th>
          <!--<th scope="col">Edad</th>-->
          <th scope="col">Estado</th>
          <th scope="col">Fecha de Solicitud</th>
          <th scope="col">Fecha Cierre Solicitud</th>
          </thead>
          <tbody>
          <tr *ngFor="let rq of requestListTable">
            <td>{{rq.folio}}</td>
            <td>{{rq.name}}</td>
            <!--<td>{{rq.kinship}}</td>-->
            <td>{{rq.typeRequest}}</td>
            <!--<td>{{rq.age}}</td>-->
            <td>{{rq.statusRequest}}</td>
            <td>{{rq.created}}</td>
            <td>{{rq.closed}}</td>
          </tr>
          </tbody>
        </table>

      </div>
      <div id="vehicles" *ngIf="_branchId == 3" >
        <table class="table">
          <thead>
          <th scope="col">Folio</th>
          <th scope="col">Tipo</th>
          <th scope="col">Marca</th>
          <!--<th scope="col">Modelo</th>
          <th scope="col">Versión</th>
          <th scope="col">No. Motor</th>
          <th scope="col">No. Serie</th>-->
          <th scope="col">Fecha de Solicitud</th>
          <th scope="col">Fecha Cierre Solicitud</th>
          <th scope="col">Estatus</th>
          </thead>
          <tbody>
          <tr *ngFor="let rq of requestsVehicleList">
            <td>{{rq.folio}}</td>
            <td>{{rq.type}}</td>
            <td>{{rq.mark}}</td>
            <!--<td>{{rq.model}}</td>
            <td>{{rq.version}}</td>
            <td>{{rq.engineNumber}}</td>
            <td>{{rq.serialNumber}}</td>-->
            <td>{{rq.created}}</td>
            <td>{{rq.closed}}</td>
            <td>{{rq.status}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


  <!-- Modal de VER detalle de solicitud-->
  <div class="modal fade " id="altaAsegurado"
       data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="verEventoLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered justify-content-center">
      <div class="modal-content evento">
        <div class="modal-header evento">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="invokeFunctionCloseRequestDetail()"></button>
          <div class="d-column-start gap-8 w-100">
            <div class="d-between">
              <h3 class="d-flex gap-8 modal-title " id="verEventoLabel">
                <img *ngIf="requestDetail.requestTypeId == 1" src="../../assets/img/ammia/iconos/plus-azul-primario.svg" alt="reunion-presencial">
                <img *ngIf="requestDetail.requestTypeId == 2" src="../../assets/img/ammia/iconos/minus-rojo.svg" alt="reunion-presencial">
                <img *ngIf="requestDetail.requestTypeId == 3" src="../../assets/img/ammia/iconos/user-turquesa.svg" alt="reunion-presencial">
                {{requestDetail.movement | titlecase}}
              </h3>
              <div [ngClass]="requestDetail.statusId == 3 ? 'tag green' : requestDetail.statusId == 4 ? 'tag red' : 'tag yellow' " data-bs-toggle="tooltip" data-bs-placement="top" title="{{requestDetail.status}}">
                {{requestDetail.status | titlecase}}
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="requestDetail.statusId == 3">
                  <path d="M8.76187 3.49403L5.11593 7.16393L3.7634 5.76587L2.99893 6.52315L4.58668 8.15422C4.64549 8.21247 4.7631 8.27073 4.88071 8.32898C4.99832 8.38723 5.11593 8.38723 5.17474 8.38723C5.23354 8.38723 5.40996 8.38723 5.46876 8.32898C5.58637 8.27073 5.64518 8.21247 5.76279 8.15422L9.58514 4.25131L8.76187 3.49403Z" />
                  <path d="M10.291 1.74757C9.17365 0.640777 7.64471 0 5.99815 0C4.41041 0 2.88147 0.640777 1.76416 1.74757C0.64686 2.91262 0 4.36893 0 6C0 7.57282 0.64686 9.08738 1.76416 10.2524C2.88147 11.3592 4.41041 12 5.99815 12C7.5859 12 9.11484 11.3592 10.2321 10.2524C11.3494 9.14563 11.9963 7.63107 11.9963 6C12.0551 4.36893 11.4083 2.91262 10.291 1.74757ZM6.05696 10.835C3.35191 10.835 1.17611 8.62136 1.17611 5.94175C1.17611 3.26214 3.35191 1.04854 6.05696 1.04854C8.76201 1.04854 10.9378 3.26214 10.9378 5.94175C10.9378 8.67961 8.76201 10.835 6.05696 10.835Z" />
                </svg>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="requestDetail.statusId != 3 && requestDetail.statusId != 4">
                  <path d="M6 0C4.52275 0.00105318 3.09806 0.548311 2 1.5365V0.5C2 0.367392 1.94732 0.240215 1.85355 0.146447C1.75978 0.0526784 1.63261 0 1.5 0C1.36739 0 1.24021 0.0526784 1.14645 0.146447C1.05268 0.240215 1 0.367392 1 0.5V2C1 2.39782 1.15804 2.77935 1.43934 3.06066C1.72064 3.34196 2.10217 3.5 2.5 3.5H4C4.13261 3.5 4.25978 3.44732 4.35355 3.35355C4.44732 3.25978 4.5 3.13261 4.5 3C4.5 2.86739 4.44732 2.74021 4.35355 2.64645C4.25978 2.55268 4.13261 2.5 4 2.5H2.5C2.48314 2.4975 2.46644 2.49399 2.45 2.4895C3.26476 1.66891 4.33906 1.15654 5.48953 1.03983C6.64 0.923127 7.79531 1.20933 8.75826 1.84958C9.72121 2.48983 10.4321 3.44445 10.7696 4.55047C11.1071 5.6565 11.0502 6.84538 10.6088 7.91417C10.1673 8.98296 9.36861 9.8654 8.34897 10.4109C7.32934 10.9563 6.15199 11.131 5.01791 10.9051C3.88382 10.6792 2.86329 10.0667 2.13053 9.17209C1.39776 8.27753 0.998185 7.15637 1 6C1 5.86739 0.947321 5.74021 0.853553 5.64645C0.759785 5.55268 0.632608 5.5 0.5 5.5C0.367392 5.5 0.240215 5.55268 0.146447 5.64645C0.0526784 5.74021 0 5.86739 0 6C0 7.18669 0.351894 8.34672 1.01118 9.33342C1.67047 10.3201 2.60754 11.0891 3.7039 11.5433C4.80025 11.9974 6.00665 12.1162 7.17054 11.8847C8.33443 11.6532 9.40352 11.0818 10.2426 10.2426C11.0818 9.40352 11.6532 8.33443 11.8847 7.17054C12.1162 6.00665 11.9974 4.80025 11.5433 3.7039C11.0891 2.60754 10.3201 1.67047 9.33342 1.01118C8.34672 0.351894 7.18669 0 6 0Z" />
                  <path d="M6.00017 3C5.86756 3 5.74038 3.05268 5.64662 3.14645C5.55285 3.24021 5.50017 3.36739 5.50017 3.5V6C5.5002 6.1326 5.55289 6.25975 5.64667 6.3535L7.14667 7.8535C7.24097 7.94458 7.36727 7.99497 7.49837 7.99383C7.62947 7.9927 7.75487 7.94011 7.84758 7.84741C7.94028 7.7547 7.99287 7.6293 7.99401 7.4982C7.99514 7.3671 7.94475 7.2408 7.85367 7.1465L6.50017 5.793V3.5C6.50017 3.36739 6.44749 3.24021 6.35372 3.14645C6.25995 3.05268 6.13278 3 6.00017 3Z" />
                </svg>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="requestDetail.statusId == 4">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 1.15254C3.32282 1.15254 1.15254 3.32282 1.15254 6C1.15254 8.67718 3.32282 10.8475 6 10.8475C8.67718 10.8475 10.8475 8.67718 10.8475 6C10.8475 3.32282 8.67718 1.15254 6 1.15254ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0346 3.9654C8.25965 4.19044 8.25965 4.55532 8.0346 4.78037L4.78037 8.0346C4.55532 8.25965 4.19044 8.25965 3.9654 8.0346C3.74035 7.80956 3.74035 7.44468 3.9654 7.21963L7.21963 3.9654C7.44468 3.74035 7.80956 3.74035 8.0346 3.9654Z" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9654 3.9654C4.19044 3.74035 4.55532 3.74035 4.78037 3.9654L8.0346 7.21963C8.25965 7.44468 8.25965 7.80956 8.0346 8.0346C7.80956 8.25965 7.44468 8.25965 7.21963 8.0346L3.9654 4.78037C3.74035 4.55532 3.74035 4.19044 3.9654 3.9654Z" />
                </svg>
              </div>

            </div>
            <h5>{{requestDetail.subBranch | titlecase}}</h5>
          </div>

        </div>
        <div class="modal-body">
          <div class="datos-grupo">
            <div class="datos-row">
              <div>
                <p class="label">Número de folio</p>
                <p class="dato">{{requestDetail.folio}}</p>
              </div>
            </div>
            <div class="datos-row">
              <div>
                <p class="label">Solicitante</p>
                <p class="dato">{{requestDetail.applicantName | titlecase}}</p>
              </div>
              <div>
                <p class="label">Póliza</p>
                <p class="dato">{{requestDetail.number}}</p>
              </div>
            </div>
            <div class="datos-row">
              <div>
                <p class="label" *ngIf="_branchId == 1 && (requestDetail.requestTypeId == 1 || requestDetail.requestTypeId == 2)">Asegurado</p>
                <p class="label" *ngIf="_branchId == 3 && (requestDetail.requestTypeId == 1 || requestDetail.requestTypeId == 2)">Vehículo</p>
                <p class="label" *ngIf="requestDetail.requestTypeId == 3">Asunto</p>
                <p class="dato">{{requestDetail.description | titlecase }}</p>
              </div>
              <div>
                <p class="label">Tipo de asegurado</p>
                <p class="dato">{{requestDetail.type}}</p>
              </div>
            </div>
            <div class="datos-row">
              <div>
                <p class="label">Razón social</p>
                <p class="dato">{{requestDetail.client}}</p>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

  <!-- Nueva solicitud DANOS -->
  <div class="offcanvas offcanvas-end hacer-solicitud" tabindex="-1" id="nuevaSolicitudDanosComponent3" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header modal-header">

      <h3 class="modal-title" id="offcanvasRightLabel">Hacer solicitud</h3>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body p-3 p-md-5">


      <form [formGroup]="myFormRequestdamage">
        <div class="mb-3">
          <p class="solicitud-parrafo">Solicita correcciones o modificaciones de los datos de bienes asegurados o de las pólizas.</p>

        </div>

        <!-- SEPARADOR -->
        <div class="separador-tarjetas">
          <h4>Pólizas</h4>
          <hr>
        </div>

        <div class="w-100 d-center" >
          <div  class="poliza-vehiculo-card" *ngFor="let p of policyAmmiaList">
            <a href="javascript:;" (click)="invokeServiceGetClientByPolicy(p)">
              <h5>Daños</h5>
              <h6>{{p.subBranch | titlecase}}</h6>
              <h6>{{p.number}}</h6>
            </a>

          </div>
        </div>
        <br>
        <!--- cliente de siniestro --->
        <div class="form-row">
          <div class="form-group">
            <label >Razón Social</label>
            <select class="form-select ammiaAdmin-style  mb-3" aria-label=" example" [(ngModel)]="dataRequestDamage.clientId"
                    name="relationshipDep" required formControlName="client">
              <option *ngFor="let item of clientList" [value]="item.clientId"
              >{{item.name}}</option>
            </select>

          </div>
        </div>

        <!-- Asunto de la solicitud-->
        <div class="form-row">
          <div class="form-group">
            <label for="asuntoSolicitud">Asunto de la solicitud</label>
            <input type="text" class="form-control ammiaAdmin-style"
                   id="asuntoSolicitud" name="asuntoSolicitud" required placeholder="Ingresa asunto" formControlName="subject"
                   [(ngModel)]="dataRequestDamage.subject">
            <div class="invalid-feedback">
              Please choose a username.
            </div>
          </div>
        </div>

        <!-- Descripcion de la solicitud -->
        <div class="form-row">
          <div class="form-group">
            <label for="descripcionSolicitud">Descripción de la solicitud</label>
            <textarea type="text" class="form-control ammiaAdmin-style descripcion" id="descripcionSolicitud" name="descripcionSolicitud"
                      formControlName="description" [(ngModel)]="dataRequestDamage.description"></textarea>
          </div>
        </div>


        <!-- SEPARADOR -->
        <!--<div class="separador-tarjetas">
          <h4>Datos del bien asegurado</h4>
          <hr>
        </div>
        <div class="form-row mt-4">
          <div class="form-group">
            <label for="buscarAsegurado">Buscar bien asegurado</label>
            <div class="search-container d-between w-100">
              <input type="text" class=" form-control ammiaAdmin-style" placeholder="Buscar por tipo de bien, descripción o número de póliza"  >
              <img src="../../assets/img/ammia/iconos/search.svg" alt="Ícono de Agregar tarea" class="search-icon">
            </div>
          </div>
        </div>-->


      </form>

    </div>

    <div class=" offcanvas-footer d-center mt-3">
      <button class="btn primario " id="" type="button" aria-controls="offcanvasRight" data-bs-toggle="offcanvas" data-bs-target="#solicitudReportada"
              [disabled]="myFormRequestdamage.invalid" (click)="invokeFunctionSaveRequestGeneralDamage()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5237 6.98805L10.2319 14.3279L7.52681 11.5317L5.99787 13.0463L9.17336 16.3084C9.29097 16.4249 9.52619 16.5415 9.76142 16.658C9.99664 16.7745 10.2319 16.7745 10.3495 16.7745C10.4671 16.7745 10.8199 16.7745 10.9375 16.658C11.1727 16.5415 11.2904 16.4249 11.5256 16.3084L19.1703 8.50262L17.5237 6.98805Z" />
          <path d="M20.5819 3.49515C18.3473 1.28155 15.2894 0 11.9963 0C8.82081 0 5.76293 1.28155 3.52833 3.49515C1.29372 5.82524 0 8.73786 0 12C0 15.1456 1.29372 18.1748 3.52833 20.5049C5.76293 22.7184 8.82081 24 11.9963 24C15.1718 24 18.2297 22.7184 20.4643 20.5049C22.6989 18.2913 23.9926 15.2621 23.9926 12C24.1102 8.73786 22.8165 5.82524 20.5819 3.49515ZM12.1139 21.6699C6.70382 21.6699 2.35222 17.2427 2.35222 11.8835C2.35222 6.52427 6.70382 2.09709 12.1139 2.09709C17.524 2.09709 21.8756 6.52427 21.8756 11.8835C21.8756 17.3592 17.524 21.6699 12.1139 21.6699Z" />
        </svg>

        Generar solicitud
      </button>
    </div>

  </div>

  <!-- solicitud REPORTADO -->
  <div class="offcanvas offcanvas-end hacer-solicitud" tabindex="-1" id="solicitudReportada" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header modal-header">

      <h3 class="modal-title" id="offcanvasRightLabel">Reporte de solicitud generado</h3>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="w-100 d-column-center p-3 p-md-5" *ngIf="responseServiceDamage == 1">
      <a href="javascript:;"   aria-controls="offcanvasRight">
        <dotlottie-player src="https://lottie.host/bb64e4a0-a834-4f97-8152-fba5e5234f5a/3vObIm0Ks5.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
      </a>

      <p class="">Se ha generado el reporte de tu solicitud. Un ejecutivo Surexs te contactará lo antes posible y te brindará atención.</p>


      <div class="d-center w-100 mt-5">
        <button class="btn primario" id="btnGenerarSolicitudVehiculo"type="button" data-bs-dismiss="offcanvas" aria-label="Close">
          <img src="../../assets/img/ammia/iconos/check-azuld2.svg" alt="" class="icono-sm">
          Entendido
        </button>
      </div>
    </div>

    <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="pills-profile-tab2" *ngIf="responseServiceDamage == 0">
      <div class="w-100 d-column-center">
        <a href="javascript:;"  aria-controls="offcanvasRight" >
          <dotlottie-player src="https://lottie.host/06fc7b75-f5d2-4524-86f8-fbbe96bf456a/VGiGPUrFSr.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
        </a>
        <div>
          <p class="solicitud-parrafo">Estamos validando tu solicitud. No cierres ni actualices esta venta. </p>
        </div>
      </div>
    </div>
    <div class="w-100 d-column-center" *ngIf="responseServiceDamage == 2">
      <a href="javascript:;"   aria-controls="offcanvasRight" >
        <dotlottie-player src="https://lottie.host/4c9dabcd-e6c6-41c2-84c9-7cf1b79890af/yn2rFJ1ICK.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
      </a>
      <div>
        <p class="solicitud-parrafo">Se ha producido un error al dar de alta la solicitud, por favor contacta al administrador.
        </p>
      </div>
    </div>

  </div>

  <div class="modal-shadow-evento"></div>

  <!-- Modal de Información completa solicitudes -->
  <div class="modal fade" id="tablaCompleta"  tabindex="-1" aria-labelledby="verEventoLabel1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered justify-content-center modal-xl xxl">
      <div class="modal-content evento">
        <div class="modal-header evento">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="invokeFunctionCloseModalTable()"></button>
          <div class="d-column-start gap-8">
            <h3 class="d-flex gap-8 modal-title " id="verEventoLabel1">
              Vista Completa
            </h3>
            <button class="btn secundario mb-4" type="button" (click)="invokeServiceForDownloadFileRequest()">
              <img src="../../assets/img/ammia/iconos/check-azuld2.svg" alt="check" class="icono-sm">
              Descargar Reporte
            </button>
          </div>

        </div>
        <div class="modal-body">
          <div class="tab-content" infinite-scroll
               [infiniteScrollDistance]="2"
               [infiniteScrollThrottle]="50"
               [scrollWindow]="false"
               (scrolled)="_branchId == 1 ? onScrollRequestTable(): onScrollRequestCarTable()" [hidden]="reportGridViewType" >
            <div id="benefitsM" *ngIf=" _branchId == 1" >
              <table class="table align-middle">
                <thead>
                <th scope="col">Folio</th>
                <th scope="col">Titular</th>
                <th scope="col">Parentesco</th>
                <th scope="col">Tipo</th>
                <th scope="col">Edad</th>
                <th scope="col">Estado</th>
                <th scope="col">Fecha de Solicitud</th>
                <th scope="col">Fecha Cierre Solicitud</th>
                </thead>
                <tbody>
                <tr *ngFor="let rq of requestListTable">
                  <td>{{rq.folio}}</td>
                  <td>{{rq.name}}</td>
                  <td>{{rq.kinship}}</td>
                  <td>{{rq.typeRequest}}</td>
                  <td>{{rq.age}}</td>
                  <td>{{rq.statusRequest}}</td>
                  <td>{{rq.created}}</td>
                  <td>{{rq.closed}}</td>
                </tr>
                </tbody>
              </table>

            </div>
            <div id="vehiclesM" *ngIf="_branchId == 3" >
              <table class="table align-middle">
                <thead>
                <th scope="col">Folio</th>
                <th scope="col">Tipo</th>
                <!--<th scope="col">Vehículo</th>
                <th scope="col">Modelo</th>
                <th scope="col">Versión</th>-->
                <th scope="col">No. Motor</th>
                <th scope="col">No. Serie</th>
                <th scope="col">Fecha de Solicitud</th>
                <th scope="col">Fecha Cierre Solicitud</th>
                <th scope="col">Estatus</th>
                </thead>
                <tbody>
                <tr *ngFor="let rq of requestsVehicleList">
                  <td>
                    <div class="d-column-start ">
                      <a href="javascript:;">
                        <h5>{{rq.folio}}</h5>
                        <h6>Marca: {{rq.mark}}</h6>
                        <h6>Modelo:  {{rq.model}}</h6>
                        <h6>Version: {{rq.version}}</h6>
                      </a>
                    </div>
                  </td>
                  <td>{{rq.type}}</td>
                  <!--<td>{{rq.mark}}</td>
                  <td>{{rq.model}}</td>
                  <td>{{rq.version}}</td>-->
                  <td>{{rq.engineNumber}}</td>
                  <td>{{rq.serialNumber}}</td>
                  <td>{{rq.created}}</td>
                  <td>{{rq.closed}}</td>
                  <td>{{rq.status}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
        <div class="modal-footer">

          <button class="btn secundario mb-4" id="btnContinuar" type="button" data-bs-dismiss="modal" aria-label="Close" (click)="invokeFunctionCloseModalTable()">
            <img src="../../assets/img/ammia/iconos/back-azul-ammia.svg" alt="check" class="icono-sm">

            Volver
          </button>
        </div>

      </div>
    </div>
  </div>



</div>
