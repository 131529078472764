import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../api.service';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SharedService} from '../shared-service.service';
@Component({
  selector: 'app-event-ammia-benefits',
  templateUrl: './event-ammia-benefits.component.html',
  styleUrls: ['./event-ammia-benefits.component.css']
})
export class EventAmmiaBenefitsComponent implements OnInit {
  get branchId(): number {
    return this._branchId;
  }
  @Input()
  set branchId(value: number) {
    this._branchId = value;
  }

  // tslint:disable-next-line:variable-name
  protected _branchId: number;
  dataFilterRequest: any = {};
  dataFilterIncident: any = {};
  requestList: any = [];
  incidentList: any = [];
  requestDetail: any = {};
  incidentDetail: any = {};
  requestPage: number;
  incidentPage: number;
  totalRequestRecords: number;
  totalIncidentRecords: number;
  attachmentList: any = [];
  tabId: number;
  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public shared: SharedService) {

    /**
     * Observable para monitorear sí es necesario recargar la informacion de eventos, Solicitudes, siniestros
     */
    this.shared.fUpdateRequestsObservable().subscribe(
      fRequests => {
        if (fRequests) {
          this.requestPage = 1; // Reiniciamos el contador de paginas de eventos
          this.requestList = []; // Reiniciamos la lista de eventos
          this.invokeServiceGetRequestPages();
        }
      }
    );
  }

  ngOnInit(): void {
    this.tabId = 1;
    this.requestPage = 1;
    this.incidentPage = 1;
    this.invokeFunctionInitFilters();
    this.invokeServiceGetRequestPages();
  }

  invokeFunctionInitFilters() {
    this.dataFilterRequest = {
      page: 1,
      size: 5,
      typeId: 0,
      statusId: 0,
      branchId: this._branchId
    };
    this.dataFilterIncident = {
      page: 1,
      size: 5,
      branchId: this._branchId
    };
  }

  onScrollRequest() {
    console.log('entra scroll solicitudes');
    if (this.totalRequestRecords === this.requestList.length) {
      return;
    }
    this.requestPage++;
    this.invokeServiceGetRequestPages();
  }

  invokeServiceGetRequestPages() {
    this.dataFilterRequest.page = this.requestPage;
    console.log(this.dataFilterRequest);
    this.api.getRequestByBranch(this.dataFilterRequest)
      .then(
        (response: any) => {
          if (response != null) {
            this.totalRequestRecords = response.total;
            this.requestList = this.requestList.concat(response.request);
          }
        }, error => {
          console.info(error);
        });
  }

  onScrollIncident() {
    if (this.totalIncidentRecords === this.incidentList.length) {
      return;
    }
    this.incidentPage++;
    this.invokeServiceGetIncidentPages();
  }

  invokeServiceGetIncidentPages() {
    this.dataFilterIncident.page = this.incidentPage;
    console.log(this.dataFilterIncident);
    this.api.getIncidentByBranch(this.dataFilterIncident)
      .then(
        (response: any) => {
          if (response != null) {
            this.totalIncidentRecords = response.total;
            this.incidentList = this.incidentList.concat(response.incidents);
          }
        }, error => {
          console.info(error);
        });
  }

  invokeFunctionGetDetailRequest(request: any) {
    const objectData = {
      requestId: request.requestId,
      requestDetailId: request.requestDetailId
    };
    this.api.getRequestDetailById(objectData)
      .then(
        (response: any) => {
          if (response != null) {
            this.requestDetail = response[0];
          }
        }, error => {
          console.info(error);
        });
  }
  invokeServiceGetDetailIncident(incident: any) {
    console.log(incident);
    const objectData = {
      incidentId: incident.incidentId
    };
    this.api.getIncidentDetailById(objectData)
      .then(
        (response: any) => {
          if (response != null) {
            this.incidentDetail = response;
          }
        }, error => {
          console.info(error);
        });
  }

  invokeFunctionDetailIncident(incident: any) {
    this.invokeServiceGetDetailIncident(incident);
    this.invokeServiceGetAttachmentsByIncident(incident.incidentId);
    /*const modalShadowElement = document.querySelector('.modal-shadow-evento');
    const shadowGeneral = document.querySelector('.modal-backdrop');
    modalShadowElement.classList.add('visible');
    shadowGeneral.classList.add('no-visible');*/
  }

  invokeFunctionCloseModalIncident() {
    const modalShadowElement = document.querySelector('.modal-shadow-evento');
    modalShadowElement.classList.remove('visible');
    this.incidentDetail = [];
  }

  invokeServiceGetAttachmentsByIncident(incident: number) {
    const objectData = {
      incidentId: incident
    };
    this.api.getAttachmentsByIncident(objectData)
      .then(
        (response: any) => {
          if (response != null) {
            this.attachmentList = response;
          }
        }, error => {
          console.info(error);
        });
  }

  public invokeServiceForDownloadFileAttachment(attachment: any)  {
    const objData = {
      attachmentId: attachment.attachmentId
    };
    this.api.getAttachmentIncidentById(objData)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  invokeFunctionChangeTab(id: number) {
    this.tabId = id;
    if (this.tabId === 1) {
      this.requestPage = 1;
      this.requestList = [];
      this.incidentList =  [];
      this.invokeServiceGetRequestPages();
    } else {
      this.incidentPage = 1;
      this.incidentList =  [];
      this.requestList = [];
      this.invokeServiceGetIncidentPages();
    }
  }


  invokeFunctionOpenDetailRequest(r: any) {
    /*const modalShadowElement = document.querySelector('.modal-shadow-evento');
    const shadowGeneral = document.querySelector('.modal-backdrop');
    modalShadowElement.classList.add('visible');
    shadowGeneral.classList.add('no-visible');
    console.log(r);*/
    this.invokeFunctionGetDetailRequest(r);
  }
  invokeFunctionCloseRequestDetail() {
    const modalShadowElement = document.querySelector('.modal-shadow-evento');
    modalShadowElement.classList.remove('visible');
    this.requestDetail = [];
  }

}
