<div class="row" style="padding-left: 1rem;">
  <!-- CONTAINER GENERAL -->
  <main class="container-fluid container-general">

    <!--  Contenedor row index -->
    <div class="row container-dashboard">
      <!-- HEADER COBRANZAS  -->
      <div class="row resumen-beneficios">
        <div class="col-12 resumen-beneficios-header mb-0 pb-0">
          <div class="d-flex w-in">
            <h3 class="white-space-nowrap pe-3">Cobranza</h3>
            <hr>
          </div>

        </div>
      </div>

      <div class="row">
        <!-- NAV PILLS TABLAS DE COBRANZA -->
        <div class="nav nav-tabs nueva-solicitud cobranza   " id="pills-tab2" role="tablist">
          <div class="nav-item" role="presentation" *ngIf="dataBranchGroup[0].totalNetPremium == 1">
            <button class="nav-link active" id="altaIndividual" data-bs-toggle="pill" data-bs-target="#cobranzaBeneficios" type="button" role="tab"
                    aria-controls="altaIndividual" aria-selected="true" (click)="invokeFunctionGetReceipts(1)">
              Programa de Beneficios
            </button>
          </div>
          <div class="nav-item" role="presentation" *ngIf="dataBranchGroup[2].totalNetPremium == 1">
            <button class="nav-link" id="altaMultiple" data-bs-toggle="pill" data-bs-target="#cobranzaVehiculos" type="button" role="tab"
                    aria-controls="altaMultiple" aria-selected="false" (click)="invokeFunctionGetReceipts(3)">
              Programa de Vehículos
            </button>
          </div>
          <div class="nav-item" role="presentation" *ngIf="dataBranchGroup[1].totalNetPremium == 1">
            <button class="nav-link" id="solicitudGeneral" data-bs-toggle="pill" data-bs-target="#cobranzaDanos" type="button" role="tab"
                    aria-controls="solicitudGeneral" aria-selected="false" (click)="invokeFunctionGetReceipts(2)">
              Programa de Daños y Especiales
            </button>
          </div>
        </div>
        <!-- TABLAS DE COBRANZA CONTAINER DE NAV -->
        <div class="tab-content " id="alta-baja-solicitud-content">
          <!-- Tabla BENEFICIOS -->
          <div class="tab-pane fade show active" id="cobranzaBeneficios" role="tabpanel" aria-labelledby="pills-home-tab2">
            <div class="row cobranzas-contenedor">
              <div class="col-12 d-between py-2 mb-2">
                <div class="d-between-end gap-16 mb-2">
                  <div class="form-group w-min-content-20">
                    <label>Filtrar por estatus</label>
                    <select class="form-select ammiaAdmin-style  mb-3" aria-label=" example" [(ngModel)]="dataFilterReceipt.statusId"
                            (change)="invokeFunctionLoadChangeFilter()" name="relationshipDep" required >
                      <option *ngFor="let item of statusList" [value]="item.statusId"
                      >{{item.description}}</option>
                    </select>
                  </div>
                  <div class="form-group w-min-content-20">
                    <label>Filtrar por Poliza</label>
                    <div class="search-container d-between w-100">
                      <select class="form-select ammiaAdmin-style  mb-3" aria-label=" example" [(ngModel)]="dataFilterReceipt.policyId"
                              (change)="invokeFunctionLoadChangeFilter()" name="relationshipDep" required >
                        <option *ngFor="let item of policyList" [value]="item.policyId"
                        >{{item.number}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <a href="javascript:;">
                  <button  class="btn secundario ms-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#addContacto"
                  (click)="invokeServiceForDownloadFileReceipt()">
                    <img src="../../assets/img/ammia/iconos/download-azul.svg" alt="Ícono de Agregar tarea" class="icono-sm">
                    Descargar Reporte
                  </button>
                </a>
              </div>
              <div class="col-12  table-container-cobranzas"
                   infinite-scroll
                   [infiniteScrollDistance]="2"
                   [infiniteScrollThrottle]="50"
                   [scrollWindow]="false"
                   (scrolled)="onScrollReceipts()">
                <table class="table table-borderless h-in table-fixed-header ">
                  <thead>
                  <tr class="">
                    <th scope="col"><h4>Seguro</h4></th>
                    <th scope="col"><h4>Aseguradora</h4></th>
                    <th scope="col"><h4>Estatus</h4></th>
                    <th scope="col"><h4>Forma de pago</h4></th>
                    <th scope="col"><h4>Fecha de pago</h4></th>
                    <th scope="col"><h4>Prima Neta</h4></th>
                    <th scope="col"><h4>Prima Total</h4></th>
                    <th scope="col"><h4>IVA</h4></th>
                    <th scope="col"><h4>Derechos</h4></th>
                    <th scope="col"><h4>Recargos</h4></th>
                    <th scope="col"><h4></h4></th>
                  </tr>
                  </thead>
                  <tbody class="">
                  <tr class="asegurados-beneficios-card" *ngFor="let r of receiptList">

                    <td>
                      <div class="d-column-start ">
                        <a href="javascript:;">
                          <h5>{{r.typePolicy | titlecase}}</h5>
                          <h6>No. de póliza: <br> {{ r.numberPolicy }}</h6>
                        </a>
                      </div>
                    </td>
                    <td>
                      <p>{{ r.insuranceName | titlecase }}</p>
                    </td>
                    <td>
                      <div  [ngClass]="r.status == 'PAGADO' ? 'tag pago' : r.status == 'PENDIENTE' ? 'tag yellow' : 'tag red' "
                           data-bs-toggle="tooltip" data-bs-placement="top" title="Pago {{r.status | lowercase}}"  >
                        {{r.status | lowercase }}
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="r.status == 'PAGADO' " >
                          <path
                            d="M2.33333 3.08301C2.33333 3.81842 1.73542 4.41634 1 4.41634V7.74968C1.73542 7.74968 2.33333 8.34759 2.33333 9.08301H9.66667C9.66667 8.34759 10.2646 7.74968 11 7.74968V4.41634C10.2646 4.41634 9.66667 3.81842 9.66667 3.08301H2.33333ZM0 3.41634C0 2.68092 0.597917 2.08301 1.33333 2.08301H10.6667C11.4021 2.08301 12 2.68092 12 3.41634V8.74968C12 9.48509 11.4021 10.083 10.6667 10.083H1.33333C0.597917 10.083 0 9.48509 0 8.74968V3.41634ZM3.66667 6.08301C3.66667 5.77659 3.72702 5.47317 3.84428 5.19008C3.96154 4.90699 4.13341 4.64976 4.35008 4.43309C4.56675 4.21642 4.82398 4.04455 5.10707 3.92729C5.39017 3.81003 5.69358 3.74967 6 3.74967C6.30642 3.74967 6.60983 3.81003 6.89293 3.92729C7.17602 4.04455 7.43325 4.21642 7.64992 4.43309C7.86659 4.64976 8.03846 4.90699 8.15572 5.19008C8.27298 5.47317 8.33333 5.77659 8.33333 6.08301C8.33333 6.38943 8.27298 6.69284 8.15572 6.97594C8.03846 7.25903 7.86659 7.51625 7.64992 7.73292C7.43325 7.94959 7.17602 8.12147 6.89293 8.23873C6.60983 8.35599 6.30642 8.41634 6 8.41634C5.69358 8.41634 5.39017 8.35599 5.10707 8.23873C4.82398 8.12147 4.56675 7.94959 4.35008 7.73292C4.13341 7.51625 3.96154 7.25903 3.84428 6.97594C3.72702 6.69284 3.66667 6.38943 3.66667 6.08301ZM5.33333 5.08301C5.33333 5.26634 5.48333 5.41634 5.66667 5.41634V6.74968H5.5C5.31667 6.74968 5.16667 6.89968 5.16667 7.08301C5.16667 7.26634 5.31667 7.41634 5.5 7.41634H6H6.5C6.68333 7.41634 6.83333 7.26634 6.83333 7.08301C6.83333 6.89968 6.68333 6.74968 6.5 6.74968H6.33333V5.08301C6.33333 4.89967 6.18333 4.74967 6 4.74967H5.66667C5.48333 4.74967 5.33333 4.89967 5.33333 5.08301Z" />
                        </svg>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="r.status == 'PENDIENTE' " >
                          <path
                                d="M6 0C4.52275 0.00105318 3.09806 0.548311 2 1.5365V0.5C2 0.367392 1.94732 0.240215 1.85355 0.146447C1.75978 0.0526784 1.63261 0 1.5 0C1.36739 0 1.24021 0.0526784 1.14645 0.146447C1.05268 0.240215 1 0.367392 1 0.5V2C1 2.39782 1.15804 2.77935 1.43934 3.06066C1.72064 3.34196 2.10217 3.5 2.5 3.5H4C4.13261 3.5 4.25978 3.44732 4.35355 3.35355C4.44732 3.25978 4.5 3.13261 4.5 3C4.5 2.86739 4.44732 2.74021 4.35355 2.64645C4.25978 2.55268 4.13261 2.5 4 2.5H2.5C2.48314 2.4975 2.46644 2.49399 2.45 2.4895C3.26476 1.66891 4.33906 1.15654 5.48953 1.03983C6.64 0.923127 7.79531 1.20933 8.75826 1.84958C9.72121 2.48983 10.4321 3.44445 10.7696 4.55047C11.1071 5.6565 11.0502 6.84538 10.6088 7.91417C10.1673 8.98296 9.36861 9.8654 8.34897 10.4109C7.32934 10.9563 6.15199 11.131 5.01791 10.9051C3.88382 10.6792 2.86329 10.0667 2.13053 9.17209C1.39776 8.27753 0.998185 7.15637 1 6C1 5.86739 0.947321 5.74021 0.853553 5.64645C0.759785 5.55268 0.632608 5.5 0.5 5.5C0.367392 5.5 0.240215 5.55268 0.146447 5.64645C0.0526784 5.74021 0 5.86739 0 6C0 7.18669 0.351894 8.34672 1.01118 9.33342C1.67047 10.3201 2.60754 11.0891 3.7039 11.5433C4.80025 11.9974 6.00665 12.1162 7.17054 11.8847C8.33443 11.6532 9.40352 11.0818 10.2426 10.2426C11.0818 9.40352 11.6532 8.33443 11.8847 7.17054C12.1162 6.00665 11.9974 4.80025 11.5433 3.7039C11.0891 2.60754 10.3201 1.67047 9.33342 1.01118C8.34672 0.351894 7.18669 0 6 0Z" />
                          <path
                                d="M6.00017 3C5.86756 3 5.74038 3.05268 5.64662 3.14645C5.55285 3.24021 5.50017 3.36739 5.50017 3.5V6C5.5002 6.1326 5.55289 6.25975 5.64667 6.3535L7.14667 7.8535C7.24097 7.94458 7.36727 7.99497 7.49837 7.99383C7.62947 7.9927 7.75487 7.94011 7.84758 7.84741C7.94028 7.7547 7.99287 7.6293 7.99401 7.4982C7.99514 7.3671 7.94475 7.2408 7.85367 7.1465L6.50017 5.793V3.5C6.50017 3.36739 6.44749 3.24021 6.35372 3.14645C6.25995 3.05268 6.13278 3 6.00017 3Z" />

                        </svg>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="r.status == 'CANCELADO'">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6 1.15254C3.32282 1.15254 1.15254 3.32282 1.15254 6C1.15254 8.67718 3.32282 10.8475 6 10.8475C8.67718 10.8475 10.8475 8.67718 10.8475 6C10.8475 3.32282 8.67718 1.15254 6 1.15254ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0346 3.9654C8.25965 4.19044 8.25965 4.55532 8.0346 4.78037L4.78037 8.0346C4.55532 8.25965 4.19044 8.25965 3.9654 8.0346C3.74035 7.80956 3.74035 7.44468 3.9654 7.21963L7.21963 3.9654C7.44468 3.74035 7.80956 3.74035 8.0346 3.9654Z" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9654 3.9654C4.19044 3.74035 4.55532 3.74035 4.78037 3.9654L8.0346 7.21963C8.25965 7.44468 8.25965 7.80956 8.0346 8.0346C7.80956 8.25965 7.44468 8.25965 7.21963 8.0346L3.9654 4.78037C3.74035 4.55532 3.74035 4.19044 3.9654 3.9654Z" />
                        </svg>

                      </div>
                    </td>
                    <td>
                      <p class="text-center">{{r.payment | lowercase }}</p>
                    </td>
                    <td>
                      <p> {{r.paymentDate }}</p>
                    </td>
                    <td>
                      <p><b>{{ r.netPremium | currency: '$' }}</b></p>
                    </td>
                    <td>
                      <p>{{ r.totalPremium | currency: '$' }}</p>
                    </td>
                    <td>
                      <p>{{ r.iva | currency: '$' }}</p>
                    </td>
                    <td>
                      <p>{{r.rigths === null ? 0 : r.rigths | currency: '$' }}</p>
                    </td>
                    <td>
                      <p> {{ r.charges === null ? 0 : r.charges | currency: '$' }}</p>
                    </td>
                    <td>
                      <button type="button"  [ngClass]="r.colorInsuranceHex == '0' ? 'enlace-disable': 'enlace'"
                              (click)="invokeServiceForDownloadReceiptAttachmentZipFile(r)" [disabled]="r.colorInsuranceHex == '0'">
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.7 9.6C15.4878 9.6 15.2843 9.68429 15.1343 9.83432C14.9843 9.98434 14.9 10.1878 14.9 10.4V13.6C14.9 13.8122 14.8157 14.0157 14.6657 14.1657C14.5157 14.3157 14.3122 14.4 14.1 14.4H2.9C2.68783 14.4 2.48434 14.3157 2.33431 14.1657C2.18429 14.0157 2.1 13.8122 2.1 13.6V10.4C2.1 10.1878 2.01571 9.98434 1.86569 9.83432C1.71566 9.68429 1.51217 9.6 1.3 9.6C1.08783 9.6 0.884344 9.68429 0.734315 9.83432C0.584285 9.98434 0.5 10.1878 0.5 10.4V13.6C0.5 14.2365 0.752856 14.847 1.20294 15.2971C1.65303 15.7471 2.26348 16 2.9 16H14.1C14.7365 16 15.347 15.7471 15.7971 15.2971C16.2471 14.847 16.5 14.2365 16.5 13.6V10.4C16.5 10.1878 16.4157 9.98434 16.2657 9.83432C16.1157 9.68429 15.9122 9.6 15.7 9.6ZM7.932 10.968C8.00808 11.0408 8.0978 11.0979 8.196 11.136C8.29176 11.1783 8.3953 11.2002 8.5 11.2002C8.6047 11.2002 8.70824 11.1783 8.804 11.136C8.9022 11.0979 8.99192 11.0408 9.068 10.968L12.268 7.768C12.4186 7.61736 12.5033 7.41304 12.5033 7.2C12.5033 6.98696 12.4186 6.78264 12.268 6.632C12.1174 6.48136 11.913 6.39673 11.7 6.39673C11.487 6.39673 11.2826 6.48136 11.132 6.632L9.3 8.472V0.8C9.3 0.587827 9.21572 0.384344 9.06569 0.234315C8.91566 0.0842855 8.71217 0 8.5 0C8.28783 0 8.08434 0.0842855 7.93431 0.234315C7.78429 0.384344 7.7 0.587827 7.7 0.8V8.472L5.868 6.632C5.79341 6.55741 5.70486 6.49824 5.6074 6.45787C5.50994 6.4175 5.40549 6.39673 5.3 6.39673C5.19451 6.39673 5.09006 6.4175 4.9926 6.45787C4.89514 6.49824 4.80659 6.55741 4.732 6.632C4.65741 6.70659 4.59824 6.79514 4.55787 6.8926C4.5175 6.99006 4.49673 7.09451 4.49673 7.2C4.49673 7.30549 4.5175 7.40994 4.55787 7.5074C4.59824 7.60486 4.65741 7.69341 4.732 7.768L7.932 10.968Z" />
                        </svg>
                        Descargar
                      </button>
                    </td>

                  </tr>
                  </tbody>
                </table>
              </div>



            </div>
          </div>
          <!-- Tabla VEHICULOS -->
          <div class="tab-pane fade " id="cobranzaVehiculos" role="tabpanel" aria-labelledby="pills-home-tab2">
            <div class="row cobranzas-contenedor">
              <div class="col-12 d-between py-2 mb-2">
                <div class="d-between-end gap-16 mb-2">
                  <div class="form-group w-min-content-20">
                    <label>Filtrar por estatus</label>
                    <select class="form-select ammiaAdmin-style  mb-3" aria-label=" example" [(ngModel)]="dataFilterReceipt.statusId"
                            (change)="invokeFunctionLoadChangeFilter()" name="relationshipDep" required >
                      <option *ngFor="let item of statusList" [value]="item.statusId"
                      >{{item.description}}</option>
                    </select>
                  </div>
                  <div class="form-group w-min-content-20">
                    <label>Filtrar por Poliza</label>
                    <div class="search-container d-between w-100">
                      <select class="form-select ammiaAdmin-style  mb-3" aria-label=" example" [(ngModel)]="dataFilterReceipt.policyId"
                              (change)="invokeFunctionLoadChangeFilter()" name="relationshipDep" required >
                        <option *ngFor="let item of policyList" [value]="item.policyId"
                        >{{item.number}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <a href="javascript:;">
                  <button  class="btn secundario ms-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#addContacto"
                           (click)="invokeServiceForDownloadFileReceipt()">
                    <img src="../../assets/img/ammia/iconos/download-azul.svg" alt="Ícono de Agregar tarea" class="icono-sm">
                    Descargar Reporte
                  </button>
                </a>
              </div>
              <div class="col-12  table-container-cobranzas"
                   infinite-scroll
                   [infiniteScrollDistance]="2"
                   [infiniteScrollThrottle]="50"
                   [scrollWindow]="false"
                   (scrolled)="onScrollReceipts()">
                <table class="table table-borderless h-in table-fixed-header ">
                  <thead>
                  <tr class="">
                    <th scope="col"><h4>Seguro</h4></th>
                    <th scope="col"><h4>Aseguradora</h4></th>
                    <th scope="col"><h4>Estatus</h4></th>
                    <th scope="col"><h4>Forma de pago</h4></th>
                    <th scope="col"><h4>Fecha de pago</h4></th>
                    <th scope="col"><h4>Prima Neta</h4></th>
                    <th scope="col"><h4>Prima Total</h4></th>
                    <th scope="col"><h4>IVA</h4></th>
                    <th scope="col"><h4>Derechos</h4></th>
                    <th scope="col"><h4>Recargos</h4></th>
                    <th scope="col"><h4></h4></th>
                  </tr>
                  </thead>
                  <tbody class="">
                  <tr class="asegurados-beneficios-card" *ngFor="let r of receiptList">

                    <td>
                      <div class="d-column-start ">
                        <a href="javascript:;">
                          <h5>{{r.typePolicy | titlecase}}</h5>
                          <h6>No. de póliza: <br> {{ r.numberPolicy }}</h6>
                        </a>
                      </div>
                    </td>
                    <td>
                      <p>{{ r.insuranceName | titlecase }}</p>
                    </td>
                    <td>
                      <div  [ngClass]="r.status == 'PAGADO' ? 'tag pago' : r.status == 'PENDIENTE' ? 'tag yellow' : 'tag red' "
                            data-bs-toggle="tooltip" data-bs-placement="top" title="Pago {{r.status | lowercase}}">
                        {{r.status | lowercase }}
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="r.status == 'PAGADO' " >
                          <path
                            d="M2.33333 3.08301C2.33333 3.81842 1.73542 4.41634 1 4.41634V7.74968C1.73542 7.74968 2.33333 8.34759 2.33333 9.08301H9.66667C9.66667 8.34759 10.2646 7.74968 11 7.74968V4.41634C10.2646 4.41634 9.66667 3.81842 9.66667 3.08301H2.33333ZM0 3.41634C0 2.68092 0.597917 2.08301 1.33333 2.08301H10.6667C11.4021 2.08301 12 2.68092 12 3.41634V8.74968C12 9.48509 11.4021 10.083 10.6667 10.083H1.33333C0.597917 10.083 0 9.48509 0 8.74968V3.41634ZM3.66667 6.08301C3.66667 5.77659 3.72702 5.47317 3.84428 5.19008C3.96154 4.90699 4.13341 4.64976 4.35008 4.43309C4.56675 4.21642 4.82398 4.04455 5.10707 3.92729C5.39017 3.81003 5.69358 3.74967 6 3.74967C6.30642 3.74967 6.60983 3.81003 6.89293 3.92729C7.17602 4.04455 7.43325 4.21642 7.64992 4.43309C7.86659 4.64976 8.03846 4.90699 8.15572 5.19008C8.27298 5.47317 8.33333 5.77659 8.33333 6.08301C8.33333 6.38943 8.27298 6.69284 8.15572 6.97594C8.03846 7.25903 7.86659 7.51625 7.64992 7.73292C7.43325 7.94959 7.17602 8.12147 6.89293 8.23873C6.60983 8.35599 6.30642 8.41634 6 8.41634C5.69358 8.41634 5.39017 8.35599 5.10707 8.23873C4.82398 8.12147 4.56675 7.94959 4.35008 7.73292C4.13341 7.51625 3.96154 7.25903 3.84428 6.97594C3.72702 6.69284 3.66667 6.38943 3.66667 6.08301ZM5.33333 5.08301C5.33333 5.26634 5.48333 5.41634 5.66667 5.41634V6.74968H5.5C5.31667 6.74968 5.16667 6.89968 5.16667 7.08301C5.16667 7.26634 5.31667 7.41634 5.5 7.41634H6H6.5C6.68333 7.41634 6.83333 7.26634 6.83333 7.08301C6.83333 6.89968 6.68333 6.74968 6.5 6.74968H6.33333V5.08301C6.33333 4.89967 6.18333 4.74967 6 4.74967H5.66667C5.48333 4.74967 5.33333 4.89967 5.33333 5.08301Z" />
                        </svg>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="r.status == 'PENDIENTE' " >
                          <path
                            d="M6 0C4.52275 0.00105318 3.09806 0.548311 2 1.5365V0.5C2 0.367392 1.94732 0.240215 1.85355 0.146447C1.75978 0.0526784 1.63261 0 1.5 0C1.36739 0 1.24021 0.0526784 1.14645 0.146447C1.05268 0.240215 1 0.367392 1 0.5V2C1 2.39782 1.15804 2.77935 1.43934 3.06066C1.72064 3.34196 2.10217 3.5 2.5 3.5H4C4.13261 3.5 4.25978 3.44732 4.35355 3.35355C4.44732 3.25978 4.5 3.13261 4.5 3C4.5 2.86739 4.44732 2.74021 4.35355 2.64645C4.25978 2.55268 4.13261 2.5 4 2.5H2.5C2.48314 2.4975 2.46644 2.49399 2.45 2.4895C3.26476 1.66891 4.33906 1.15654 5.48953 1.03983C6.64 0.923127 7.79531 1.20933 8.75826 1.84958C9.72121 2.48983 10.4321 3.44445 10.7696 4.55047C11.1071 5.6565 11.0502 6.84538 10.6088 7.91417C10.1673 8.98296 9.36861 9.8654 8.34897 10.4109C7.32934 10.9563 6.15199 11.131 5.01791 10.9051C3.88382 10.6792 2.86329 10.0667 2.13053 9.17209C1.39776 8.27753 0.998185 7.15637 1 6C1 5.86739 0.947321 5.74021 0.853553 5.64645C0.759785 5.55268 0.632608 5.5 0.5 5.5C0.367392 5.5 0.240215 5.55268 0.146447 5.64645C0.0526784 5.74021 0 5.86739 0 6C0 7.18669 0.351894 8.34672 1.01118 9.33342C1.67047 10.3201 2.60754 11.0891 3.7039 11.5433C4.80025 11.9974 6.00665 12.1162 7.17054 11.8847C8.33443 11.6532 9.40352 11.0818 10.2426 10.2426C11.0818 9.40352 11.6532 8.33443 11.8847 7.17054C12.1162 6.00665 11.9974 4.80025 11.5433 3.7039C11.0891 2.60754 10.3201 1.67047 9.33342 1.01118C8.34672 0.351894 7.18669 0 6 0Z" />
                          <path
                            d="M6.00017 3C5.86756 3 5.74038 3.05268 5.64662 3.14645C5.55285 3.24021 5.50017 3.36739 5.50017 3.5V6C5.5002 6.1326 5.55289 6.25975 5.64667 6.3535L7.14667 7.8535C7.24097 7.94458 7.36727 7.99497 7.49837 7.99383C7.62947 7.9927 7.75487 7.94011 7.84758 7.84741C7.94028 7.7547 7.99287 7.6293 7.99401 7.4982C7.99514 7.3671 7.94475 7.2408 7.85367 7.1465L6.50017 5.793V3.5C6.50017 3.36739 6.44749 3.24021 6.35372 3.14645C6.25995 3.05268 6.13278 3 6.00017 3Z" />

                        </svg>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="r.status == 'CANCELADO'">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6 1.15254C3.32282 1.15254 1.15254 3.32282 1.15254 6C1.15254 8.67718 3.32282 10.8475 6 10.8475C8.67718 10.8475 10.8475 8.67718 10.8475 6C10.8475 3.32282 8.67718 1.15254 6 1.15254ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0346 3.9654C8.25965 4.19044 8.25965 4.55532 8.0346 4.78037L4.78037 8.0346C4.55532 8.25965 4.19044 8.25965 3.9654 8.0346C3.74035 7.80956 3.74035 7.44468 3.9654 7.21963L7.21963 3.9654C7.44468 3.74035 7.80956 3.74035 8.0346 3.9654Z" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9654 3.9654C4.19044 3.74035 4.55532 3.74035 4.78037 3.9654L8.0346 7.21963C8.25965 7.44468 8.25965 7.80956 8.0346 8.0346C7.80956 8.25965 7.44468 8.25965 7.21963 8.0346L3.9654 4.78037C3.74035 4.55532 3.74035 4.19044 3.9654 3.9654Z" />
                        </svg>

                      </div>
                    </td>
                    <td>
                      <p class="text-center">{{r.payment | lowercase }}</p>
                    </td>
                    <td>
                      <p> {{r.paymentDate }}</p>
                    </td>
                    <td>
                      <p><b>{{ r.netPremium | currency: '$' }}</b></p>
                    </td>
                    <td>
                      <p>{{ r.totalPremium | currency: '$' }}</p>
                    </td>
                    <td>
                      <p>{{ r.iva | currency: '$' }}</p>
                    </td>
                    <td>
                      <p>{{r.rigths === null ? 0 : r.rigths | currency: '$' }}</p>
                    </td>
                    <td>
                      <p> {{ r.charges === null ? 0 : r.charges | currency: '$' }}</p>
                    </td>
                    <td>
                      <button type="button" class="enlace" (click)="invokeServiceForDownloadReceiptAttachmentZipFile(r)">
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.7 9.6C15.4878 9.6 15.2843 9.68429 15.1343 9.83432C14.9843 9.98434 14.9 10.1878 14.9 10.4V13.6C14.9 13.8122 14.8157 14.0157 14.6657 14.1657C14.5157 14.3157 14.3122 14.4 14.1 14.4H2.9C2.68783 14.4 2.48434 14.3157 2.33431 14.1657C2.18429 14.0157 2.1 13.8122 2.1 13.6V10.4C2.1 10.1878 2.01571 9.98434 1.86569 9.83432C1.71566 9.68429 1.51217 9.6 1.3 9.6C1.08783 9.6 0.884344 9.68429 0.734315 9.83432C0.584285 9.98434 0.5 10.1878 0.5 10.4V13.6C0.5 14.2365 0.752856 14.847 1.20294 15.2971C1.65303 15.7471 2.26348 16 2.9 16H14.1C14.7365 16 15.347 15.7471 15.7971 15.2971C16.2471 14.847 16.5 14.2365 16.5 13.6V10.4C16.5 10.1878 16.4157 9.98434 16.2657 9.83432C16.1157 9.68429 15.9122 9.6 15.7 9.6ZM7.932 10.968C8.00808 11.0408 8.0978 11.0979 8.196 11.136C8.29176 11.1783 8.3953 11.2002 8.5 11.2002C8.6047 11.2002 8.70824 11.1783 8.804 11.136C8.9022 11.0979 8.99192 11.0408 9.068 10.968L12.268 7.768C12.4186 7.61736 12.5033 7.41304 12.5033 7.2C12.5033 6.98696 12.4186 6.78264 12.268 6.632C12.1174 6.48136 11.913 6.39673 11.7 6.39673C11.487 6.39673 11.2826 6.48136 11.132 6.632L9.3 8.472V0.8C9.3 0.587827 9.21572 0.384344 9.06569 0.234315C8.91566 0.0842855 8.71217 0 8.5 0C8.28783 0 8.08434 0.0842855 7.93431 0.234315C7.78429 0.384344 7.7 0.587827 7.7 0.8V8.472L5.868 6.632C5.79341 6.55741 5.70486 6.49824 5.6074 6.45787C5.50994 6.4175 5.40549 6.39673 5.3 6.39673C5.19451 6.39673 5.09006 6.4175 4.9926 6.45787C4.89514 6.49824 4.80659 6.55741 4.732 6.632C4.65741 6.70659 4.59824 6.79514 4.55787 6.8926C4.5175 6.99006 4.49673 7.09451 4.49673 7.2C4.49673 7.30549 4.5175 7.40994 4.55787 7.5074C4.59824 7.60486 4.65741 7.69341 4.732 7.768L7.932 10.968Z" />
                        </svg>
                        Descargar
                      </button>
                    </td>

                  </tr>
                  </tbody>
                </table>
              </div>



            </div>
          </div>
          <!-- Tabla DAÑOS -->
          <div class="tab-pane fade " id="cobranzaDanos" role="tabpanel" aria-labelledby="pills-home-tab2">
            <div class="row cobranzas-contenedor">
              <div class="col-12 d-between py-2 mb-2">
                <div class="d-between-end gap-16 mb-2">
                  <div class="form-group w-min-content-20">
                    <label>Filtrar por estatus</label>
                    <select class="form-select ammiaAdmin-style  mb-3" aria-label=" example" [(ngModel)]="dataFilterReceipt.statusId"
                            (change)="invokeFunctionLoadChangeFilter()" name="relationshipDep" required >
                      <option *ngFor="let item of statusList" [value]="item.statusId"
                      >{{item.description}}</option>
                    </select>
                  </div>
                  <div class="form-group w-min-content-20">
                    <label>Filtrar por Poliza</label>
                    <div class="search-container d-between w-100">
                      <select class="form-select ammiaAdmin-style  mb-3" aria-label=" example" [(ngModel)]="dataFilterReceipt.policyId"
                              (change)="invokeFunctionLoadChangeFilter()" name="relationshipDep" required >
                        <option *ngFor="let item of policyList" [value]="item.policyId"
                        >{{item.number}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <a href="javascript:;">
                  <button  class="btn secundario ms-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#addContacto"
                           (click)="invokeServiceForDownloadFileReceipt()">
                    <img src="../../assets/img/ammia/iconos/download-azul.svg" alt="Ícono de Agregar tarea" class="icono-sm">
                    Descargar Reporte
                  </button>
                </a>
              </div>
              <div class="col-12  table-container-cobranzas"
                   infinite-scroll
                   [infiniteScrollDistance]="2"
                   [infiniteScrollThrottle]="50"
                   [scrollWindow]="false"
                   (scrolled)="onScrollReceipts()">
                <table class="table table-borderless h-in table-fixed-header ">
                  <thead>
                  <tr class="">
                    <th scope="col"><h4>Seguro</h4></th>
                    <th scope="col"><h4>Aseguradora</h4></th>
                    <th scope="col"><h4>Estatus</h4></th>
                    <th scope="col"><h4>Forma de pago</h4></th>
                    <th scope="col"><h4>Fecha de pago</h4></th>
                    <th scope="col"><h4>Prima Neta</h4></th>
                    <th scope="col"><h4>Prima Total</h4></th>
                    <th scope="col"><h4>IVA</h4></th>
                    <th scope="col"><h4>Derechos</h4></th>
                    <th scope="col"><h4>Recargos</h4></th>
                    <th scope="col"><h4></h4></th>
                  </tr>
                  </thead>
                  <tbody class="">
                  <tr class="asegurados-beneficios-card" *ngFor="let r of receiptList">

                    <td>
                      <div class="d-column-start ">
                        <a href="javascript:;">
                          <h5>{{r.typePolicy | titlecase}}</h5>
                          <h6>No. de póliza: <br> {{ r.numberPolicy }}</h6>
                        </a>
                      </div>
                    </td>
                    <td>
                      <p>{{ r.insuranceName | titlecase }}</p>
                    </td>
                    <td>
                      <div  [ngClass]="r.status == 'PAGADO' ? 'tag pago' : r.status == 'PENDIENTE' ? 'tag yellow' : 'tag red' "
                            data-bs-toggle="tooltip" data-bs-placement="top" title="Pago {{r.status | lowercase}}">
                        {{r.status | lowercase }}
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="r.status == 'PAGADO' " >
                          <path
                            d="M2.33333 3.08301C2.33333 3.81842 1.73542 4.41634 1 4.41634V7.74968C1.73542 7.74968 2.33333 8.34759 2.33333 9.08301H9.66667C9.66667 8.34759 10.2646 7.74968 11 7.74968V4.41634C10.2646 4.41634 9.66667 3.81842 9.66667 3.08301H2.33333ZM0 3.41634C0 2.68092 0.597917 2.08301 1.33333 2.08301H10.6667C11.4021 2.08301 12 2.68092 12 3.41634V8.74968C12 9.48509 11.4021 10.083 10.6667 10.083H1.33333C0.597917 10.083 0 9.48509 0 8.74968V3.41634ZM3.66667 6.08301C3.66667 5.77659 3.72702 5.47317 3.84428 5.19008C3.96154 4.90699 4.13341 4.64976 4.35008 4.43309C4.56675 4.21642 4.82398 4.04455 5.10707 3.92729C5.39017 3.81003 5.69358 3.74967 6 3.74967C6.30642 3.74967 6.60983 3.81003 6.89293 3.92729C7.17602 4.04455 7.43325 4.21642 7.64992 4.43309C7.86659 4.64976 8.03846 4.90699 8.15572 5.19008C8.27298 5.47317 8.33333 5.77659 8.33333 6.08301C8.33333 6.38943 8.27298 6.69284 8.15572 6.97594C8.03846 7.25903 7.86659 7.51625 7.64992 7.73292C7.43325 7.94959 7.17602 8.12147 6.89293 8.23873C6.60983 8.35599 6.30642 8.41634 6 8.41634C5.69358 8.41634 5.39017 8.35599 5.10707 8.23873C4.82398 8.12147 4.56675 7.94959 4.35008 7.73292C4.13341 7.51625 3.96154 7.25903 3.84428 6.97594C3.72702 6.69284 3.66667 6.38943 3.66667 6.08301ZM5.33333 5.08301C5.33333 5.26634 5.48333 5.41634 5.66667 5.41634V6.74968H5.5C5.31667 6.74968 5.16667 6.89968 5.16667 7.08301C5.16667 7.26634 5.31667 7.41634 5.5 7.41634H6H6.5C6.68333 7.41634 6.83333 7.26634 6.83333 7.08301C6.83333 6.89968 6.68333 6.74968 6.5 6.74968H6.33333V5.08301C6.33333 4.89967 6.18333 4.74967 6 4.74967H5.66667C5.48333 4.74967 5.33333 4.89967 5.33333 5.08301Z" />
                        </svg>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="r.status == 'PENDIENTE' " >
                          <path
                            d="M6 0C4.52275 0.00105318 3.09806 0.548311 2 1.5365V0.5C2 0.367392 1.94732 0.240215 1.85355 0.146447C1.75978 0.0526784 1.63261 0 1.5 0C1.36739 0 1.24021 0.0526784 1.14645 0.146447C1.05268 0.240215 1 0.367392 1 0.5V2C1 2.39782 1.15804 2.77935 1.43934 3.06066C1.72064 3.34196 2.10217 3.5 2.5 3.5H4C4.13261 3.5 4.25978 3.44732 4.35355 3.35355C4.44732 3.25978 4.5 3.13261 4.5 3C4.5 2.86739 4.44732 2.74021 4.35355 2.64645C4.25978 2.55268 4.13261 2.5 4 2.5H2.5C2.48314 2.4975 2.46644 2.49399 2.45 2.4895C3.26476 1.66891 4.33906 1.15654 5.48953 1.03983C6.64 0.923127 7.79531 1.20933 8.75826 1.84958C9.72121 2.48983 10.4321 3.44445 10.7696 4.55047C11.1071 5.6565 11.0502 6.84538 10.6088 7.91417C10.1673 8.98296 9.36861 9.8654 8.34897 10.4109C7.32934 10.9563 6.15199 11.131 5.01791 10.9051C3.88382 10.6792 2.86329 10.0667 2.13053 9.17209C1.39776 8.27753 0.998185 7.15637 1 6C1 5.86739 0.947321 5.74021 0.853553 5.64645C0.759785 5.55268 0.632608 5.5 0.5 5.5C0.367392 5.5 0.240215 5.55268 0.146447 5.64645C0.0526784 5.74021 0 5.86739 0 6C0 7.18669 0.351894 8.34672 1.01118 9.33342C1.67047 10.3201 2.60754 11.0891 3.7039 11.5433C4.80025 11.9974 6.00665 12.1162 7.17054 11.8847C8.33443 11.6532 9.40352 11.0818 10.2426 10.2426C11.0818 9.40352 11.6532 8.33443 11.8847 7.17054C12.1162 6.00665 11.9974 4.80025 11.5433 3.7039C11.0891 2.60754 10.3201 1.67047 9.33342 1.01118C8.34672 0.351894 7.18669 0 6 0Z" />
                          <path
                            d="M6.00017 3C5.86756 3 5.74038 3.05268 5.64662 3.14645C5.55285 3.24021 5.50017 3.36739 5.50017 3.5V6C5.5002 6.1326 5.55289 6.25975 5.64667 6.3535L7.14667 7.8535C7.24097 7.94458 7.36727 7.99497 7.49837 7.99383C7.62947 7.9927 7.75487 7.94011 7.84758 7.84741C7.94028 7.7547 7.99287 7.6293 7.99401 7.4982C7.99514 7.3671 7.94475 7.2408 7.85367 7.1465L6.50017 5.793V3.5C6.50017 3.36739 6.44749 3.24021 6.35372 3.14645C6.25995 3.05268 6.13278 3 6.00017 3Z" />

                        </svg>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="r.status == 'CANCELADO'">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6 1.15254C3.32282 1.15254 1.15254 3.32282 1.15254 6C1.15254 8.67718 3.32282 10.8475 6 10.8475C8.67718 10.8475 10.8475 8.67718 10.8475 6C10.8475 3.32282 8.67718 1.15254 6 1.15254ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0346 3.9654C8.25965 4.19044 8.25965 4.55532 8.0346 4.78037L4.78037 8.0346C4.55532 8.25965 4.19044 8.25965 3.9654 8.0346C3.74035 7.80956 3.74035 7.44468 3.9654 7.21963L7.21963 3.9654C7.44468 3.74035 7.80956 3.74035 8.0346 3.9654Z" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9654 3.9654C4.19044 3.74035 4.55532 3.74035 4.78037 3.9654L8.0346 7.21963C8.25965 7.44468 8.25965 7.80956 8.0346 8.0346C7.80956 8.25965 7.44468 8.25965 7.21963 8.0346L3.9654 4.78037C3.74035 4.55532 3.74035 4.19044 3.9654 3.9654Z" />
                        </svg>

                      </div>
                    </td>
                    <td>
                      <p class="text-center">{{r.payment | lowercase }}</p>
                    </td>
                    <td>
                      <p> {{r.paymentDate }}</p>
                    </td>
                    <td>
                      <p><b>{{ r.netPremium | currency: '$' }}</b></p>
                    </td>
                    <td>
                      <p>{{ r.totalPremium | currency: '$' }}</p>
                    </td>
                    <td>
                      <p>{{ r.iva | currency: '$' }}</p>
                    </td>
                    <td>
                      <p>{{r.rigths === null ? 0 : r.rigths | currency: '$' }}</p>
                    </td>
                    <td>
                      <p> {{ r.charges === null ? 0 : r.charges | currency: '$' }}</p>
                    </td>
                    <td>
                      <button type="button"  class="enlace" (click)="invokeServiceForDownloadReceiptAttachmentZipFile(r)">
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.7 9.6C15.4878 9.6 15.2843 9.68429 15.1343 9.83432C14.9843 9.98434 14.9 10.1878 14.9 10.4V13.6C14.9 13.8122 14.8157 14.0157 14.6657 14.1657C14.5157 14.3157 14.3122 14.4 14.1 14.4H2.9C2.68783 14.4 2.48434 14.3157 2.33431 14.1657C2.18429 14.0157 2.1 13.8122 2.1 13.6V10.4C2.1 10.1878 2.01571 9.98434 1.86569 9.83432C1.71566 9.68429 1.51217 9.6 1.3 9.6C1.08783 9.6 0.884344 9.68429 0.734315 9.83432C0.584285 9.98434 0.5 10.1878 0.5 10.4V13.6C0.5 14.2365 0.752856 14.847 1.20294 15.2971C1.65303 15.7471 2.26348 16 2.9 16H14.1C14.7365 16 15.347 15.7471 15.7971 15.2971C16.2471 14.847 16.5 14.2365 16.5 13.6V10.4C16.5 10.1878 16.4157 9.98434 16.2657 9.83432C16.1157 9.68429 15.9122 9.6 15.7 9.6ZM7.932 10.968C8.00808 11.0408 8.0978 11.0979 8.196 11.136C8.29176 11.1783 8.3953 11.2002 8.5 11.2002C8.6047 11.2002 8.70824 11.1783 8.804 11.136C8.9022 11.0979 8.99192 11.0408 9.068 10.968L12.268 7.768C12.4186 7.61736 12.5033 7.41304 12.5033 7.2C12.5033 6.98696 12.4186 6.78264 12.268 6.632C12.1174 6.48136 11.913 6.39673 11.7 6.39673C11.487 6.39673 11.2826 6.48136 11.132 6.632L9.3 8.472V0.8C9.3 0.587827 9.21572 0.384344 9.06569 0.234315C8.91566 0.0842855 8.71217 0 8.5 0C8.28783 0 8.08434 0.0842855 7.93431 0.234315C7.78429 0.384344 7.7 0.587827 7.7 0.8V8.472L5.868 6.632C5.79341 6.55741 5.70486 6.49824 5.6074 6.45787C5.50994 6.4175 5.40549 6.39673 5.3 6.39673C5.19451 6.39673 5.09006 6.4175 4.9926 6.45787C4.89514 6.49824 4.80659 6.55741 4.732 6.632C4.65741 6.70659 4.59824 6.79514 4.55787 6.8926C4.5175 6.99006 4.49673 7.09451 4.49673 7.2C4.49673 7.30549 4.5175 7.40994 4.55787 7.5074C4.59824 7.60486 4.65741 7.69341 4.732 7.768L7.932 10.968Z" />
                        </svg>
                        Descargar
                      </button>
                    </td>

                  </tr>
                  </tbody>
                </table>
              </div>



            </div>
          </div>
        </div>
      </div>
    </div>

  </main>
  <!--TERMINA CONTAINER GENERAL -->

</div>
