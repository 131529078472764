import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {TypeaheadContainerComponent, TypeaheadDirective, TypeaheadMatch} from "ngx-bootstrap/typeahead";

@Component({
  selector: 'app-dashboard-cars',
  templateUrl: './dashboard-cars.component.html',
  styleUrls: ['./dashboard-cars.component.css']
})
export class DashboardCarsComponent implements OnInit {

  dataFilter: any = {};
  carList: any = [];
  totalCarsRecords: number;
  carPage = 1;
  policyDetail: any = {};
  car: any = {};
  attachmentList: any = [];
  incidentDetail: any = {};
  // tslint:disable-next-line:variable-name
  _branchId: number;
  modal: number;
  email: string;
  myForm: FormGroup;
  requestDetail: any = {};
  // Variables para typeahead (búsqueda de autos) ->
  searchItemSelected: string;
  searchFilter: string;
  typeaheadLoading: boolean;
  dataSource: Observable<any>;
  carSelected: any = {};
  // tslint:disable-next-line:variable-name
  @ViewChild('typeaheadDirective') _typeahead: TypeaheadDirective;
  public get container(): TypeaheadContainerComponent {
    return this._typeahead._container;
  }
  constructor(private router: Router,
              private api: ApiService,
              public shared: SharedService,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public fb: FormBuilder) {
    this.myForm = this.fb.group({
      email: ['', [Validators.required]]
    });

    /**
     * Observable para monitorear los cambios en typeahead de Autos
     */
    this.dataSource = new Observable((observer: any) => {
      // Runs on every search
      observer.next({
        token: this.searchItemSelected,
        filter: this.searchFilter
      });
    }).pipe(
      mergeMap((data: { token: string, filter: string }) => this.getCarAsObservable(data))
    );
  }

  ngOnInit(): void {
    this.modal = 0;
    this._branchId = 3;
    this.invokeFunctionInitFilter();
    this.invokeServiceForGetCarsList();
    this.searchFilter = 'todos';
  }

  /**
   * Función para recuperar las coincidencias de la búsqueda en el typeahead de 'Autos'
   *
   * 1.- Invocamos el servicio REST @getFinderCar y retornamos la respuesta
   * @param token {} Filtro para realizar la búsqueda
   */
  getCarAsObservable(token: {}): Observable<any> {
    return this.api.getFinderALLCar(token).pipe();
  }
  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }
  typeaheadOnSelect(e: TypeaheadMatch): void {
    console.log(e.item);
    this.carSelected = e.item;
    this.dataFilter.search = this.carSelected.carId;
    this.carList = [];
    this.invokeServiceForGetCarsList();
  }
  onInputChange(event: any) {
    const inputValue = event.target.value;
    console.log(event.target.value);
    if (inputValue === '') {
      this.dataFilter.search = '0';
      this.carSelected = {};
      this.carPage = 1;
      this.carList = [];
      this.invokeServiceForGetCarsList();
    }
  }

  public invokeServiceForDownloadFileVehicles() {
    this.api.getFileVehicle()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  'Vehiculos.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForGetCarsList() {
    console.log('autos lista');
    console.log(this.dataFilter);
    this.api.getCarsAmmia(this.dataFilter)
      .then(
        (response: any) => {
          if (response.total > 0) {
            this.totalCarsRecords = response.total;
            this.carList = this.carList.concat(response.carList);
          }
        }, error => {
          console.error(error);
        });
  }
  invokeFunctionInitFilter() {
    this.dataFilter = {
      search: '0',
      page: 1,
      size: 5
    };
  }

  onScrollCar() {
    console.log('scroll');
    if (this.totalCarsRecords === this.carList.length) {
      return;
    }
    this.carPage++;
    this.dataFilter.page = this.carPage;
    this.invokeServiceForGetCarsList();
  }

  invokeServiceGetPolicyDetailByCarPolicy(c: any) {
    this.car = c;
    const dataObjet = {
      policyId: c.idPolicy,
      carId: c.carId
    };
    this.api.getPolicyDetailAmmiaCar(dataObjet)
      .subscribe(
        response => {
          this.policyDetail = response;
          console.log(this.policyDetail);
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForDownloadPolicyAttachmentZipFile(policy) {
    this.api.getPolicyAttachmentZip(policy.policyId)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  `anexos_poliza_${policy.number}.zip`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForDownloadPolicyAttachmentFile(attachment) {
    this.api.getPolicyAttachmentFile(attachment.attachmentId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          console.error(error);
        }
      );
  }


  invokeFunctionDetailIncident(incident: any) {
    this.invokeServiceGetDetailIncident(incident);
    this.invokeServiceGetAttachmentsByIncident(incident.incidentId);
    this.modal = 2;
  }

  invokeServiceGetDetailIncident(incident: any) {
    console.log(incident);
    const objectData = {
      incidentId: incident.incidentId
    };
    this.api.getIncidentDetailById(objectData)
      .then(
        (response: any) => {
          if (response != null) {
            this.incidentDetail = response;
          }
        }, error => {
          console.info(error);
        });
  }

  invokeServiceGetAttachmentsByIncident(incident: number) {
    const objectData = {
      incidentId: incident
    };
    this.api.getAttachmentsByIncident(objectData)
      .then(
        (response: any) => {
          if (response != null) {
            this.attachmentList = response;
          }
        }, error => {
          console.info(error);
        });
  }

  invokeFunctionInsuredFilter() {
    this.dataFilter.page = 1;
    this.carList = [];
    this.invokeServiceForGetCarsList();
  }

  public invokeServiceForDownloadCertificateFile() {
    const data = {
      certificateId: this.policyDetail.certificateId
    };
    this.api.getCertificateById(data)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', this.policyDetail.number + '.pdf');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          this.toastr.error('Problemas para procesar la petición, comuniquese con el administrador', 'Notificación');
          console.error(error);
        }
      );
  }
  invokeFunctionModal(modal: number) {
    this.modal = modal;
  }

  invokeFunctionSendEmail() {
    if (this.email === '' || this.email === undefined) {
      this.toastr.warning('Debe de colocar un email para enviar el certificado', 'Notificación');
      return;
    }
    const dataObjet = {
      certificateId: this.policyDetail.certificateId,
      email: this.email
    };
    this.api.sharingCertificate(dataObjet)
      .subscribe(
        response => {
          this.toastr.success('Su certificado se compartio de manera correcta.' , ' Notificación');
          this.modal = 0;
        }, error => {
          console.error(error);
        }
      );
  }

  invokeFunctionOpenDetailRequest(r: any) {
    this.invokeFunctionGetDetailRequest(r);
    this.modal = 3;
  }
  invokeFunctionGetDetailRequest(request: any) {
    const objectData = {
      requestId: request.requestId,
      requestDetailId: request.requestDetailId
    };
    this.api.getRequestDetailById(objectData)
      .then(
        (response: any) => {
          if (response != null) {
            this.requestDetail = response[0];
          }
        }, error => {
          console.info(error);
        });
  }
}
