import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dashboard-damages',
  templateUrl: './dashboard-damages.component.html',
  styleUrls: ['./dashboard-damages.component.css']
})
export class DashboardDamagesComponent implements OnInit {

  policyAmmiaList: any = [];
  policyAmmia: boolean;
  policyInitId: number;
  p: any = {};
  indexPolicy: number;
  subgroupList: any = []; // almacena la lista de Subgrupos, tendra una propiedad 'categoryList' para almacenar la lista de categorías
  subgroupId: number;
  categoryId: number;
  policyCoverageList: any = [];
  categoryList: any = [];
  constructor(private router: Router,
              private api: ApiService,
              public shared: SharedService,
              private toastr: ToastrService,
              private modalService: BsModalService) { }

  ngOnInit(): void {
    this.invokeServiceGetPoliciesDamages();
  }
  invokeServiceGetPoliciesDamages() {
    this.api.getPoliciesAmmiaDashboardDamages()
      .then(
        (response: any) => {
          if (response != null) {
            this.policyAmmiaList = response;
            this.policyInitId = this.policyAmmiaList[0].policyId;
            this.policyAmmia = true;
            this.invokeServiceGetPolicyDetail(this.policyInitId);
          }
        }, error => {
          console.info(error);
        });
  }
  invokeServiceGetPolicyDetail(id: number) {
    this.policyInitId = id;
    console.log(id);
    const dataPolicy = {
      policyId: id
    };
    this.api.getPoliciesAmmiaDashboardBenefitsDetail(dataPolicy)
      .then(
        (response: any) => {
          if (response != null) {
            this.p = response[0];
          }
        }, error => {
          console.info(error);
        });
  }

  public invokeServiceForDownloadFilePolicy() {
    const objData = {
      policyId: this.p.policyId
    };
    this.api.getFileCsvPolicy(objData)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  this.p.number + '.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForDownloadPolicyFile(policy) {
    this.api.getPolicyFile(policy.policyId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', policy.number + '.pdf');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }
  invokeServiceForDownloadPolicyAttachmentZipFile(policy) {
    this.api.getPolicyAttachmentZip(policy.policyId)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  `anexos_poliza_${policy.number}.zip`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForDownloadPolicyAttachmentFile(attachment) {
    this.api.getPolicyAttachmentFile(attachment.attachmentId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          console.error(error);
        }
      );
  }
  invokeFunctionDownloadFilePolicy() {
    this.api.getFilePolicy()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  'Polizas.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

}
