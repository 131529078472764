<div class="">
  <div class="row">
    <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
      <div class="row">
        <div class="col-xs-12 p-0">
          <p class="title-section pull-left">Estadísticos</p>
          <div class="btn-group pull-right" dropdown>
            <button style="padding: 0" id="button-chart-types" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" aria-controls="dropdown-chart-types">
              Ventanas <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-chart-types" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-chart-types">
              <li role="menuitem">
                <a class="dropdown-item" href="javascript:;" (click)="shared.fOpenSidebar(9, 'md')">Ventana 1</a>
                <a class="dropdown-item" href="javascript:;" (click)="shared.fOpenSidebar(11, 'md')">Ventana 2</a>
                <a class="dropdown-item" href="javascript:;" (click)="shared.fOpenSidebar(12, 'md')">Ventana 3</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 element-card">
          Grafica aquí
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
      <div class="row">
        <div class="col-xs-12  p-0">
          <p class="title-section">Renovaciones</p>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 element-card">
          Grafica aquí
        </div>
      </div>
    </div>

    <div class="col-lg-2 col-md-3 col-sm-3 col-xs-12">
      <div class="row">
        <div class="col-xs-12  p-0">
          <p class="title-section pull-left">Cobranza</p>
          <div class="btn-group pull-right" dropdown>
            <button style="padding: 0" id="button-collection-filter" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" aria-controls="dropdown-collection-filter">
              Opcion1 <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-collection-filter" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-collection-filter">
              <li role="menuitem">
                <a class="dropdown-item" href="javascript:;">Opcion1</a>
                <a class="dropdown-item" href="javascript:;">Opcion2</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12  element-card">
          Grafica aquí
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-40">
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
      <div class="row">
        <div class="col-xs-12  p-0">
          <p class="title-section pull-left">UDI</p>
          <div class="btn-group pull-right" dropdown>
            <button style="padding: 0" id="button-udi-filter" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" aria-controls="dropdown-udi-filter">
              Opcion1 <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-udi-filter" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-udi-filter">
              <li role="menuitem">
                <a class="dropdown-item" href="javascript:;">Opcion1</a>
                <a class="dropdown-item" href="javascript:;">Opcion2</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 element-card">
          Grafica aquí
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
      <div class="row">
        <div class="col-xs-12  p-0">
          <p class="title-section pull-left">Comisión</p>
          <div class="btn-group pull-right" dropdown>
            <button style="padding: 0" id="button-commission-filter" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" aria-controls="dropdown-commission-filter">
              Opcion1 <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-commission-filter" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-commission-filter">
              <li role="menuitem">
                <a class="dropdown-item" href="javascript:;">Opcion1</a>
                <a class="dropdown-item" href="javascript:;">Opcion2</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 element-card">
          Grafica aquí
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <div class="row">
        <div class="col-xs-12  p-0">
          <p class="title-section pull-left">Siniestros</p>
          <div class="btn-group pull-right" dropdown>
            <button style="padding: 0" id="button-incident-filter" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" aria-controls="dropdown-incident-filter">
              Opcion1 <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-incident-filter" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-incident-filter">
              <li role="menuitem">
                <a class="dropdown-item" href="javascript:;">Opcion1</a>
                <a class="dropdown-item" href="javascript:;">Opcion2</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 element-card">
          Grafica aquí
        </div>
      </div>
    </div>
  </div>
</div>
