import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared-service.service';

@Component({
  selector: 'app-quote-history',
  templateUrl: './quote-history.component.html',
  styleUrls: ['./quote-history.component.css']
})
export class QuoteHistoryComponent implements OnInit {

  constructor(public shared: SharedService) { }

  ngOnInit() {
  }

}
