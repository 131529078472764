import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SharedService} from '../shared-service.service';

@Component({
  selector: 'app-incident-ammia',
  templateUrl: './incident-ammia.component.html',
  styleUrls: ['./incident-ammia.component.css']
})
export class IncidentAmmiaComponent implements OnInit {
  get branchId(): number {
    return this._branchId;
  }
  @Input()
  set branchId(value: number) {
    this._branchId = value;
  }

  // tslint:disable-next-line:variable-name
  protected _branchId: number;
  dataFilterIncident: any = {};
  requestList: any = [];
  incidentList: any = [];
  requestDetail: any = {};
  incidentDetail: any = {};
  incidentPage: number;
  totalIncidentRecords: number;
  attachmentList: any = [];
  incidentAmmia: any = {};
  policyAmmiaList: any = [];
  typeIncidentList: any = [];
  dataClaim: any = {};
  clientList: any = [];
  responseService: number; // 0 procesando 1 ok 2 error
  myFormClaim: FormGroup;
  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public shared: SharedService) {
    this.myFormClaim = this.fb.group({
      client: ['', [Validators.required]],
      typeClaim: ['', [Validators.required]],
      contact: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
        Validators.maxLength(10)]],
      subject: ['',  [Validators.required, Validators.maxLength(50)]],
      description: ['']
    });
  }

  ngOnInit(): void {
    this.incidentPage = 1;
    this.invokeFunctionInitFilters();
    this.invokeServiceGetIncidentPages();
  }
  invokeFunctionInitFilters() {
    this.dataFilterIncident = {
      page: 1,
      size: 5,
      branchId: this._branchId
    };
  }
  onScrollIncident() {
    if (this.totalIncidentRecords === this.incidentList.length) {
      return;
    }
    this.incidentPage++;
    this.invokeServiceGetIncidentPages();
  }
  invokeServiceGetIncidentPages() {
    this.dataFilterIncident.page = this.incidentPage;
    console.log(this.dataFilterIncident);
    this.api.getIncidentByBranch(this.dataFilterIncident)
      .then(
        (response: any) => {
          if (response != null) {
            this.totalIncidentRecords = response.total;
            this.incidentList = this.incidentList.concat(response.incidents);
          }
        }, error => {
          console.info(error);
        });
  }
  invokeServiceGetDetailIncident(incident: any) {
    console.log(incident);
    const objectData = {
      incidentId: incident.incidentId
    };
    this.api.getIncidentDetailById(objectData)
      .then(
        (response: any) => {
          if (response != null) {
            this.incidentDetail = response;
          }
        }, error => {
          console.info(error);
        });
  }
  invokeFunctionDetailIncident(incident: any) {
    this.invokeServiceGetDetailIncident(incident);
    this.invokeServiceGetAttachmentsByIncident(incident.incidentId);
    /*const modalShadowElement = document.querySelector('.modal-shadow-evento');
    const shadowGeneral = document.querySelector('.modal-backdrop');
    modalShadowElement.classList.add('visible');
    shadowGeneral.classList.add('no-visible');*/
  }
  invokeFunctionCloseModalIncident() {
    const modalShadowElement = document.querySelector('.modal-shadow-evento');
    modalShadowElement.classList.remove('visible');
    this.incidentDetail = [];
  }

  invokeServiceGetAttachmentsByIncident(incident: number) {
    const objectData = {
      incidentId: incident
    };
    this.api.getAttachmentsByIncident(objectData)
      .then(
        (response: any) => {
          if (response != null) {
            this.attachmentList = response;
          }
        }, error => {
          console.info(error);
        });
  }
  public invokeServiceForDownloadFileAttachment(attachment: any)  {
    const objData = {
      attachmentId: attachment.attachmentId
    };
    this.api.getAttachmentIncidentById(objData)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }
  invokeFunctionLoadNewIncident() {
    this.responseService = 0;
    this.dataClaim = {
      name: '',
      description: '',
      userId: localStorage.getItem('user-id'),
      insuranceId: 0,
      groupId: 0,
      clientId: 0,
      policyId: 0,
      typeIncidentId: 0,
      contact: '',
      folioSurexs: ''
    };
    this.invokeServiceGetPoliciesDamages();

  }
  invokeServiceGetPoliciesDamages() {
    this.api.getPoliciesAmmiaDashboardDamages()
      .then(
        (response: any) => {
          if (response != null) {
            this.policyAmmiaList = response;
          }
        }, error => {
          console.info(error);
        });
  }
  invokeServiceGetClientByPolicy(p: any) {
    this.dataClaim.policyId = p.policyId;
    this.dataClaim.insuranceId = p.insuranceId;
    this.invokeServiceGetClientsByPolicy(p.policyId);
    console.log(p);
    const data = {
      subBranchId: p.subBranchId
    };
    this.api.getTypeIncidentsBySubBranches(data)
      .then(
        (response: any) => {
          if (response != null) {
            this.typeIncidentList = response;
          }
        }, error => {
          console.info(error);
        });
  }
  invokeServiceGetClientsByPolicy(idPolicy: number) {
    const data = {
      policyId: idPolicy
    };
    this.api.getClientsByPolicy(data)
      .then(
        (response: any) => {
          if (response != null) {
            this.clientList = response;
            this.dataClaim.clientId = response[0].clientId;
          }
        }, error => {
          console.info(error);
        });
  }
  invokeFunctionSaveClaim() {
    console.log(this.dataClaim);
    this.api.saveClaimDamage(this.dataClaim)
      .then(
        (response: any) => {
          if (response != null) {
            this.responseService = 1;
            this.incidentPage = 1;
            this.invokeFunctionInitFilters();
            this.invokeServiceGetIncidentPages();
          }
        }, error => {
          this.responseService = 2;
          console.info(error);
        });
  }
}
