<!--
<div class="modal-header">
  <h4 class="modal-title pull-left">Ingresa tu correo</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="myForm" novalidate>
    <input type="email" class="form-control border-input" [(ngModel)]="email" placeholder="usuario@dominio.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
           formControlName="email">
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-custom" (click)="onCancel()" >Cancelar</button>
  <button class="btn btn-custom" (click)="sendemail(email)" [disabled]="myForm.invalid">Aceptar</button>
</div>-->
<!-- CONTAINER GENERAL -->
<main class="container-fluid container-general">


  <!--  Contenedor row index -->
  <div class="row contenedor-inicio">

    <div class=" col-12 col-lg-4">
      <div class="row inicio-sesion-contenedor inicio" >
        <!-- Inicia barra de CTA-->
        <div class="col-12 d-center">
          <img class="img-fluid" src="../../assets/img/ammia/logos/ammiaadmin-logo.svg" alt="logotipo">

        </div>
        <!-- Termina barra de CTA-->
        <div class="col-12 d-center">
          <h3>Restablece tu contraseña</h3>
        </div>
        <div>
          <p>Para restablecer tu contraseña, te enviaremos un código al email asociado a tu cuenta.</p>
        </div>
        <form [formGroup]="myForm" novalidate>
          <div class="col-12 ">
            <div class="form-row">
              <div class="form-group">
                <label for="nombreTarea">Correo electrónico</label>
                <input type="text" class="form-control ammiaAdmin-style" id="nombreTarea" name="nombreTarea"
                       [(ngModel)]="email" placeholder="usuario@dominio.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                       formControlName="email">
                <div class="invalid-feedback">
                  Por favor ingresa el correo.
                </div>
              </div>
            </div>

          </div>
        </form>
          <div class="col-8 d-column-center gap-12">
            <a class="btn primario lg mb-4" (click)="sendemail(email)">
              <img  class="icono-sm"  src="../../assets/img/ammia/iconos/lock-azul.svg" alt="checked">
              Enviar código
            </a>
          </div>

        <div class="col-10 d-center ">
          <a href="javascript:;" (click)="invokeFunctionBackLogin()">
            <button type="button" data-toggle="modal" data-target="#" href="" class="enlace gris btn-link" >
              Volver a Iniciar sesión
            </button>
          </a>
        </div>
      </div>
      <!-- Fin Segundo row: col-container tareas -->
    </div>
    <!-- Fin de la columna "tareas" -->


    <!-- Columna "resumen-funnel" -->
    <div class="d-none d-lg-flex col p-0 foto-inicio-container">
      <img class="foto-inicio" src="../../assets/img/ammia/imagenes/foto-inicio.jpg" alt="foto inicio">
    </div>
    <!-- Fin de la columna "resumen-funnel" -->

  </div>
  <!--End  Contenedor row index -->
</main>
<!--TERMINA CONTAINER GENERAL -->
