/*import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: ' [ngModel] [upperCaseText]'
  // selector: 'input[type=text]', host: { '(input)': 'ref.nativeElement.value=$event.target.value.toUpperCase()', }
})
export class UpperCaseTextDirective {
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  value: any;
  @HostListener('input', ['$event']) onInputChange($event) { this.value = $event.target.value.toUpperCase();
  this.ngModelChange.emit(this.value);

  constructor() { }

}*/

import {Directive, EventEmitter, HostListener, Output} from '@angular/core';
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ngModel] [upperCaseText]'
})

export class UpperCaseTextDirective {
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  value: any;
  @HostListener('input', ['$event'])
  onInputChange($event) {
    this.value = $event.target.value.toUpperCase();
    this.ngModelChange.emit(this.value);
  }
}
